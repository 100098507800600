import { RavenNavIconActive } from 'pages/raven/assets/RavonNavIcons';
import { FC } from 'react';

interface AgentMessageLoadingProps {}

export const AgentMessageLoading: FC<AgentMessageLoadingProps> = () => {
  return (
    <div className='custom-agent-thinking flex items-center gap-2'>
      <RavenNavIconActive size='28' className='rounded-full' />
      <p className='custom-agent-loader w-fit text-sm text-textSecondary'>
        I'm on it, please wait...
      </p>
    </div>
  );
};
