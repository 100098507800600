import { Button } from 'libraryV2/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'libraryV2/ui/dropdown-menu';

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from 'libraryV2/ui/dialog';
import { useState } from 'react';
import { UtilityContents, toast, useSelector } from '../../../../../export';
import { IUserActionFlowTableActionTypes } from 'pages/automationWorkflow/interface';
import useAutomationDashboard from '../../../hooks/useAutomationDashboard';
import useTranslation from 'components/customHooks/useTranslation';
import { Ellipsis } from 'lucide-react';
import { getRowActionIcon } from 'pages/automationWorkflow/utility/function';
import { navigate } from '@reach/router';

interface DataTableRowActionsProps {
  row: any;
}

export function DataTableRowActions({ row }: DataTableRowActionsProps) {
  const { t, isRtlLanguage } = useTranslation();
  const { selectedProject } = useSelector((state: any) => state.dashboard);
  const {
    handleDeleteWorkflowConfirmation,
    updateWorkflowPrimitiveDataAfterRename,
    cloneUserActionFlow,
    getConnectedEcommerce,
    updateSelectedUserActionFlowData,
  } = useAutomationDashboard();

  const [deleteModal, setDeleteModal] = useState(false);

  const isStoreConnected = isNaN(row?.original?.trigger_match_unique_ref)
    ? false
    : !!getConnectedEcommerce(Number(row?.original?.trigger_match_unique_ref));

  const utilityList = !!isStoreConnected
    ? UtilityContents.UserActionFlowTableActionData
    : UtilityContents.UserActionFlowTableActionData.filter(
        (data: IUserActionFlowTableActionTypes) =>
          data?.label !== UtilityContents?.UserActionFlowTableActionLabels?.EDIT
      );

  const renderCardDeleteModal = () => {
    return (
      <Dialog open={deleteModal} onOpenChange={setDeleteModal}>
        <DialogContent
          onOpenAutoFocus={(e) => e.preventDefault()}
          hideCloseButton={true}
          className='sm:max-w-[425px] bg-white'
        >
          <DialogTitle className='text-zinc-900 rtl:text-right text-lg font-semibold leading-7'>
            {t('Delete Webhook?')}
          </DialogTitle>

          <p className='text-zinc-500 text-sm rtl:text-right font-normal'>
            {t(
              'This will permanently delete the Webhook. Are you sure you want to delete?'
            )}
          </p>

          <DialogFooter className='rtl:gap-2 rtl:justify-start'>
            <DialogClose>
              <Button
                type='button'
                variant='default'
                className='bg-background-hover text-zinc-900 shadow-none hover:text-zinc-900 hover:bg-background-hover'
              >
                {t('No')}
              </Button>
            </DialogClose>

            <Button
              type='button'
              variant='destructive'
              className='bg-destructive-hover text-white text-sm font-medium'
            >
              {t('Yes! Delete')}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <Button
            variant='ghost'
            className='flex h-8 w-8 p-0 data-[state=open]:bg-muted'
          >
            <Ellipsis className='h-4 w-4' />
            <span className='sr-only'>{t('Open menu')}</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align={isRtlLanguage ? 'start' : 'end'}
          className='w-[160px] bg-white border border-zinc-200'
        >
          {utilityList.map(
            (item: IUserActionFlowTableActionTypes, index: number) => {
              return (
                <DropdownMenuItem
                  key={index}
                  className={`px-4 py-2 hover:bg-gray-50 text-sm text-gray-600 cursor-pointer my-1`}
                  onClick={() => {
                    if (
                      item?.label ===
                      UtilityContents?.UserActionFlowTableActionLabels?.History
                    ) {
                      if (!isStoreConnected) {
                        toast({
                          title: 'No Store Connected',
                          description: 'No active ecommerce is connected',
                        });
                      } else {
                        updateSelectedUserActionFlowData(row?.original);
                        navigate(
                          `/projects/${selectedProject?.id}/workflow-automation/${row?.original?.id}/history`
                        );
                      }
                    } else if (
                      item?.label ===
                      UtilityContents?.UserActionFlowTableActionLabels?.EDIT
                    ) {
                      updateSelectedUserActionFlowData(row?.original);
                      navigate(
                        `/projects/${selectedProject?.id}/workflow-automation/${row?.original?.id}`
                      );
                    } else if (
                      item?.label ===
                      UtilityContents.UserActionFlowTableActionLabels.DELETE
                    ) {
                      handleDeleteWorkflowConfirmation(row?.original);
                    } else if (
                      item?.label ===
                      UtilityContents.UserActionFlowTableActionLabels.RENAME
                    ) {
                      updateWorkflowPrimitiveDataAfterRename(row?.original);
                    } else if (
                      item?.label ===
                      UtilityContents.UserActionFlowTableActionLabels.DUPLICATE
                    ) {
                      cloneUserActionFlow(row?.original);
                    }
                  }}
                >
                  <div className='flex items-center gap-2 text-sm text-textPrimary'>
                    {getRowActionIcon(item?.label, 15)}
                    {item?.label}
                  </div>
                </DropdownMenuItem>
              );
            }
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      {renderCardDeleteModal()}
    </div>
  );
}
