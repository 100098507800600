import { ColumnDef } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { IUserFlowLogTableDataTypes } from 'pages/automationWorkflow/interface';
import { DataTableRowActions } from './DataTableRowActions';
import { toUpper } from 'lodash';

export const automationHistoryTableColumns: ColumnDef<
  IUserFlowLogTableDataTypes,
  unknown
>[] = [
  {
    id: 'triggered_date',
    accessorKey: 'triggered_time',
    header: 'Triggered Date',
    cell: ({ row }) => {
      const info: number = row.getValue('triggered_time') ?? 0;
      return (
        <span className='text-sm text-textPrimary'>
          {dayjs(info * 1000).format('MMM DD, YYYY')}
        </span>
      );
    },
  },
  {
    id: 'triggered_time',
    accessorKey: 'triggered_time',
    header: 'Triggered Time',
    cell: ({ row }) => {
      const info: number = row.getValue('triggered_time') ?? 0;
      return (
        <span className='text-sm text-textPrimary'>
          {dayjs(info * 1000).format('hh:mm A')}
        </span>
      );
    },
  },
  {
    id: 'unique_identifier_value',
    accessorKey: 'unique_identifier_value',
    header: 'Order ID',
    cell: ({ row }) => {
      const value: string = row.getValue('unique_identifier_value') ?? '';
      return <span className='text-sm text-textPrimary'>#{value}</span>;
    },
  },
  {
    id: 'unique_identifier_value',
    accessorKey: 'unique_identifier_value',
    header: 'Checkout ID',
    cell: ({ row }) => {
      const value: string = row.getValue('unique_identifier_value') ?? '';
      return <span className='text-sm text-textPrimary'>#{value}</span>;
    },
  },
  {
    id: 'status',
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      const value: string = row.getValue('status') ?? '';
      const textColor =
        value === 'completed'
          ? 'text-green-500'
          : value === 'started'
          ? 'text-amber-500'
          : 'text-red-500';
      const bgColor =
        value === 'completed'
          ? 'bg-green-500'
          : value === 'started'
          ? 'bg-amber-500'
          : 'bg-red-500';
      return (
        <div
          className={`inline-flex items-center border rounded px-2 gap-1 ${textColor}`}
        >
          <div className={`w-2 h-2 ${bgColor} rounded-full`}></div>
          <span className='text-xs'>
            {value === 'started'
              ? 'Ongoing'
              : value === 'completed'
              ? 'Successful'
              : 'Failed'}
          </span>
        </div>
      );
    },
  },
  {
    id: 'recovered_status',
    accessorKey: 'recovered_status',
    header: 'Recovered Status',
    cell: ({ row }) => {
      const value: boolean = row.getValue('recovered_status') ?? '';
      const textColor = value ? 'text-green-500' : 'text-textPrimary';
      const bgColor = value ? 'bg-green-50' : 'bg-gray-50';
      return (
        <div
          className={`inline-flex items-center border rounded px-2 ${textColor} ${bgColor}`}
        >
          <span className='text-xs'>
            {value ? 'Recovered' : 'Not Recovered'}
          </span>
        </div>
      );
    },
  },
  {
    id: 'sales',
    accessorKey: 'sales',
    header: 'Sales',
    cell: ({ row }) => {
      const value: number = row.getValue('sales') ?? '--';
      const currency: string = toUpper(row?.original?.currency) ?? '';
      return (
        <span className='text-sm text-textPrimary'>{`${currency} ${value}`}</span>
      );
    },
  },
  {
    id: 'actions',
    cell: ({ row }) => {
      return <DataTableRowActions history_id={row?.original?.history_id} />;
    },
  },
];
