import { FileText, Gem, Trash } from 'lucide-react';
import {
  DocIcon,
  DocxIcon,
  PDFIcon,
  TxTIcon,
} from 'pages/raven/assets/RavenDocumentToolIcons';
import { bytesToSize } from 'pages/settings/savedReplies/utils';
import { FC } from 'react';
import { DocumentTrainButton } from './DocumentTrainButton';
import { cn } from 'libraryV2/utils';
import { useCustomAgentTools } from 'pages/raven/hooks/useCustomAgentTools';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
interface UploadedDocumentToolsProps {
  uploadedDocumentList: any[];
  onDeleteUploadedDocument: (documentId: number) => Promise<boolean>;
  updateDocumentTrainStatus: (documentId: number) => void;
}

export const ManageUploadedDocuments: FC<UploadedDocumentToolsProps> = ({
  uploadedDocumentList = [],
  onDeleteUploadedDocument,
  updateDocumentTrainStatus,
}) => {
  const { hasEnoughToken, availableToken, usableToken } = useCustomAgentTools();

  // views
  const renderFileIcon = (fileType: string) => {
    switch (fileType) {
      case 'pdf':
        return <PDFIcon />;
      case 'txt':
        return <TxTIcon />;
      case 'doc':
        return <DocIcon />;
      case 'docx':
        return <DocxIcon />;
      default:
        return <FileText className='w-6 h-6 text-textPrimary' />;
    }
  };

  const renderSingleDocumentInfo = (
    document: typeof uploadedDocumentList[number]
  ) => {
    const shouldDisabled =
      !hasEnoughToken(document?.tokenRequired) && !document?.isTrained;
    const fileName = document?.name;
    const fileSize = bytesToSize(document?.size);

    return (
      <>
        <div
          className={cn(
            'w-[64px] flex justify-center items-center flex-grow-0 border-r border-zinc-200',
            {
              'pointer-events-none opacity-50': shouldDisabled,
            }
          )}
        >
          {renderFileIcon(document.type)}
        </div>
        <div
          className={cn(
            'flex-grow flex flex-col justify-center self-center text-sm font-medium leading-5',
            {
              'pointer-events-none opacity-50': shouldDisabled,
            }
          )}
        >
          <p className='text-textPrimary truncate'>{fileName}</p>
          <p className='text-xs text-textPrimary-disable font-normal'>
            {fileSize}
          </p>
        </div>
      </>
    );
  };

  return (
    <div className='flex flex-col gap-3'>
      {uploadedDocumentList.map((document) => {
        const tokenRequired = document?.tokenRequired;
        const shouldShowTooltip =
          availableToken < tokenRequired &&
          usableToken < tokenRequired &&
          !document?.isTrained;
        return (
          <div
            key={document?.id}
            className='group flex h-[64px] gap-3 rounded-xl border border-border hover:bg-zinc-50 pointer-events-auto'
          >
            {renderSingleDocumentInfo(document)}
            <div className='ltr:pr-2 rtl:pl-2 flex-shrink-0 flex flex-col group-hover:flex items-center justify-end cursor-pointer p-2'>
              <button
                type='button'
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteUploadedDocument(document?.id);
                }}
                className='w-4 hidden group-hover:flex self-end h-4 flex-shrink-0 mb-1.5'
              >
                <Trash className='w-full h-full text-red-500' />
              </button>
              <div className='flex gap-1.5 items-center'>
                <DocumentTrainButton
                  document={document}
                  updateDocumentTrainStatus={updateDocumentTrainStatus}
                />
                <TooltipProviderCustomised
                  content={
                    shouldShowTooltip
                      ? "You don't have enough tokens to train this file."
                      : ''
                  }
                  side='left'
                  asChild
                >
                  <div className='flex flex-shrink-0 z-20 gap-1 items-center w-fit border border-border px-1.5 py-0.5 rounded-md'>
                    <Gem
                      strokeWidth={2.5}
                      className='text-purple-500 w-3 h-3'
                    />
                    <span className='text-xs font-semibold text-purple-500'>
                      {document.tokenRequired}
                    </span>
                  </div>
                </TooltipProviderCustomised>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
