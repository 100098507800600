import useTranslation from 'components/customHooks/useTranslation';
import Button from 'library/button';
import { useEffect, useState } from 'react';
import { checkingEquality } from 'utilities/utils';
import SettingsPageHeader from '../components/SettingsPageHeader';
import ChildItem from './ChildItem';
import useTicketConfigurationSettings from './hooks/useTicketSettings';
import SingleItemParent from './SingleItemParent';

interface InitialValueProps {
  has_enabled_ticket_management: {
    status: boolean;
    agent_ticket_limit: number;
    has_enabled_fallback_ticket_distribution: boolean;
    fallback_ticket_distribution_time_limit: number; // in minutes
    should_reassign_tickets_on_agent_logout: boolean;
    should_reassign_tickets_on_agent_away: boolean;
  };
  has_enabled_ticket_hold_management: {
    status: boolean;
    customer_response_time_limit: number; // in minutes
    ticket_hold_limit: number;
  };
  has_enabled_ticket_resolution_management: {
    status: boolean;
    customer_response_time_limit: number; // in minutes
    should_send_csat_on_ticket_closure: boolean;
    ticket_closing_message: string;
  };
}

const initialValue = {
  has_enabled_ticket_management: {
    status: false,
    agent_ticket_limit: 5,
    has_enabled_fallback_ticket_distribution: false,
    fallback_ticket_distribution_time_limit: 30, // in minutes
    should_reassign_tickets_on_agent_logout: true,
    should_reassign_tickets_on_agent_away: false,
  },
  has_enabled_ticket_hold_management: {
    status: false,
    customer_response_time_limit: 10, // in minutes
    ticket_hold_limit: 20,
  },
  has_enabled_ticket_resolution_management: {
    status: false,
    customer_response_time_limit: 30, // in minutes
    should_send_csat_on_ticket_closure: false,
    ticket_closing_message: '',
  },
};

const TicketConfigurationSettings = () => {
  const { t } = useTranslation();
  const {
    getTicketConfigurationData,
    updateConfigurationData,
    validatePayload,
  } = useTicketConfigurationSettings();
  const [previousSettingsData, setPreviousSettingsData] =
    useState<InitialValueProps>(initialValue);
  const [settingsData, setSettingsData] =
    useState<InitialValueProps>(initialValue);
  const [validation, setValidation] = useState({
    validate: false,
    message: '',
  });

  const [apiCallLoading, setApiCallLoading] = useState({
    getDataLoading: false,
    updateDataLoading: false,
  });

  const handleMangementDataChange = (propertyKey: string, value: any) => {
    setSettingsData({ ...settingsData, [propertyKey]: value });
  };

  const handleSettingsChange = (
    parentPropertyKey: string,
    propertyKey: string,
    value: any
  ) => {
    //@ts-ignore
    let parentPreviousData = settingsData[parentPropertyKey] ?? {};
    handleMangementDataChange(parentPropertyKey, {
      ...parentPreviousData,
      [propertyKey]: value,
    });
  };

  const getConfigurationData = async () => {
    setApiCallLoading({ ...apiCallLoading, getDataLoading: true });
    const data = await getTicketConfigurationData();
    if (!!data) {
      setPreviousSettingsData({ ...data });
      setSettingsData({ ...data });
    }
    setApiCallLoading({ ...apiCallLoading, getDataLoading: false });
  };

  useEffect(() => {
    getConfigurationData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!checkingEquality(previousSettingsData, settingsData)) {
      setValidation(validatePayload(settingsData));
    } else
      setValidation({
        validate: false,
        message: '',
      });
    //eslint-disable-next-line
  }, [settingsData]);

  const handleSaveButtonEvent = async () => {
    setApiCallLoading({ ...apiCallLoading, updateDataLoading: true });
    const result = await updateConfigurationData({ ...settingsData });
    if (!!result) {
      setPreviousSettingsData({ ...result });
      setSettingsData({ ...result });
    }
    setApiCallLoading({ ...apiCallLoading, updateDataLoading: false });
  };

  const renderComponent = () => {
    return (
      <>
        <div className='mt-6'>
          <SingleItemParent
            title={t('Enable Intelligent Ticket Management')}
            description={t(
              'Optimize agent performance with automated ticket management systems.'
            )}
            status={settingsData?.has_enabled_ticket_management?.status}
            onChange={(value) =>
              handleSettingsChange(
                'has_enabled_ticket_management',
                'status',
                value
              )
            }
          >
            <div className='grid w-full grid-cols-1 divide-y'>
              <ChildItem
                title='Agent Ticket Limit'
                subTitle='Control workloads by setting the maximum number of tickets an agent can handle.'
                dataType='number'
                value={
                  previousSettingsData?.has_enabled_ticket_management
                    ?.agent_ticket_limit
                }
                handleValueChange={(value) =>
                  handleSettingsChange(
                    'has_enabled_ticket_management',
                    'agent_ticket_limit',
                    value
                  )
                }
              />
              <ChildItem
                title='Enable Fallback Ticket Distribution'
                subTitle='Enable reassign tickets if an agent fails to respond within the specified time limit.'
                dataType='switch'
                value={
                  previousSettingsData?.has_enabled_ticket_management
                    ?.has_enabled_fallback_ticket_distribution
                }
                handleValueChange={(value) =>
                  handleSettingsChange(
                    'has_enabled_ticket_management',
                    'has_enabled_fallback_ticket_distribution',
                    value
                  )
                }
              />
              <ChildItem
                title='Fallback Ticket Distribution'
                isDisable={
                  !settingsData?.has_enabled_ticket_management
                    ?.has_enabled_fallback_ticket_distribution
                }
                subTitle='Automatically reassign tickets if an agent fails to respond within the specified time limit.'
                dataType='timer'
                value={
                  previousSettingsData?.has_enabled_ticket_management
                    ?.fallback_ticket_distribution_time_limit
                }
                handleValueChange={(value) =>
                  handleSettingsChange(
                    'has_enabled_ticket_management',
                    'fallback_ticket_distribution_time_limit',
                    value
                  )
                }
              />
              <ChildItem
                title='Enable Ticket Reassignment on Agent Logout'
                subTitle='Automatically distribute tickets to available agents when an agent logs out.'
                dataType='switch'
                value={
                  previousSettingsData?.has_enabled_ticket_management
                    ?.should_reassign_tickets_on_agent_logout
                }
                handleValueChange={(value) =>
                  handleSettingsChange(
                    'has_enabled_ticket_management',
                    'should_reassign_tickets_on_agent_logout',
                    value
                  )
                }
              />
              <ChildItem
                title='Enable Ticket Reassignment on Away Status'
                subTitle='Automatically distribute tickets to available agents when an agent sets Away status.'
                dataType='switch'
                value={
                  previousSettingsData?.has_enabled_ticket_management
                    ?.should_reassign_tickets_on_agent_away
                }
                handleValueChange={(value) =>
                  handleSettingsChange(
                    'has_enabled_ticket_management',
                    'should_reassign_tickets_on_agent_away',
                    value
                  )
                }
              />
            </div>
          </SingleItemParent>
          <div className='p-1 my-2' />
          <SingleItemParent
            title={t('Responsive Ticket Hold Management')}
            description={t(
              'Fine-tune your support approach by setting personalized time and holding limits for customer response.'
            )}
            status={settingsData?.has_enabled_ticket_hold_management?.status}
            onChange={(value) =>
              handleSettingsChange(
                'has_enabled_ticket_hold_management',
                'status',
                value
              )
            }
          >
            <div className='grid w-full grid-cols-1 divide-y'>
              <ChildItem
                title={'Customer Response Time Limit (Required)'}
                subTitle={
                  'If customers do not reply within the defined time limit, tickets automatically go on hold.'
                }
                dataType='timer'
                value={
                  previousSettingsData?.has_enabled_ticket_hold_management
                    ?.customer_response_time_limit
                }
                handleValueChange={(value) =>
                  handleSettingsChange(
                    'has_enabled_ticket_hold_management',
                    'customer_response_time_limit',
                    value
                  )
                }
              />
              <ChildItem
                title='Hold limit for Tickets'
                subTitle='Set how many tickets an agent can hold.'
                dataType='number'
                value={
                  previousSettingsData?.has_enabled_ticket_hold_management
                    ?.ticket_hold_limit
                }
                handleValueChange={(value) =>
                  handleSettingsChange(
                    'has_enabled_ticket_hold_management',
                    'ticket_hold_limit',
                    value
                  )
                }
              />
            </div>
          </SingleItemParent>
          <div className='p-1 my-2' />
          <SingleItemParent
            title={t('Ticket Resolution Management')}
            description={t(
              'Effortlessly manage tickets with time limits, closure messages, and optional CSAT surveys for comprehensive support.'
            )}
            status={
              settingsData?.has_enabled_ticket_resolution_management?.status
            }
            onChange={(value) =>
              handleSettingsChange(
                'has_enabled_ticket_resolution_management',
                'status',
                value
              )
            }
          >
            <div className='grid w-full grid-cols-1 divide-y'>
              <ChildItem
                title='Customer Response Time Limit (Required)'
                subTitle='If customers do not reply within the defined time limit, tickets will be closed automatically.'
                dataType='timer'
                value={
                  previousSettingsData?.has_enabled_ticket_resolution_management
                    ?.customer_response_time_limit
                }
                handleValueChange={(value) =>
                  handleSettingsChange(
                    'has_enabled_ticket_resolution_management',
                    'customer_response_time_limit',
                    value
                  )
                }
              />
              <ChildItem
                title='Send CSAT After Ticket Closure'
                subTitle='Toggle to enable or disable the automatic sending of CSAT surveys after successfully closing tickets for valuable customer feedback.'
                dataType='switch'
                value={
                  previousSettingsData?.has_enabled_ticket_resolution_management
                    ?.should_send_csat_on_ticket_closure
                }
                handleValueChange={(value) =>
                  handleSettingsChange(
                    'has_enabled_ticket_resolution_management',
                    'should_send_csat_on_ticket_closure',
                    value
                  )
                }
              />
              <ChildItem
                title='Closing Message Customization'
                subTitle='Personalize post-closure messages to provide additional context or appreciation to customers.'
                dataType='textarea'
                isDisable={
                  settingsData?.has_enabled_ticket_resolution_management
                    ?.should_send_csat_on_ticket_closure
                }
                value={
                  previousSettingsData?.has_enabled_ticket_resolution_management
                    ?.ticket_closing_message
                }
                handleValueChange={(value) =>
                  handleSettingsChange(
                    'has_enabled_ticket_resolution_management',
                    'ticket_closing_message',
                    value
                  )
                }
              />
            </div>
          </SingleItemParent>
        </div>
        <div className='float-right mt-4'>
          <Button
            isDisabled={!validation.validate}
            isLoading={apiCallLoading.updateDataLoading}
            intent='primary'
            onClick={() => {
              handleSaveButtonEvent();
            }}
          >
            {t('Save')}
          </Button>
        </div>
      </>
    );
  };

  const renderLoadingView = () => {
    return (
      <>
        <div className='w-full p-4 mt-4 border border-gray-300 rounded-md shadow'>
          <div className='flex space-x-4 animate-pulse'>
            <div className='flex-1 py-1 space-y-6'>
              <div className='h-2 space-y-3 bg-gray-300 rounded'></div>
              <div className='space-y-3'>
                <div className='grid grid-cols-3 gap-4'>
                  <div className='h-2 col-span-2 bg-gray-300 rounded'></div>
                  <div className='h-2 col-span-1 bg-gray-300 rounded'></div>
                </div>
                <div className='h-2 bg-gray-300 rounded'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-full p-4 mt-4 border border-gray-300 rounded-md shadow'>
          <div className='flex space-x-4 animate-pulse'>
            <div className='flex-1 py-1 space-y-6'>
              <div className='h-2 space-y-3 bg-gray-300 rounded'></div>
              <div className='space-y-3'>
                <div className='grid grid-cols-3 gap-4'>
                  <div className='h-2 col-span-2 bg-gray-300 rounded'></div>
                  <div className='h-2 col-span-1 bg-gray-300 rounded'></div>
                </div>
                <div className='h-2 bg-gray-300 rounded'></div>
              </div>
            </div>
          </div>
        </div>
        <div className='w-full p-4 mt-4 border border-gray-300 rounded-md shadow'>
          <div className='flex space-x-4 animate-pulse'>
            <div className='flex-1 py-1 space-y-6'>
              <div className='h-2 space-y-3 bg-gray-300 rounded'></div>
              <div className='space-y-3'>
                <div className='grid grid-cols-3 gap-4'>
                  <div className='h-2 col-span-2 bg-gray-300 rounded'></div>
                  <div className='h-2 col-span-1 bg-gray-300 rounded'></div>
                </div>
                <div className='h-2 bg-gray-300 rounded'></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const mainView = () => {
    return apiCallLoading.getDataLoading
      ? renderLoadingView()
      : renderComponent();
  };

  return (
    <div className=''>
      <SettingsPageHeader
        title='Ticket Configuration'
        subtitle='Configure your ticketing system from here.'
      />
      <div className='px-5'>{mainView()}</div>
    </div>
  );
};

export default TicketConfigurationSettings;
