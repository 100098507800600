import { DataTable } from 'libraryV2/ui/data-table';
import { FC, useEffect, useState } from 'react';
import { AgentTableColumns } from './AgentTableColumns';
import { useDebounce } from 'pages/settings/slaSettings/export';
import { useRavenCustomAgent } from 'pages/raven/hooks/useRavenCustomAgent';
import { ICustomAgentColumnFilter } from 'pages/raven/interface';
import { AgentTableToolbar } from './AgentTableToolbar';
import { Loader2, Plus } from 'lucide-react';
import NoCustomAggentFound from 'assets/icons/emptyPageIcon.svg';
import { Link, useParams } from '@reach/router';
import useThrottledEffect from 'components/customHooks/useThrottleEffect';
import { toast } from 'libraryV2/ui/use-toast';

interface RavenAgentTableContainerProps {}

export const RavenAgentTableContainer: FC<
  RavenAgentTableContainerProps
> = () => {
  const { projectId } = useParams();
  const [firstRender, setFirstRender] = useState<boolean>(true);

  const {
    fetchCustomAgentList,
    customAgentList,
    isCustomAgentListLoading,
    totalCustomAgentCount,
    currentTeamAgentInfo,
    fetchTeamAgentList,
    deleteCustomAgents,
  } = useRavenCustomAgent();

  const [paginationData, setPaginationData] = useState({
    offset: 0,
    limit: 10,
  });

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  // lazy initialize
  const [columnFilters, setColumnFilters] = useState<ICustomAgentColumnFilter>(
    () => {
      return {
        status: [],
        agents: [],
        search: '',
      };
    }
  );
  const debounceFilters = useDebounce(columnFilters, 1000);
  const hasTableFilter = Object.values(debounceFilters).some((v) => v?.length);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    setPaginationData((old) => ({ ...old, offset: 0 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceFilters]);

  useThrottledEffect(
    () => {
      fetchCustomAgentList({ ...paginationData, ...debounceFilters });
    },
    [paginationData],
    3 * 1000
  );

  useEffect(() => {
    // handles an edge case when total data is 21,limit=10, offset=20 and 21th entry is deleted
    if (totalCustomAgentCount > 0 && customAgentList?.length === 0) {
      fetchCustomAgentList({
        ...paginationData,
        ...debounceFilters,
        offset: paginationData.offset - paginationData.limit,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customAgentList?.length]);

  const handleBulkDelete = async () => {
    try {
      const result = await deleteCustomAgents(Object.keys(rowSelection));
      fetchCustomAgentList({ ...paginationData, ...debounceFilters });
      setRowSelection({});
      return result;
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    // current team/project agent. we need it for filter
    fetchTeamAgentList().catch((er) => {
      toast({
        toastType: 'destructive',
        title: 'Failed',
        description: 'Something went wrong. please try agian.',
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLimitChange = async (newLimit: number) => {
    setPaginationData((old) => ({ ...old, limit: newLimit }));
  };

  const handlePageUpdate = async (newOffset: number) => {
    setPaginationData((old) => ({ ...old, offset: newOffset }));
  };

  // views
  const renderLoadingView = () => {
    if (!isCustomAgentListLoading) {
      return null;
    }
    return (
      <div className='w-full text-green-500 justify-center items-center flex-1 flex h-full'>
        <Loader2 className='w-12 h-12 animate-spin' />
      </div>
    );
  };

  const clearRowSelection = () => {
    setRowSelection({});
  };

  const renderCustomAgentTable = () => {
    return (
      <div className='w-full flex flex-col gap-3'>
        <AgentTableToolbar
          filterState={columnFilters}
          rowSelection={rowSelection}
          setFilterState={setColumnFilters}
          onClearRowSelection={clearRowSelection}
          handleBulkDelete={handleBulkDelete}
          currentTeamAgentInfo={currentTeamAgentInfo?.agentList || []}
        />
        <DataTable
          columns={AgentTableColumns}
          data={customAgentList || []}
          hasPagination={true}
          rowSelection={rowSelection}
          dataLoading={isCustomAgentListLoading}
          setRowSelection={setRowSelection}
          paginationProps={{
            limit: paginationData.limit,
            offset: paginationData.offset,
            totalRowCount: totalCustomAgentCount,
            onLimitChange: handleLimitChange,
            onPageChange: handlePageUpdate,
          }}
          reactTableOptions={{
            getRowId: (row) => row?.id,
          }}
        />
      </div>
    );
  };

  const renderNoCustomAgentFound = () => {
    return (
      <div className='flex flex-1 h-auto justify-center items-center'>
        <div className='flex flex-col gap-2 text-textPrimary text-center'>
          <img
            src={NoCustomAggentFound}
            alt='Empty Document Icon'
            className='h-24 mb-2'
          />
          <h3 className='text-lg font-semibold text-center leading-7 w-full'>
            No Custom Agents Created
          </h3>
          <p className='text-textSecondary text-sm leading-5 font-medium'>
            Create your first custom agent to streamline workflows and enhance
            automation.
          </p>
          {/* @ts-ignore */}
          <Link
            to={`/projects/${projectId}/raven/create-custom-agent`}
            className='text-xs w-fit mx-auto font-medium bg-primary flex px-4 h-8 items-center rounded-md gap-1 text-white hover:bg-primary-hover'
          >
            <Plus strokeWidth={2.5} className='w-4 h-4 text-white' />
            <span>Create Custom Agent</span>
          </Link>
        </div>
      </div>
    );
  };

  if (isCustomAgentListLoading) {
    return renderLoadingView();
  } else if (!customAgentList?.length && !hasTableFilter) {
    return renderNoCustomAgentFound();
  }
  return renderCustomAgentTable();
};
