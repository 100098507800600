import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';
import {
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  navigate,
  WarningModal,
} from '../../export';

interface WrapperProps {
  title: string;
  disableSaveButton?: boolean;
  onBackClick?: () => void;
  children: React.ReactNode;
  isConfimrationLoading?: boolean;
  onSave: () => Promise<{ status: boolean }>;
}

const Wrapper: React.FC<WrapperProps> = ({
  title,
  onSave,
  children,
  onBackClick,
  isConfimrationLoading = false,
  disableSaveButton = false,
}) => {
  const { t, isRtlLanguage } = useTranslation();
  const [shouldRenderWarningModal, setShouldRenderWarningModal] =
    React.useState(false);

  return (
    <>
      <div className='z-50 bg-white'>
        {/* Header */}
        <div className='flex items-center justify-between h-[52px] bg-white w-full border-b border-border-light px-4 py-[10px] sticky top-0 z-40'>
          <div className='flex items-center '>
            <Button
              size='icon'
              className='ltr:mr-3 rtl:ml-3'
              variant='outline'
              onClick={() => {
                if (disableSaveButton) onBackClick && onBackClick();
                else setShouldRenderWarningModal(true);
              }}
            >
              {isRtlLanguage ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </Button>
            <h4 className='text-textPrimary'>{title}</h4>
          </div>
          <Button
            onClick={async () => {
              let res = await onSave();
              if (res.status) navigate('/settings/sla-settings');
            }}
            isLoading={isConfimrationLoading}
            disabled={disableSaveButton || isConfimrationLoading}
          >
            <span
              className={` ${isConfimrationLoading ? 'ltr:mr-1 rtl:ml-1' : ''}`}
            >
              {t('Save Changes')}
            </span>
          </Button>
        </div>

        {/* Body */}
        <div className='p-6'>{children}</div>
      </div>

      {shouldRenderWarningModal && (
        <WarningModal
          isOpened={shouldRenderWarningModal}
          title={'Leaving This Page?'}
          isConfirming={isConfimrationLoading}
          description={`Please save before leaving this page, otherwise you will lose your unsaved modifications.`}
          cancelTitle={`Discard Changes`}
          confirmTitle={`Save Changes`}
          onCancel={() => {
            onBackClick && onBackClick();
            setShouldRenderWarningModal(false);
          }}
          confirmationVariant='default'
          onConfirm={async () => {
            let res: { status: boolean } = await onSave();
            if (res.status) {
              onBackClick && onBackClick();
              setShouldRenderWarningModal(false);
            }
          }}
        />
      )}
    </>
  );
};

export default Wrapper;
