import { useParams } from '@reach/router';
import dayjs from 'dayjs';
import { startCase } from 'lodash';
import {
  IWhatsappAccountInfo,
  whatsappMessageTemplateProps,
} from 'pages/integration/interface';
import {
  IReprtingPlatforms,
  IWhatsappConversationOverviewData,
  IWhatsappMetricsTemplateHistory,
  IWhatsappTemplateAnalyticsData,
  WhatsappReportingTab,
} from 'pages/reportingV2/interface';
import { WhatsappTemplateAnalyticsApiDataLimit } from 'pages/reportingV2/utility/content';
import { appendQueryParamsToUrl } from 'pages/reportingV2/utility/utils';
import { DateRange } from 'react-day-picker';
import { useDispatch, useSelector } from 'react-redux';

interface WhatsappTemplateData {
  dateRange: DateRange;
  platformsToFilter: IReprtingPlatforms[];
  totalDataOfTemplateMetrics: number;
  isWhatsappBsbIntegration: boolean;
  whatsappMetricsReport: IWhatsappConversationOverviewData;
  whatsappAccountInfo: IWhatsappAccountInfo;
  whatsappTemplateMetrics: any;
  shouldFetchData: boolean;
  shouldFetchWhatsappMetricsData: boolean;
  templateMetricsTableOffset: number;
  whatsappTemplateData: {
    templateList: whatsappMessageTemplateProps[];
    platformId: string;
  };
  templateAnalyticsLogsTotalData: number;
  templateAnalyticsLogs: IWhatsappMetricsTemplateHistory[];
  currentWhatsappMetricsTab: WhatsappReportingTab;
  selectedTemplateAnalytics: IWhatsappTemplateAnalyticsData;
}

const useWhatsappMetrics = () => {
  const routeParams = useParams();
  const projectId = routeParams?.projectId;
  const dispatch = useDispatch();
  const { platformData } = useSelector((state: any) => state.dashboard);
  const capitalizeWords = startCase;
  const {
    dateRange,
    platformsToFilter,
    totalDataOfTemplateMetrics,
    isWhatsappBsbIntegration,
    whatsappAccountInfo,
    whatsappMetricsReport,
    whatsappTemplateMetrics,
    shouldFetchData,
    shouldFetchWhatsappMetricsData,
    templateMetricsTableOffset,
    whatsappTemplateData,
    templateAnalyticsLogsTotalData,
    templateAnalyticsLogs,
    currentWhatsappMetricsTab,
    selectedTemplateAnalytics,
  }: WhatsappTemplateData = useSelector((state: any) => state.reportingV2);

  const isWaAccountInfoLoading = useSelector(
    (state: any) => state.loading.effects.integration.updateWhatsappAccountInfo
  );
  const platformList = useSelector(
    (state: any) => state?.dashboard?.platformList
  );

  const isTemplateOverviewLoading = useSelector(
    (state: any) => state.loading.effects.reportingV2.fetchTemplateList
  );

  const isTemplateHistoryDataLoading = useSelector(
    (state: any) =>
      state.loading.effects?.reportingV2?.fetchTemplateAnalyticsLogs
  );

  const whatsappPlatformList = platformList.filter(
    (platform: any) => platform?.platform_type === 'whatsapp_bsp'
  );

  const {
    fetchWhatsappConversationMetrics: isFetchWhatsappConversationMetricsLoading,
    fetchTemplateMetrics: isFetchTemplateMetricsLoading,
  } = useSelector((state: any) => state.loading.effects.reportingV2);

  const updatePlatformsToFilter = (waPlatform: IReprtingPlatforms) => {
    if (waPlatform?.platform_type !== 'whatsapp_bsp') {
      return;
    }
    dispatch.reportingV2.updateStateData({
      key: 'platformsToFilter',
      value: [waPlatform],
    });
  };

  const fetchWhatsappConversationMetrics = () => {
    const payload = {
      projectId: projectId,
      platformIds: platformsToFilter
        .map((platform: any) => platform.id)
        .toString(),
      start: dayjs(dateRange?.from)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss'),
      end: dayjs(dateRange?.to).endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
    };

    dispatch.reportingV2.fetchWhatsappConversationMetrics(payload);
  };

  const fetchWhatsappAccountOverView = async (waPlatformId: number) => {
    fetchWhatsappConversationMetrics();
    handleUpdateWhatsappAccountInfo(waPlatformId.toString());
  };

  const handleUpdateWhatsappAccountInfo = async (id: string) => {
    try {
      const res = await dispatch.integration.updateWhatsappAccountInfo(id);
      if (res?.success) {
        dispatch.reportingV2.updateStateData({
          key: 'whatsappAccountInfo',
          value: res?.dataSource,
        });
        return { success: res?.success, dataSource: res?.dataSource };
      } else {
        return { success: res?.success, dataSource: res?.dataSource };
      }
    } catch (error) {
      console.log(error);

      return { success: false };
    }
  };

  const updateIsWhatsappBsbIntegration = (value: boolean) => {
    dispatch.reportingV2.updateStateData({
      key: 'isWhatsappBsbIntegration',
      value: value,
    });
  };

  const updateSelectedReportingViewMode = (value: string) => {
    dispatch.reportingV2.updateStateData({
      key: 'selectedReportingViewMode',
      value: value,
    });
  };

  const fetchWhatsappTemplateMetrics = ({
    searchValue = '',
    statusFilter = '',
    typeFilter = '',
    templateFilter = '',
    offset,
  }: {
    searchValue?: string;
    statusFilter?: string;
    typeFilter?: string;
    templateFilter?: string;
    offset: number;
  }) => {
    const payload: any = {
      projectId: projectId,
      platformId: platformsToFilter[0]?.id,
      from: dayjs(dateRange?.from).format('YYYY-MM-DDTHH:mm:ss'),
      to: dayjs(dateRange?.to).format('YYYY-MM-DDTHH:mm:ss'),
      limit: WhatsappTemplateAnalyticsApiDataLimit,
      offset: offset,
      search: searchValue,
      status: statusFilter,
      category: typeFilter,
      // templates: ['all'],
    };

    // Conditionally add the status key if statusFilter has elements
    if (statusFilter.length > 0) {
      payload.status = `[${statusFilter}]`;
    }
    // Conditionally add the status key if statusFilter has elements
    if (typeFilter.length > 0) {
      payload.category = `[${typeFilter}]`;
    }

    // Dispatch the action with the constructed payload
    dispatch.reportingV2.fetchTemplateMetrics(payload);
  };

  const updateSelectedWhatsappTemplateName = (value: string) => {
    dispatch.reportingV2.updateStateData({
      key: 'selectedWhatsappTemplateName',
      value: value,
    });
  };

  const updateSelectedTemplateAnalytics = (
    templateData: IWhatsappTemplateAnalyticsData | null
  ) => {
    dispatch.reportingV2.updateStateData({
      key: 'selectedTemplateAnalytics',
      value: templateData,
    });
  };

  const updateShouldFetchWhatsappMetricsData = (value: boolean) => {
    dispatch.reportingV2.updateStateData({
      key: 'shouldFetchWhatsappMetricsData',
      value: value,
    });
  };

  const updateCurrentWhatsappMetricsTab = (tab: WhatsappReportingTab) => {
    dispatch.reportingV2.updateStateData({
      key: 'currentWhatsappMetricsTab',
      value: tab,
    });
    appendQueryParamsToUrl({ subTab: tab });
  };

  const fetchTemplateList = (platformId: number) => {
    dispatch.reportingV2.fetchTemplateList(platformId + '');
  };

  const fetchTemplateHistoryData = ({
    searchValue = '',
    statusFilter = '',
    sourceFilter = '',
    templateFilter = '',
    offset,
    limit,
  }: {
    searchValue?: string;
    statusFilter?: string;
    sourceFilter?: string;
    templateFilter?: string;
    limit: number;
    offset: number;
  }) => {
    const payload: any = {
      projectId: projectId,
      platformId: platformsToFilter[0]?.id,
      from: dayjs(dateRange?.from).format('YYYY-MM-DDTHH:mm:ss'),
      to: dayjs(dateRange?.to).format('YYYY-MM-DDTHH:mm:ss'),
      limit: limit,
      offset: offset,
      search: searchValue,
      status: 'all',
      source: sourceFilter,
      templateIDs: templateFilter || 'all',
    };
    dispatch.reportingV2.fetchTemplateAnalyticsLogs(payload);
  };

  return {
    platformData,
    dateRange,
    shouldFetchData,
    capitalizeWords,
    platformsToFilter,
    whatsappPlatformList,
    whatsappTemplateMetrics,
    isTemplateHistoryDataLoading,
    selectedTemplateAnalytics,
    fetchTemplateList,
    whatsappTemplateData,
    currentWhatsappMetricsTab,
    updateCurrentWhatsappMetricsTab,
    isWhatsappBsbIntegration,
    whatsappMetricsReport,
    isWaAccountInfoLoading,
    fetchWhatsappAccountOverView,
    whatsappAccountInfo,
    totalDataOfTemplateMetrics,
    isTemplateOverviewLoading,
    templateAnalyticsLogsTotalData,
    templateMetricsTableOffset,
    shouldFetchWhatsappMetricsData,
    fetchTemplateHistoryData,
    updatePlatformsToFilter,
    isFetchTemplateMetricsLoading,
    updateSelectedTemplateAnalytics,
    fetchWhatsappTemplateMetrics,
    updateShouldFetchWhatsappMetricsData,
    updateIsWhatsappBsbIntegration,
    handleUpdateWhatsappAccountInfo,
    fetchWhatsappConversationMetrics,
    templateAnalyticsLogs,
    updateSelectedReportingViewMode,
    updateSelectedWhatsappTemplateName,
    isFetchWhatsappConversationMetricsLoading,
  };
};

export default useWhatsappMetrics;
