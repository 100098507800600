import {
  cn,
  useAutomationWorkflow,
  React,
  DelayIcon,
} from 'pages/automationWorkflow/export';
import {
  ActionNodeDetailsLog,
  ActionNodeDetailsLogCard,
} from './ActionNodeDetailsLog';
import { IAutomationLogResult } from 'pages/automationWorkflow/interface';

interface DelayActionNodeProps {
  ResultIcon: JSX.Element | null;
  data: any;
}

export const DelayActionNode: React.FC<DelayActionNodeProps> = ({
  ResultIcon,
  data,
}) => {
  const { isViewLog } = useAutomationWorkflow();

  const renderDelayActionLogDetails = () => {
    const logResult: IAutomationLogResult =
      data?.general.data?.component_computation_info;
    const status = logResult?.current_component_state;
    const errorMessage = status === 'failed' && logResult.result.pop();

    return (
      <ActionNodeDetailsLogCard
        date={logResult?.ends_at}
        errorMessage={errorMessage}
        cardTitle='Delay For'
        ResultIcon={ResultIcon}
        render={() => {
          return (
            <span className='font-medium text-gray-700 capitalize'>
              ({data?.general?.delay?.valueOfDelay}){' '}
              {data?.general?.delay?.timeOfDelay}
            </span>
          );
        }}
      />
    );
  };

  return (
    <div className='flex flex-col gap-3'>
      <div className='flex items-center'>
        <div className='mr-3'>
          <DelayIcon />
        </div>
        <div className='grid grid-flow-row gap-1'>
          <div className='text-sm font-semibold leading-4 text-gray-500'>
            Delay
          </div>
          <div
            className={cn([
              'text-xs leading-4 text-gray-400',
              isViewLog ? 'hidden' : '',
            ])}
          >
            (Change)
          </div>
        </div>
      </div>

      {isViewLog && (
        <ActionNodeDetailsLog
          render={renderDelayActionLogDetails}
          actionData={data}
        />
      )}

      <div className='p-3 flex gap-2 items-center border border-gray-200 rounded-md'>
        {isViewLog ? ResultIcon : null}
        <div className='text-sm text-gray-500'>
          Delay for{' '}
          <span className='font-medium text-gray-700 capitalize'>
            ({data?.general?.delay?.valueOfDelay}){' '}
            {data?.general?.delay?.timeOfDelay}
          </span>
        </div>
      </div>
    </div>
  );
};
