import { FC } from 'react';

import {
  Button,
  ChevronLeft,
  CalendarDateRangePicker,
  useNavigate,
  DateRange,
} from '../../export';
import useDatalab from 'pages/datalab/hooks/useDatalab';
import useTranslation from 'components/customHooks/useTranslation';
import { ChevronRight } from 'lucide-react';
interface DatalabHeaderProps {
  defaultDateRange: DateRange | undefined,
 }
 
export const DataLabHeader: FC<DatalabHeaderProps> = ({
  defaultDateRange
}) => {
  const { isRtlLanguage } = useTranslation();
  const navigate = useNavigate();
  const { selectedDatalabInfo, projectId } = useDatalab();

  const handleBackButtonClick = () => {
    navigate(`/projects/${projectId}/data-lab`);
  };

  return (
    <div className='flex justify-between items-center border-b border-l border-gray-300 h-[52px]'>
      <div className='mt-1 pl-2 flex justify-between items-center m-2 gap-2'>
        <Button variant='outline' size='icon' onClick={handleBackButtonClick}>
          {isRtlLanguage ? (
            <ChevronRight className='h-4 w-4' />
          ) : (
            <ChevronLeft className='h-4 w-4' />
          )}
        </Button>
        <h4 className='font-bold text-xl text-textPrimary'>
          {' '}
          {selectedDatalabInfo?.title}{' '}
        </h4>
      </div>

      <div className='mt-1 pr-2 m-2'>
        <CalendarDateRangePicker defaultDateRange={defaultDateRange} className={'bg-white'} />
      </div>
    </div>
  );
};
