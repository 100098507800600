import {
  Button,
  ColumnDef,
  CopyToClipboard,
  DataTableColumnHeader,
  DataTableRowActions,
  TooltipProviderCustomised,
} from '../../../export';

import { CheckIcon, MinusIcon } from '@radix-ui/react-icons';
import { cn } from 'libraryV2/utils';
import { FileText, Eye } from 'lucide-react';
import { PdfFileIcon } from 'pages/settings/savedReplies/utils';
import defaultMediaImage from '../../../../../assets/images/default-product-image.svg';
import { Badge } from '../../../../../libraryV2/ui/badge';
import { getLocalizedTimeDateFormat } from '../../../utils';

interface IAttachment {
  id?: string;
  type: 'image' | 'file' | 'video';
  name: string;
  url: string;
}

type LabsDataType = Record<string, any>;
type LabFieldType = {
  slug?: string;
  type?: string;
  choices?: { name: string; color: string }[];
};

function createDynamicColumns(
  labId: string,
  labsData: LabsDataType[] = [],
  lab_fields: LabFieldType[] = [],
  hasReadAccess: boolean,
  hasWriteAccess: boolean,
  hasDeleteAccess: boolean,
  deleteBulkDatalabList: (labId: string, rowIds: string[]) => void,
  addActionsColumn: boolean = true
) {
  // Safeguard for labsData being null or undefined
  if (!Array.isArray(labsData) || labsData.length === 0) {
    return [];
  }

  const columnList = () => {
    try {
      // Safeguard for lab_fields being null/undefined or not an array
      let slugs = Array.isArray(lab_fields)
        ? lab_fields.map((item: { slug?: string }) => item.slug || '')
        : [];

      let metaSlug = ['source', 'created_by', 'created_at'];
      let temproryMetaSlug = ['last_updated_at', 'last_updated_by'];

      // Check if any metaSlug exists in slugs
      const uniqueMetaSlug = metaSlug.filter((meta) => !slugs.includes(meta));

      // Combine the unique metaSlug with the slugs
      return [...uniqueMetaSlug, ...slugs, ...temproryMetaSlug];
    } catch (error) {
      console.error('Error generating column list:', error);
      return ['source', 'created_by', 'created_at'];
    }
  };

  const truncateText = (text: string, maxLength: number) => {
    try {
      if (text?.length <= maxLength) {
        return text;
      }
      const extensionMatch = text.match(/\.(\w+)$/);
      if (extensionMatch) {
        const extension = extensionMatch[0];
        const baseLength = maxLength - extension.length;
        return `${text.slice(0, baseLength)}...${extension}`;
      }
      return `${text.slice(0, maxLength)}...`;
    } catch (err) {
      console.error('Error truncating text:', err);
      return '--';
    }
  };
  const renderGroupView = (groupItems: any[] = [],
  ) => {
    try {
      return (
        <span className='flex items-center gap-1'>
          <Eye size={16} />
          {`${groupItems?.length} Entries`}
        </span>
      )
    }catch (error) {
      console.error('Error rendering group view:', error);
      return (
        <span className='flex items-center gap-1'>
          <Eye size={16} />
          {`0 Entries`}
        </span>
      )
    }
  }
  const renderViewForMultiselectField = (
    optionsList: any[] = [],
    choices: { name: string; color: string }[] = []
  ) => {
    try {
      const getColor = (name: string) => {
        const choice = choices?.find((choice) => choice.name === name);
        return choice ? choice.color : 'text-textPrimary';
      };

      const formattedOptionsList = optionsList.map((option) => {
        let name: string;
        let color: string;

        if (typeof option === 'object' && option !== null && 'name' in option) {
          name = truncateText(option.name, 17);
          color = option.color || 'text-textPrimary';
        } else {
          name = typeof option === 'string' ? truncateText(option, 17) : '--';
          color = getColor(option);
        }

        return { name, color };
      });

      return (
        <Badge list={formattedOptionsList} variant={'datalab'} maxVisible={1} />
      );
    } catch (error) {
      console.error('Error rendering multiselect field:', error);
      return <Badge variant={'datalab'} list={[]} maxVisible={1} />;
    }
  };

  const renderSingleAttachmentView = (attachment: IAttachment) => {
    switch (attachment.type) {
      case 'image': {
        return (
          <div className='flex items-center border border-zinc-200 rounded-md p-0.5 space-x-2 shadow-sm'>
            <div className='h-5 w-5 flex-shrink-0 rounded border border-zinc-200 overflow-hidden'>
              <img
                src={attachment?.url}
                className='h-full w-full object-cover'
                alt={attachment?.name}
                onError={(e) => {
                  e.currentTarget.src = defaultMediaImage;
                }}
              />
            </div>
            <span className='text-xs font-medium'>
              {truncateText(attachment?.name || attachment?.url, 15)}
            </span>
          </div>
        );
      }
      case 'video': {
        return (
          <div className='flex items-center border border-zinc-200 rounded-md p-0.5 space-x-2 shadow-sm'>
            <div className='h-5 max-h-5 flex-shrink-0 w-5 grid rounded border border-zinc-200'>
              <video className='object-fill rounded-md h-full max-h-5 w-full'>
                <source src={attachment?.url} />
              </video>
            </div>
            <span className='text-xs font-medium'>
              {truncateText(attachment?.name || attachment?.url, 15)}
            </span>
          </div>
        );
      }
      default: {
        return (
          <div className='flex items-center border border-zinc-200 rounded-md p-0.5 space-x-2 shadow-sm'>
            <div className='h-5 group flex-shrink-0 relative w-5 '>
              {attachment?.url?.endsWith('.pdf') ? (
                <PdfFileIcon size={20} />
              ) : (
                <FileText className='w-5 h-5 text-zinc-900' />
              )}
            </div>
            <span className='text-xs font-medium'>
              {truncateText(attachment?.name || attachment?.url, 15)}
            </span>
          </div>
        );
      }
    }
  };

  const createCellRenderer =
    (key: string) =>
    ({ row }: { row: any }) => {
      try {
        let cellValue = row.getValue(key);
        let fieldDetails = lab_fields?.find((field) => field.slug === key);
        let cellType = fieldDetails?.type;

        if (cellValue === null || cellValue === '' || cellValue === undefined) {
          return <div>--</div>;
        }
        if(cellType === 'group'){
          return renderGroupView(cellValue)
        }
        if (
          cellType === 'image' ||
          cellType === 'video' ||
          cellType === 'file'
        ) {
          const attachment: IAttachment = {
            type: cellType,
            url: cellValue,
            name: '',
            id: '',
          };
          return renderSingleAttachmentView(attachment);
        }
        if (cellType === 'datetime') {
          return getLocalizedTimeDateFormat(
            cellValue,
            'en',
            new Date(cellValue).getTime() > 0
              ? 'MMM DD YYYY, hh:mm A'
              : 'MMM DD, YYYY'
          );
        }

        if (cellType === 'date') {
          return getLocalizedTimeDateFormat(cellValue, 'en', 'MMM DD, YYYY');
        }

        if (cellType === 'multiselect') {
          return renderViewForMultiselectField(
            cellValue,
            fieldDetails?.choices || []
          );
        }

        if (cellType === 'singleselect') {
          return renderViewForMultiselectField(
            [cellValue],
            fieldDetails?.choices || []
          );
        }

        if (cellType === 'url') {
          return (
            <div className='flex gap-2'>
              <TooltipProviderCustomised content={cellValue}>
                <Button
                  variant={'link'}
                  className='p-0'
                  onClick={() =>
                    window.open(cellValue, '_blank', 'noopener,noreferrer')
                  }
                >
                  <span className='w-40 truncate ...'>{cellValue}</span>
                </Button>
              </TooltipProviderCustomised>
              <CopyToClipboard textToCopy={cellValue} />
            </div>
          );
        }

        if (typeof cellValue === 'string' && cellValue?.length > 30) {
          return (
            <div className='flex gap-2'>
              <TooltipProviderCustomised content={cellValue}>
                <span>{truncateText(cellValue, 30)}</span>
              </TooltipProviderCustomised>
            </div>
          );
        }

        return <div>{`${cellValue}`}</div>;
      } catch (error) {
        console.error('Error rendering cell:', error);
        return <div>--</div>;
      }
    };

  const dynamicColumns: ColumnDef<LabsDataType>[] = columnList().map((key) => ({
    accessorKey: key,
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={key} />
    ),
    cell: createCellRenderer(key),
    enableSorting: true,
    enableHiding: true,
  }));

  // Add the select component column
  dynamicColumns.unshift({
    id: 'select',
    header: ({ table }) => (
      <div
        className={cn(
          'mr-2 flex h-4 w-4 items-center justify-center border border-textPrimary rounded-[5px]',
          table.getIsAllPageRowsSelected()
            ? 'bg-primary text-primary-foreground border-primary'
            : table.getIsSomePageRowsSelected()
            ? 'bg-primary text-primary-foreground border-primary'
            : ''
        )}
        onClick={() =>
          table.toggleAllPageRowsSelected(!table.getIsAllPageRowsSelected())
        }
      >
        <MinusIcon
          className={cn('h-4 w-3 text-white', {
            hidden:
              !table.getIsAllPageRowsSelected() &&
              !table.getIsSomePageRowsSelected(),
          })}
        />
      </div>
    ),
    cell: ({ row }: { row: any }) => {
      return (
        <div
          className={cn(
            'mr-2 flex h-4 w-4 items-center justify-center border border-textPrimary rounded-[5px]',
            row.getIsSelected()
              ? 'bg-primary text-primary-foreground border-primary'
              : ''
          )}
          onClick={(e) => {
            e.stopPropagation();
            row.toggleSelected(!row.getIsSelected());
          }}
        >
          <CheckIcon
            className={cn('h-4 w-4 text-white', {
              hidden: !row.getIsSelected(),
            })}
          />
        </div>
      );
    },

    enableSorting: false,
    enableHiding: false,
  });

  // Add the actions column
  if (addActionsColumn) {
    dynamicColumns.push({
      id: 'actions',
      cell: ({ row }: { row: any }) => (
        <DataTableRowActions
          row={row}
          labId={labId}
          hasReadAccess={hasReadAccess}
          hasWriteAccess={hasWriteAccess}
          hasDeleteAccess={hasDeleteAccess}
          deleteBulkDatalabList={deleteBulkDatalabList}
        />
      ),
      enableSorting: false,
      enableHiding: false,
    });
  }

  return dynamicColumns;
}

export default createDynamicColumns;
