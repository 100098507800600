import { FC } from 'react';
import CustomReportingLineChart from './LineChart';
import { IWhatsappConversationOverviewData } from 'pages/reportingV2/interface';
import { Button } from 'libraryV2/ui/button';
import { Download } from 'lucide-react';
import { cn } from 'libraryV2/utils';

interface ConversationReportsProps {
  isLoading: boolean;
  conversationReportsData: IWhatsappConversationOverviewData;
}

export const ConversationReports: FC<ConversationReportsProps> = ({
  isLoading,
  conversationReportsData,
}) => {
  const shoudlDisableExportButton =
    isLoading ||
    conversationReportsData?.conversation_reports_per_day?.length === 0;
  return (
    <div>
      <div className='mb-4 flex justify-between'>
        <div>
          <h3 className='text-textPrimary text-base font-medium leading-6 mb-1'>
            Conversation Reports
          </h3>
          <p className='text-textSecondary text-sm'>
            Track template performance over time to understand engagement
            trends.
          </p>
        </div>

        <Button
          disabled={shoudlDisableExportButton}
          variant={'ghost'}
          className={cn(
            'text-textPrimary h-8 py-1.5 px-2.5 border border-border-soft shadow-none gap-2 bg-white',
            {
              'cursor-not-allowed': shoudlDisableExportButton,
            }
          )}
        >
          <Download className='w-4 h-4' />
          <span className='text-xs'>Export</span>
        </Button>
      </div>
      <CustomReportingLineChart
        data={conversationReportsData?.conversation_reports_per_day ?? []}
        loader={isLoading}
      />
    </div>
  );
};
