import { useEffect } from 'react';
import { ReactFlowIndex } from '../reactWorkflow/ReactFlowIndex';
import AutomationLogHeader from './components/AutomatonLogHeader';
import useAutomationLog from './hooks/useAutomationLog';
import { Loader2 } from 'lucide-react';
import { useParams } from '@reach/router';

export default function AutomationLog() {
  const { historyId } = useParams();
  const { isAutomationLogLoading, updateAutomationflowViewType } =
    useAutomationLog();
  useEffect(() => {
    updateAutomationflowViewType(historyId);
    //eslint-disable-next-line
  }, []);
  const renderLoadingView = () => {
    return (
      <div className='h-[100vh] w-full flex justify-center items-center'>
        <div className='relative w-full h-full prevent-select flex flex-col '>
          <div className='flex-grow w-full h-auto flex justify-center items-center'>
            <Loader2 size={48} className='animate-spin text-green-400' />
          </div>
        </div>
      </div>
    );
  };
  const renderAutomationFlowLog = () => {
    return (
      <div className='h-[100vh] w-full flex justify-center items-center'>
        <div className='relative w-full h-full prevent-select flex flex-col '>
          <div className='absolute w-full'>
            <AutomationLogHeader />
          </div>
          <ReactFlowIndex />
        </div>
      </div>
    );
  };
  return isAutomationLogLoading
    ? renderLoadingView()
    : renderAutomationFlowLog();
}
