import { cn } from 'libraryV2/utils';
import { RavenNavIconActive } from 'pages/raven/assets/RavonNavIcons';
import { TestMessageType } from 'pages/raven/interface';

interface SingleTestChatMessageProps {
  messageData?: TestMessageType;
  messageSource: string;
  messageText: string;
  time: string;
}
export const SingleTestChatMessage: React.FC<SingleTestChatMessageProps> = ({
  messageText,
  messageSource,
  time,
}) => {
  return (
    <div
      className={cn('flex w-full gap-2', {
        'justify-end': messageSource === 'user',
      })}
    >
      {messageSource === 'assistant' && (
        <RavenNavIconActive size='28' className='rounded-full' />
      )}
      <div
        className={cn(
          'p-3 rounded-xl max-w-[75%] w-fit bg-background-container border border-border',
          {
            'rounded-tr-sm': messageSource === 'user',
            'rounded-tl-sm': messageSource === 'assistant',
          }
        )}
      >
        <p className={cn('text-sm text-textPrimary whitespace-pre-line')}>
          {messageText}
        </p>
        <p
          className={cn(
            'text-[10px] text-textSecondary text-right font-normal'
          )}
        >
          {time}
        </p>
      </div>
    </div>
  );
};
