import { SelectedTeamInterface } from 'index';
import { useDispatch, useSelector } from 'react-redux';
import { ICurrentTeamAgentInfo, ICustomAgentColumnFilter } from '../interface';
export const useRavenCustomAgent = () => {
  const dispatch = useDispatch();
  const selectedTeam: SelectedTeamInterface = useSelector(
    (state: any) => state?.dashboard?.selectedProject
  );
  const customAgentList = useSelector(
    (state: any) => state.raven?.customAgentList
  );

  const currentTeamAgentInfo: ICurrentTeamAgentInfo = useSelector(
    (state: any) => state.raven?.currentTeamAgent
  );

  const isCustomAgentListLoading = useSelector(
    (state: any) => state.loading?.effects?.raven?.fetchRavenCustomAgentList
  );
  const fetchCustomAgentList = async ({
    limit = 10,
    offset = 0,
    status,
    search,
  }: {
    limit: number;
    offset: number;
  } & ICustomAgentColumnFilter) => {
    offset = Math.max(offset, 0);
    const queryParams = new URLSearchParams();
    queryParams.append('limit', limit + '');
    queryParams.append('offset', offset + '');
    queryParams.append('search', search);
    queryParams.append('team_id', selectedTeam?.id + '');

    if (Array.isArray(status) && status.length) {
      status.forEach((singleStatus) => {
        queryParams.append('status', singleStatus?.value);
      });
    }
    dispatch.raven.fetchRavenCustomAgentList(queryParams.toString());
  };

  const totalCustomAgentCount = useSelector(
    (state: any) => state.raven?.totalCustomAgentCount
  );

  const fetchTeamAgentList = async () => {
    dispatch.raven?.fetchTeamAgentList(selectedTeam?.id);
  };

  const deleteCustomAgents = async (customAgentIds: string[]) => {
    return dispatch.raven?.deleteCustomAgent({ customAgentIds });
  };

  return {
    customAgentList,
    fetchTeamAgentList,
    currentTeamAgentInfo,
    fetchCustomAgentList,
    totalCustomAgentCount,
    isCustomAgentListLoading,
    deleteCustomAgents,
  };
};
