import { navigate, useParams } from '@reach/router';
import { Button } from 'libraryV2/ui/button';
import { ChevronLeft, Loader2 } from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import { CreateCustomAgentSteps } from './stepper';
import { AgentCreationFormContainer } from './AgentCreationFormContainer';
import { CustomAgentFormSteps } from 'pages/raven/utils/content';
import { useDispatch } from 'react-redux';
import {
  calculateCompletedStepsFromAgentData,
  mapApiResponseToState,
} from 'pages/raven/utils/utility';
interface CreateCustomAgentProps {}

export const EditCustomAgent: FC<CreateCustomAgentProps> = () => {
  const { agentId } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [stepCompleted, setStepCompleted] = useState(0);
  const [formSteps, setFormSteps] = useState(
    CustomAgentFormSteps.map((v, _) => ({ ...v, isDone: false }))
  );

  const handleNextStep = () => {
    if (currentStep >= formSteps.length + 1) {
      return;
    }
    setStepCompleted(currentStep);
    setFormSteps((oldSteps) => {
      return oldSteps.map((v) => {
        if (v.id !== currentStep) {
          return v;
        }
        return { ...v, isDone: true };
      });
    });
    setCurrentStep((old) => {
      return old + 1;
    });
  };

  const handlePreviousStep = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 0));
  };

  const renderPageHeader = () => {
    return (
      <>
        <Button
          variant={'outline'}
          className='h-8 w-8 text-textPrimary p-0'
          onClick={() => navigate(-1)}
        >
          <ChevronLeft className='h-4 w-4 text-textPrimary' />
        </Button>
        <h1 className='text-textPrimary text-xl leading-7 font-bold'>
          Edit Custom Agent
        </h1>
      </>
    );
  };

  const handleStep = (step = 1) => {
    setCurrentStep(step);
  };
  //fetch custom agent data
  const fetchAgentDataAndUpdateState = async () => {
    try {
      setIsLoading(true);
      const data = await dispatch.raven.fetchSingleCustomAgent(agentId);
      const mappedData = mapApiResponseToState(data);
      dispatch.raven.updateStateData([
        {
          key: 'customAgentFormData',
          value: mappedData,
        },
      ]);
      const completedStep = calculateCompletedStepsFromAgentData(mappedData);
      setStepCompleted(completedStep);
      setFormSteps(
        CustomAgentFormSteps.map((v, _) => ({
          ...v,
          isDone: v.id <= completedStep,
        }))
      );
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!agentId) {
      return;
    }
    fetchAgentDataAndUpdateState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId]);

  useEffect(() => {
    dispatch?.raven?.fetchGptModelList().catch((err: any) => {
      console.log(err);
    });

    return () => {
      dispatch.raven.resetCreateCustomAgentFormData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div
        data-testid='raven-manage-agent-container'
        className='min-h-[calc(100vh-2px)] w-full flex-1 flex flex-col text-textPrimary ltr:border-l rtl:border-r bg-white border-border'
      >
        <div className='w-full h-[52px] border-border-soft border-b flex gap-3 items-center px-4'>
          {renderPageHeader()}
        </div>
        <div className='flex flex-1 justify-center items-center'>
          <Loader2 className='w-12 h-12 text-primary animate-spin' />
        </div>
      </div>
    );
  }

  return (
    <div
      data-testid='raven-manage-agent-container'
      className='min-h-[calc(100vh-2px)] w-full flex-1 flex flex-col text-textPrimary ltr:border-l rtl:border-r bg-white border-border'
    >
      <div className='w-full h-[52px] border-border-soft border-b flex gap-3 items-center px-4'>
        {renderPageHeader()}
      </div>
      <div className='flex flex-1'>
        <div
          id='raven-form-step-container'
          className='w-[230px] p-3 h-fit flex-shrink-0'
        >
          <CreateCustomAgentSteps
            steps={formSteps}
            currentStep={currentStep}
            stepCompleted={stepCompleted}
            onStepSelect={handleStep}
          />
        </div>
        <div className='flex w-full flex-grow border-border ltr:border-l rtl:border-r  h-auto'>
          <AgentCreationFormContainer
            currentStep={formSteps?.find((s) => s.id === currentStep)!}
            onNextStep={handleNextStep}
            onPreviousStep={handlePreviousStep}
          />
        </div>
      </div>
    </div>
  );
};
