import { PlayCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import React, { Fragment, useCallback, useEffect, useState } from 'react';

import { InformationCircleIcon } from '@heroicons/react/24/solid';
import useTranslation from 'components/customHooks/useTranslation';
import TranslationWrapper from 'components/customHooks/useTranslationWrapper';
import { cn } from 'libraryV2/utils';
import _ from 'lodash';
import { FileText, ImageIcon, Video } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import {
  handleFileUpload,
  handleImageUpload,
  handleVideoUpload,
  TFileUploadResult,
} from './../utils';
import ProgressCircle from './ProgressCircle';
type FileType = 'video' | 'image' | 'file';
interface Props {
  file: string;
  handleClear: () => void;
  handleFileChange: (uploadResult: TFileUploadResult) => void;
  fileType: FileType;
  fileMimeTypes: string[];
  shouldDisable?: boolean;
  allowedFileSize?: number;
}
const defaultFileSizeLimits = {
  // in binary bytes
  video: 7 * 1024 * 1024,
  image: 7 * 1024 * 1024,
  file: 7 * 1024 * 1024,
} as const;
const getFileTypeErrorMessages = (
  allowedMimeTypes: string[],
  headerType: FileType,
  size: number
) => {
  const sizeInMB = Math.floor(size / (1024 * 1024));
  if (headerType === 'file') {
    allowedMimeTypes = ['/pdf', '/doc', 'docx'];
  }
  return `${_.capitalize(
    headerType
  )} size should be less than ${sizeInMB}MB & type should be ${allowedMimeTypes
    .map((v) => v.split('/')?.pop()?.toUpperCase())
    .join(' or ')}`;
};
const getUploadGuideMessage = (
  allowedMimeTypes: string[],
  headerType: FileType,
  size: number
) => {
  const sizeInMB = Math.floor(size / (1024 * 1024));

  let mimeTypesMessage = `MP4 - Maximum file size is {{${sizeInMB}}} MB`;
  if (headerType === 'image') {
    mimeTypesMessage = `JPG, PNG or GIF - Maximum file size is {{${sizeInMB}}} MB`;
  } else if (headerType === 'file') {
    mimeTypesMessage = `PDF, DOC or DOCX - Maximum file size is {{${sizeInMB}}} MB`;
  }

  return (
    <span className='text-sm leading-5 text-zinc-900'>
      <TranslationWrapper text='Upload or drag & drop your' />
      {'  '}
      <TranslationWrapper text={headerType} /> {'  '}
      <TranslationWrapper text='here' />
      <br />
      <div className='text-[#A1A1AA] text-xs leading-5 gap-1 flex w-full justify-center flex-wrap mt-1'>
        <TranslationWrapper text={mimeTypesMessage} />
      </div>
    </span>
  );
};

const SavedReplyAttachmentUploader: React.FC<Props> = ({
  file,
  handleClear,
  handleFileChange,
  fileType,
  shouldDisable = false,
  fileMimeTypes = [],
  allowedFileSize,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [sizeLimitExceeded, setSizetLimitExceeded] = useState<boolean>(false);
  const [uploadFailed, setUploadFailed] = useState<boolean>(false);
  const uploadFailedErrorMessage = t(
    'Failed to upload file. Please try again!'
  );
  const maxAllowedFileSize = allowedFileSize ?? defaultFileSizeLimits[fileType];
  const fileSizeLimitError = getFileTypeErrorMessages(
    fileMimeTypes,
    fileType,
    maxAllowedFileSize
  );
  const uploadCallback = (url: TFileUploadResult | null) => {
    if (!url) {
      setUploadFailed(true);
    } else {
      handleFileChange(url);
      setUploadFailed(false);
    }
    setLoading(false);
  };
  const handleUploadProgress = useCallback((progressEvent: ProgressEvent) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setUploadProgress(percentCompleted);
  }, []);
  const uploadWrapper = (file: File) => {
    switch (fileType) {
      case 'image':
        handleImageUpload(file, uploadCallback, handleUploadProgress);
        break;
      case 'video':
        handleVideoUpload(file, uploadCallback, handleUploadProgress);
        break;
      case 'file':
        handleFileUpload(file, uploadCallback, handleUploadProgress);
        break;
      default:
        break;
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: fileMimeTypes.join(','),
    onDrop: (files: File[]) => {
      setLoading(true);
      uploadWrapper(files[0]);
      setSizetLimitExceeded(false);
    },
    disabled: shouldDisable,
    onDropRejected: () => {
      setSizetLimitExceeded(true);
      setLoading(false);
    },
    multiple: false,
    maxSize: maxAllowedFileSize,
  });

  const renderHeaderPreview = () => {
    switch (fileType) {
      case 'image':
        return (
          <div className='py-2 h-[140px]'>
            <img
              src={file}
              alt='Block'
              className='w-auto h-full rounded-md mx-auto object-cover object-top'
            />
          </div>
        );
      case 'video':
        return (
          <div className={`flex w-full`}>
            <div
              key={1}
              className='relative cursor-pointer  w-[50%] max-w-[190px] mx-auto'
            >
              <div className='absolute bg-gray-500 bg-opacity-40 rounded-md h-[120px] w-full flex justify-center items-center'>
                <PlayCircleIcon className='text-white w-9 h-9' />
              </div>
              <video className='object-fill rounded-md h-[120px] w-full'>
                <source src={file} />
              </video>
            </div>
          </div>
        );
      case 'file':
        return (
          <div className='relative flex flex-col items-center'>
            <FileText className='w-10 h-10 text-gray-400' />
            <div className='w-9/12 text-center mt-2 truncate text-gray-600'>
              {file?.split('/').pop()}
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const renderHeaderUploadGuide = () => {
    switch (fileType) {
      case 'image':
        return (
          <>
            <div className='py-2 px-2 border rounded-lg'>
              <ImageIcon className='text-zinc-900 w-6 h-6' />
            </div>
            <p className='w-[80%] text-center mt-2'>
              {getUploadGuideMessage(
                fileMimeTypes,
                fileType,
                maxAllowedFileSize
              )}
            </p>
          </>
        );
      case 'video':
        return (
          <>
            <div className='py-2 px-2 border rounded-lg'>
              <Video className='text-zinc-900 w-6 h-6' />
            </div>
            <p className='w-[80%] text-center mt-2'>
              {getUploadGuideMessage(
                fileMimeTypes,
                fileType,
                maxAllowedFileSize
              )}
            </p>
          </>
        );
      case 'file':
        return (
          <>
            <div className='py-2 px-2 border rounded-lg'>
              <FileText className='text-zinc-900 w-6 h-6' />
            </div>
            <p className='w-[80%] text-center mt-2'>
              {getUploadGuideMessage(
                fileMimeTypes,
                fileType,
                maxAllowedFileSize
              )}
            </p>
          </>
        );
      default:
        return null;
    }
  };
  useEffect(() => {
    setSizetLimitExceeded(false);
    setUploadFailed(false);
  }, [fileType]);
  return (
    <>
      <section className='image-upload border-gray-500 hover:bg-zinc-50'>
        <div
          {...getRootProps()}
          tabIndex={-1}
          className={cn(
            'flex flex-col items-center justify-center p-2 text-[#8d99ae] max-h-[138px]',
            {
              'cursor-not-allowed': shouldDisable,
            }
          )}
        >
          {!file && !loading && (
            <Fragment>
              <input {...getInputProps()} />
              {renderHeaderUploadGuide()}
            </Fragment>
          )}
          {loading && <ProgressCircle progress={uploadProgress} />}
          {!!file && !loading && (
            <div className='relative w-full'>
              <div className='absolute top-2 ltr:right-2 rtl:left-2 z-20'>
                <XMarkIcon
                  className='h-5 w-5 text-red-500'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClear();
                  }}
                />
              </div>
              <div>{renderHeaderPreview()}</div>
            </div>
          )}
        </div>
      </section>
      {sizeLimitExceeded || uploadFailed ? (
        <p className='flex items-center text-red-500 text-sm mt-1'>
          <span className='mr-1'>
            <InformationCircleIcon className='h-4 w-4' />
          </span>
          {uploadFailed ? uploadFailedErrorMessage : fileSizeLimitError}
        </p>
      ) : null}
    </>
  );
};

export default SavedReplyAttachmentUploader;
