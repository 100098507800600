const MetricsGraphLoader = () => {
  return (
    <div className='min-w-full border border-l divide-y divide-gray-200 shadow'>
      <div className='px-6 py-4 bg-white animate-pulse'>
        {[0, 1, 2, 3, 4, 5].map((item) => (
          <div
            key={item}
            className={`h-4 bg-gray-200 mb-6 ${
              item === 0 ? 'mt-3' : ''
            } rounded`}
          />
        ))}
      </div>
    </div>
  );
};

export default MetricsGraphLoader;
