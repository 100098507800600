import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { Row } from '@tanstack/react-table';

import { Button } from 'libraryV2/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'libraryV2/ui/dropdown-menu';
import { Eye, SquarePen, Trash } from 'lucide-react';
import useSavedReplies from '../../hooks/useSavedReplies';
import SavedReplySheet, { ISavedReply } from '../SavedReplySheet';

import useTranslation from 'components/customHooks/useTranslation';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from 'libraryV2/ui/dialog';
import { cn } from 'libraryV2/utils';
import { useState } from 'react';
import {
  ISavedReplyApiResponse,
  mapSavedReplyApiResponseToState,
} from '../../utils';

interface DataTableRowActionsProps {
  row: Row<ISavedReply>;
}

export function DataTableRowActions({ row }: DataTableRowActionsProps) {
  const { t, isRtlLanguage } = useTranslation();

  const {
    deleteSavedReply,
    savedReplySheetAction,
    isAdminUser,
    setSavedReplySheetAction,
  } = useSavedReplies();
  const [deleteModal, setDeleteModal] = useState(false);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const hasReplyManagePermission =
    isAdminUser || row.original.visibleTo === 'only_me';
  const handleDeleteRow = () => {
    deleteSavedReply(row.original.id!);
    setDeleteModal(false);
  };
  const renderCardDeleteModal = () => {
    return (
      <Dialog open={deleteModal} onOpenChange={setDeleteModal}>
        <DialogContent
          onOpenAutoFocus={(e) => e.preventDefault()}
          hideCloseButton={true}
          className='sm:max-w-[425px] bg-white'
        >
          <DialogTitle className='text-zinc-900 rtl:text-right text-lg font-semibold leading-7'>
            {t('Delete Saved Reply??')}
          </DialogTitle>

          <p className='text-zinc-500 text-sm font-normal'>
            {t(
              'This will permanently delete the Saved Reply. Are you sure you want to delete?'
            )}
          </p>

          <DialogFooter className='rtl:justify-start'>
            <DialogClose>
              <Button
                type='button'
                variant='default'
                className='bg-[#F4F4F5] rtl:ml-2 text-zinc-900 shadow-none hover:text-zinc-900 hover:bg-[#F4F4F5]'
              >
                {t('No')}
              </Button>
            </DialogClose>

            <Button
              onClick={() => handleDeleteRow()}
              type='button'
              variant='destructive'
              className='bg-[#FF0000] text-white text-sm font-medium'
            >
              {t('Yes! Delete')}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };
  return (
    <div>
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger asChild>
          <Button
            variant='ghost'
            className='flex h-8 w-8 p-0 data-[state=open]:bg-muted'
          >
            <DotsHorizontalIcon className='h-4 w-4' />
            <span className='sr-only'>{t('Open menu')}</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align={isRtlLanguage ? 'start' : 'end'}
          className='w-[160px] bg-white border border-zinc-200'
        >
          <DropdownMenuItem
            onSelect={() => {
              setIsSheetOpen(true);
              setSavedReplySheetAction('VIEW');
            }}
            className='hover:bg-zinc-100 rounded text-zinc-900 flex gap-2 rtl:flex-row-reverse'
          >
            <Eye className='w-4 h-4' />
            <span>{t('View Details')}</span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onSelect={() => {
              setIsSheetOpen(true);
              setSavedReplySheetAction('EDIT');
            }}
            disabled={!hasReplyManagePermission}
            className={cn(
              'hover:bg-zinc-100 rounded flex gap-2 rtl:flex-row-reverse',
              {
                'cursor-not-allowed': !hasReplyManagePermission,
              }
            )}
          >
            <SquarePen className='w-4 h-4' />
            <span>{t('Edit')}</span>
          </DropdownMenuItem>
          <DropdownMenuSeparator className='bg-zinc-200' />
          <DropdownMenuItem
            onSelect={(e) => {
              setDeleteModal(true);
            }}
            disabled={!hasReplyManagePermission}
            className={cn(
              'hover:bg-zinc-100 rounded flex gap-2 rtl:flex-row-reverse',
              {
                'cursor-not-allowed': !hasReplyManagePermission,
              }
            )}
          >
            <Trash className='w-4 h-4' />
            <span>{t('Delete')}</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      {renderCardDeleteModal()}
      {savedReplySheetAction && isSheetOpen && (
        <SavedReplySheet
          actionType={savedReplySheetAction as 'EDIT'}
          isOpen={isSheetOpen}
          setIsOpen={(status) => {
            setIsSheetOpen(status);
            setSavedReplySheetAction('CREATE');
          }}
          data={mapSavedReplyApiResponseToState(
            row.original as unknown as ISavedReplyApiResponse
          )}
        />
      )}
    </div>
  );
}
