import { Menu, Transition } from '@headlessui/react';
import {
  CheckCircleIcon,
  ClipboardDocumentIcon,
  PauseCircleIcon,
} from '@heroicons/react/24/outline';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { useLocation } from '@reach/router';
import useTranslation from 'components/customHooks/useTranslation';
import { SelectedTeamInterface } from 'index';
import { CirclePlay } from 'lucide-react';
import { useTagSettings } from 'old/pages/settings/hooks/useTagSettings';
import {
  AgentListInterface,
  assignTicketRequestBody,
  ITicketQueueData,
  TicketActionInterface,
  TicketInterface,
  UpdateSingleTicketReducerInterface,
} from 'pages/inbox/inboxInterface';
import React, { Fragment, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { classNames, copyClipboard } from 'utilities/utils';
import collapseButton from '../../../../assets/images/collapseButton.svg';
import loaderIcon from '../../../../assets/images/loaderIcon.svg';
import AgentGroupAssignmentPopover from './AgentGroupAssignmentPopover';
import ReopenTicketPopover from './ReopnTicketPopover';
import Tag from './Tag';
import TagDropDown from './TagDropDown';
import PriorityDropdown from './PriorityDropDown';

interface Props {
  userId: number;
  showRightbar: boolean;
  selectedQueueType: string;
  agentGroup: GroupInterface[];
  hasSupervisorAccess: boolean;
  resolveTicketLoading: boolean;
  conversationLoading: boolean;
  selectedTicket: TicketInterface;
  agentList: AgentListInterface[];
  selectedProject: SelectedTeamInterface;
  ticketQueue: ITicketQueueData;
  ticketActions: TicketActionInterface[];
  isTicketHoldEnabled: boolean;

  holdTicket: (
    ticketId: number,
    status: boolean,
    ticketData: TicketInterface,
    userId: number
  ) => boolean;
  handleTicketResolve: (value: boolean) => void;
  handleRightbarAppearence: (value: boolean) => void;
  updateStateData: (key: string, value: string) => void;
  updateBotTicketsAfterAssign: () => void;
  updateSingleTicket: (payload: UpdateSingleTicketReducerInterface) => void;
  assignTicket: (requestBody: assignTicketRequestBody) => Promise<boolean>;
  assignBotTicket: (requestBody: assignTicketRequestBody) => Promise<boolean>;
  removeTicketFromListOnAgentAssignment: (
    payload: UpdateSingleTicketReducerInterface
  ) => void;
  reopenClosedTicket: (
    requestBody: assignTicketRequestBody
  ) => Promise<boolean>;
}

const MiddleBarHeader: React.FC<Props> = ({
  userId,
  agentList,
  agentGroup,
  holdTicket,
  ticketQueue,
  showRightbar,
  ticketActions,
  selectedTicket,
  selectedProject,
  selectedQueueType,
  conversationLoading,
  hasSupervisorAccess,
  resolveTicketLoading,
  isTicketHoldEnabled,
  assignTicket,
  updateStateData,
  assignBotTicket,
  updateSingleTicket,
  reopenClosedTicket,
  handleTicketResolve,
  handleRightbarAppearence,
  updateBotTicketsAfterAssign,
  removeTicketFromListOnAgentAssignment,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { isTagMandatoryEnable } = useTagSettings();
  const [isCopied, setIsCopied] = useState(false);
  const isTicketOnHold = !!selectedTicket?.is_on_hold;
  const isEnterpriseOrBusiness = ['enterprise', 'business'].includes(
    selectedProject?.subscription_plan?.plan_type
  );

  // React.useEffect(() => {}, [isTicketOnHold]);

  const renderTagComponentView = () => {
    return (
      <>
        <TagDropDown hasSupervisorAccess={hasSupervisorAccess} />
        {!!selectedTicket && selectedTicket?.tags?.length > 0 && (
          <Tag tags={selectedTicket?.tags} />
        )}
      </>
    );
  };

  const renderPriorityDropdownView = () => {
    return (
      <>
        <PriorityDropdown />
      </>
    );
  };

  const renderCloseChatButtonView = () => {
    if (
      !!selectedTicket &&
      !selectedTicket?.is_resolved &&
      selectedQueueType !== 'bot'
    )
      return (
        <>
          <div id='close-chat'>
            <button
              data-for='mid-close-chat-btn'
              data-tip='Please add a tag to close the ticket'
              id='mid-close-chat-btn'
              className={classNames(
                'flex gap-2 px-3 py-2  rounded-md',
                isTagMandatoryEnable && selectedTicket?.tags?.length < 1
                  ? 'bg-[#E5E7EB] text-[#6B7280]'
                  : 'bg-[#CDF0DF] text-[#038E4C]'
              )}
              disabled={
                (isTagMandatoryEnable && selectedTicket?.tags?.length < 1) ||
                resolveTicketLoading
              }
              onClick={() => {
                handleTicketResolve(true);
              }}
            >
              {resolveTicketLoading && (
                <div className='py-0.5 animate-spin'>
                  <img src={loaderIcon} alt='loading' />
                </div>
              )}
              {t('Close Chat')}
            </button>
            {isTagMandatoryEnable && selectedTicket?.tags?.length < 1 && (
              // @ts-ignore
              <ReactTooltip
                id={`mid-close-chat-btn`}
                place='bottom'
                type='dark'
                effect='solid'
              />
            )}
          </div>
        </>
      );
    else return <></>;
  };

  const renderPendingTicketView = () => {
    return (
      <>
        <AgentGroupAssignmentPopover
          userId={userId}
          agentList={agentList}
          agentGroup={agentGroup}
          selectedTicket={selectedTicket}
          selectedProject={selectedProject}
          selectedQueueType={selectedQueueType}
          hasSupervisorAccess={hasSupervisorAccess}
          assignTicket={assignTicket}
          assignBotTicket={assignBotTicket}
          updateStateData={updateStateData}
          updateSingleTicket={updateSingleTicket}
          removeTicketFromListOnAgentAssignment={
            removeTicketFromListOnAgentAssignment
          }
          updateBotTicketsAfterAssign={updateBotTicketsAfterAssign}
        />
        {renderCloseChatButtonView()}
      </>
    );
  };

  const renderResolvedTicketView = () => {
    return (
      <ReopenTicketPopover
        userId={userId}
        agentList={agentList}
        agentGroup={agentGroup}
        hasClickedOnAgent={true}
        selectedTicket={selectedTicket}
        updateSateData={updateStateData}
        reopenClosedTicket={reopenClosedTicket}
        updateSingleTicket={updateSingleTicket}
        hasSupervisorAccess={hasSupervisorAccess}
      />
    );
  };

  const renderCollapseButton = () => {
    return (
      <>
        {showRightbar ? null : (
          <div
            id='collapse-button'
            onClick={() => handleRightbarAppearence(!showRightbar)}
          >
            <img
              className='max-w-[39px]'
              src={collapseButton}
              alt='left-collapse'
            />{' '}
          </div>
        )}
      </>
    );
  };

  const handleCopyLink = () => {
    const link = `${location.origin}${location.pathname}?customer_id=${
      selectedTicket?.customer_id
    }${selectedQueueType === 'bot' ? '' : `&ticket_id=${selectedTicket?.id}`}`;
    copyClipboard(link);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3500);
  };

  const renderCopyLinkIcon = () => {
    return (
      <div
        className='flex items-center justify-center p-2 border border-gray-300 rounded-md cursor-pointer'
        onClick={() => handleCopyLink()}
        data-for='copy-link'
        data-tip
      >
        {!isCopied && (
          <ClipboardDocumentIcon className='text-gray-500' height={20} />
        )}
        {isCopied && <CheckCircleIcon className='text-green-500' height={20} />}
        {/* @ts-ignore */}
        <ReactTooltip
          id='copy-link'
          type='dark'
          effect='solid'
          backgroundColor='#4B5563'
          multiline={true}
          place={'bottom'}
          className='w-auto break-words whitespace-pre-wrap sm:text-sm'
        >
          {isCopied ? t('Copied!') : t('Copy conversation link')}
        </ReactTooltip>
      </div>
    );
  };

  const renderDotsIconView = () => {
    return (
      <Menu>
        <Menu.Button
          data-for='copy-link-2'
          data-tip
          className='px-1.5 border border-gray-300 rounded-md flex justify-center items-center cursor-pointer focus:border-primary'
        >
          <EllipsisVerticalIcon className='w-6 text-gray-500' color='#6B7280' />
        </Menu.Button>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute ltr:right-[60px] rtl:left-[60px] mt-[40px]  origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-[999999]'>
            <div className='p-1'>
              {isTicketHoldEnabled && (
                <Menu.Item>
                  <div
                    className='flex items-center justify-start px-2 py-1 cursor-pointer'
                    onClick={() => {
                      holdTicket(
                        selectedTicket?.id,
                        !isTicketOnHold,
                        selectedTicket,
                        userId
                      );
                    }}
                  >
                    {isTicketOnHold ? (
                      <CirclePlay
                        className='mr-2 text-gray-500 rtl:mr-0 rtl:ml-2'
                        color='#6B7280'
                        height={20}
                      />
                    ) : (
                      <PauseCircleIcon
                        className='mr-2 text-gray-500 rtl:mr-0 rtl:ml-2'
                        color='#6B7280'
                        height={20}
                      />
                    )}
                    {isTicketOnHold && t('unHold Ticket')}
                    {!isTicketOnHold && t('Hold Ticket')}
                  </div>
                </Menu.Item>
              )}
              <Menu.Item>
                <div
                  className='flex items-center justify-start px-2 py-1 cursor-pointer'
                  onClick={() => handleCopyLink()}
                >
                  <ClipboardDocumentIcon
                    className='mr-2 text-gray-500 rtl:mr-0 rtl:ml-2'
                    color='#6B7280'
                    height={20}
                  />
                  {t('copy link')}
                </div>
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
        {isCopied && (
          // @ts-ignore
          <ReactTooltip
            id='copy-link-2'
            type='dark'
            effect='solid'
            backgroundColor='#4B5563'
            multiline={true}
            place={'bottom'}
            className='w-auto break-words whitespace-pre-wrap sm:text-sm'
          >
            {isCopied
              ? t('Copied!')
              : t('Copy conversation link or hold ticket')}
          </ReactTooltip>
        )}
      </Menu>
    );
  };

  // TODO: Fix business logic
  // const showOpenchatInClosedTab = () => {
  //   const isDirectMessageAllowed = ticketActions.find(
  //     (item) => item.action === 'direct_message'
  //   )?.is_allowed;
  //   const isTicketResolved = ticketQueue?.isResolved;

  //   switch (selectedTicket?.customer_platform_type) {
  //     case 'whatsapp_bsp':
  //       if (!isDirectMessageAllowed && isTicketResolved) {
  //         return true;
  //       } else if (!isDirectMessageAllowed) {
  //         return true;
  //       } else {
  //         return false;
  //       }
  //     default:
  //       if (!isDirectMessageAllowed && isTicketResolved) {
  //         return false;
  //       } else {
  //         return true;
  //       }
  //   }
  // };

  return (
    <div
      data-testid='middle-top-bar'
      className='flex items-center flex-none h-16 bg-white border-b border-gray-200'
    >
      <div className='flex content-center justify-between w-full px-4 py-3'>
        <div id='leftmost-elements' className='flex justify-start w-1/2 gap-2'>
          {ticketQueue?.type !== 'bot' && renderTagComponentView()}
          {ticketQueue?.type !== 'bot' && renderPriorityDropdownView()}
        </div>
        <div id='rightmost-elements' className='flex justify-end w-1/2 gap-2'>
          {!isEnterpriseOrBusiness && renderCopyLinkIcon()}
          {!!selectedTicket &&
            selectedTicket?.is_resolved &&
            !selectedTicket?.is_reopened &&
            // showOpenchatInClosedTab() &&
            renderResolvedTicketView()}
          {!!selectedTicket &&
            !selectedTicket?.is_resolved &&
            // showOpenchatInClosedTab() &&
            renderPendingTicketView()}
          {isEnterpriseOrBusiness && renderDotsIconView()}
          {renderCollapseButton()}
        </div>
      </div>
    </div>
  );
};

export default MiddleBarHeader;
