import { Button } from 'libraryV2/ui/button';
import { Separator } from 'libraryV2/ui/separator';
import { Form } from 'libraryV2/ui/form';
import {
  CustomAgentToolType,
  ICustomAgentFormSteps,
} from 'pages/raven/interface';
import { FC } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { customAgentformSchema, CustomAgentToolFormData } from './toolsSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { getDefaultToolData } from 'pages/raven/utils/utility';

import { AddNewToolButton } from './AddNewToolButton';
import { CustomAgentApiTool } from './apiTool/CustomAgentApiTool';
import { DocumentTool } from './documentTool/DocumentTool';
import { WebsiteTool } from './websiteTool/WebsiteTool';

interface ToolsProps {
  step: ICustomAgentFormSteps;
  onNextStep: () => void;
  onPreviousStep: () => void;
}

export const Tools: FC<ToolsProps> = ({ onNextStep, onPreviousStep }) => {
  const form = useForm<CustomAgentToolFormData>({
    resolver: zodResolver(customAgentformSchema),
    mode: 'onChange',
    defaultValues: {
      tools: [],
    },
  });

  const toolsFormFieldArray = useFieldArray({
    control: form.control,
    name: 'tools',
  });

  const renderSingleTools = (tool: any, index: number) => {
    const toolType: CustomAgentToolType = tool?.type || 'api';
    if (toolType === 'api') {
      return (
        <CustomAgentApiTool
          toolData={tool}
          index={index}
          fieldArrayInstance={toolsFormFieldArray}
        />
      );
    } else if (toolType === 'document') {
      return (
        <DocumentTool
          tooldata={tool}
          index={index}
          fieldArrayInstance={toolsFormFieldArray}
        />
      );
    }
    return (
      <WebsiteTool
        toolData={tool}
        index={index}
        fieldArrayInstance={toolsFormFieldArray}
      />
    );
  };

  const onSubmit = (data: CustomAgentToolFormData) => {
    // TODO: Implement be api
    console.log(`🚀 final  tools form data`, data);
    // Handle form submission
  };

  const handleAddNewTool = (toolType: CustomAgentToolType) => {
    const newTool = getDefaultToolData(toolType) as {
      type: typeof toolType;
      id: string;
      isOpen: boolean;
      data: any;
    };

    toolsFormFieldArray.append(newTool);
  };

  return (
    <div
      data-testId='raven-tools-section'
      className='flex flex-col h-[calc(100vh-56px)] w-full pt-6 overflow-y-auto'
    >
      <div className='flex flex-col flex-1 mx-auto max-w-2xl gap-6 min-h-0'>
        <div className='space-y-1.5'>
          <h1 className='font-bold flex text-xl leading-6'>Tools</h1>
          <p className='text-sm font-medium text-textSecondary'>
            Provide the necessary resources like APIs, documents, and links to
            enhance your custom agent's capabilities.
          </p>
        </div>
        <Separator />
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className='w-full'>
            <div className='flex flex-col w-full gap-4'>
              {toolsFormFieldArray.fields.map((singletool, index) =>
                renderSingleTools(singletool, index)
              )}
              <AddNewToolButton onToolSelect={handleAddNewTool} />
              <div className='flex justify-between w-full'>
                <Button
                  type='button'
                  variant='ghost'
                  className='bg-background-container'
                  onClick={onPreviousStep}
                >
                  Back
                </Button>
                <div className='flex items-center gap-2'>
                  <Button type='button' variant={'outline'}>
                    Save as Draft
                  </Button>
                  <Button type='submit'>Next</Button>
                </div>
              </div>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
};
