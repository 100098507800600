import { Button } from 'libraryV2/ui/button';
import { Form } from 'libraryV2/ui/form';
import { Loader2, Trash2Icon } from 'lucide-react';
import React, { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  formatDatalabFormEntryUpdate,
  formatDatalabNewEntryRequestPayload,
  getDefaultFieldsValue,
  mapFormValues,
  isEmtyObject,
} from 'pages/datalab/utils';
import { IDataLabField, SingleFormField } from 'pages/datalab/export';
import useInboxDatalab from 'pages/inbox/hooks/useInboxDatalab';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';
import NewAlertModal from 'library/modal/NewAlertModal';
import CopyText from '../rightbar/components/CopyText';
import SearchSelectField from 'pages/datalab/components/formFields/searchableField';

interface InboxDatalabFormProps {
  datalabEntries?: any;
  datalabShape: any;
  formAction: 'EDIT' | 'VIEW' | 'CREATE';
}

const InboxDatalabForm: React.FC<InboxDatalabFormProps> = ({
  formAction,
  datalabShape,
  datalabEntries,
}) => {
  const { t } = useTranslation();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  // const isFormViewMode = formAction === 'VIEW';
  const isFormEditMode = formAction === 'EDIT';
  const isFormCreateMode = formAction === 'CREATE';

  const {
    inboxDatalabData,
    updateInboxDatalabStateData,
    insertDatalabEntries,
    showEntryDeleteWarning,
    isInsertedDatalabEntriesLoading,
    setShouldShowSuccessOrWarningModal,
    entryInsertErrorMessage,
    successResponseData,
    setEntryInsertErrorMessage,
    removeSingleEntry,
    isRemovingSingleEntryLoading,
    shouldShowSuccessOrWarningModal,
    setShowEntryDeleteWarning,
    updateSingleEntry,
    getPrefilledCustomerData,
    clearStatesExceptDatalabListAndCustomerEntry,
  } = useInboxDatalab();

  const prefilledCustomerData = getPrefilledCustomerData();
  const labId = inboxDatalabData?.selectedDatalabId;
  const inboxDatalabAction = inboxDatalabData?.formAction;

  const finalFormValues = isFormCreateMode
    ? getDefaultFieldsValue(datalabShape?.lab_fields, {
        shouldPrefill: true,
        customerAttributes: prefilledCustomerData,
      })
    : mapFormValues(
        datalabEntries,
        getDefaultFieldsValue(datalabShape?.lab_fields)
      );

  const datalabHookForm = useForm({
    mode: 'onChange',
    defaultValues: finalFormValues,
  });

  const onSubmit = async (values: Record<string, any>) => {
    setIsSubmitLoading(true);
    if (isFormCreateMode) {
      const requestPayload = formatDatalabNewEntryRequestPayload(values);
      insertDatalabEntries(requestPayload).finally(() => {
        setIsSubmitLoading(false);
      });
    } else if (isFormEditMode) {
      const payload: Record<string, any> = formatDatalabFormEntryUpdate(
        structuredClone(datalabEntries),
        values
      );
      payload['labId'] = labId;
      updateSingleEntry(payload).finally(() => {
        setIsSubmitLoading(false);
      });
    }
  };

  const renderFormFields = () => {
    const fields = datalabShape?.lab_fields;
    if (!fields || !Array.isArray(fields) || fields.length === 0) {
      return null;
    }
    return fields.map((field: IDataLabField) => {
      return (
        <Fragment key={field.id}>
          {isEmtyObject(field?.api_call) || formAction === 'VIEW' ? (
            <SingleFormField
              formActionType={formAction}
              //@ts-ignore
              data={field}
              valuePath=''
            />
          ) : (
            <SearchSelectField
              fieldData={field}
              datalabFields={fields}
              valuePath=''
              shouldDisableField={false}
            />
          )}
        </Fragment>
      );
    });
  };

  const renderEiditModeButtons = () => {
    return (
      <Fragment>
        <Button
          type='button'
          variant={'outline'}
          className='flex-1 flex gap-2 px-4 py-3'
          onClick={() => {
            clearStatesExceptDatalabListAndCustomerEntry();
          }}
        >
          {t('Cancel')}
        </Button>

        <Button
          className='text-white flex-1 gap-2'
          type='submit'
          disabled={!datalabHookForm.formState.isDirty}
        >
          {t('Save Changes')}
        </Button>
      </Fragment>
    );
  };

  const renderViewModeButtons = () => {
    return (
      <Fragment>
        <Button
          className='text-white flex gap-2 px-4 py-3 flex-1'
          onClick={() => {
            updateInboxDatalabStateData('formAction', 'EDIT');
          }}
        >
          <PencilSquareIcon className='h-4 w-4' />
          {t('Edit')}
        </Button>

        <Button
          className='flex gap-2 flex-1 hover:border-green-500'
          variant={'outline'}
          type='button'
          onClick={() => {
            setShowEntryDeleteWarning(true);
            setShouldShowSuccessOrWarningModal(true);
          }}
        >
          <Trash2Icon className='h-4 w-4' />
          {t('Delete')}
        </Button>
      </Fragment>
    );
  };

  const renderDatalabRightbarFooterView = () => {
    return (
      <div className='fixed z-30 bottom-2 h-[7.5vh] border-t p-2 w-[22.6%] bg-white'>
        <div className='flex flex-wrap text-sm justify-between gap-2 w-full'>
          {/* {getFooterButtonSet()} */}
          {inboxDatalabAction === 'VIEW'
            ? renderViewModeButtons()
            : renderEiditModeButtons()}
        </div>
      </div>
    );
  };

  const renderModalDescriptionView = () => {
    let hasError = entryInsertErrorMessage.length !== 0;

    if (hasError) {
      return (
        <div className='rounded-md h-auto max-h-80 overflow-y-auto w-full mt-2 flex text-center justify-center items-center overflow-hidden whitespace-normal bg-red-100 border border-red-500 text-red-600 my-2 px-3 py-2'>
          {entryInsertErrorMessage}
        </div>
      );
    } else if (showEntryDeleteWarning) {
      return t('You are about to delete this entry. Do you want to continue?');
    } else if (!!successResponseData) {
      return (
        <div className='w-full'>
          <span
            className='text-sm line-clamp-4 font-normal'
            data-tip={successResponseData?.sub_title}
            data-for='subtitle_datalab'
          >
            {successResponseData?.sub_title}
          </span>
          {/* @ts-ignore */}

          <br />
          {!!successResponseData?.message && (
            <div className='relative bg-gray-100 border border-gray-200 rounded-md px-4 py-1 flex items-center'>
              <div className='absolute top-2 right-2 w-9/12'>
                <div className='float-right '>
                  <CopyText
                    height={24}
                    textToCopy={successResponseData?.message || ''}
                  />
                </div>
              </div>
              <span
                className='block text-sm text-gray-600 font-normal mx-2 my-4 text-left whitespace-normal'
                style={{ overflowWrap: 'anywhere' }}
              >
                {successResponseData?.message}
              </span>
            </div>
          )}
        </div>
      );
    } else return '';
  };

  const handleModalClose = (hasError: boolean) => {
    if (hasError) {
      setEntryInsertErrorMessage('');
      setShouldShowSuccessOrWarningModal(false);
    } else {
      clearStatesExceptDatalabListAndCustomerEntry();
    }
  };

  const dataLabEntryResponseModal = () => {
    let hasError = entryInsertErrorMessage.length !== 0;
    return (
      <NewAlertModal
        isShown={shouldShowSuccessOrWarningModal || showEntryDeleteWarning}
        intent={hasError || showEntryDeleteWarning ? 'danger' : 'primary'}
        title={
          hasError
            ? t('Datalab changes failed')
            : showEntryDeleteWarning
            ? t('Delete Datalab')
            : !!successResponseData
            ? t(successResponseData?.title || t('Datalab changes saved'))
            : t('Datalab changes saved')
        }
        description={renderModalDescriptionView()}
        cancelButtonTitle={showEntryDeleteWarning ? t('Cancel') : t('Close')}
        confirmButtonTitle={
          hasError
            ? t('Try again')
            : showEntryDeleteWarning
            ? t('Delete')
            : t('Close')
        }
        onConfirm={() => {
          showEntryDeleteWarning ? removeSingleEntry() : insertDatalabEntries();
        }}
        onCancelComplete={() => {
          handleModalClose(hasError);
        }}
        onClose={() => {
          handleModalClose(hasError);
        }}
        isConfirmationLoading={
          isRemovingSingleEntryLoading || isInsertedDatalabEntriesLoading
        }
        shouldShowConfirmButton={hasError || showEntryDeleteWarning}
        shouldRenderDescriptionAsNode={
          typeof renderModalDescriptionView() !== 'string'
        }
      />
    );
  };

  const renderDataLabForm = () => (
    <>
      <Form {...datalabHookForm}>
        <form
          onSubmit={datalabHookForm.handleSubmit(onSubmit)}
          className='space-y-6'
        >
          {renderFormFields()}
          {renderDatalabRightbarFooterView()}
        </form>
      </Form>
      {dataLabEntryResponseModal()}
    </>
  );

  const renderLoadingView = () => {
    return (
      <div className='h-full w-full flex justify-center items-center'>
        <Loader2 className='animate-spin text-green-500 h-12 w-12' />
      </div>
    );
  };

  return isSubmitLoading ? renderLoadingView() : renderDataLabForm();
};

export default InboxDatalabForm;
