import { IWhatsappMetricsTemplateHistory } from 'pages/reportingV2/interface';
import { FC } from 'react';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'libraryV2/ui/sheet';
import { startCase } from 'lodash';
interface TemplateLogDetailsSheetProps {
  logData: IWhatsappMetricsTemplateHistory;
}

export const TemplateLogDetailsSheet: FC<TemplateLogDetailsSheetProps> = ({
  logData,
}) => {
  const renderShideSeetView = () => {
    return (
      <div className='flex flex-col gap-3'>
        {Object.entries(logData).map(([key, value]) => (
          <div className='p-3 border border-border-soft rounded-md ' key={key}>
            <p className='text-textSecondary font-medium text-xs mb-1.5'>
              {startCase(key)}
            </p>
            <p className='text-textPrimary text-sm font-medium overflow-auto break-all whitespace-pre-wrap'>
              {value === '' ? 'N/A' : JSON.stringify(value, null, 2)}
            </p>
          </div>
        ))}
      </div>
    );
  };
  return (
    <Sheet>
      <SheetTrigger className='invisible group-hover:visible text-blue-500'>
        View Details
      </SheetTrigger>
      <SheetContent className='bg-white sm:max-w-[480px] overflow-y-auto'>
        <SheetHeader>
          <SheetTitle>View Details</SheetTitle>
          <SheetDescription></SheetDescription>
        </SheetHeader>
        {renderShideSeetView()}
      </SheetContent>
    </Sheet>
  );
};
