import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from 'libraryV2/ui/sheet';
import DatalabForm from './DatalabForm';
import { TDatalabFormModes } from 'pages/datalab/export';
import useTranslation from 'components/customHooks/useTranslation';

interface SiteSheetProps {
  onClose: () => void;
  header: string;
  isOpen: boolean;
  datalabEntries: any;
  datalabShape: any;
  mode: TDatalabFormModes;
  onEdit?: () => void;
  onDelete?: () => void;
}

const DatalabSideSheet = ({
  onClose,
  header,
  isOpen = false,
  datalabEntries,
  datalabShape,
  mode,
  onEdit,
  onDelete
}: SiteSheetProps) => {
  const { t } = useTranslation();
  return (
    <Sheet open={isOpen} onOpenChange={onClose} modal={true}>
      <SheetContent
        side='right'
        className='bg-white sm:max-w-[470px] sm:w-[470px] overflow-auto p-0'
      >
        <SheetHeader className='px-6 py-5'>
          <SheetTitle className='text-zinc-900 rtl:text-right'>
            {t(header)}
          </SheetTitle>
        </SheetHeader>
        <div>
          {mode === 'CREATE' ? (
            <DatalabForm
              onClose={onClose}
              formAction='CREATE'
              datalabShape={datalabShape}
            />
          ) : (
            <DatalabForm
              onClose={onClose}
              formAction={mode}
              datalabEntries={datalabEntries}
              datalabShape={datalabShape}
              onDelete={onDelete}
              onEdit={onEdit}
            />
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default DatalabSideSheet;
