import useTranslation from 'components/customHooks/useTranslation';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'libraryV2/ui/alert-dialog';

type SingleDeleteProps = {
  userIds: [number];
};

type BulkDeleteProps = {
  userIds: number[];
};
type DeleteWarningAlertProps = {
  renderWarningModal: boolean;
  setRenderWarningModal: React.Dispatch<React.SetStateAction<boolean>>;
  isSingleDelete: boolean;
  handleUserDeletion: () => Promise<void>;
  table?: any;
} & (SingleDeleteProps | BulkDeleteProps);

interface AlertMessageProps {
  userIds: number[];
  isSingleDelete: boolean;
}

export const DeleteWarningAlert: React.FC<DeleteWarningAlertProps> = ({
  renderWarningModal: render,
  setRenderWarningModal,
  userIds,
  handleUserDeletion,
  isSingleDelete,
  table,
}) => {
  const { t } = useTranslation();
  const handleDelete = async () => {
    await handleUserDeletion();
    setRenderWarningModal(false);
    table?.resetRowSelection();
  };

  return (
    <AlertDialog open={render} onOpenChange={setRenderWarningModal}>
      <AlertDialogContent className='bg-white'>
        <AlertDialogHeader>
          <AlertDialogTitle className='rtl:text-right'>
            {isSingleDelete
              ? t('Delete Team Member')
              : t('Delete Team Members')}
          </AlertDialogTitle>
          <AlertDialogDescription>
            <AlertMesage userIds={userIds} isSingleDelete={isSingleDelete} />
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className='rtl:flex-row-reverse'>
          <AlertDialogCancel>{t('No')}</AlertDialogCancel>
          <AlertDialogAction
            className='bg-[#FF0000] hover:bg-[#FF0000] rtl:ml-2'
            onClick={handleDelete}
          >
            {t('Yes! Delete')}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const AlertMesage: React.FC<AlertMessageProps> = ({
  userIds,
  isSingleDelete,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {isSingleDelete ? (
        <p className='text-sm rtl:text-right text-textSecondary'>
          {t(
            'Are you sure you want to remove this team member? This action cannot be undone and will permanently remove all access they have.'
          )}
        </p>
      ) : (
        <p className='text-sm rtl:text-right text-textSecondary'>
          {t(
            `Are you sure you want to delete {{${userIds.length}}} team members? This action cannot be undone and will permanently remove all the access they have.`
          )}
        </p>
      )}
    </>
  );
};
