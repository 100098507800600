import { Link } from 'library';
import useTranslation from 'components/customHooks/useTranslation';

interface SettingsPageHeaderProps {
  title: string;
  subtitle?: string;
  docUrl?: string;
  docUrlText?: string;
  isDocUrlVisible?: boolean;
}

const SettingsPageHeader: React.FC<SettingsPageHeaderProps> = ({
  title,
  subtitle,
  docUrl,
  docUrlText,
  isDocUrlVisible = true
}) => {
  const { t } = useTranslation();
  return (
    <div className='w-full'>
      <div className='px-4 pt-2.5 pb-[9px] mb-6 border-b border-border-light'>
        <h2 className='text-textPrimary'>{t(title)}</h2>
      </div>
      {subtitle && (
        <p className='max-w-2xl px-5 mb-5 subtitle-medium mt-[6px]'>
          {t(subtitle)}
          {docUrl && docUrlText && isDocUrlVisible &&(
            <Link
              url={docUrl}
              openInNewTab={true}
              showUnderline={true}
            >
              {t(docUrlText)}
            </Link>
          )}
        </p>
      )}
    </div>
  );
};
export default SettingsPageHeader;
