import { Column } from '@tanstack/react-table';
import * as React from 'react';

import useTranslation from 'components/customHooks/useTranslation';
import { Button } from 'libraryV2/ui/button';
import { Checkbox } from 'libraryV2/ui/checkbox';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from 'libraryV2/ui/command';
import { Popover, PopoverContent, PopoverTrigger } from 'libraryV2/ui/popover';
import { UserCog } from 'lucide-react';

interface UserRoleFilterProps<TData, TValue> {
  column?: Column<TData, TValue>;
  title?: string;
  options: {
    label: string;
    value: string;
    icon?: React.ComponentType<{ className?: string }>;
  }[];
  resetKey?: number;
}

export function UserRoleFilter<TData, TValue>({
  column,
  title,
  options,
  selectedValues,
  setSelectedValues,
  resetKey,
}: UserRoleFilterProps<TData, TValue> & {
  selectedValues: string[];
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const { t } = useTranslation();
  const [localSelectedValues, setLocalSelectedValues] =
    React.useState(selectedValues);
  const [open, setOpen] = React.useState(false);
  const facets = column?.getFacetedUniqueValues();
  const selectedSet = new Set(localSelectedValues);

  React.useEffect(() => {
    setLocalSelectedValues([]);
  }, [resetKey]);

  const applyFilter = () => {
    setSelectedValues(localSelectedValues);
    column?.setFilterValue(
      localSelectedValues.length ? localSelectedValues : undefined
    );
    setOpen(false);
  };

  const clearFilter = () => {
    setLocalSelectedValues([]);
    setSelectedValues([]);
    column?.setFilterValue(undefined);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          onClick={() => setOpen(true)}
          variant='outline'
          size='sm'
          className='h-8 border-dashed'
        >
          <UserCog className='ltr:mr-2 rtl:ml-2 h-4 w-4' />
          {title}
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-[200px] p-0 bg-white' align='start'>
        <Command>
          <CommandList>
            <CommandEmpty>{t('No results found.')}</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                const isSelected = selectedSet.has(option.value);
                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      const newSelectedValues = isSelected
                        ? localSelectedValues.filter(
                            (value) => value !== option.value
                          )
                        : [...localSelectedValues, option.value];
                      setLocalSelectedValues(newSelectedValues);
                    }}
                  >
                    <Checkbox
                      checked={isSelected}
                      className={`ltr:mr-2 rtl:ml-2  ${
                        isSelected
                          ? 'bg-primary text-white ring-0 border-0'
                          : ''
                      }`}
                    />
                    {option.icon && (
                      <option.icon className='ltr:mr-2 rtl:ml-2 h-4 w-4 text-muted-foreground' />
                    )}
                    <span>{t(option.label)}</span>
                    {facets?.get(option.value) && (
                      <span
                        className='ltr:ml-auto rtl:mr-auto flex h-4 w-4 items-center
                        justify-center font-mono text-xs'
                      >
                        {facets.get(option.value)}
                      </span>
                    )}
                  </CommandItem>
                );
              })}
            </CommandGroup>
            {selectedSet.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup className='flex items-center justify-center gap-1'>
                  <CommandItem
                    onSelect={clearFilter}
                    className='inline-flex items-center text-center hover:cursor-pointer ltr:mr-2 rtl:ml-2'
                  >
                    {t('Clear Filter')}
                  </CommandItem>
                  <CommandItem
                    onSelect={applyFilter}
                    className='inline-flex items-center text-center text-white
                     bg-primary rounded-lg hover:cursor-pointer'
                  >
                    {t('Apply Filter')}
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
