import { Link } from '@reach/router';
import { SelectedTeamInterface } from 'index';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import { FC, useState } from 'react';
import { ravenNavMenu } from 'utilities/utils';
import { status as Environment } from 'utilities/config';
import {
  RavenNavIconActive,
  RavenNavIconDefault,
  RavenNavIconHover,
} from 'pages/raven/assets/RavonNavIcons';
interface RavenNavComponentProps {
  selectedProject: SelectedTeamInterface;
  activeNavId: number;
  updateActiveNav: (id: number) => void;
}

const allowedTeamIds = [1153, 5179, 9];

export const RavenNavComponent: FC<RavenNavComponentProps> = ({
  selectedProject,
  activeNavId,
  updateActiveNav,
}) => {
  const [buttonState, setButtonState] = useState<
    'default' | 'hover' | 'selected'
  >('default');

  const isLiveAllowedTeam =
    allowedTeamIds.includes(+selectedProject?.id) &&
    Environment === 'production';
  const isStaging = Environment === 'staging';
  const shouldRenderNav = isLiveAllowedTeam || isStaging;

  const renderLogo = () => {
    if (buttonState === 'selected' || activeNavId === ravenNavMenu.id) {
      return <RavenNavIconActive key={'btn-active-raven'} />;
    } else if (buttonState === 'hover') {
      return <RavenNavIconHover key={'btn-hover-raven'} />;
    }
    return <RavenNavIconDefault key={'btn-default-raven'} />;
  };
  // feature-flags for stage & live
  if (!shouldRenderNav) {
    return null;
  }

  return (
    <TooltipProviderCustomised side='right' content='Raven'>
      {/* @ts-ignore  */}
      <Link
        className='w-9 h-9'
        to={`/projects/${selectedProject?.id}/raven`}
        aria-current={activeNavId === 0 ? 'page' : undefined}
        onClick={() => {
          updateActiveNav(ravenNavMenu?.id);
          setButtonState('selected');
        }}
        onMouseEnter={() => setButtonState('hover')}
        onMouseLeave={() => setButtonState('default')}
      >
        {renderLogo()}
      </Link>
    </TooltipProviderCustomised>
  );
};
