import { FC, useRef } from 'react';
import { useOnClickOutside } from './hooks/useOutsideClick';
import { RavenDashboard } from './components/ravenDashboard';
interface IRavenProps {}

export const Raven: FC<IRavenProps> = () => {
  const ref = useRef(null);

  useOnClickOutside(ref, (e) => {
    // will handle form draft saving here later
    console.log('Clicked outside!');
    e.preventDefault();
  });

  return (
    <div
      ref={ref}
      data-testid='gpt-agent-container'
      className='h-full w-full flex-1 min-h-[calc(100vh-2px)] flex flex-col bg-white ltr:border-l rtl:border-r border-border'
    >
      <RavenDashboard />
    </div>
  );
};
