import { Fragment, useEffect, useRef, useState } from 'react';
import SearchField from './SearchField';
import { Listbox, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid';
import { EmptyFileIcon } from 'pages/inbox/assets/iconComponent/EmptyStateIcon';
import TextWithTooltip from 'library/text';
import { IOrder } from 'pages/automationWorkflow/interface';
import {
  CircleSpinner,
  Tooltip,
  useDynamicSearchField,
  XMarkIcon,
} from 'pages/automationWorkflow/export';
import { containsHtmlTag } from 'pages/automationWorkflow/utility/function';

interface Props {
  value: any;
  condition: IOrder;
  representType: string;
  shouldDisableField?: boolean;
  handleSelectedValue: (selectedValue: any) => void;
}

const DynamicSearchField: React.FC<Props> = ({
  condition,
  value = '',
  representType = 'singleSelect',
  handleSelectedValue,
  shouldDisableField = false,
}) => {
  const [query, setQuery] = useState<string>('');
  const [options, setOptions] = useState<any>([]);
  const [staticOptions, setStaticOptions] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('No Result Found');
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const listboxRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef(null);

  const { conditionComponentDynamicApiCallFunc } = useDynamicSearchField();

  const { api_request_data } = condition;
  const { response, ...request } = api_request_data;

  const api_call = {
    isStatic: false,
    request,
    response,
  };

  // Event listener for outside click
  const handleOutsideClick = (e: MouseEvent) => {
    if (listboxRef.current && !listboxRef.current.contains(e.target as Node)) {
      setIsOpen(false);
    }
  };

  // Attach event listener when the component mounts
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleSearchResponse = (response: {
    success: boolean;
    data: any[];
    error: string;
  }) => {
    if (response.success) {
      setOptions([...response?.data]);
      if (!!api_call?.isStatic) {
        setStaticOptions([...response?.data]);
      }
    } else {
      setOptions([]);
      setError(response?.error);
    }
    setLoading(false);
  };

  const handleSearch = () => {
    if (!!api_call?.isStatic && staticOptions?.length > 0) {
      const filteredOptions = staticOptions.filter((data: any) =>
        data?.title.toLowerCase().includes(query.toLowerCase())
      );
      setOptions(filteredOptions);
    } else {
      setLoading(true);
      const { request, response } = api_call;
      conditionComponentDynamicApiCallFunc(
        request,
        response,
        handleSearchResponse,
        query
      );
    }
  };

  useEffect(() => {
    handleSearch();
    //eslint-disable-next-line
  }, [query]);

  const handleMultiSelectOptionOnClick = (option: any) => {
    const optionValue = option?.value;
    let updatedOptions = value;

    if (value.some((obj: any) => obj?.value === optionValue)) {
      updatedOptions = updatedOptions.filter(
        (optionObj: any) => optionObj?.value !== optionValue
      );
      const filteredSelectedOptions = selectedOptions.filter(
        (op: any) => op?.value !== option?.value
      );
      setSelectedOptions([...filteredSelectedOptions]);
    } else {
      setSelectedOptions([...selectedOptions, option]);
      updatedOptions = [...value, { ...option, label: option?.title }];
    }

    handleSelectedValue([...updatedOptions]);
  };

  const loadingView = () => {
    return (
      <div className='w-full p-4 flex items-center justify-center'>
        <CircleSpinner className='animate-spin ml-1 h-5 w-5 text-primary inline ' />
      </div>
    );
  };

  const renderEmptyListView = () => {
    return (
      <div className='p-8 flex flex-col justify-center items-center'>
        <EmptyFileIcon />
        <span className='block text-sm text-center font-normal text-gray-500 mt-2'>
          {error}
        </span>
      </div>
    );
  };

  const renderSingleSelectListView = () => {
    return (
      <Listbox.Options>
        {options.map((option: any, index: number) => (
          <Listbox.Option key={index} value={option}>
            {({ active }) => (
              <div
                className={`${
                  active ? 'bg-gray-100' : ''
                } cursor-pointer select-none p-2 flex items-center justify-between`}
                onClick={() => {
                  if (
                    !!value &&
                    value?.length > 0 &&
                    value[0]?.value === option?.value
                  ) {
                    handleSelectedValue([]);
                    setSelectedOptions([]);
                  } else {
                    handleSelectedValue([{ ...option, label: option?.title }]);
                    setSelectedOptions([option]);
                  }
                  setIsOpen(false);
                }}
              >
                <div className='flex items-center w-full justify-between flex-row'>
                  <div className='w-[70%] min-w-[70%] flex-1'>
                    <TextWithTooltip
                      text={option?.title}
                      dataFor={`${option?.title}_${index}`}
                      dataTip={option?.title}
                      className={'z-[9999] text-sm text-gray-600 font-normal'}
                      toolTipPosition='left'
                    />
                  </div>

                  <div className=' float-right w-full flex items-center justify-end'>
                    {!!option?.subTitle &&
                      !containsHtmlTag(option?.subTitle) && (
                        <TextWithTooltip
                          text={option?.subtitle}
                          dataFor={`${option?.subtitle}_${index}`}
                          dataTip={option?.subtitle}
                          className='z-[9999] text-sm text-gray-600 font-normal text-right'
                          toolTipPosition='left'
                        />
                      )}
                    {value === option?.value && (
                      <CheckIcon className='w-5 h-5 text-green-500' />
                    )}
                  </div>
                </div>
              </div>
            )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    );
  };

  const renderMultiSelectListView = () => {
    return (
      <Listbox.Options>
        {options.map((option: any, index: number) => (
          <Listbox.Option key={index} value={option}>
            {({ active }) => (
              <div
                className={`${
                  active ? 'bg-gray-100' : ''
                } cursor-pointer select-none p-2 flex items-center justify-between`}
                onClick={() => handleMultiSelectOptionOnClick(option)}
              >
                <input
                  id={`${option?.value}_${index}`}
                  name={option?.value}
                  type='checkbox'
                  checked={
                    !!value &&
                    value.some((obj: any) => obj?.value === option?.value)
                  }
                  className='w-4 h-4 border-gray-300 rounded text-primary mr-2 focus:ring-primary'
                  onChange={() => {}}
                />
                <div className='flex w-[95%] items-center justify-between flex-row'>
                  <div className='w-[70%] min-w-[70%] flex-1'>
                    <TextWithTooltip
                      text={option?.title}
                      dataFor={`${option?.value}_${index}`}
                      dataTip={option?.title}
                      className={'z-[9999]'}
                    />
                  </div>

                  {!!option?.subTitle && !containsHtmlTag(option?.subTitle) && (
                    <div className='float-right ml-auto w-full flex items-center justify-end '>
                      <TextWithTooltip
                        text={option?.subtitle}
                        dataFor={`${option?.subtitle}_${index}`}
                        dataTip={option?.subtitle}
                        className={
                          'z-[9999] text-sm text-gray-600 font-normal text-right'
                        }
                        toolTipPosition='left'
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </Listbox.Option>
        ))}
      </Listbox.Options>
    );
  };

  const renderListBodyView = () => {
    if (loading) return loadingView();
    else if (!!options && options.length < 1) return renderEmptyListView();
    else if (representType.toLowerCase() === 'singleselect')
      return renderSingleSelectListView();
    else return renderMultiSelectListView();
  };

  const renderSelectedOptionView = () => {
    return (
      <>
        {value?.length !== 0 && (
          <div className='mt-2 grid grid-cols-2 gap-2'>
            {value?.map(
              (option: { label: string; value: string }, index: number) => (
                <div
                  key={index}
                  className='flex items-center justify-between bg-gray-100 rounded-xl py-1 px-4 col-span-1'
                >
                  <div className='text-gray-800 font-medium text-sm'>
                    <Tooltip text={option?.label}>
                      {option?.label.length > 15
                        ? option?.label.substring(0, 10) + '...'
                        : option?.label}
                    </Tooltip>
                  </div>
                  <div
                    className='cursor-pointer'
                    onClick={() => handleMultiSelectOptionOnClick(option)}
                  >
                    <XMarkIcon className='h-5 w-5 text-gray-800' />
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </>
    );
  };

  const renderMultiselectedValue = () => {
    return (
      <span
        className={`block pr-5 truncate text-left ${
          !!value ? 'text-gray-700' : 'text-gray-500 font-normal'
        }`}
      >
        Selected Options
      </span>
    );
  };

  const renderListViewButton = () => {
    return (
      <Listbox.Button
        disabled={shouldDisableField}
        onClick={() => {
          if (!shouldDisableField) setIsOpen(!isOpen);
        }}
        className={`relative inline-flex justify-between w-full p-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-md shadow-sm  focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary bg-white hover:bg-gray-50 ${
          shouldDisableField ? 'cursor-not-allowed bg-gray-100' : ''
        }`}
      >
        {representType.toLowerCase() === 'singleselect' && (
          <TextWithTooltip
            text={
              !!selectedOptions && selectedOptions.length > 0
                ? selectedOptions[0]?.title
                : 'Select an option'
            }
            dataFor={`singleSelect_1`}
            dataTip={
              !!selectedOptions && selectedOptions.length > 0
                ? selectedOptions[0]?.title
                : 'Select an option'
            }
            className={`z-[9999] block pr-5 truncate text-left ${
              !!value ? 'text-gray-700' : 'text-gray-500 font-normal'
            }`}
            toolTipPosition='left'
          />
        )}
        {representType.toLowerCase() === 'multiselect' &&
          renderMultiselectedValue()}
        <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
          {isOpen ? (
            <XCircleIcon
              className='w-5 h-5 text-gray-400'
              aria-hidden='true'
              onClick={() => setIsOpen(false)}
            />
          ) : (
            <ChevronDownIcon
              className='w-5 h-5 text-gray-400'
              aria-hidden='true'
            />
          )}
        </span>
      </Listbox.Button>
    );
  };

  const renderListView = () => {
    return (
      <Listbox value={value} onChange={() => {}}>
        <div className='relative mb-2' ref={listboxRef}>
          {renderListViewButton()}

          {representType.toLowerCase() === 'multiselect' &&
            renderSelectedOptionView()}

          <Transition
            show={isOpen}
            as={Fragment}
            afterEnter={() => {
              if (!!searchRef?.current) {
                searchRef?.current?.focus();
                handleSearch();
              }
            }}
          >
            {(ref) => (
              <div
                ref={ref}
                className='absolute right-0 z-10 w-full mt-2 mb-2 origin-bottom-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 '
              >
                <SearchField
                  isApiSearch={true}
                  ref={searchRef}
                  onChange={(value: string) => setQuery(value)}
                />

                <div className='w-full overflow-x-hidden  overflow-y-scroll max-h-40'>
                  {renderListBodyView()}
                </div>
              </div>
            )}
          </Transition>
        </div>
      </Listbox>
    );
  };

  return (
    <>
      <div className='col-span-6 mb-6 sm:col-span-6'>{renderListView()}</div>
    </>
  );
};

export default DynamicSearchField;
