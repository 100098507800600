import {
  AllConversationDataTooltip,
  ApproximateChargesTooltip,
} from 'pages/reportingV2/utility/content';
import { FC } from 'react';
import CustomPieChart from './PieChart';
import { startCase } from 'lodash';
import { IWhatsappConversationOverviewData } from 'pages/reportingV2/interface';

interface ConversationOverviewProps {
  isLoading: boolean;
  currency: string;
  conversationOverviewData: IWhatsappConversationOverviewData;
}

const convertToArrayOfObjects = (input: any) => {
  return Object.entries(input).map(([name, value]) => ({
    name: startCase(name),
    value,
  }));
};

export const ConversationOverview: FC<ConversationOverviewProps> = ({
  isLoading,
  currency,
  conversationOverviewData,
}) => {
  const formattedCurrency = currency === 'USD' ? '$' : currency;
  const allConversationGraphData = convertToArrayOfObjects(
    conversationOverviewData?.total_conversation_per_category || {}
  );
  const approximateChargeGraphData = convertToArrayOfObjects(
    conversationOverviewData?.total_cost_per_category || {}
  );

  const totalConversation = conversationOverviewData?.total_conversation ?? 0;

  const approximateChargesTitle = `Approximate Charges  (${formattedCurrency}${
    conversationOverviewData?.total_cost?.toFixed(2) ?? 0
  })`;

  return (
    <div className='p-0 bg-white shadow-sm space-y-4'>
      <div className=''>
        <h3 className='text-textPrimary text-base font-medium leading-6 mb-1'>
          Conversation overview
        </h3>
        <p className='text-textSecondary text-sm'>
          All data insights are approximate and may vary slightly from your
          invoices due to minor data processing differences.
        </p>
      </div>
      <div className='grid grid-cols-2 gap-5'>
        <CustomPieChart
          color='#EF7711'
          loader={isLoading}
          title={`All Conversation (${totalConversation})`}
          toolTipInfo={AllConversationDataTooltip}
          data={allConversationGraphData}
        />

        <CustomPieChart
          color='#EF7711'
          loader={isLoading}
          title={approximateChargesTitle}
          toolTipInfo={ApproximateChargesTooltip}
          data={approximateChargeGraphData}
        />
      </div>
    </div>
  );
};
