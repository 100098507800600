import useTranslation from 'components/customHooks/useTranslation';
import { useEffect, useState } from 'react';
import { DataTable } from '../../../../../libraryV2/ui/data-table';
import {
  CreateDynamicColumns,
  Input,
  SearchIcon,
  SLACreation,
  useDebounce,
  useSLA,
} from '../../export';
import { ISLAData } from '../../interface';

export default function SlaTable() {
  const {
    slaList,
    limit,
    offset,
    total,
    platformList,
    handleDeleteSla,
    handleSLASearch,
    isSLADeleteLoading,
    handleLimitUpdate,
    handlePageChange,
    handleSLAConfigurationClick,
    handleIsActiveStatusChange,
  } = useSLA();

  const [localSLAData, setLocalSLAData] = useState<ISLAData[]>([]);

  useEffect(() => {
    setLocalSLAData(slaList);
  }, [slaList]);

  const columns = CreateDynamicColumns(
    platformList,
    localSLAData,
    true,
    true,
    true,
    handleIsActiveStatusChange,
    handleDeleteSla,
    handleSLAConfigurationClick,
    isSLADeleteLoading
  );

  const handleLimitChange = async (newLimit: number) => {
    await handleLimitUpdate(newLimit);
  };

  const handlePageUpdate = async (newOffset: number) => {
    await handlePageChange(newOffset, limit);
  };

  return (
    <div className='container pb-10 mx-auto'>
      <TableHeader
        handleSLASearch={handleSLASearch}
        setLocalSLAData={setLocalSLAData}
      />
      <DataTable
        columns={columns}
        data={localSLAData}
        hasPagination={true}
        paginationProps={{
          limit: limit,
          offset: offset,
          totalRowCount: total,
          onLimitChange: handleLimitChange,
          onPageChange: handlePageUpdate,
        }}
      />
    </div>
  );
}

const TableHeader = ({
  handleSLASearch,
  setLocalSLAData,
}: {
  handleSLASearch: (
    name: string
  ) => Promise<{ status: boolean; data: ISLAData[] }>;
  setLocalSLAData: (slaList: ISLAData[]) => void;
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500); // 500ms debounce time

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await handleSLASearch(debouncedSearchTerm);
        if (response.status) {
          setLocalSLAData(response.data);
        } else {
          setLocalSLAData([]);
        }
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className='flex justify-between mb-4 rtl:flex-row-reverse'>
      <div className='relative flex items-center'>
        <SearchIcon className='absolute w-4 h-4 text-gray-500 left-2 top-3.5' />
        <Input
          placeholder={t('Search')}
          value={searchTerm}
          onChange={handleSearchInputChange}
          className='h-8 w-[150px] lg:w-[250px] pl-8 rounded-lg'
        />
      </div>
      <SLACreation />
    </div>
  );
};
