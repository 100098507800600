import { FC } from 'react';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from 'libraryV2/ui/collapsible';
import { UseFieldArrayReturn } from 'react-hook-form';
import { Cable, ChevronDownIcon, ChevronUpIcon, X } from 'lucide-react';
import { Separator } from 'libraryV2/ui/separator';

interface CustomAgentApiToolProps {
  toolData: any;
  index: number;
  fieldArrayInstance: UseFieldArrayReturn<{
    tools: any;
  }>;
}

export const CustomAgentApiTool: FC<CustomAgentApiToolProps> = ({
  toolData,
  index,
  fieldArrayInstance,
}) => {
  const updateToolOpenStatus = (status: boolean) => {
    fieldArrayInstance.update(index, { ...toolData, isOpen: status });
  };

  return (
    <Collapsible
      open={toolData?.isOpen}
      onOpenChange={updateToolOpenStatus}
      className='rounded-md border border-border-soft'
    >
      <CollapsibleTrigger className='flex w-full gap-2 items-center py-2.5 px-4'>
        <div className='h-6 w-6 flex items-center justify-center rounded border border-border-soft'>
          <Cable className='w-4 h-4 text-textPrimary-disable' />
        </div>
        <span className='text-sm font-semibold text-textPrimary'>
          Make API call
        </span>
        {toolData?.isOpen ? (
          <ChevronUpIcon className='w-5 h-5 text-textPrimary-disable' />
        ) : (
          <ChevronDownIcon className='w-5 h-5 text-textSecondary-disable' />
        )}

        <button
          onClick={(e) => {
            e.stopPropagation();
            fieldArrayInstance.remove(index);
          }}
          className='h-8 w-8 flex ml-auto items-center justify-center rounded-md bg-red-50'
        >
          <X className='w-4 h-4 text-red-500' strokeWidth={2.5} />
        </button>
      </CollapsibleTrigger>
      <CollapsibleContent className='bg-background-container rounded-md'>
        <div className='p-4'>
          <div className='bg-white space-y-3 p-4 rounded-md'></div>
        </div>
        <Separator />
        <div className='p-4'>
          <div className='bg-white p-4 rounded'></div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};
