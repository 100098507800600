import { format } from 'date-fns';
import {
  CustomAgentToolType,
  IManageGPTAgent,
  TCreateCustomAgentFormData,
  TestMessageType,
} from '../interface';
import { dummyDocumentdata } from '../components/createAgents/steps/tools/toolsSchema';
type Role = 'user' | 'assistant';

export const formatTestMessageData = (data: TestMessageType) => {
  const id = data?.id || (Math.random() * Math.random()).toString(30).slice(2);
  const content = data?.content || data?.message?.content || '';
  const source = data?.role || data?.message?.role;
  const time = data?.created_at
    ? // @ts-expect-error
      format(data?.created_at, 'hh:mm a')
    : format(new Date(), 'hh:mm a');
  const systemUpdates = data?.reasonings || [];

  const result = {
    id,
    content,
    source: source as Role,
    time,
    systemUpdates,
  };

  return result;
};

export const mapApiResponseToState = (
  apiData: IManageGPTAgent
): TCreateCustomAgentFormData => {
  return {
    id: apiData?.id || '',
    status: apiData?.status || 'draft',
    name: apiData?.title || '',
    public_description: apiData?.description || '',
    backstory: apiData?.instruction,
    role: apiData?.role || '',
    model_used: apiData?.gptmodel_id,
    memory_limit: apiData?.memory_limit || 5,
    temperature: apiData?.temperature || 0.0,
    top_p: apiData?.top_p || 0.0,
  };
};

export const calculateCompletedStepsFromAgentData = (
  agentData: TCreateCustomAgentFormData
): number => {
  if (agentData?.status === 'active') {
    return 6;
  }
  if (agentData?.model_used) {
    return 5;
  }
  if (agentData?.backstory && agentData?.role) {
    return 2;
  }
  if (agentData?.name && agentData?.public_description) {
    return 1;
  }
  return 0;
};

export const getDefaultToolData = (toolType: CustomAgentToolType) => {
  const baseData = {
    id: (Date.now() * Math.random()).toString(30).slice(2),
    type: toolType,
    isOpen: true,
  };

  switch (toolType) {
    case 'api':
      return {
        ...baseData,
        data: {
          method: 'GET',
          url: '',
          headers: [{ key: '', value: '' }],
          requestData: '',
        },
      };
    case 'document':
      return {
        ...baseData,
        data: {
          title: '',
          modelUsed: '',
          description: '',
          documents: dummyDocumentdata,
        },
      };
    case 'website':
      return {
        ...baseData,
        data: {
          title: '',
          description: '',
          url: '',
          pages: [],
        },
      };
  }
};
