import { ICustomAgentFormSteps } from 'pages/raven/interface';
import { FC } from 'react';
import { Instruction } from './steps/Instruction';
import { GeneralInfo } from './steps/GeneralInfo';
import { Tools } from './steps/tools';
import { Configuration } from './steps/Configuration';

import { Deploy } from './steps/Deploy';
import { TestCustomAgent } from './steps/testCustomAgent/index';

interface AgentCreationFormContainerProps {
  currentStep: ICustomAgentFormSteps;
  onPreviousStep: () => void;
  onNextStep: () => void;
}

export const AgentCreationFormContainer: FC<
  AgentCreationFormContainerProps
> = ({ currentStep, onNextStep, onPreviousStep }) => {
  switch (currentStep?.id) {
    case 1:
      return <GeneralInfo step={currentStep} onNextStep={onNextStep} />;
    case 2:
      return (
        <Instruction
          step={currentStep}
          onNextStep={onNextStep}
          onPreviousStep={onPreviousStep}
        />
      );
    case 3:
      return (
        <Tools
          step={currentStep}
          onNextStep={onNextStep}
          onPreviousStep={onPreviousStep}
        />
      );
    case 4:
      return (
        <Configuration
          step={currentStep}
          onNextStep={onNextStep}
          onPreviousStep={onPreviousStep}
        />
      );
    case 5:
      return (
        <TestCustomAgent
          step={currentStep}
          onNextStep={onNextStep}
          onPreviousStep={onPreviousStep}
        />
      );
    case 6:
      return <Deploy />;

    default:
      return null;
  }
};
