'use client';
import { ColumnDef } from '@tanstack/react-table';
import TranslationWrapper from 'components/customHooks/useTranslationWrapper';
import { Button } from 'libraryV2/ui/button';
import { Checkbox } from 'libraryV2/ui/checkbox';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import { cn } from 'libraryV2/utils';
import { FileText } from 'lucide-react';
import defaultMediaImage from '../../../assets/images/defaultMediaImage.svg';
import { PdfFileIcon } from '../../utils';
import { ISavedReply, ISavedReplyAttachment } from '../SavedReplySheet';
import { DataTableColumnHeader } from './DataTableColumnHeader';
import { DataTableRowActions } from './DataTableRowActions';

export const columns: ColumnDef<ISavedReply>[] = [
  {
    id: 'select',
    header: ({ table }) => {
      const isRowSelected =
        table.getIsAllPageRowsSelected() ||
        (table.getIsSomePageRowsSelected() && 'indeterminate');
      return (
        <Checkbox
          checked={isRowSelected}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label='Select all'
          className={cn({
            'bg-primary translate-y-[2px] text-white ring-0 border-0':
              isRowSelected,
          })}
          IsSomePageRowsSelected={!!isRowSelected}
        />
      );
    },
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        aria-label='Select row'
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        className={cn({
          'bg-primary text-white ring-0 border-0': row.getIsSelected(),
        })}
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'title',
    header: ({ column }) => (
      <DataTableColumnHeader
        className='text-zinc-500 font-medium'
        column={column}
        title='Keywords'
      />
    ),
    cell: ({ row }) => {
      const title: string = row.getValue('title') ?? '';
      return (
        <TooltipProviderCustomised content={title.length > 14 ? title : ''}>
          <div className='w-[100px] font-medium truncate text-zinc-900'>
            {row.getValue('title')}
          </div>
        </TooltipProviderCustomised>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'text',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        className='text-zinc-500 font-medium'
        title='Saved replies'
      />
    ),
    cell: ({ row }) => {
      const text: string = row.getValue('text');
      return (
        <div className='flex'>
          <span className='max-w-[400px] text-sm truncate font-normal'>
            {text}
          </span>
        </div>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'attachments',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        className='text-zinc-500 font-medium'
        title='Attachments'
      />
    ),
    cell: ({ row }) => {
      const rawData: ISavedReplyAttachment[] = row.getValue('attachments');
      return <AttachmentColumn attachments={rawData} />;
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'for_team',
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        className='text-zinc-500 font-medium'
        title='Visible To'
      />
    ),
    cell: ({ row }) => {
      const value =
        row.getValue('for_team') === true ? 'Everyone in the team' : 'Only me';
      return (
        <div className='flex items-center'>
          <TranslationWrapper text={value} />
        </div>
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: 'actions',
    cell: ({ row }) => <DataTableRowActions row={row} />,
  },
];

const AttachmentColumn = ({
  attachments,
}: {
  attachments: ISavedReplyAttachment[];
}) => {
  const attachmentList = Array.isArray(attachments) ? attachments : [];
  if (!attachmentList.length) {
    return null;
  }
  const asset = attachmentList[0];
  const renderSingleAttachmentView = (attachment: ISavedReplyAttachment) => {
    switch (attachment.type) {
      case 'image': {
        return (
          <div
            key={attachment?.id}
            className='h-5 w-5 flex-shrink-0 cursor-pointer rounded-md border border-zinc-200'
          >
            <img
              src={attachment?.url}
              className='h-full w-full rounded-md object-cover'
              alt={attachment?.name}
              onError={(e) => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = defaultMediaImage;
              }}
            />
          </div>
        );
      }
      case 'video': {
        return (
          <div
            key={attachment?.id}
            className='h-5 max-h-5 flex-shrink-0 w-5 grid rounded border border-zinc-200'
          >
            <video className='object-fill rounded-md h-full max-h-5 w-full'>
              <source src={attachment?.url} />
            </video>
          </div>
        );
      }
      default: {
        return (
          <div
            key={attachment?.id}
            className='h-5 group flex-shrink-0 relative w-5 '
          >
            {attachment?.name?.endsWith('.pdf') ? (
              <PdfFileIcon size={20} />
            ) : (
              <FileText className='w-5 h-5 text-zinc-900' />
            )}
          </div>
        );
      }
    }
  };
  return (
    <div className='flex gap-1.5'>
      <div className='flex gap-2 w-fit p-1 border border-zinc-200 rounded'>
        {renderSingleAttachmentView(asset)}
        <p className='flex text-xs text-zinc-900'>
          <span className='block w-[60px] truncate'>{asset.name}</span>
          <span>
            {asset.name.length > 10 ? asset.name.split('.').pop() : ''}
          </span>
        </p>
      </div>

      {attachmentList.length > 1 ? (
        <Button
          variant={'ghost'}
          className='h-auto px-1.5 py-1 text-xs hover:bg-zinc-100 border border-zinc-200 rounded'
        >
          {`+${attachmentList.length - 1}`}
        </Button>
      ) : null}
    </div>
  );
};
