import useTranslation from 'components/customHooks/useTranslation';
import {
  Button,
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  ReactTable as Table,
} from '../../../export';

interface DataTablePaginationProps<TData> {
  table?: Table<TData>;
  limit: number;
  offset: number;
  totalRowCount: number;
  onLimitChange: (newLimit: number) => void;
  onPageChange: (newOffset: number) => void;
  selectedRowIds: string[];
}

export function DataTablePagination<TData>({
  limit,
  offset,
  totalRowCount,
  onLimitChange,
  onPageChange,
  selectedRowIds,
}: DataTablePaginationProps<TData>) {
  const { t } = useTranslation();
  const handleLimitChange = (newLimit: string) => {
    onLimitChange(Number(newLimit));
  };

  const handlePageChange = (newOffset: number) => {
    onPageChange(newOffset);
  };

  const currentPage = Math.ceil(offset / limit) + Number(!!totalRowCount);
  const totalPage = Math.ceil(totalRowCount / limit);

  const renderPageLimitSelectionMenu = () => {
    return (
      <div className='flex items-center ltr:space-x-2 rtl:mx-2'>
        <p className='text-sm font-medium rtl:ml-2'>{t('Rows per page')}</p>
        <Select value={`${limit}`} onValueChange={handleLimitChange}>
          <SelectTrigger className='h-8 w-[70px]'>
            <SelectValue placeholder={`${limit}`} />
          </SelectTrigger>
          <SelectContent
            side='top'
            className='rounded-lg bg-white shadow-lg border border-gray-200'
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <SelectItem
                key={pageSize}
                value={`${pageSize}`}
                className='w-full bg-white hover:bg-gray-100 rounded-md'
              >
                {pageSize}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    );
  };

  return (
    <div className='flex items-center justify-between px-2 py-3 bg-white rounded-b-md'>
      <div className='flex-1 text-sm text-muted-foreground'>
        {selectedRowIds?.length } {t('of')}{' '}
        {totalRowCount} {t('row(s) selected.')}
      </div>
      <div className='flex items-center space-x-6 lg:space-x-8'>
        {renderPageLimitSelectionMenu()}
        <div className='flex items-center justify-center text-sm font-medium'>
          {`${t('Page')} ${currentPage} ${t('of')} ${totalPage}`}
        </div>
        <div className='flex items-center space-x-2'>
          <Button
            variant='outline'
            className='hidden w-8 h-8 p-0 lg:flex rtl:order-4'
            onClick={() => handlePageChange(0)}
            disabled={offset === 0}
          >
            <span className='sr-only'>{t('Go to first page')}</span>
            <DoubleArrowLeftIcon className='w-4 h-4' />
          </Button>
          <Button
            variant='outline'
            className='w-8 h-8 p-0 rtl:order-3'
            onClick={() => handlePageChange(offset - limit)}
            disabled={offset === 0}
          >
            <span className='sr-only'>{t('Go to previous page')}</span>
            <ChevronLeftIcon className='w-4 h-4' />
          </Button>
          <Button
            variant='outline'
            className='w-8 h-8 p-0 rtl:order-2'
            onClick={() => handlePageChange(offset + limit)}
            disabled={offset + limit >= totalRowCount}
          >
            <span className='sr-only'>{t('Go to next page')}</span>
            <ChevronRightIcon className='w-4 h-4' />
          </Button>
          <Button
            variant='outline'
            className='hidden w-8 h-8 p-0 lg:flex'
            onClick={() =>
              handlePageChange((Math.ceil(totalRowCount / limit) - 1) * limit)
            }
            disabled={offset + limit >= totalRowCount}
          >
            <span className='sr-only'>{t('Go to last page')}</span>
            <DoubleArrowRightIcon className='w-4 h-4' />
          </Button>
        </div>
      </div>
    </div>
  );
}
