import { MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/solid';
import { navigate } from '@reach/router';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';
import { InboxIcon } from 'evergreen-ui';
import { PlatformInterface, SelectedTeamInterface } from 'index';
import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Tick from '../../assets/icons/component/Tick';
import aliceIcon from '../../assets/images/aliceLogo.svg';
import TeamCreation from '../../pages/settings/teamManagement/TeamCreation';
import { plansProperty } from '../../utilities/content';

type teamlistTypes = {
  teams: SelectedTeamInterface[];
  selectedTeam: SelectedTeamInterface;
  setSelectedProject: (project: SelectedTeamInterface) => void;
  updateSelectedProjectOnInbox: (project: SelectedTeamInterface) => void;
  updateSelectedProject: (project: SelectedTeamInterface) => void;
  fetchPlatformList: (teamId: number) => void;
  updateSelectedNav: (navId: number) => void;
  setSelectedPlatform: (selectedPlatform: PlatformInterface) => void;
  logoutAPI: () => void;
  toggleUserStatus: (status: string) => void;
  trialAvailed: boolean;
  trialRemaining: number;
  fetchDashboardCardData: (teamId: number) => void;
  toggleMenuModal: Function;
  fetchProject: () => void;
};

export enum Position {
  TOP = 'top',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  BOTTOM = 'bottom',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  LEFT = 'left',
  RIGHT = 'right',
}

const TeamList = (props: teamlistTypes) => {
  const { t } = useTranslation();
  const [teamSearch, setTeamSearch] = React.useState('');
  const [showTeamCreationModal, setShowTeamCreationModal] =
    React.useState(false);

  const isChecked = (teamId: number | undefined) =>
    props?.selectedTeam?.id === teamId;

  const { isPartnerRestricted, currentPartnerInfo } = usePartner();
  const shouldRenderPartnerLogAsDefaultLogo = isPartnerRestricted(
    'teamlist/function/renderDefaultLogo'
  );

  const renderDefaultLogo = () => {
    if (shouldRenderPartnerLogAsDefaultLogo && currentPartnerInfo?.icon) {
      return currentPartnerInfo?.icon;
    } else {
      return (
        <img
          id='teamImage'
          className={`w-10 h-10 inline-block rounded-md border border-gray-200`}
          src={aliceIcon}
          alt='team_avatar'
        />
      );
    }
  };

  const changeTeam = async (teamElem: SelectedTeamInterface | null) => {
    if (teamElem) {
      let mainData: any = props?.teams.filter(
        (teamLocal: any) => teamLocal?.id === teamElem?.id
      );
      if (mainData.length > 0) {
        mainData = mainData[0];
      } else {
        mainData = null;
      }
      await props.setSelectedProject(mainData);
      await props.updateSelectedProject(mainData);
      await props.updateSelectedProjectOnInbox(mainData);
      // TODO: This is a legacy API, remove when all dependencies are handled.
      // await props.fetchPlatformList(teamElem?.id);
      //await props.fetchDashboardCardData(teamElem?.id);
      await props.updateSelectedNav(0);
      navigate('/dashboard');
      props.toggleMenuModal();
    }
  };

  const renderTeams = (teamElem: SelectedTeamInterface, index: number) => {
    const hasOwnLogo = !!teamElem?.image && teamElem?.image.length !== 0;
    return (
      <div
        key={`${teamElem?.id}`}
        data-for={`${teamElem?.id}`}
        data-tip={teamElem?.name}
      >
        <div
          className='flex justify-center w-full h-auto px-4 py-3 border-b align-center'
          onClick={() => changeTeam(teamElem)}
        >
          <div className='flex justify-center w-full gap-2 cursor-pointer'>
            <div className='w-10' id=''>
              {hasOwnLogo ? (
                <img
                  className={`rounded-md w-10 h-10 border-gray-200 border ${
                    teamElem?.image ? '' : 'p-1'
                  }`}
                  src={teamElem?.image || aliceIcon}
                  alt=''
                  onError={(e) => {
                    e.currentTarget.onerror = null;
                    e.currentTarget.src = aliceIcon;
                  }}
                />
              ) : (
                renderDefaultLogo()
              )}
            </div>
            <div className='w-[60%] justify-center flex flex-column px-1'>
              <p className='w-full text-sm font-semibold text-gray-900 truncate'>
                {teamElem?.name}
              </p>
              {teamElem?.creator?.is_trial_user &&
              !teamElem?.subscription_anchor_time ? (
                <p
                  className='w-full text-xs text-gray-500 capitalize truncate'
                  role='none'
                >
                  {t(`{{Free Trial}}. {{${teamElem?.team_members}}} members`)}
                </p>
              ) : (
                <>
                  {teamElem?.subscription_plan?.name ===
                    plansProperty.free_plan.name && (
                    <p
                      className='w-full text-xs text-gray-500 capitalize truncate'
                      role='none'
                    >
                      {t(`Account Restricted`)}
                    </p>
                  )}
                  {teamElem?.subscription_plan?.name !==
                    plansProperty.free_plan.name && (
                    <p
                      className='w-full text-xs text-gray-500 capitalize truncate'
                      role='none'
                    >
                      {t(
                        `{{${teamElem?.subscription_plan?.name}}}. {{${teamElem?.team_members}}} members`
                      )}
                    </p>
                  )}
                </>
              )}
            </div>
            <div className='w-[20%] mb-5 p-1 flex flex-end justify-right align-center'>
              {isChecked(teamElem?.id) ? <Tick /> : null}
            </div>
          </div>
        </div>
        {teamElem.name.length > 17 && (
          // @ts-ignore
          <ReactTooltip
            key={index}
            id={`${teamElem?.id}`}
            place='top'
            type='dark'
            effect='float'
          />
        )}
      </div>
    );
  };

  const fromIndex = (arr: SelectedTeamInterface[]): number => {
    const indexSum = arr.map((elem: SelectedTeamInterface, index: number) => {
      if (elem?.id === props?.selectedTeam?.id) {
        return index;
      } else {
        return 0;
      }
    });
    return indexSum.filter((elem) => elem !== 0)[0];
  };

  const teamPositionMove = () => {
    const arr = props.teams;
    const toIndex = 0;
    const element = arr.splice(fromIndex(arr), 1)[0];
    arr.splice(toIndex, 0, element);
    return arr.filter((elemFilter) =>
      elemFilter?.name.toLowerCase().includes(teamSearch.trim().toLowerCase())
    );
  };

  const renderNoTeamFound = () => {
    return (
      props?.teams.filter((elemFilter) =>
        elemFilter?.name.toLowerCase().includes(teamSearch.trim().toLowerCase())
      ).length === 0 && (
        <div className='flex items-center justify-center gap-2 p-2 mt-2 text-xs font-medium text-gray-600 rounded-md cursor-default'>
          <InboxIcon className='w-4 h-4' />
          <span>{t('Not Found')}</span>
        </div>
      )
    );
  };

  const renderSearchMoudle = () => {
    return (
      props.teams.length > 4 && (
        <div className='sticky z-30 flex p-3 py-2 bg-white border-b rounded-t-lg -top-px'>
          <div className='relative w-full rounded-md shadow-sm'>
            <div className='absolute inset-y-0 flex items-center w-full pl-3 pointer-events-none ltr:left-0 rtl:right-2'>
              <MagnifyingGlassIcon
                className='w-5 h-5 text-gray-400'
                aria-hidden='true'
              />
            </div>
            <input
              type='search'
              value={teamSearch}
              disabled={false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTeamSearch(e.target.value);
              }}
              className='block w-full border-gray-300 rounded-md ltr:pl-10 rtl:pr-8 focus:ring-primary focus:border-primary sm:text-sm'
              placeholder={t('Search Team')}
            />
          </div>
        </div>
      )
    );
  };

  return (
    <div>
      {renderSearchMoudle()}
      <div
        tabIndex={-1}
        className={
          props.teams.length > 4
            ? 'overflow-y-scroll max-h-[265px]'
            : 'overflow-hidden max-h-[265px]'
        }
      >
        {teamPositionMove().map((teamElem, index) => {
          return renderTeams(teamElem, index);
        })}
      </div>
      {renderNoTeamFound()}
      <div
        className='flex w-full h-auto px-4 py-3 text-sm font-medium text-gray-900 truncate border-t cursor-pointer align-center'
        onClick={() => setShowTeamCreationModal(true)}
      >
        <span className='w-5 h-5 p-[3px] mr-2'>
          <PlusIcon />
        </span>
        {t('Create New Team')}
      </div>
      <TeamCreation
        showModal={showTeamCreationModal}
        setShowModal={setShowTeamCreationModal}
        changeTeam={changeTeam}
      />
    </div>
  );
};

const mapState = <T extends { auth: any; dashboard: any }>(state: T) => ({
  userName: state.auth.full_name,
  userEmail: state.auth.email,
  userAvatar: state.auth.avatar,
  userStatus: state.auth.status,
  teams: state.dashboard.projects,
  platformList: state.dashboard.platformList,
  permissionList: state.dashboard.permission.list || [],
  projectProgressInfo: state.dashboard.projectProgressInfo,
  trialAvailed: state.auth.trial_availed,
  trialRemaining: state.auth.trial_remaining,
});

const mapDispatch = (dispatch: any) => ({
  setSelectedProject: (project: SelectedTeamInterface) =>
    dispatch.dashboard.setSelectedProject(project),
  updateSelectedProjectOnInbox: (project: SelectedTeamInterface) =>
    dispatch.inbox.updateSelectedProjectOnInbox(project),
  updateSelectedProject: (project: SelectedTeamInterface) =>
    dispatch.settings.setSelectedProject(project),
  fetchPlatformList: (teamId: number) =>
    dispatch.dashboard.fetchPlatformList(teamId),
  setSelectedPlatform: (platform: PlatformInterface) =>
    dispatch.dashboard.setSelectedPlatform(platform),
  logoutAPI: () => dispatch.auth.logoutAPI(),
  toggleUserStatus: (status: string) => dispatch.auth.updateStatus(status),
  fetchDashboardCardData: (teamId: number) =>
    dispatch.dashboard.fetchDashboardCardData(teamId),
  fetchProject: () => dispatch.dashboard.fetchProject(),
  updateSelectedNav: (navId: number) =>
    dispatch.dashboard.updateSelectedNav(navId),
});

export default connect(mapState, mapDispatch)(TeamList);
