import { default as dayJs, default as dayjs } from 'dayjs';
import 'dayjs/locale/ar';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(advancedFormat);
dayjs.extend(localizedFormat);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'Just now',
    m: 'Just now',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy',
  },
});

dayjs.updateLocale('ar', {
  months: [
    'يناير',
    'فبراير',
    'مارس',
    'أبريل',
    'مايو',
    'يونيو',
    'يوليو',
    'أغسطس',
    'سبتمبر',
    'أكتوبر',
    'نوفمبر',
    'ديسمبر',
  ],
  relativeTime: {
    future: 'في %s',
    past: 'منذ %s',
    s: 'ثوان',
    m: 'دقيقة',
    mm: '%d دقائق',
    h: 'ساعة',
    hh: '%d ساعات',
    d: 'يوم',
    dd: '%d أيام',
    M: 'شهر',
    MM: '%d أشهر',
    y: 'سنة',
    yy: '%d سنوات',
  },
  formats: {
    ll: 'D MMM YYYY',
  },
});

export const getDashboardLanguage = (): string => {
  try {
    const localStorageLanguage = localStorage
      ?.getItem('language')
      ?.toLowerCase()
      ?.trim();

    if (!localStorageLanguage) {
      return 'en';
    }

    if (localStorageLanguage === 'english') {
      return 'en';
    } else if (localStorageLanguage === 'arabic') {
      return 'ar';
    }
    // add more languages here

    return 'en';
  } catch (error) {
    // In case localStorage is not available (e.g., in private browsing)
    return 'en';
  }
};

// Function to get relative or formatted time
export const getRelativeTime = (timeStamp: string) => {
  const language = getDashboardLanguage();
  dayJs.locale(language);

  const start = dayJs.unix(parseInt(timeStamp));
  const now = dayJs();
  const oneYearAgo = now.subtract(1, 'year');

  if (start.isBefore(oneYearAgo)) {
    return start.local().format('MMM D, YYYY');
  }

  return start.local().from(now);
};

export const userRolesLabels = [
  {
    value: 'supervisor',
    label: 'Supervisor',
  },
  {
    value: 'developer',
    label: 'Developer',
  },
  {
    value: 'agent',
    label: 'Agent',
  },
  {
    value: 'marketeer',
    label: 'Marketer',
  },
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'owner',
    label: 'Owner',
  },
];
