/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { WhatsappBspIcon } from 'pages/integration/utils/content';
import { CalendarDateRangePicker } from 'libraryV2/ui/CalendarDateRangePicker';
import { DateRange } from 'react-day-picker';

import {
  ReportingPlatformMultiSelect,
  IReprtingPlatforms,
} from './ReportingPlatformMultiSelect';

import { useReporting } from 'pages/reportingV2/hooks/useReporting';
import {
  appendQueryParamsToUrl,
  getWhatsappDateRangePresets,
} from 'pages/reportingV2/utility/utils';
import { SingleSelect } from 'libraryV2/extended-ui/SingleSelect';
import { Button } from 'libraryV2/ui/button';
import { cn } from 'libraryV2/utils';
import { Check, ChevronDown } from 'lucide-react';

interface props {
  platformList: IReprtingPlatforms[];
  dateRange: any;
  setDateRange: (val: any) => void;
  updateStateData: (key: string, value: any) => void;
}

const ReportingNav: React.FC<props> = ({
  platformList,
  dateRange,
  setDateRange,
  updateStateData,
}) => {
  // validate date range, fallback to current date
  dateRange = {
    from: new Date(dateRange.from || new Date()),
    to: new Date(dateRange.to || new Date()),
  };
  const {
    updatePlatformsToFilter,
    currentWhatsappMetricsTab,
    platformsToFilter,
    currentReportingTab,
  } = useReporting();

  const handleFilterClear = () => {
    //remove all platforms from url
    appendQueryParamsToUrl({ platform: null });
    updatePlatformsToFilter([]);
  };

  const handleApplyChannelFilter = (
    selectedPlatformList: IReprtingPlatforms[]
  ) => {
    // append the platform ids to the url
    appendQueryParamsToUrl({
      platforms: selectedPlatformList.map((p) => p.id).join(','),
    });
    updatePlatformsToFilter(selectedPlatformList);
  };
  const handleWaChannelFilter = (
    selectedPlatform: IReprtingPlatforms | undefined
  ) => {
    if (!selectedPlatform || selectedPlatform === undefined) {
      updatePlatformsToFilter([]);
      appendQueryParamsToUrl({
        platforms: '',
      });
      return;
    }
    // append the platform ids to the url
    appendQueryParamsToUrl({
      platforms: selectedPlatform.id.toString(),
    });
    updatePlatformsToFilter([selectedPlatform]);
  };

  const handleDateRangeSelect = (range: DateRange) => {
    setDateRange(range);
    updateStateData('dateRange', range);
  };

  const renderWaChannelSelectTrigger = (
    option: IReprtingPlatforms | undefined,
    isPopoverOpen: boolean
  ) => {
    return (
      <Button
        variant='outline'
        role='combobox'
        aria-expanded={isPopoverOpen}
        className={cn('w-[250px] px-2 overflow-hidden justify-between')}
      >
        <div className='w-full flex justify-between items-center gap-2'>
          <div className='flex items-center gap-2 text-sm font-normal'>
            {option ? (
              <>
                <img
                  src={WhatsappBspIcon}
                  alt='wa-bsp-icon'
                  className='w-4 flex-shrink-0 h-4'
                />
                <span className='truncate max-w-[200px] text-textPrimary'>
                  {option.title}
                </span>
              </>
            ) : (
              <span>Select a channel</span>
            )}
          </div>
          <ChevronDown className='w-4 flex-shrink-0 h-4' />
        </div>
      </Button>
    );
  };

  const renderChannelSelect = () => {
    if (currentReportingTab !== 'whatsapp-metrics') {
      return (
        <ReportingPlatformMultiSelect
          platformList={platformList}
          selectedPlatformList={platformsToFilter}
          onClearFilter={handleFilterClear}
          onApplyFilter={handleApplyChannelFilter}
        />
      );
    }

    return (
      <SingleSelect
        options={platformList || []}
        selectedOption={platformsToFilter[0]}
        onSelectOption={(value) => handleWaChannelFilter(value)}
        getOptionValue={(option) => option?.id?.toString() || ''}
        renderTrigger={renderWaChannelSelectTrigger}
        renderOption={(option, isSelected) => (
          <div className='flex items-center gap-2'>
            <Check
              className={cn('w-4 h-4 flex-shrink-0 opacity-0', {
                'opacity-100': isSelected,
              })}
            />
            <img src={WhatsappBspIcon} alt='wa-bsp-icon' className='w-5 h-5' />
            <span className='truncate'> {option.title} </span>
          </div>
        )}
        classNames={{
          selectTrigger: 'max-w-[200px] truncate',
          commandItem: 'truncate',
        }}
      />
    );
  };

  return (
    <div className='w-full h-[52px] sticky z-20 top-0 flex justify-between items-center px-4 bg-white border-b border-border'>
      <h3 className='text-xl font-bold leading-7 text-textPrimary'>Reports</h3>
      <div className='flex items-center gap-2'>
        {renderChannelSelect()}
        <CalendarDateRangePicker
          dateRange={dateRange}
          hideTimeInput={
            currentReportingTab === 'whatsapp-metrics' &&
            currentWhatsappMetricsTab !== 'whatsapp-template-history'
          }
          onDateSelect={handleDateRangeSelect}
          hideCalenderNavigation={currentReportingTab === 'whatsapp-metrics'}
          variant='outline'
          className='w-full'
          dayPickerdisabledProp={
            currentReportingTab === 'whatsapp-metrics'
              ? {
                  before: new Date('2024-10-01'),
                  after: new Date(),
                }
              : false
          }
          dateRangePresets={
            currentReportingTab === 'whatsapp-metrics'
              ? getWhatsappDateRangePresets(new Date())
              : []
          }
        />
      </div>
    </div>
  );
};

export default ReportingNav;
