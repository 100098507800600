import { Card, CardContent } from 'libraryV2/ui/card';
import { React } from '../../../export';
import { Info } from 'lucide-react';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import useAutomationDashboard from '../hooks/useAutomationDashboard';
import { getDayJSFormat } from 'utilities/utils';
import MetricesCardLoader from './MetricesCardLoader';
import { DateRange } from 'react-day-picker';
import { calculateDurationInDays } from 'pages/automationWorkflow/utility/function';

interface Props {
  dateRange: DateRange;
  setCachOff: (arg: boolean) => void;
  loading: boolean;
}

const AutomationMetriceCards: React.FC<Props> = ({
  dateRange,
  setCachOff,
  loading,
}) => {
  const { automationMetricsData } = useAutomationDashboard();

  const dateFormat = {
    future: 'in %s',
    past: '%s ago',
    s: 'Just now',
    m: 'Just now',
    mm: '%d min ago',
    h: '1 hour ago',
    hh: '%d hour ago',
    d: '1 day ago',
    dd: '%d day ago',
    M: '1 month ago',
    MM: '%d month ago',
    y: '1 year ago',
    yy: '%d year ago',
  };

  const automationMetrics = [
    {
      name: 'Automation Triggered',
      current_count: automationMetricsData?.automation_triggered?.current_count,
      previous_count:
        automationMetricsData?.automation_triggered?.previous_count,
      growth_percentage:
        automationMetricsData?.automation_triggered?.growth_percentage,
      TooltipContent: 'Total number of automations triggered. ',
    },
    {
      name: 'Automation Succeeded',
      current_count: automationMetricsData?.automation_succeeded?.current_count,
      previous_count:
        automationMetricsData?.automation_succeeded?.previous_count,
      growth_percentage:
        automationMetricsData?.automation_succeeded?.growth_percentage,
      TooltipContent:
        'Total number of automations that were completed successfully.',
    },

    {
      name: 'Automation Failed',
      current_count: automationMetricsData?.automation_failed?.current_count,
      previous_count: automationMetricsData?.automation_failed?.previous_count,
      growth_percentage:
        automationMetricsData?.automation_failed?.growth_percentage,
      TooltipContent:
        'Total number of automations that failed to run successfully.',
    },
  ];
  return (
    <>
      <div className='px-5 mt-3'>
        <p className='text-textSecondary text-sm'>
          Data updated{' '}
          {getDayJSFormat(automationMetricsData?.updated_at * 1000, dateFormat)}
          <span
            onClick={() => setCachOff(true)}
            className='pl-2 text-link cursor-pointer'
          >
            Refresh now
          </span>
        </p>
      </div>
      {loading ? (
        <MetricesCardLoader />
      ) : (
        <div className='flex items-center justify-between gap-3 w-full mt-2 px-4'>
          {automationMetrics.map((item, index) => (
            <Card key={index} className='w-full h-28'>
              <CardContent className='mt-3'>
                <div>
                  <div className='flex items-center justify-start gap-1'>
                    <span className='text-sm font-medium'>{item?.name}</span>
                    <TooltipProviderCustomised content={item.TooltipContent}>
                      <Info size={13} />
                    </TooltipProviderCustomised>
                  </div>
                  <div>
                    <span className='text-2xl font-bold text-textPrimary'>
                      {item?.current_count}
                    </span>
                  </div>
                  <div>
                    <span className='text-textSecondary text-xs font-medium'>
                      <span
                        className={
                          item?.growth_percentage > 0
                            ? 'text-green-500 font-semibold'
                            : item?.growth_percentage < 0
                            ? 'text-red-500'
                            : 'text-textPrimary'
                        }
                      >
                        {item?.growth_percentage?.toFixed(2)}%
                      </span>{' '}
                      from{' '}
                      <span className='text-textPrimary font-semibold'>
                        {item?.previous_count}
                      </span>{' '}
                      of previous{' '}
                      <span className='text-textPrimary font-semibold'>
                        {calculateDurationInDays(dateRange)}
                      </span>{' '}
                      days
                    </span>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      )}
    </>
  );
};

export default AutomationMetriceCards;
