import React from 'react';
import {
  EmptyPage,
  Loader2Icon,
  SLACreation,
  SlaTable,
  useSLA,
  useSLAConfig,
} from './export';
import SettingsPageHeader from '../components/SettingsPageHeader';

const SLASettings = () => {
  const { clearSLAData } = useSLAConfig();
  const {
    slaList,
    getSlaList,
    isSLAFetchingListLoading,
    handleSLAConfigurationClick,
    getAgentAccessList,
  } = useSLA();

  React.useEffect(() => {
    const fetchSLAList = async () => await getSlaList();
    const fetchAgentAccess = async () => await getAgentAccessList();
    clearSLAData();
    fetchSLAList();
    fetchAgentAccess();
    // eslint-disable-next-line
  }, []);
  
  return (
    <div>
      {isSLAFetchingListLoading && <Loading />}
      {!isSLAFetchingListLoading && (
        <>
          <SettingsPageHeader 
            title='SLA Configurations'
            subtitle="Service Level Agreement (SLA) policies help you set and maintain targets for your team's response and resolution times."
            docUrl='https://docs.myalice.ai/myalice-inbox/advanced-ticket-management/sla-configurations'
            docUrlText='Learn More'
          />
          <div className='h-[84vh] px-5'>
            {slaList.length === 0 ? (
              <NoSLAComponent />
            ) : (
              <SlaList
                slaList={slaList}
                handleSLAConfigurationClick={handleSLAConfigurationClick}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

const NoSLAComponent = () => {
  return (
    <EmptyPage
      title='No SLA Policies Found'
      titleClass={'text-textPrimary'}
      subtitle={`You don't have any SLA policies at the moment. Add a new policy to begin tracking response and resolution times.`}
    >
      <SLACreation />
    </EmptyPage>
  );
};

const SlaList = ({ slaList, handleSLAConfigurationClick }: any) => {
  return (
    <>
      <SlaTable />
    </>
  );
};

const Loading = () => {
  return (
    <div className='w-full flex justify-center items-center h-[90vh]'>
      <Loader2Icon size={48} className='mb-8 text-green-500 animate-spin' />
    </div>
  );
};

export default SLASettings;
