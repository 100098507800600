import { navigate } from '@reach/router';
import { FC } from 'react';
import { useParams } from '@reach/router';
import { useSelector } from 'react-redux';
interface DataTableRowActionsProps {
  history_id: number;
}
export const DataTableRowActions: FC<DataTableRowActionsProps> = ({
  history_id,
}) => {
  const { flowId } = useParams();
  const { selectedProject } = useSelector((state: any) => state.dashboard);
  return (
    <span
      onClick={() => {
        navigate(
          `/projects/${selectedProject?.id}/workflow-automation/${flowId}/history/${history_id}`
        );
      }}
      className='text-link cursor-pointer'
    >
      View History
    </span>
  );
};
