import { useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InvitationsType } from '../interface';
import useUserManagement from './useUserManagement';

const useInviteTeamMembers = () => {
  const dispatch = useDispatch();
  const { getAgentAccessList, getRemainningTeamMemberCount } = useUserManagement();
  const selectedProject = useSelector(
    (state: any) => state?.dashboard?.selectedProject
  );
  const selectedRoleData = useSelector(
    (state: any) => state.settingsUserManagementConfig.selectedRole
  );
  const [emails, setEmails] = useState<string[]>([]);
  const [emailInputValue, setEmailInputValue] = useState<string>('');
  const [selectedRole, setSelectedRole] = useState<number>(0);
  const [formState, setFormState] = useState<InvitationsType[]>([]);
  const [invalidEmails, setInvalidEmails] = useState<string[]>([]);
  const [duplicateEmails, setDuplicateEmails] = useState<string[]>([]);
  const [existingDuplicateEmails, setExistingDuplicateEmails] = useState<
    string[]
  >([]);

  const [isFormValid, setIsFormValid] = useState(false);
  const handleEmailInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailInputValue(e.target.value);
  };

  const handleEmailsChange = (newEmails: string[]) => {
    if(newEmails.length > 0 && newEmails.length > getRemainningTeamMemberCount()) {
      validateForm();
      clearSelectedRoleData();
      return;
    }
    setEmails(newEmails);

    const updatedFormState = newEmails.map((email) => ({
      email,
      role_id: selectedRole,
    }));
    setFormState(updatedFormState);
    validateForm();
  };

  const setAndSyncSelectedRole = (role: number) => {
    setSelectedRole(() => role);
    updateFormValidationStatus(role);
    // Update formState with the new role
    setFormState((prevFormState) =>
      prevFormState.map((invitation) => ({
        ...invitation,
        role_id: role,
      }))
    );
    validateForm();
  };

  const handleInviteTeamMemberSubmit = async () => {
    const res = await dispatch.settingsUserManagementConfig.sendMultipleInvitations({
        teamId: selectedProject?.id,
        requestBody: {
          invitations: formState,
        },
    });
    if(res.status){
      await getAgentAccessList();
    }
  };

  const updateFormValidationStatus = (currentRole: number = selectedRole) => {
    if (
      invalidEmails.length > 0 ||
      duplicateEmails.length > 0 ||
      existingDuplicateEmails.length > 0 ||
      currentRole === 0
    ) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  };

  const updateSelectedRoleData = (data: any) => {
    dispatch.settingsUserManagementConfig.updateSelectedRole(data);
  };

  const clearSelectedRoleData = () => {
    dispatch.settingsUserManagementConfig.updateSelectedRole(null);
  };

  const validateForm = () => {
    const hasErrors = 
      invalidEmails.length > 0 || 
      duplicateEmails.length > 0 || 
      existingDuplicateEmails.length > 0 || 
      getRemainningTeamMemberCount() <= 0 ||
      selectedRole === 0;
      setIsFormValid(!hasErrors);
  };

  const getFormValidationStatus = () => {
    if(
      invalidEmails.length > 0 ||
      duplicateEmails.length > 0 ||
      existingDuplicateEmails.length > 0 ||
      selectedRole === 0
    ) {
      return false;
    } else {
      return true;
    }
  }


  return {
    emails,
    setEmails,
    emailInputValue,
    setEmailInputValue,
    handleEmailInputChange,
    handleEmailsChange,
    formState,
    setFormState,
    handleInviteTeamMemberSubmit,
    setAndSyncSelectedRole,
    isFormValid,
    setIsFormValid,
    selectedRole,
    invalidEmails,
    setInvalidEmails,
    duplicateEmails,
    setDuplicateEmails,
    existingDuplicateEmails,
    setExistingDuplicateEmails,
    updateFormValidationStatus,
    selectedRoleData,
    updateSelectedRoleData,
    clearSelectedRoleData,
    getFormValidationStatus,
    validateForm,
  };
};
export default useInviteTeamMembers;
