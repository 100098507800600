import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguageKey } from 'utilities/utils';

const TranslationContext = createContext<any>(null);
const DEFAULT_LANGUAGE = 'english';

const TranslationProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch<any>();
  const dbLanguage = useSelector(
    (state: any) => state.auth?.dashboard_language || null
  );
  const activeNavId = useSelector((state: any) => state.dashboard?.navActiveId);

  const localStorageLanguage =
    localStorage.getItem('language') || DEFAULT_LANGUAGE;

  const initialLanguageRef = useRef<string | null>(null);

  const getInitialLanguage = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamValue = urlParams.get('lang');

    // Process language sources in priority order
    const languageSources = [
      urlParamValue?.trim().toLowerCase(),
      dbLanguage?.trim().toLowerCase(),
      localStorageLanguage.trim().toLowerCase(),
      DEFAULT_LANGUAGE,
    ];

    const firstValidLanguage =
      languageSources.find((lang) => lang != null) || DEFAULT_LANGUAGE;

    return getLanguageKey(firstValidLanguage);
  };

  const [dashboardLanguage, setDashboardLanguage] = useState(
    getInitialLanguage()
  );

  useEffect(() => {
    initialLanguageRef.current = dashboardLanguage;
  }, [dashboardLanguage]);

  const isRtlLanguage = ['arabic'].includes(dashboardLanguage);

  const updateLanguage = useCallback(
    async (newLanguage: string) => {
      if (!newLanguage) return; // Guard clause to prevent empty updates
      const sanitizedLanguage = getLanguageKey(
        newLanguage.trim().toLowerCase()
      );

      // Avoid unnecessary updates if the language hasn't changed
      if (sanitizedLanguage === dashboardLanguage) return;

      try {
        await dispatch.auth.updateStateData({
          key: 'dashboard_language',
          value: sanitizedLanguage,
        });

        setDashboardLanguage(sanitizedLanguage);
        localStorage.setItem('language', sanitizedLanguage);
      } catch (error) {
        console.error('Failed to update language:', error);
      }
    },
    [dispatch, dashboardLanguage]
  );

  useEffect(() => {
    const direction = dashboardLanguage === 'arabic' ? 'rtl' : 'ltr';
    document.body.setAttribute('dir', direction);

    return () => {
      document.body.removeAttribute('dir');
    };
  }, [dashboardLanguage]);

  // Synchronize language across different sources
  useEffect(() => {
    // If initial language was already set from URL or other sources, don't re-trigger
    if (initialLanguageRef.current) {
      initialLanguageRef.current = null;
      return;
    }

    // Prioritize logic: URL params > dbLanguage > localStorage
    const searchParams = new URLSearchParams(window.location.search);
    const urlParamValue = searchParams.get('lang');

    if (urlParamValue) {
      const urlLanguage = getLanguageKey(urlParamValue.trim().toLowerCase());
      if (urlLanguage && urlLanguage !== dashboardLanguage) {
        updateLanguage(urlLanguage);
        return;
      }
    }

    if (dbLanguage) {
      const sanitizedDbLanguage = getLanguageKey(
        dbLanguage.trim().toLowerCase()
      );
      if (sanitizedDbLanguage && sanitizedDbLanguage !== dashboardLanguage) {
        updateLanguage(sanitizedDbLanguage);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbLanguage, updateLanguage]);

  return (
    <TranslationContext.Provider
      value={{
        dashboardLanguage,
        updateLanguage,
        isRtlLanguage,
        activeNavId,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslationContext = () => useContext(TranslationContext);
export default TranslationProvider;
