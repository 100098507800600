'use client';

import { Link, useLocation } from '@reach/router';
import useTranslation from 'components/customHooks/useTranslation';
import { PermissionInterface } from 'index';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from 'libraryV2/ui/collapsible';
import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from 'libraryV2/ui/sidebar';
import { ChevronRight } from 'lucide-react';
import { useEffect } from 'react';
import { checkPermission } from 'utilities/utils';
import useSettings from '../hooks/useSettings';
import { ISettingsNavItem, ISettingsNavLink } from '../interface';

export function SettingsNavMenu({ items }: { items: ISettingsNavItem[] }) {
  const { t, isRtlLanguage } = useTranslation();
  const {
    permissionList,
    initializeDefaultLocationHash,
    scrollToHashElement,
    getAccessibleAndCategorizedNavItems,
  } = useSettings();

  const location = useLocation();

  useEffect(() => {
    initializeDefaultLocationHash();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    scrollToHashElement();
    // eslint-disable-next-line
  }, [location.hash]);

  const filteredItems = getAccessibleAndCategorizedNavItems(items);
  const getLinkActiveStatus = (url: string): boolean => {
    const [path, anchor] = url.split('#');
    const isActive = anchor
      ? location.hash === `#${anchor}`
      : location.pathname === path;
    return isActive;
  };

  return (
    <SidebarGroup>
      <SidebarMenu>
        {filteredItems.map((item) => (
          <Collapsible
            key={item.title}
            asChild
            defaultOpen={item.isActive}
            className='group/collapsible px-1.5 text-textPrimary '
          >
            <SidebarMenuItem>
              <CollapsibleTrigger asChild>
                <SidebarMenuButton
                  tooltip={t(item.title)}
                  className='text-textPrimary '
                  dir={isRtlLanguage ? 'rtl' : 'ltr'}
                >
                  {item.icon && <item.icon size={18} />}
                  <span className=' font-medium'>{t(item.title)}</span>
                  <ChevronRight
                    size={18}
                    className='ltr:ml-auto rtl:ml-0 rtl:mr-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-180 rtl:rotate-180'
                  />
                </SidebarMenuButton>
              </CollapsibleTrigger>
              <CollapsibleContent className=''>
                <SidebarMenuSub className='ltr:pr-0 rtl:pl-0 rtl:pr-2.5 rtl:border-r rtl:border-l-0'>
                  {item.items && item.items.length > 0
                    ? item.items.map((subItem) => (
                        <SingleSettingsItem
                          key={subItem.title}
                          subItem={subItem}
                          permissionList={permissionList}
                          isActive={getLinkActiveStatus(subItem.url)}
                        />
                      ))
                    : null}
                </SidebarMenuSub>
              </CollapsibleContent>
            </SidebarMenuItem>
          </Collapsible>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
}
interface SingleSettingsItemProps {
  subItem: ISettingsNavLink;
  permissionList: PermissionInterface[];
  isActive: boolean;
}
const SingleSettingsItem: React.FC<SingleSettingsItemProps> = ({
  subItem,
  permissionList,
  isActive,
}) => {
  const { t, isRtlLanguage } = useTranslation();
  return (
    <>
      {(subItem.itemGlobalAccess ||
        checkPermission(permissionList, subItem.permission?.readAccess)) && (
        <SidebarMenuSubItem
          key={subItem.title}
          className={`py-1 px-2 rounded-lg font-medium hover:bg-[#F4F4F5] ${
            isActive ? 'bg-[#F4F4F5] text-textPrimary' : 'text-textPrimary'
          }`}
          dir={isRtlLanguage ? 'rtl' : 'ltr'}
        >
          <SidebarMenuSubButton asChild className='rtl:text-right'>
            {/* @ts-ignore */}
            <Link to={subItem.url}>{t(subItem.title)}</Link>
          </SidebarMenuSubButton>
        </SidebarMenuSubItem>
      )}
    </>
  );
};
