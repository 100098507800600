import Audio from '@uppy/audio';
import '@uppy/audio/dist/style.css';
import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/provider-views/dist/style.css';
import { DashboardModal, useUppy } from '@uppy/react';
import Transloadit from '@uppy/transloadit';
import useTranslation from 'components/customHooks/useTranslation';
import React from 'react';
import { getPreSignedPutUrl } from '../../../utilities/awsS3';
import { env as environment } from '../../../utilities/config';
import { feedChannels } from '../../../utilities/utils';
import { SendMessageRequestBodyInterface } from '../inboxInterface';

interface Props {
  open?: boolean;
  planType?: string;
  fileType?: string;
  platformType: string;
  conversationId?: string;
  handleUppyModalClose: () => void;
  getAttachmentFiles?: (attachments: UppyAwsS3SuccessfulResponse[]) => void;
  sendMessage: (messageRequestBody: SendMessageRequestBodyInterface) => void;
}

export interface UppyAwsS3SuccessfulResponse {
  id: string;
  name: string;
  type: string;
  source: string;
  uploadURL: string;
  results: never;
}

interface TransloaditResponse {
  uploadURL: never;
  type: never;
  results: {
    mp3_encoded?: [{ url: string }];
    wav_encoded?: [{ url: string }];
  };
}

const UppyFileUploader: React.FC<Props> = ({
  fileType,
  planType,
  sendMessage,
  platformType,
  open = false,
  conversationId,
  getAttachmentFiles,
  handleUppyModalClose,
}) => {
  const { t } = useTranslation();
  const getAwsS3PreSignedPutUrl = async (
    fileType: string | undefined,
    fileName: string,
    fileExtension: string
  ) => {
    let rawFileType = fileType;
    let extension = rawFileType?.split('/')[1];
    let crypto = require('crypto');

    let rawfileName = fileName.split(`.${fileExtension}`);
    let updatedFileName = `${rawfileName[0]}_${crypto
      .randomBytes(15)
      .toString('hex')}.${extension}`;

    return await getPreSignedPutUrl(updatedFileName);
  };

  const isUsingTransloadit = () => {
    return (
      platformType === 'whatsapp_bsp' ||
      platformType === 'facebook_messenger' ||
      platformType === 'instagram_messenger'
    );
  };

  //This function encode audio files to a certain format
  const getAssemblyOptions = () => {
    let params = {};
    if (platformType === 'instagram_messenger') {
      params = {
        auth: {
          key: process.env.REACT_APP_UPPY_TRANSLOADIT_KEY,
          expires: undefined,
        },
        steps: {
          ':original': {
            robot: '/upload/handle',
          },
          wav_encoded: {
            use: ':original',
            robot: '/audio/encode',
            result: true,
            ffmpeg_stack: 'v4.3.1',
            ffmpeg: {
              'q:a': -1,
              'b:a': 62000,
              ar: 22000,
            },
            preset: 'wav',
          },
          exported: {
            use: ['wav_encoded', ':original'],
            robot: '/s3/store',
            credentials: environment,
          },
        },
        // It's more secure to use a template_id and enable
        // Signature Authentication
      };
    } else {
      params = {
        auth: {
          key: process.env.REACT_APP_UPPY_TRANSLOADIT_KEY,
          expires: undefined,
        },
        steps: {
          ':original': {
            robot: '/upload/handle',
          },
          ogg_encoded: {
            use: ':original',
            robot: '/audio/encode',
            result: true,
            ffmpeg_stack: 'v4.3.1',
            ffmpeg: {
              'q:a': -1,
              'b:a': 62000,
              ar: 22000,
            },
            preset: 'ogg',
          },
          mp3_encoded: {
            use: ':original',
            robot: '/audio/encode',
            result: true,
            ffmpeg_stack: 'v4.3.1',
            preset: 'mp3',
          },
          exported: {
            use: ['mp3_encoded', ':original'],
            robot: '/s3/store',
            credentials: environment,
          },
        },
        // It's more secure to use a template_id and enable
        // Signature Authentication
      };
    }
    return params;
  };

  // TODO: need r&d to check if it can be refactord of all the uppyInstances
  const uppyImage = useUppy(() => {
    let uppyInstance = new Uppy({
      autoProceed: false,
      meta: { type: 'avatar' },
      restrictions: {
        maxFileSize: 5 * 1024 * 1024, // change the first number to set limitation for file size '6 *----*----'
        maxNumberOfFiles: 50,
        allowedFileTypes:
          platformType === 'whatsapp_bsp'
            ? ['.jpg', '.jpeg', '.png']
            : ['image/*'],
      },
    }).use(AwsS3, {
      // @ts-ignore
      async getUploadParameters(file) {
        return {
          method: 'PUT',
          url: await getAwsS3PreSignedPutUrl(
            file.type,
            file.name,
            file.extension
          ),
          headers: {
            'Content-Type': file.type,
          },
        };
      },
    });
    return uppyInstance;
  });

  // Added for stage testing for companion server compatibility
  // const uppyImage = useUppy(() => {
  //   let uppyInstance = new Uppy({
  //     autoProceed: false,
  //     meta: { type: 'avatar' },
  //     restrictions: {
  //       maxFileSize: 5 * 1024 * 1024, // change the first number to set limitation for file size '6 *----*----'
  //       maxNumberOfFiles: 50,
  //       allowedFileTypes:
  //         platformType === 'whatsapp_bsp'
  //           ? ['.jpg', '.jpeg', '.png']
  //           : ['image/*'],
  //     },
  //     //@ts-ignore
  //   }).use(AwsS3, { companionUrl: 'https://gatewaytest.myalice.ai/companion' });
  //   return uppyInstance;
  // });

  const uppyVideo = useUppy(() => {
    let uppyInstance = new Uppy({
      autoProceed: false,
      meta: { type: 'avatar' },
      restrictions: {
        maxFileSize:
          platformType === 'whatsapp_bsp' ? 16 * 1024 * 1024 : 7 * 1024 * 1024, // change the first number to set limitation for file size '6 *----*----'
        maxNumberOfFiles: 10,
        allowedFileTypes: ['video/*'],
      },
    }).use(AwsS3, {
      // @ts-ignore
      async getUploadParameters(file) {
        return {
          method: 'PUT',
          url: await getAwsS3PreSignedPutUrl(
            file.type,
            file.name,
            file.extension
          ),
          headers: {
            'Content-Type': file.type,
          },
        };
      },
    });
    return uppyInstance;
  });

  const uppyApplications = useUppy(() => {
    let uppyInstance = new Uppy({
      autoProceed: false,
      meta: { type: 'avatar' },
      restrictions: {
        maxFileSize:
          platformType === 'whatsapp_bsp' ? 50 * 1024 * 1024 : 5 * 1024 * 1024, // change the first number to set limitation for file size '6 *----*----'
        maxNumberOfFiles: 10,
        allowedFileTypes: ['application/*'],
      },
    }).use(AwsS3, {
      // @ts-ignore
      async getUploadParameters(file) {
        return {
          method: 'PUT',
          url: await getAwsS3PreSignedPutUrl(
            `application/${file.extension}`,
            file.name,
            file.extension
          ),
          headers: {
            'Content-Type': file.type,
          },
        };
      },
    });
    return uppyInstance;
  });

  const uppyLocalAudio = useUppy(() => {
    let uppyInstance = new Uppy({
      autoProceed: false,
      restrictions: {
        maxFileSize: 5 * 1024 * 1024, // change the first number to set limitation for file size '6 *----*----'
        maxNumberOfFiles: 10,
        allowedFileTypes: ['audio/*'],
      },
    });
    // Transloadit plugin is being used in uppy instance
    if (isUsingTransloadit()) {
      return uppyInstance.use(Transloadit, {
        waitForEncoding: true,
        // @ts-ignore
        params: getAssemblyOptions(),
      });
    } else {
      return uppyInstance.use(AwsS3, {
        // @ts-ignore
        async getUploadParameters(file) {
          return {
            method: 'PUT',
            url: await getAwsS3PreSignedPutUrl(
              file.type,
              file.name,
              file.extension
            ),
            headers: {
              'Content-Type': file.type,
            },
          };
        },
      });
    }
  });

  const uppyAudio = useUppy(() => {
    let uppyInstance = new Uppy({
      autoProceed: false,
      restrictions: {
        maxFileSize: 5 * 1024 * 1024, // change the first number to set limitation for file size '6 *----*----'
        maxNumberOfFiles: 10,
        allowedFileTypes: ['audio/*'],
      },
    }).use(Audio, {
      showAudioSourceDropdown: true,
      locale: { strings: { pluginNameAudio: t('Record Audio') } },
    });

    // Transloadit plugin is being used in uppy instance
    if (isUsingTransloadit()) {
      return uppyInstance.use(Transloadit, {
        waitForEncoding: true,
        // @ts-ignore
        params: getAssemblyOptions(),
      });
    } else {
      return uppyInstance.use(AwsS3, {
        // @ts-ignore
        async getUploadParameters(file) {
          return {
            method: 'PUT',
            url: await getAwsS3PreSignedPutUrl(
              file.type,
              file.name,
              file.extension
            ),
            headers: {
              'Content-Type': file.type,
            },
          };
        },
      });
    }
  });

  const getUppyInstances = () => {
    switch (fileType) {
      case 'image':
        return uppyImage;
      case 'audioLocal':
        return uppyLocalAudio;
      case 'audio':
        return uppyAudio;
      case 'video':
        return uppyVideo;
      case 'application':
        return uppyApplications;
      default:
        return uppyImage;
    }
  };

  const sendAttachment = (
    attachmentList: UppyAwsS3SuccessfulResponse[] | TransloaditResponse[],
    shouldEncode: boolean
  ) => {
    closeModalHandler();
    if (shouldEncode) {
      // encoded response from transloadit
      attachmentList.forEach(
        (attachment: UppyAwsS3SuccessfulResponse | TransloaditResponse) => {
          let audioAttachment =
            platformType === 'instagram_messenger'
              ? attachment.results.wav_encoded
              : attachment.results.mp3_encoded;
          if (!!audioAttachment) {
            audioAttachment.forEach((audio) => {
              sendMessage({
                audio: audio.url,
                action: 'direct_message',
              });
            });
          }
        }
      );
    } else {
      if (['gmail', 'custom_email', 'office365'].includes(platformType)) {
        // @ts-ignore
        getAttachmentFiles(attachmentList);
      } else {
        attachmentList.forEach(
          // regular response from uppy
          (attachment: UppyAwsS3SuccessfulResponse | TransloaditResponse) => {
            let attachmentData = attachment.type.split(/[/]/);
            let isAudio = attachmentData[0] === 'audio' || false;
            let isImage = attachmentData[0] === 'image' || false;
            let isVideo = attachmentData[0] === 'video' || false;
            let isFile = attachmentData[0] === 'application' || false;

            sendMessage({
              image: isImage ? attachment.uploadURL : null,
              audio: isAudio ? attachment.uploadURL : null,
              video: isVideo ? attachment.uploadURL : null,
              file: isFile ? attachment.uploadURL : null,
              reply_to_message_id: conversationId || null,
              action: feedChannels.includes(platformType)
                ? 'public_reply'
                : 'direct_message',
            });
          }
        );
      }
    }
  };

  const closeModalHandler = () => {
    uppyImage.reset();
    uppyAudio.reset();
    uppyVideo.reset();
    uppyLocalAudio.reset();
    uppyApplications.reset();
    handleUppyModalClose();
  };

  React.useEffect(() => {
    // result type is set to any because UploadCompleteCallback Type 'UploadResult<Record<string, unknown>, Record<string, unknown>>' is not assignable to type 'UppyAwsS3Response'
    // need to check further
    const sendFiles = (result: any) => {
      if (!!result.successful) {
        sendAttachment(result.successful, false);
      }
    };

    // Transloadit plugin is being used in uppy instance
    const sendAudio = (result: any) => {
      if (isUsingTransloadit() && !!result.transloadit) {
        sendAttachment(result.transloadit, true);
      } else if (!!result.successful) {
        sendAttachment(result.successful, false);
      }
    };
    uppyImage.on('complete', sendFiles);
    uppyLocalAudio.on('complete', sendAudio);
    uppyVideo.on('complete', sendFiles);
    uppyApplications.on('complete', sendFiles);
    uppyAudio.on('complete', sendAudio);
    // This is to tell React to remove the old listener if a different function is passed to the `sendAttachment` variable:
    return () => {
      uppyImage.off('complete', sendFiles);
      uppyVideo.off('complete', sendFiles);
      uppyAudio.off('complete', sendAudio);
      uppyLocalAudio.off('complete', sendAudio);
      uppyApplications.off('complete', sendFiles);
    };
    // eslint-disable-next-line
  }, [sendAttachment]);

  return (
    <DashboardModal
      open={open}
      uppy={
        fileType === 'audio' && planType !== 'free'
          ? uppyAudio
          : getUppyInstances()
      }
      plugins={['Url', 'ImageEditor', 'Audio']}
      locale={{
        strings: {
          uploadXFiles: {
            0: `${
              ['gmail', 'custom_email', 'office365'].includes(platformType)
                ? 'Upload'
                : 'Send'
            } %{smart_count} file`,
            1: `${
              ['gmail', 'custom_email', 'office365'].includes(platformType)
                ? 'Upload'
                : 'Send'
            } %{smart_count} files`,
          },
          myDevice: t('Upload Audio'),
          browseFiles: 'Upload',
          dropPasteFiles: t(
            `%{browseFiles} or drag your ${
              fileType === 'application'
                ? 'file'
                : fileType === 'audioLocal'
                ? 'audio'
                : fileType
            }${
              platformType === 'whatsapp_bsp' && fileType === 'image'
                ? '. Supported formats: JPEG, PNG only'
                : ''
            }`
          ),
          dropPasteImportFiles: t(
            '%{browseFiles} or drag your audio file. Record your audio by clicking on the button below'
          ),
        },
      }}
      proudlyDisplayPoweredByUppy={false}
      onRequestClose={() => closeModalHandler()}
    />
  );
};

export default UppyFileUploader;
