import { React } from '../../../export';
import { CalendarDateRangePicker } from 'libraryV2/ui/CalendarDateRangePicker';
import { DateRange } from 'react-day-picker';

interface Props {
  dateRange: any;
  setDateRange: (val: any) => void;
}

const AutomationDashboardHeader: React.FC<Props> = ({
  dateRange,
  setDateRange,
}) => {
  const handleDateRangeSelect = (range: DateRange) => {
    setDateRange(range);
  };
  return (
    <div className='flex items-center justify-between w-full px-4 py-3 bg-white border-b border-gray-200 ltr:border-l rtl:border-r h-[52px]'>
      <span className='text-xl font-bold text-gray-900'>Automations</span>
      <CalendarDateRangePicker
        dateRange={dateRange}
        onDateSelect={handleDateRangeSelect}
        variant='outline'
        className='w-full'
      />
    </div>
  );
};

export default AutomationDashboardHeader;
