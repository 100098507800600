import axios from '../../../utilities/httpClient';
import config from '../../../utilities/config';
import { endOfDay, subDays } from 'date-fns';
import { getReportingVariableNameFromEndpoint } from '../../../utilities/utils';
import _ from 'lodash';
import dayjs from 'dayjs';
import { toaster } from 'evergreen-ui';
import { exportCSV } from '../../../utilities/utils';

const initialState = {
  data: null,
  currentTab: '',
  currentWhatsappMetricsTab: 'whatsapp-account-overview',
  shouldFetchData: false,
  shouldFetchWhatsappMetricsData: false,
  dateRange: {
    from: endOfDay(subDays(new Date(), 7)),
    to: new Date(),
  },
  platformData: {
    id: null,
    type: 'all',
    title: 'All Channels',
  },
  platformsToFilter: [],
  userCount: null,
  interactiveUserCount: null,
  referralGrowthData: null,
  intentGrowthData: null,
  languageData: null,
  timezoneData: null,
  countryData: null,
  heatmapData: null,
  genderData: null,
  postbackVTextData: null,
  triggeredMsgReferralnKeyword: null,
  triggeredMsgIntentnPostback: null,
  ticketResolvedCount: null,
  uniqueTicketCount: null,
  avgTicketPerUser: null, // Not being used
  avgTicketPerOverallUser: null,
  ticketCreatedCount: null,
  avgProcessFRT: null,
  textualVsAutomation: [{ textual_message: 0, automated: 0 }],
  ticketTagCloud: [],
  ticketListTable: [],
  agentList: [],
  averageResolveTime: null,
  averageQueueTime: null,
  agentStats: [],
  conversationLogs: [],
  intentList: [],
  csatTotalCount: null,
  csatRespondedCount: null,
  csatTotalResponseCount: null,
  csatAvgRating: null,
  csatResponseList: [],
  csatResponseListTotal: 0,
  csatResponseDist: [],
  isBusinessHourEnabled: false,
  whatsappMetricsReport: null,
  isWhatsappBsbIntegration: false,
  whatsappTemplateMetrics: [],
  totalDataOfTemplateMetrics: 0,
  templateMetricsTableOffset: 0,
  selectedAnalytics: null,
  whatsappAccountInfo: null,
  selectedTemplateAnalytics: null,
  selectedPlatformId: null,
  selectedReportingViewMode: 'reporting',
  templateAnalyticsLogs: [],
  templateAnalyticsLogsTotalData: 0,
  selectedLogsData: null,
  selectedWhatsappTemplateName: null,
  whatsappTemplateData: {
    templateList: [],
    channelId: null,
  },
};

export const reporting = {
  state: {
    ...initialState,
  },
  reducers: {
    updateStateData(state, payload) {
      //key value
      return { ...state, [payload.key]: payload.value };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    //user metrics
    async fetchUserReportData(payload, rootState) {
      /*
      payload= {projectId: num, endpoint, platformIds: [], start, end , isBusinessHourEnabled }
       */
      try {
        if (payload.projectId) {
          const res = await axios.get(
            config.reporting.analytics(payload.projectId, payload.endpoint),
            {
              params: {
                platform: payload.platformIds,
                start: payload.start,
                end: payload.end,
                business_hour_status: payload.isBusinessHourEnabled,
              },
            }
          );
          if (res.data.success) {
            dispatch.reportingV2.updateStateData({
              key: getReportingVariableNameFromEndpoint(payload.endpoint),
              value: res.data.dataSource,
            });
          } else {
            dispatch.reportingV2.updateStateData({
              key: getReportingVariableNameFromEndpoint(payload.endpoint),
              value: null,
            });
          }
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchUserReportDataDefaultAppend(payload, rootState) {
      /*
      payload= {projectId: num, endpoint, platformIds: [], start, end , isBusinessHourEnabled }
       */
      try {
        if (payload.projectId) {
          const res = await axios.get(
            config.reporting.analytics(payload.projectId, payload.endpoint),
            {
              params: {
                platform: payload.platformIds,
                start: payload.start,
                end: payload.end,
                business_hour_status: payload.isBusinessHourEnabled,
              },
            }
          );

          if (res.data.success) {
            dispatch.reportingV2.updateStateData({
              key: getReportingVariableNameFromEndpoint(payload.endpoint),
              value: res.data.dataSource,
            });
          } else {
            dispatch.reportingV2.updateStateData({
              key: getReportingVariableNameFromEndpoint(payload.endpoint),
              value: [],
            });
          }
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchUserReportDataUserMetricsLineGraph(payload, rootState) {
      /*

      payload= {projectId: num, endpoint, platformIds: [], start, end  }

       */
      try {
        if (payload.projectId) {
          const res = await axios.get(
            config.reporting.analytics(payload.projectId, payload.endpoint),
            {
              params: {
                platform: payload.platformIds,
                start: payload.start,
                end: payload.end,
              },
            }
          );
          const graphList = [
            'get_language_metrics',
            'get_timezone_metrics',
            'get_country_metrics',
            'get_ticket_avg_queue_time',
            'get_ticket_avg_resolve_time',
          ];

          if (res.data.success) {
            if (graphList.indexOf(payload.endpoint) !== -1) {
              let dataFinal = { ...res.data.dataSource };
              if (_.isEmpty(res.data.dataSource.max)) {
                dataFinal.max = {
                  name: 'Not Available',
                  value: 0,
                  growth: '+0.00%',
                };
              }
              dispatch.reportingV2.updateStateData({
                key: getReportingVariableNameFromEndpoint(payload.endpoint),
                value: dataFinal,
              });
            }
          }
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchUserReportHeatmap(payload, rootState) {
      /*
      payload= {projectId: num, platformIds: [], start, end  }
       */
      try {
        if (payload.projectId) {
          const res = await axios.get(
            config.reporting.heatmap(payload.projectId),
            {
              params: {
                platform: payload.platformIds,
                start: payload.start,
                end: payload.end,
              },
            }
          );
          if (res.data.success) {
            let defaultHeatmapData = Array(7)
              .fill(0)
              .map((x) => new Array(24).fill(0));
            const payloadLocal = res.data.dataSource
              .filter((e) => e.day < 7)
              .filter((e) => e.hour < 24);
            payloadLocal.forEach((elem) => {
              defaultHeatmapData[elem.day][elem.hour] = elem.count;
            });
            dispatch.reportingV2.updateStateData({
              key: 'heatmapData',
              value: defaultHeatmapData,
            });
          } else {
            dispatch.reportingV2.updateStateData({
              key: 'heatmapData',
              value: null,
            });
          }
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchAutomationTicketTable(payload, rootState) {
      /*
      payload= {projectId: num, groupId: number || null, limit: number, offset: number }
       */
      try {
        if (payload.projectId) {
          const res = await axios.get(
            config.reporting.automationTicketTable(payload.projectId),
            {
              params: {
                platform: payload.platformIds,
                start: payload.start,
                end: payload.end,
                agent_id: payload.groupId,
                limit: payload.limit,
                offset: payload.offset,
              },
            }
          );
          if (res.data.success) {
            dispatch.reportingV2.updateStateData({
              key: 'ticketListTable',
              value: res.data.dataSource,
            });
            dispatch.reportingV2.updateStateData({
              key: 'totalTicketList',
              value: res.data.total,
            });
          } else {
            dispatch.reportingV2.updateStateData({
              key: 'ticketListTable',
              value: [],
            });
          }
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchUserReportDataOnTabClick(payload, rootState) {
      /*
     payload: {
    projectId: number;
    platformIds: string;
    start: string;
    end: string;
    endpoint: string;
  }
       */
      try {
        if (payload.projectId) {
          const res = await axios.get(
            config.reporting.analytics(payload.projectId, payload.endpoint),
            {
              params: {
                platform: payload.platformIds,
                start: payload.start,
                end: payload.end,
              },
            }
          );
          if (res.data.success) {
            dispatch.reportingV2.updateStateData({
              key: getReportingVariableNameFromEndpoint(payload.endpoint),
              value: res.data.dataSource,
            });
          } else {
            dispatch.reportingV2.updateStateData({
              key: getReportingVariableNameFromEndpoint(payload.endpoint),
              value: null,
            });
          }
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async exportCustomerTicket(payload, rootState) {
      /*
      payload= {projectId: num, endpoint }
       */
      try {
        const res = await axios.get(
          config.reporting.exportTicket(payload.projectId),
          {
            params: {
              platform: rootState.reportingV2.platformData.id,
              start: dayjs(rootState.reportingV2.dateRange[0].startDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              end: dayjs(rootState.reportingV2.dateRange[0].endDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              agent_id: payload.groupId,
            },
          }
        );
        if (res.status === 200) {
          return true;
        } else {
          return false;
        }
      } catch (err) {
        // console.log(err.response);
        return false;
      }
    },
    async exportAgentDetails(payload, rootState) {
      /*
      payload= {projectId: num, platformIds: "1,2", start, end, isBusinessHourEnabled }
       */
      try {
        const res = await axios.get(
          config.reporting.exportAgentDetails(payload.projectId),
          {
            params: {
              platform: payload.platformIds,
              start: payload.start,
              end: payload.end,
              business_hour_status: payload.isBusinessHourEnabled,
            },
          }
        );
        if (res.status === 200) {
          exportCSV(
            res.data,
            `agent-details_${rootState.dashboard.selectedProject.name}-${payload.start} to ${payload.end}`
          );
        } else {
          toaster.warning('Failed', { description: 'Failed to Export Data' });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async exportIncomingVsAutomationChart(projectId, rootState) {
      /*
      projectId: num
       */
      try {
        const res = await axios.get(
          config.reporting.exportIncomingVsAutomationChart(projectId),
          {
            params: {
              platform: rootState.reportingV2.platformData.id,
              start: dayjs(rootState.reportingV2.dateRange[0].startDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              end: dayjs(rootState.reportingV2.dateRange[0].endDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
            },
          }
        );
        if (res.status === 200) {
          exportCSV(
            res.data,
            `incoming-vs-automation_${
              rootState.dashboard.selectedProject.name
            }-${dayjs(rootState.reportingV2.dateRange[0].startDate).format(
              'YYYY-MM-DDTHH:mm:ss'
            )} to ${dayjs(rootState.reportingV2.dateRange[0].endDate).format(
              'YYYY-MM-DDTHH:mm:ss'
            )}`
          );
        } else {
          toaster.warning('Failed', { description: 'Failed to Export Data' });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async exportTagCloud(projectId, rootState) {
      /*
      projectId: num
       */
      try {
        const res = await axios.get(
          config.reporting.exportTagCloud(projectId),
          {
            params: {
              platform: rootState.reportingV2.platformData.id,
              start: dayjs(rootState.reportingV2.dateRange[0].startDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              end: dayjs(rootState.reportingV2.dateRange[0].endDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
            },
          }
        );
        if (res.status === 200) {
          exportCSV(
            res.data,
            `tag-list_${rootState.dashboard.selectedProject.name}-${dayjs(
              rootState.reportingV2.dateRange[0].startDate
            ).format('YYYY-MM-DDTHH:mm:ss')} to ${dayjs(
              rootState.reportingV2.dateRange[0].endDate
            ).format('YYYY-MM-DDTHH:mm:ss')}`
          );
        } else {
          toaster.warning('Failed', { description: 'Failed to Export Data' });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchConversationLog(payload, rootState) {
      /*
      payload= {projectId: num, agentId: num, intent: string, limit, offset }
       */
      try {
        const res = await axios.get(
          config.reporting.conversationLog(payload.projectId),
          {
            params: {
              platform: rootState.reportingV2.platformData.id,
              start: dayjs(rootState.reportingV2.dateRange[0].startDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              end: dayjs(rootState.reportingV2.dateRange[0].endDate).format(
                'YYYY-MM-DDTHH:mm:ss'
              ),
              agent_id: payload.agentId,
              intent: payload.intent,
              limit: payload.limit,
              offset: payload.offset,
            },
          }
        );
        if (res.data.success) {
          dispatch.reportingV2.updateStateData({
            key: 'conversationLogs',
            value: res.data.dataSource,
          });
        } else {
          dispatch.reportingV2.updateStateData({
            key: 'conversationLogs',
            value: null,
          });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchIntentReporting(projectId) {
      /*
        projectId : num
       */
      try {
        if (projectId) {
          const res = await axios.get(config.reporting.intent(projectId));
          if (res.data.success) {
            dispatch.reportingV2.updateStateData({
              key: 'intentList',
              value: res.data.dataSource,
            });
          } else {
            dispatch.reportingV2.updateStateData({
              key: 'intentList',
              value: [],
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    //csat
    async fetchCsatStack(payload, rootState) {
      /*
      payload= {projectId: num, endpoint: string , start, end,platformIds}
      */
      try {
        const res = await axios.get(
          config.reporting.csat(payload.projectId, payload.endpoint),
          {
            params: {
              platform: payload.platformIds,
              start: payload.start,
              end: payload.end,
            },
          }
        );
        if (!!res && res.data.success) {
          if (payload.endpoint === 'csat-total-count') {
            dispatch.reportingV2.updateStateData({
              key: 'csatTotalCount',
              value: res.data.dataSource,
            });
          } else if (payload.endpoint === 'csat-responded-count') {
            dispatch.reportingV2.updateStateData({
              key: 'csatRespondedCount',
              value: res.data.dataSource,
            });
          } else if (payload.endpoint === 'csat-average-rating') {
            dispatch.reportingV2.updateStateData({
              key: 'csatAvgRating',
              value: res.data.dataSource,
            });
          } else if (payload.endpoint === 'csat-response-count') {
            dispatch.reportingV2.updateStateData({
              key: 'csatTotalResponseCount',
              value: res.data.dataSource,
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchCsatResponseList(payload, rootState) {
      const {
        teamId,
        search,
        limit,
        offset,
        agentsIds = [],
        ratings = [],
        start,
        end,
        platformIds,
      } = payload;
      try {
        const res = await axios.get(config.reporting.csatResponseList(teamId), {
          params: {
            platform: platformIds,
            start: start,
            end: end,
            search: search,
            limit: limit,
            offset: offset,
            agents: agentsIds.toString(),
            ratings: ratings.toString(),
          },
        });
        if (!!res && res.data.success) {
          dispatch.reportingV2.updateStateData({
            key: 'csatResponseList',
            value: res.data.dataSource,
          });
          dispatch.reportingV2.updateStateData({
            key: 'csatResponseListTotal',
            value: res.data.total,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchCsatResponseDist(payload, rootState) {
      try {
        const res = await axios.get(
          config.reporting.csatResponseDist(payload.projectId),
          {
            params: {
              platform: payload?.platformIds,
              start: payload?.start,
              end: payload?.end,
              search: payload?.search,
              limit: payload?.limit,
              offset: payload?.offset,
            },
          }
        );
        if (!!res && res.data.success) {
          dispatch.reportingV2.updateStateData({
            key: 'csatResponseDist',
            value: res.data.dataSource,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },

    async exportCSAT(payload, rootState) {
      /*
      payload=
      projectId : number
      search: filterFormat?.customerName,
      offset: 0,
      agentsIds: filterFormat?.agent ?? '',
      ratings: filterFormat.rating ?? '',
       */
      try {
        const res = await axios.get(
          config.reporting.exportCSAT(payload.projectId),
          {
            params: {
              platform: payload.platformIds,
              start: payload.start,
              end: payload.end,
              search: payload.search,
              ratings: payload.ratings,
              agentsIds: payload.agentsIds,
            },
          }
        );
        if (res.status === 200) {
          exportCSV(
            res.data,
            `agent-details_${rootState.dashboard.selectedProject.name}-${payload.start} to ${payload.end}`
          );
        } else {
          toaster.warning('Failed', { description: 'Failed to Export Data' });
        }
      } catch (err) {
        // console.log(err.response);
        toaster.warning('Failed', {
          description: 'Failed to Export Data due to an exception',
        });
      }
    },
    async exportCSATSummary(payload, rootState) {
      /*
      payload= projectId : number, platformIds: string, start: string, end: string
       */
      try {
        const res = await axios.get(
          config.reporting.exportCSATSummary(payload.projectId),
          {
            params: {
              platform: payload.platformIds,
              start: payload.start,
              end: payload.end,
            },
          }
        );
        if (res.status === 200) {
          exportCSV(
            res.data,
            `agent-details_${rootState.dashboard.selectedProject.name}-${payload.start} to ${payload.end}`
          );
        } else {
          toaster.warning('Failed', { description: 'Failed to Export Data' });
        }
      } catch (err) {
        // console.log(err.response);
        toaster.warning('Failed', {
          description: 'Failed to Export Data due to an exception',
        });
      }
    },

    // whatsapp metics
    async fetchWhatsappConversationMetrics(payload, rootState) {
      /*
      payload= {projectId: num, groupId: number || null, limit: number, offset: number }
       */
      try {
        if (payload.projectId) {
          const res = await axios.get(
            config.reporting.conversationMetrics(payload.projectId),
            {
              params: {
                platform: payload.platformIds,
                start: payload.start,
                end: payload.end,
              },
            }
          );
          if (res.data.success) {
            dispatch.reportingV2.updateStateData({
              key: 'whatsappMetricsReport',
              value: res.data.dataSource,
            });
          }
        }
      } catch (err) {
        console.log(err.response);
      }
    },
    async fetchTemplateMetrics(payload, rootState) {
      /*
      payload= {projectId: num, agentId: num, intent: string, limit, offset ,from,to,platformId}
       */
      try {
        const res = await axios.get(
          config.reporting.templateMetrics(payload.projectId),
          {
            params: {
              platform: payload.platformId,
              start: payload.from,
              end: payload.to,
              limit: payload.limit,
              offset: payload.offset,
              search: payload.search,
              status: payload.status,
              category: payload.category,
            },
          }
        );
        if (res.data.success) {
          const { template_analytics, total } = res.data.dataSource;
          dispatch.reportingV2.updateStateData({
            key: 'whatsappTemplateMetrics',
            value: template_analytics,
          });
          dispatch.reportingV2.updateStateData({
            key: 'totalDataOfTemplateMetrics',
            value: total,
          });
        } else {
          dispatch.reportingV2.updateStateData({
            key: 'whatsappTemplateMetrics',
            value: [],
          });
          dispatch.reportingV2.updateStateData({
            key: 'totalDataOfTemplateMetrics',
            value: 0,
          });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchTemplateAnalyticsLogs(payload, rootState) {
      /*
      payload= {projectId: num,platformId agentId: num, intent: string, limit, offset }
       */
      try {
        const res = await axios.get(
          config.reporting.templateAnalyticsLogs(payload.projectId),
          {
            params: {
              platform: payload.platformId,
              start: payload.from,
              end: payload.to,
              limit: payload.limit,
              offset: payload.offset,
              template_id: payload.templateIDs,
              search: payload.search,
              status: payload.status,
              sources: payload.sources,
            },
          }
        );

        if (res.data.success) {
          const { template_reports, total } = res.data.dataSource;

          dispatch.reportingV2.updateStateData({
            key: 'templateAnalyticsLogsTotalData',
            value: total,
          });
          dispatch.reportingV2.updateStateData({
            key: 'templateAnalyticsLogs',
            value: template_reports,
          });
        } else {
          dispatch.reportingV2.updateStateData({
            key: 'templateAnalyticsLogsTotalData',
            value: 0,
          });
          dispatch.reportingV2.updateStateData({
            key: 'templateAnalyticsLogs',
            value: [],
          });
        }
      } catch (err) {
        // console.log(err.response);
      }
    },
    async fetchAgentList(teamId) {
      /*
      payload= teamId : number
       */
      //note: this effect is for Reporting
      try {
        const res = await axios.get(config.reporting.agent(teamId));

        if (res.data.success) {
          dispatch.reportingV2.updateStateData({
            key: 'agentList',
            value: res.data.dataSource,
          });
        } else {
          dispatch.reportingV2.updateStateData({
            key: 'agentList',
            value: null,
          });
        }
        return { result: res.data.dataSource, error: null };
      } catch (err) {
        return {
          result: null,
          error: err.response?.data?.error,
          code: err.response?.data?.code,
          statusCode: err.response?.status,
        };
      }
    },

    async fetchTemplateList(platformId) {
      /*
      payload= platformId: string
       */
      try {
        const res = await axios.get(
          config.inbox.getWhatsAppMesssageTemplates(platformId)
        );
        if (res.data.success) {
          dispatch.reportingV2.updateStateData({
            key: 'whatsappTemplateData',
            value: {
              templateList: res.data.dataSource?.whatsapp_message_templates,
              platformId: platformId,
            },
          });
        }
      } catch (err) {
        return {
          result: null,
          error: err.response?.data?.error,
          code: err.response?.data?.code,
          statusCode: err.response?.status,
        };
      }
    },
  }),
};
