import axios from '../../../utilities/httpClient';
import config from '../../../utilities/config';
import { cloneDeep, startCase } from 'lodash';
import { toaster } from 'evergreen-ui';
import { normalizeInboxDatalabFields } from '../utils/functions';

const initialState = {
  limit: 10,
  offset: 0,
  totalEntry: 0,
  datalabList: [],
  selectedDatalabData: null, // []
  selectedDatalabFields: [],
  customerDatalabEntries: [],
  selectedDatalabEntry: null, // {}
  selectedDatalabId: null, // number
  isRightBarOpenedForDatalab: false,
  formAction: 'CREATE',
  isDatalabFieldDisabled: false,
};

export const inboxDatalab = {
  state: { ...initialState },
  reducers: {
    updateInboxDatalabStateData(state, updatedData) {
      return { ...state, [updatedData.key]: updatedData.value };
    },
    updateInboxDatalabFormAction(state, value) {
      return { ...state, formAction: value };
    },
    updateInboxDatalabFieldsValue(state, updatedFieldData) {
      const localDatalabFields = cloneDeep(state.selectedDatalabFields);
      const fieldIndex = localDatalabFields.findIndex(
        (obj) => obj?.id === updatedFieldData?.id
      );

      if (fieldIndex > -1) {
        localDatalabFields[fieldIndex][updatedFieldData.fieldName] =
          updatedFieldData?.value;
      }

      return {
        ...state,
        selectedDatalabFields: localDatalabFields,
      };
    },

    addNewEntryToCustomerDatalabEntry(state, newEntryData) {
      return {
        ...state,
        customerDatalabEntries: [newEntryData, ...state.customerDatalabEntries],
      };
    },

    updateSelectedDatalabEntry(state, updatedEntry) {
      const localEntryField = cloneDeep(state.selectedDatalabEntry);

      if (updatedEntry?.id in localEntryField) {
        localEntryField[updatedEntry?.id] = updatedEntry?.value;
      }

      return { ...state, selectedDatalabEntry: localEntryField };
    },

    updateCustomerDatalabEntry(state, updatedEntry) {
      const localEntryField = cloneDeep(state.customerDatalabEntries);
      const fieldIndex = localEntryField.findIndex(
        (obj) => obj?.id === updatedEntry?.id
      );

      if (fieldIndex > -1) {
        localEntryField[fieldIndex] = updatedEntry;
      }
      return { ...state, customerDatalabEntries: localEntryField };
    },

    removeCustomerDatalabEntry(state, removedEntryId) {
      let localEntryField = cloneDeep(state.customerDatalabEntries);
      localEntryField = localEntryField.filter(
        (entry) => entry.id !== removedEntryId
      );

      return { ...state, customerDatalabEntries: localEntryField };
    },

    updateInboxDatalabField(state, { fieldsData, customerInformation }) {
      const updatedData = [...fieldsData];
      const localCustomerDatalabEntries = state.selectedDatalabEntry;

      const normalizedFieldsData = normalizeInboxDatalabFields(
        updatedData,
        {
          ...localCustomerDatalabEntries,
        },
        customerInformation
      );

      return {
        ...state,
        selectedDatalabFields: [...normalizedFieldsData],
      };
    },
    updateCustomerDatalabEntries(state, entryData) {
      let localCustomerDatalabEntries = cloneDeep(state.customerDatalabEntries);

      localCustomerDatalabEntries = [
        ...state.customerDatalabEntries,
        ...entryData,
      ];

      const seenIds = new Set();

      // Filter out elements with duplicate or missing IDs
      localCustomerDatalabEntries = localCustomerDatalabEntries.filter(
        (element) => {
          if (!element.id || seenIds.has(element.id)) {
            return false;
          }
          seenIds.add(element.id);
          return true;
        }
      );

      return { ...state, customerDatalabEntries: localCustomerDatalabEntries };
    },
    clearStatesExceptDatalabList(state) {
      const { datalabList } = state; // Save datalabList before clearing the state
      return { ...initialState, datalabList }; // Restore datalabList in the new state
    },
    clearStatesExceptDatalabListAndCustomerEntry(state) {
      const { datalabList, customerDatalabEntries, totalEntry } = state; // Save datalabList, customerDatalabEntries before clearing the state
      return {
        ...initialState,
        datalabList,
        totalEntry,
        customerDatalabEntries,
      }; // Restore datalabList, customerDatalabEntries, totalEntry in the new state
    },
    clearState(state) {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchDatalabList(teamId) {
      try {
        const res = await axios.get(
          config.inboxDatalab.datalabList(teamId, 'stable')
        );
        if (res?.data?.success) {
          dispatch.inboxDatalab.updateInboxDatalabStateData({
            key: 'datalabList',
            value: [...res.data.dataSource],
          });
          return { success: true, data: res.data.dataSource };
        }
        return { success: false, data: [] };
      } catch (e) {
        console.error(e);
        return { success: false, data: [] };
      }
    },
    async fetchDatalabFields(payload) {
      try {
        const res = await axios.get(
          config.inboxDatalab.datalabFields(
            payload.teamId,
            payload.selectedDatalabId,
            'stable'
          )
        );
        if (res?.data?.success) {
          dispatch.inboxDatalab.updateInboxDatalabStateData({
            key: 'selectedDatalabData',
            value: res.data.dataSource,
          });
          const customerInformation =
            dispatch.inbox.getCustomerInformationStoreData();
          dispatch.inboxDatalab.updateInboxDatalabField({
            fieldsData: res.data.dataSource?.lab_fields,
            customerInformation,
          });
          return { success: true, data: res.data.dataSource };
        }
        return { success: false, data: [] };
      } catch (e) {
        console.error(e);
        toaster.notify('Something went wrong while fetching datalab fileds');
        return { success: false, data: [] };
      }
    },
    async insertDatalabEntries(payload) {
      try {
        const res = await axios.post(
          config.inboxDatalab.datalabEntries(
            payload.selectedDatalabId,
            'stable'
          ),
          payload.postRequestBody
        );
        if (res?.data?.success) {
          dispatch.inboxDatalab.addNewEntryToCustomerDatalabEntry(
            res.data.dataSource
          );
          return {
            success: true,
            data: res.data.dataSource,
            title: res.data.title,
            sub_title: res.data.sub_title,
            message: res.data.message,
            error: '',
          };
        }
        return { success: false, data: [], error: res?.data?.title };
      } catch (e) {
        if (e.response?.status === 500) {
          return {
            success: false,
            data: [],
            error: 'Something went wrong while inserting datalab entry',
          };
        } else {
          const errorData = e?.response?.data?.dataSource?.at(0);
          if (errorData?.required_parent_fields) {
            errorData.error = `Please complete all required fields: ${errorData.required_parent_fields?.toString()}`;
          } else {
            errorData.error =
              'The following fields have issues ' +
              e?.response?.data?.dataSource
                ?.map((v) => startCase(v?.key))
                ?.toString();
          }

          return {
            success: false,
            data: [],
            error:
              errorData.error ||
              'Something went wrong while inserting datalab entry',
          };
        }
      }
    },
    async fetchCustomerDatalabEntries(payload) {
      try {
        const res = await axios.get(
          config.inboxDatalab.customerDatalabEntries(
            payload.customerId,
            payload.limit,
            payload.offset,
            'stable'
          ),
          payload.requestBody
        );
        if (res?.data?.success) {
          dispatch.inboxDatalab.updateCustomerDatalabEntries(
            res.data.dataSource
          );
          dispatch.inboxDatalab.updateInboxDatalabStateData({
            key: 'totalEntry',
            value: res.data.count,
          });
          return { success: true, data: res.data.dataSource, error: '' };
        }
        dispatch.inboxDatalab.updateInboxDatalabStateData({
          key: 'customerDatalabEntries',
          value: [],
        });
        return { success: false, data: [], error: res?.data?.error };
      } catch (e) {
        dispatch.inboxDatalab.updateInboxDatalabStateData({
          key: 'customerDatalabEntries',
          value: [],
        });
        if (e.response?.status === 500) {
          return {
            success: false,
            data: [],
            error: 'Something went wrong while fetching datalab entry',
          };
        } else {
          return {
            success: false,
            data: [],
            error: e.response?.data.error,
          };
        }
      }
    },
    async updatedSingleEntry(payload) {
      try {
        const res = await axios.put(
          config.inboxDatalab.entriesAction(
            payload.selectedDatalabId,
            payload.entryId,
            'stable'
          ),
          payload.requestBody
        );
        if (res?.data?.success) {
          dispatch.inboxDatalab.updateCustomerDatalabEntry(res.data.dataSource);
          return {
            success: true,
            data: res.data.dataSource,
            title: res.data.title,
            subTitle: res.data.sub_title,
            message: res.data.message,
            error: '',
          };
        }
        return { success: false, data: [], error: res?.data?.error };
      } catch (e) {
        if (e.response?.status === 500) {
          return {
            success: false,
            data: [],
            error: 'Something went wrong while updating datalab entry',
          };
        } else {
          return {
            success: false,
            data: [],
            error: e.response?.data.error,
          };
        }
      }
    },
    async deleteSingleEntry(payload) {
      try {
        const res = await axios.delete(
          config.inboxDatalab.entriesAction(
            payload.selectedDatalabId,
            payload.entryId,
            'stable'
          )
        );
        if (res?.data?.success) {
          dispatch.inboxDatalab.removeCustomerDatalabEntry(payload.entryId);
          toaster.notify('Datalab entry successfully deleted');
          return { success: true, data: res.data.dataSource, error: '' };
        }
        toaster.notify('Datalab entry delete failed. Try again');
        return { success: false, data: [], error: res?.data?.error };
      } catch (e) {
        if (e.response?.status === 500) {
          toaster.notify('Something went wrong while deleteting datalab entry');
          return {
            success: false,
            data: [],
            error: 'Something went wrong while deleteting datalab entry',
          };
        } else {
          toaster.notify(e.response?.data.error);
          return {
            success: false,
            data: [],
            error: e.response?.data.error,
          };
        }
      }
    },
  }),
};
