import { Menu, Popover, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';
import { SelectedTeamInterface } from 'index';
import { Button } from 'library';
import { cloneDeep } from 'lodash';
import { BotIcon } from 'pages/inbox/assets/iconComponent/BotIcon';
import React, { Fragment } from 'react';
import { classNames } from '../../../../../../utilities/utils';
import defaultAvatar from '../../../../assets/images/default-avatar.png';
import noMatchesIcon from '../../../../assets/images/noMatchesIcon.svg';
import {
  getAvatarFallback,
  renderAgentStatusBadgeColor,
} from '../../../../utils/functions';
import OfflineAgentAssignModal from './OfflineAgentAssignModal';
import UserOrGroupSelectionComponent from './UserOrGroupSelectionComponent';

import { Avatar, AvatarFallback, AvatarImage } from 'libraryV2/ui/avatar';
import {
  AgentListInterface,
  assignTicketRequestBody,
  TicketInterface,
  UpdateSingleTicketReducerInterface,
} from '../../../../inboxInterface';

interface Props {
  userId: number;
  selectedTicket: TicketInterface;
  hasSupervisorAccess: boolean;
  selectedQueueType: string;
  agentGroup: GroupInterface[];
  agentList: AgentListInterface[];
  selectedProject: SelectedTeamInterface;
  updateStateData: (key: any, value: any) => void;
  updateSingleTicket: (payload: UpdateSingleTicketReducerInterface) => void;
  assignTicket: (requestBody: assignTicketRequestBody) => Promise<boolean>;
  assignBotTicket: (requestBody: assignTicketRequestBody) => Promise<boolean>;
  removeTicketFromListOnAgentAssignment: (
    payload: UpdateSingleTicketReducerInterface
  ) => void;
  updateBotTicketsAfterAssign: () => void;
}

const AgentGroupAssignmentPopover: React.FC<Props> = ({
  userId,
  agentList,
  agentGroup,
  assignTicket,
  selectedTicket,
  assignBotTicket,
  updateStateData,
  selectedProject,
  selectedQueueType,
  updateSingleTicket,
  hasSupervisorAccess,
  updateBotTicketsAfterAssign,
  removeTicketFromListOnAgentAssignment,
}) => {
  const { t } = useTranslation();
  const [notes, setNotes] = React.useState('');
  const [selectedAgentId, setSelectedAgentId] = React.useState<
    string | number | null
  >(null);
  const [selectedGroupId, setSelectedGroupId] = React.useState<
    string | number | null
  >(null);
  const [searchedAgentName, setSearchedAgentName] = React.useState('');
  const [searchedGroupName, setSearchedGroupName] = React.useState('');
  const [isAgentOffline, setIsAgentOffline] = React.useState(false);
  const [isAgentOrGroupMatchFound, setIsAgentOrGroupMatchFound] =
    React.useState(false);

  const [isAssignLoading, setAssignLoading] = React.useState<boolean>(false);

  const getAssignedAgentName = (assignedAgent: AgentListInterface) => {
    let agentName = !!assignedAgent && assignedAgent?.admin?.full_name;
    if (!!assignedAgent && assignedAgent?.admin.id === userId) {
      agentName = 'You';
    } else {
      if (agentName.length > 9) {
        agentName = agentName.substring(0, 9) + '...';
      }
    }
    return agentName;
  };

  const getAssignedGroupName = (assignedGroup: GroupInterface) => {
    let groupName = !!assignedGroup && assignedGroup?.name;
    if (groupName.length > 9) {
      groupName = assignedGroup?.name.substring(0, 9) + '...';
    }
    return groupName;
  };

  const getAssignmentButtonComponent = () => {
    const assigned_agent = selectedTicket?.assigned_agent || null;
    const assigned_group = selectedTicket?.assigned_group || null;
    if (!!assigned_agent) {
      let assignedAgent = agentList?.find(
        (item) => assigned_agent === item?.admin.id
      );
      if (!assignedAgent) return null;
      return (
        <div className='flex gap-3'>
          <div className='relative'>
            <Avatar className='justify-start h-6 w-6 rounded-full object-cover border border-gray-100'>
              <AvatarImage
                src={assignedAgent?.admin?.avatar || defaultAvatar}
                alt='icon_box'
              />
              <AvatarFallback>
                <img
                  src={defaultAvatar}
                  alt={'icon_box'}
                  className='object-cover  rounded-full h-7 w-7'
                />
              </AvatarFallback>
            </Avatar>

            <span
              className={`absolute -bottom-1 right-0 inline-block w-3 h-3 ${renderAgentStatusBadgeColor(
                assignedAgent?.admin?.status || ''
              )} border-2 border-white rounded-full`}
            />
          </div>
          <span className='text-sm font-medium text-gray-700 text'>
            {t(getAssignedAgentName(assignedAgent))}
          </span>
        </div>
      );
    }
    if (!!assigned_group) {
      let assignedGroup = agentGroup?.find(
        (item) => assigned_group === item?.id
      );
      return (
        <div className='flex gap-2'>
          <Avatar className='justify-start h-6 w-6 rounded-full object-cover border border-gray-100'>
            <AvatarImage
              src={assignedGroup?.image || defaultAvatar}
              alt='icon_box'
            />
            <AvatarFallback className='text-[10px] uppercase font-semibold text-gray-500'>
              {!!assignedGroup?.name && getAvatarFallback(assignedGroup?.name)}
            </AvatarFallback>
          </Avatar>
          <span className='text-sm font-medium text-gray-700 text'>
            {assignedGroup && getAssignedGroupName(assignedGroup)}
          </span>
        </div>
      );
    }
    if (selectedQueueType === 'bot') {
      return (
        <div className='flex gap-2'>
          <BotIcon className='justify-start object-cover w-6 h-6 ml-3 border border-gray-100 rounded-full' />
          <span className='text-sm font-medium text-gray-700 text'>Bot</span>
        </div>
      );
    }
    return (
      <span className='px-2 ml-2 text-sm font-medium text-gray-700 text'>
        {t('Assign Chat')}
      </span>
    );
  };

  const shouldEnableNoteAndAssignButton =
    !isAgentOrGroupMatchFound ||
    selectedAgentId !== null ||
    selectedGroupId !== null;

  const handleTicketAssignment = async (close: Function) => {
    let selectedTicketLocal = cloneDeep(selectedTicket);
    let assignedAgent = agentList.find(
      (item) => selectedAgentId === item?.admin.id
    );

    let assignedGroup = agentGroup?.find((item) => selectedGroupId === item.id);
    if (!!assignedAgent) {
      selectedTicketLocal = {
        ...selectedTicketLocal,
        assigned_group: 0,
        assigned_agent: selectedAgentId,
        assigned_agent_email: assignedAgent?.admin?.email || '',
        assigned_agent_avatar: assignedAgent?.admin?.avatar || '',
        assigned_agent_full_name: assignedAgent?.admin?.full_name,
      };
    }
    if (!!assignedGroup) {
      selectedTicketLocal = {
        ...selectedTicketLocal,
        assigned_agent: 0,
        assigned_group: selectedGroupId,
        assigned_group_name: assignedGroup?.name,
        assigned_group_image: assignedGroup?.image,
      };
    }

    let requestBody = {
      ticketId: selectedTicket?.id,
      customer_id: selectedTicket?.customer_id,
      agentId: selectedAgentId,
      groupId: selectedGroupId,
      note: notes,
      selectedTicket: selectedTicketLocal,
    };
    setAssignLoading(true);
    const res =
      selectedQueueType.toLowerCase() === 'bot'
        ? await assignBotTicket(requestBody)
        : await assignTicket(requestBody);
    if (res) {
      if (selectedQueueType.toLowerCase() === 'bot') {
        updateBotTicketsAfterAssign();
      } else {
        setIsAgentOffline(false);
        if (selectedQueueType === 'all') {
          updateSingleTicket({
            userId: userId,
            ticketData: selectedTicketLocal,
            projectId: selectedProject?.id,
          });
        } else if (['unassigned', 'self'].includes(selectedQueueType)) {
          removeTicketFromListOnAgentAssignment({
            userId: userId,
            ticketData: selectedTicketLocal,
            projectId: selectedProject?.id,
          });
        }
      }
      setAssignLoading(false);
      close();
    }
  };

  const handleAgentStatuswiseAssignment = async (close: Function) => {
    let assignedAgent = agentList?.find(
      (agent) => selectedAgentId === agent?.admin.id
    );
    if (assignedAgent?.admin.status === 'online' || selectedGroupId !== null) {
      handleTicketAssignment(close);
    } else {
      setIsAgentOffline(true);
    }
  };

  const renderPopoverButtonView = () => {
    return (
      <>
        {getAssignmentButtonComponent()}
        <ChevronUpDownIcon
          className='px-1 mt-0.5 w-5 h-5 text-gray-400'
          aria-hidden='true'
        />
      </>
    );
  };

  const renderPopoverPanelView = (close: Function) => {
    return (
      <Menu as='div' className='relative ' id='agent-list'>
        <Transition
          show={true}
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute max-h-[68vh] overflow-y-auto ltr:right-0 rtl:left-0 z-20 mt-2 py-1.5 origin-top-right bg-white rounded-md shadow-lg w-72 ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <Menu.Item>
              {({ active }) => (
                <div
                  className={classNames(
                    active ? 'bg-white text-gray-900' : 'text-gray-700',
                    'block text-sm'
                  )}
                >
                  <UserOrGroupSelectionComponent
                    close={close}
                    userId={userId}
                    agentList={agentList}
                    agentGroup={agentGroup}
                    shouldRenderSelectedAgent={true}
                    selectedTicket={selectedTicket}
                    handleSelectedAgentId={setSelectedAgentId}
                    handleSelectedGroupId={setSelectedGroupId}
                    handleNoAgentMatchFound={setSearchedAgentName}
                    handleNoGroupMatchFound={setSearchedGroupName}
                    handleAgentOrGroupMatchFound={setIsAgentOrGroupMatchFound}
                  />
                </div>
              )}
            </Menu.Item>
            {searchedAgentName.length > 0 && searchedGroupName.length > 0 && (
              <div className='flex flex-col justify-center w-full px-6 py-6 mb-4'>
                <img
                  className={'ml-8 marker:text-gray-100 w-40 h-32'}
                  src={noMatchesIcon}
                  alt='noMatches'
                />
                <div className='flex justify-center w-full'>
                  <p className='font-normal text-gray-500 '>
                    {t('No matches related to')} {searchedAgentName}
                  </p>
                </div>
              </div>
            )}
            {shouldEnableNoteAndAssignButton && (
              <>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active ? 'bg-white text-gray-900' : 'text-gray-700',
                        'block px-4 py-0.5 text-sm'
                      )}
                    >
                      <div className='flex justify-between mb-1'>
                        <span className='flex ml-1 text-base font-medium text-gray-700'>
                          {t('Note')}
                          <p className='px-2 mt-0.5 text-gray-500 text-sm'>
                            {t('(Optional)')}
                          </p>
                        </span>
                      </div>
                      <textarea
                        rows={2}
                        name='comment'
                        defaultValue={''}
                        onChange={(e) => {
                          setNotes(e.target.value);
                        }}
                        onKeyDown={(e: any) => {
                          e.code === 'Space' && e.stopPropagation();
                        }}
                        placeholder={t('Add a note for your teammates')}
                        className='w-full h-20 p-2 text-sm font-normal border border-gray-300 rounded-md shadow-sm focus:border-primary focus:ring-primary'
                      />
                    </div>
                  )}
                </Menu.Item>
                <div className='w-full border-t divide-y divide-solid sticky bottom-[-5px] bg-white'>
                  <div className='flex'>
                    <div className='w-1/2 px-2'></div>
                    <div className='flex justify-end w-1/2 gap-2 p-2'>
                      <Button
                        size='xs'
                        onClick={() => {
                          setSelectedAgentId(null);
                          setSelectedGroupId(null);
                          setIsAgentOrGroupMatchFound(false);
                          close();
                        }}
                      >
                        {t('Cancel')}
                      </Button>
                      <Button
                        size='xs'
                        intent='primary'
                        onClick={() => {
                          handleAgentStatuswiseAssignment(close);
                        }}
                        isDisabled={
                          (selectedAgentId === null &&
                            selectedGroupId === null) ||
                          isAssignLoading
                        }
                      >
                        {t('Assign')}
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
            {isAgentOffline && (
              <OfflineAgentAssignModal
                close={close}
                agentList={agentList}
                isLoading={isAssignLoading}
                selectedTicket={selectedTicket}
                setIsAgentOffline={setIsAgentOffline}
                selectedAgentId={selectedAgentId}
                handleTicketAssignment={handleTicketAssignment}
              />
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  return (
    <Popover className='relative'>
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`
                ${open ? 'border-2 border-primary' : 'text-opacity-90'}
                flex py-1.5 ltr:pl-1.5 rtl:pr-1.5 border border-gray-300 rounded-md focus:outline-none`}
          >
            {renderPopoverButtonView()}
          </Popover.Button>
          <Transition as={'div'}>
            <Popover.Panel className='absolute z-20 w-full mt-0'>
              {renderPopoverPanelView(close)}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
export default AgentGroupAssignmentPopover;
