import { FC } from 'react';
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from 'libraryV2/ui/popover';
import { Button } from 'libraryV2/ui/button';
import { Cable, Link, Paperclip, Plus } from 'lucide-react';
import { CustomAgentToolType } from 'pages/raven/interface';

interface AddNewToolButtonProps {
  onToolSelect: (toolType: CustomAgentToolType) => any;
}
export const AddNewToolButton: FC<AddNewToolButtonProps> = ({
  onToolSelect,
}) => {
  const handleAddNewTool = onToolSelect;
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant={'outline'} className='w-full gap-2'>
          <Plus className='w-4 h-4 text-textPrimary' />
          <span>Add Tool</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-[var(--radix-popover-trigger-width)] p-1 bg-white flex flex-col text-left'>
        <PopoverClose asChild>
          <Button
            onClick={() => handleAddNewTool('api')}
            variant={'ghost'}
            disabled
            size={'sm'}
            className='hover:bg-background-hover text-sm font-normal justify-start gap-2'
          >
            <Cable className='w-4 h-4 text-textPrimary' />
            Make API Call
          </Button>
        </PopoverClose>
        <PopoverClose asChild>
          <Button
            onClick={() => handleAddNewTool('document')}
            variant={'ghost'}
            disabled
            size={'sm'}
            className='hover:bg-background-hover text-sm font-normal justify-start gap-2'
          >
            <Paperclip className='w-4 h-4 text-textPrimary' />
            Upload Documents
          </Button>
        </PopoverClose>
        <PopoverClose asChild>
          <Button
            onClick={() => handleAddNewTool('website')}
            variant={'ghost'}
            size={'sm'}
            className='hover:bg-background-hover text-sm font-normal justify-start gap-2'
          >
            <Link className='w-4 h-4 text-textPrimary' />
            Get Info from website
          </Button>
        </PopoverClose>
      </PopoverContent>
    </Popover>
  );
};
