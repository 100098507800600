
import {useDispatch, useSelector} from 'react-redux';

const useInboxHook = () => {
    const dispatch = useDispatch();
    const dashboardData = useSelector((state: any) => state.dashboard);

    const getTicketConfigurationData = async () => {
        const result = await dispatch.settings.getTicketConfigurationData(
          dashboardData?.selectedProject?.id
        );
        return result;
    };

    return {
        getTicketConfigurationData
    }
}

export default useInboxHook;