import useTranslation from 'components/customHooks/useTranslation';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'libraryV2/ui/alert-dialog';
import React from 'react';

interface DeleteAlertDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  title: string;
  description: any;
  cancelTitle?: string;
  confirmTitle?: string;
  onCancel: () => void;
  onConfirm: () => void;
  isConfirming?: boolean;
}

const DeleteAlert: React.FC<DeleteAlertDialogProps> = ({
  open,
  onOpenChange,
  title,
  description,
  onCancel,
  onConfirm,
  cancelTitle = 'No',
  confirmTitle = 'Yes! Delete',
  isConfirming = false,
}) => {
  const { t } = useTranslation();
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent className='bg-white' >
        <AlertDialogHeader className='rtl:text-right'>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className='rtl:text-right'>
          <AlertDialogCancel
            onClick={onCancel}
            disabled={isConfirming}
            className='rtl:text-right bg-[#F4F4F5] border-0'
          >
            {t(cancelTitle)}
          </AlertDialogCancel>
          <AlertDialogAction
            className='ltr:ml-1 rtl:mr-1 text-white bg-[#FF0000] rtl:text-right hover:bg-[#D70000]'
            onClick={onConfirm}
            disabled={isConfirming}
          >
            {t(confirmTitle)}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteAlert;
