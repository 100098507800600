import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'libraryV2/ui/select';
import {
  useActions,
  useSelector,
  ConditionHeader,
} from 'pages/automationWorkflow/export';
import {
  IRadioGroup,
  IDropdownGroup,
  IChildrenUiScreen,
} from 'pages/automationWorkflow/interface';

const Discount = () => {
  // Extract required action functions from the custom hook
  const {
    updateConditionallyRenderingActionsUI,
    updateSelectedSaveActionsData,
  } = useActions();

  const { selectedSaveActionsData } = useSelector(
    (state: any) => state.workflowAutomation
  );

  // Destructure properties to improve readability
  // Specify the type for timeOfDelay or provide a default value
  const { currentScreen, component } =
    selectedSaveActionsData?.eCommerce?.discount;

  const updateStoredData = (name: string, value: string) => {
    // const { component } = selectedSaveActionsData.eCommerce?.discount;
    const storedData = component.definition.stored_data || {};
    const discountPayload = storedData.discount_payload || {};

    const updatedDiscountPayload = {
      ...discountPayload,
      [name]: value,
    };

    const updatedStoredData = {
      ...storedData,
      discount_payload: updatedDiscountPayload,
    };

    const updatedComponent = {
      ...component,
      definition: {
        ...component.definition,
        stored_data: updatedStoredData,
      },
    };

    const updateActionData = {
      eCommerce: {
        discount: {
          ...selectedSaveActionsData?.eCommerce?.discount,
          component: updatedComponent,
        },
      },
      general: {
        delay: { valueOfDelay: null },
      },
      whatsapp: { selectedTemplate: null },
    };

    updateSelectedSaveActionsData(updateActionData);
  };

  const renderConditionallyDiscountTypeView = (
    discountData: IChildrenUiScreen
  ) => {
    switch (discountData?.type) {
      case 'TextInput':
        return (
          <div className='p-4 bg-white rounded mb-6'>
            <p className='text-gray-600 font-medium text-sm mb-1'>
              {discountData?.label || ''}{' '}
              {discountData?.required && (
                <span className='text-red-400 text-sm'>*</span>
              )}
            </p>
            <input
              value={
                component?.definition?.stored_data?.discount_payload?.[
                  discountData.name
                ] ?? ''
              }
              type={discountData?.['input-type']}
              className='w-full py-2 px-3 shadow-sm border border-gray-300 rounded-md text-sm text-gray-700 focus:ring-primary focus:border-primary focus:outline-none'
              placeholder='Value'
              onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
                updateStoredData(discountData.name, value.target.value)
              }
            />
            <p className='text-gray-500 text-sm'>{discountData?.description}</p>
          </div>
        );
      case 'Dropdown':
        return (
          <div className='p-4 bg-white rounded mb-6'>
            <p className='text-gray-600 font-medium text-sm mb-1'>
              {discountData?.label || ''}
              {discountData?.required && (
                <span className='text-red-400 text-sm'>*</span>
              )}
            </p>
            <Select
              onValueChange={(value: any) => {
                updateStoredData(discountData.name, value);
              }}
              value={
                component?.definition?.stored_data?.discount_payload?.[
                  discountData.name
                ] ?? ''
              }
            >
              <SelectTrigger className='w-full focus:border-primary'>
                <SelectValue placeholder='Select An Option' />
              </SelectTrigger>
              <SelectContent className='z-50 bg-white mt-1'>
                {discountData?.data_source?.length &&
                  discountData?.data_source?.map(
                    (item: IDropdownGroup, index: number) => (
                      <SelectItem key={index} value={item?.id}>
                        {item?.title}
                      </SelectItem>
                    )
                  )}
              </SelectContent>
            </Select>

            <p className='text-gray-500 text-sm'>{discountData?.description}</p>
          </div>
        );
      case 'RadioButtonsGroup':
        return (
          <div className='p-4 bg-white rounded mb-6'>
            <p className='text-gray-600 font-medium text-sm mb-1'>
              {discountData?.label || ''}
            </p>
            {discountData?.['data-source']?.length &&
              discountData?.['data-source'].map((item: IRadioGroup) => (
                <div key={item?.id} className='flex my-4'>
                  <input
                    type='radio'
                    id={item?.id}
                    className='text-green-400 without-ring'
                    name={discountData.name}
                    value={item.id}
                    checked={
                      component?.definition?.stored_data?.discount_payload?.[
                        discountData.name
                      ] === item?.id
                    }
                    onChange={() =>
                      updateStoredData(discountData.name, item?.id)
                    }
                  />
                  <div className='ml-3'>
                    <label
                      htmlFor={item?.id}
                      className='block  text-sm font-medium text-gray-800 -mt-1'
                    >
                      {item?.title}
                    </label>

                    <p className='text-gray-500 text-sm'>{item?.description}</p>
                  </div>
                </div>
              ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <ConditionHeader
        title={currentScreen?.title}
        previousUIAction={() =>
          updateConditionallyRenderingActionsUI('actionElement')
        }
      />
      <div
        className='bg-gray-50 p-4 overflow-auto'
        style={{ height: 'calc(100vh - 196px)' }}
      >
        {currentScreen.layout.children[0].children.map(
          (item: IChildrenUiScreen, index: number) => (
            <div key={index}>{renderConditionallyDiscountTypeView(item)}</div>
          )
        )}
      </div>
    </div>
  );
};

export default Discount;
