import useTranslation from 'components/customHooks/useTranslation';
import { subscriptionDetailsType } from 'index';
import { Alert, AlertDescription } from 'libraryV2/ui/alert';
import { Info } from 'lucide-react';
import { FC, useEffect, useState } from 'react';
import { createDymanicColumns } from './component/userManagementTable/DynamicColumn';
import { UserManagementTable } from './component/userManagementTable/UserManagementTable';
import useUserManagement from './hooks/useUserManagement';
import { ISelectedProject, IUserManagement } from './interface';
import SettingsPageHeader from '../components/SettingsPageHeader';
import { Link } from 'library';
const TeamUserSettings = () => {
  const [transformedData, setTransformedData] = useState<IUserManagement[]>([]);
  const {
    getAgentRoleList,
    getAgentAccessList,
    agentAccessList,
    subscriptionDetails,
    getSubscriptionDetails,
    selectedProject,
    clearUserManagementData,
    getTotalTeamMemberCount,
  } = useUserManagement();

  useEffect(() => {
    initializeMethods();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject?.id]);

  useEffect(() => {
    setTransformedData(tableDataMapper());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentAccessList]);

  const initializeMethods = () => {
    const fetchAgentRoleList = async () => await getAgentRoleList();
    const fetchAgentAccessList = async () => await getAgentAccessList();
    const fetchSubscriptionDetails = async () => await getSubscriptionDetails();
    clearUserManagementData();
    fetchAgentAccessList();
    fetchAgentRoleList();
    fetchSubscriptionDetails();
    setTransformedData(tableDataMapper());
  };

  function tableDataMapper(): IUserManagement[] {
    return agentAccessList.map((user: any) => ({
      id: user.admin.id,
      agents: user.admin.full_name,
      last_log_in: user.admin.last_online_time,
      user_role: user.role,
      role_name: user.role.name,
      email: user.admin.email,
      avatar: user.admin.avatar ? user.admin.avatar : '',
      is_confirmed: user.is_confirmed,
    }));
  }
  const columns = createDymanicColumns(selectedProject);

  return (
    <>
      <UserManagementHeader />
      <div className=' px-6'>
        <SubscriptonInfoAlert
          getTotalTeamMemberCount={getTotalTeamMemberCount}
          subscriptionDetails={subscriptionDetails}
          selectedProject={selectedProject}
        />
        {/* table */}
        <UserManagementTable
          columns={columns}
          data={transformedData}
          selectedProject={selectedProject}
        />
      </div>
    </>
  );
};

const UserManagementHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <SettingsPageHeader 
        title='User Management'
      />
      <div className='px-6 mb-4'>
        <p className='w-[500px] subtitle-medium mt-[6px]'>
          {t(
            'Manage team roles and permissions to control access across the platform, ensuring the right tools and data for each user.'
          )}
          <Link url="https://docs.myalice.ai/get-started/create-team/assign-role" openInNewTab={true} showUnderline={true}>
            Learn More
          </Link>
        </p>
      </div>
    </>
  );
};
interface SubscriptonInfoAlertProps {
  subscriptionDetails: subscriptionDetailsType;
  selectedProject: ISelectedProject;
  getTotalTeamMemberCount: () => number;
}
const SubscriptonInfoAlert: FC<SubscriptonInfoAlertProps> = ({
  subscriptionDetails,
  selectedProject,
  getTotalTeamMemberCount,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {selectedProject.is_using_new_billing && (
        <Alert className='flex items-center my-4 gap-2 flex-wrap bg-[#F0FDF4] border-[#DCFCE7] text-[#166534] p-3 rounded-md'>
          <Info size={16} className='text-[#166534]' />
          {subscriptionDetails && (
            <AlertDescription>
              {t('Your subscription plan includes')}{' '}
              <b>{getTotalTeamMemberCount()}</b> {t('team members.')}{' '}
              <b>{getTotalTeamMemberCount()}</b>/
              <b>{subscriptionDetails.project_billing_info.max_team_members}</b>{' '}
              {t('members are already filled. You can invite')}{' '}
              <b>{`${
                subscriptionDetails.project_billing_info.max_team_members -
                getTotalTeamMemberCount()
              }`}</b>{' '}
              {t('more member(s)!')}
            </AlertDescription>
          )}
        </Alert>
      )}
    </>
  );
};

export default TeamUserSettings;
