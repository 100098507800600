'use client';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';
import useTranslation from 'components/customHooks/useTranslation';
import { Button } from 'libraryV2/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from 'libraryV2/ui/dialog';
import { Input } from 'libraryV2/ui/input';
import { PlusIcon, Search, CirclePlusIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useSelector, useAutomationWorkflow } from '../../../../../export';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'libraryV2/ui/dropdown-menu';
import { Popover, PopoverContent, PopoverTrigger } from 'libraryV2/ui/popover';
import { cn } from 'libraryV2/utils';
import { EcommerceStore } from 'pages/automationWorkflow/interface';
import { useDebounce } from 'components';
import { navigate } from '@reach/router';

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  selectedTriggerTypes: string[];
  setSelectedTriggerTypes: (triggerType: string[]) => void;
  selectedSources: number[];
  setSelectedSources: (src: number[]) => void;
  setSelectedStatus: (status: boolean | undefined) => void;
  setSearchKey: (key: string) => void;
}

export function DataTableToolbar<TData>({
  table,
  selectedTriggerTypes,
  setSelectedTriggerTypes,
  selectedSources,
  setSelectedSources,
  setSelectedStatus,
  setSearchKey,
}: DataTableToolbarProps<TData>) {
  const { t } = useTranslation();
  const { integratedEcommerceList } = useSelector(
    (state: any) => state.workflowAutomation
  );
  const { clearStateData } = useAutomationWorkflow();
  const { selectedProject } = useSelector((state: any) => state.dashboard);
  const [selectedLocalStatus, setSelectedLocalStatus] = useState<string[]>([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const selectedRows = table.getSelectedRowModel().rows.map((r) => r.original);
  const [localSearchKey, setLocalSearchKey] = useState('');
  const [statusDropDownOpen, setStatusDropDownOpen] = useState(false);
  const [sourceDropdown, setSourceDropdown] = useState(false);
  const [triggerTypeDropdown, setTriggerTypeDropdown] = useState(false);

  const debounceSearchTerm = useDebounce(localSearchKey, 500);

  useEffect(() => {
    setSearchKey(debounceSearchTerm);
    // eslint-disable-next-line
  }, [debounceSearchTerm]);

  const triggerTypes = [
    {
      id: '1',
      label: 'Order Created',
      textColor: 'text-indigo-500',
      bgColor: 'bg-indigo-500',
    },
    {
      id: '2',
      label: 'Order Fulfilled',
      textColor: 'text-green-500',
      bgColor: 'bg-green-500',
    },
    {
      id: '3',
      label: 'Cart Abandoned',
      textColor: 'text-amber-500',
      bgColor: 'bg-amber-500',
    },
    {
      id: '4',
      label: 'Order Status Updated',
      textColor: 'text-blue-500',
      bgColor: 'bg-blue-500',
    },
    {
      id: '5',
      label: 'Order Cancelled',
      textColor: 'text-red-600',
      bgColor: 'bg-red-600',
    },
  ];

  const status = [
    {
      id: '1',
      label: 'Active',
      textColor: 'text-green-500',
      bgColor: 'bg-green-500',
    },
    {
      id: '2',
      label: 'Inactive',
      textColor: 'text-textSecondary',
      bgColor: 'bg-textSecondary',
    },
  ];

  // Function to handle checkbox changes
  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    filterType: 'status' | 'type' | 'source'
  ) => {
    const { id, checked } = event.target;

    if (filterType === 'status') {
      setSelectedLocalStatus(checked ? [id] : []);
      if (checked) {
        if (id === 'Active') {
          setSelectedStatus(true);
        } else if (id === 'Inactive') {
          setSelectedStatus(false);
        }
      } else {
        setSelectedStatus(undefined);
      }
    } else if (filterType === 'type') {
      let tempTriggerTypes = [...selectedTriggerTypes];
      tempTriggerTypes = checked
        ? [...tempTriggerTypes, id]
        : tempTriggerTypes.filter((item) => item !== id);
      setSelectedTriggerTypes(tempTriggerTypes);
    } else if (filterType === 'source') {
      let temp_sources = [...selectedSources];
      if (checked) {
        let source = integratedEcommerceList?.find(
          (item: EcommerceStore) => item.alice_store_id === Number(id)
        );
        temp_sources.push(source?.alice_store_id);
      } else {
        temp_sources = temp_sources.filter((item) => item !== Number(id));
      }
      setSelectedSources(temp_sources);
    }
  };

  const clearFilters = (filterType: 'status' | 'type' | 'source') => {
    if (filterType === 'status') {
      setSelectedLocalStatus([]);
      setSelectedStatus(undefined);
    } else if (filterType === 'type') {
      setSelectedTriggerTypes([]);
    } else {
      setSelectedSources([]);
    }
  };

  const renderCardDeleteModal = () => {
    return (
      <Dialog open={deleteModal} onOpenChange={setDeleteModal}>
        <DialogContent
          onOpenAutoFocus={(e) => e.preventDefault()}
          hideCloseButton={true}
          className='sm:max-w-[425px] bg-white'
        >
          <DialogTitle className='text-zinc-900 rtl:text-right text-lg font-semibold leading-7'>
            {t('Delete')} {selectedRows.length} {t('Automations?')}
          </DialogTitle>

          <p className='text-zinc-500 rtl:text-right text-sm font-normal'>
            {t(`This will permanently delete`)} {selectedRows.length}{' '}
            {t('automations that you have selected.')}{' '}
            {t('Are you sure you want to delete?')}
          </p>

          <DialogFooter className='rtl:gap-2 rtl:justify-start'>
            <DialogClose>
              <Button
                type='button'
                variant='default'
                className='bg-[#F4F4F5] text-zinc-900 shadow-none hover:text-zinc-900 hover:bg-[#F4F4F5]'
              >
                {t('No')}
              </Button>
            </DialogClose>

            <Button
              type='button'
              variant='destructive'
              className='bg-[#FF0000] text-white text-sm font-medium'
            >
              {t('Yes! Delete')}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };

  // api is not ready. will ready soon, then need this section

  // const renderSelectionToolbarView = () => {
  //   return (
  //     <div className='flex gap-2'>
  //       <Button
  //         size='sm'
  //         variant='outline'
  //         className='flex gap-2 text-white bg-[#FF0000] hover:bg-red-600 hover:text-white'
  //         disabled={!selectedRows.length}
  //         onClick={() => setDeleteModal(true)}
  //       >
  //         <Trash className='w-4 h-4' />
  //         <span>{t('Delete')}</span>
  //       </Button>

  //       <Button
  //         size='sm'
  //         variant='outline'
  //         className='flex gap-2'
  //         onClick={() => table.resetRowSelection()}
  //       >
  //         <span>{t('Cancel')}</span>
  //       </Button>
  //     </div>
  //   );
  // };

  const getSourceName = (store_id: number) => {
    let source = integratedEcommerceList.find(
      (item: EcommerceStore) => item.alice_store_id === store_id
    );
    return source?.store_name || source?.ecommerce_type;
  };

  const renderSourcesFilterView = () => {
    if (selectedSources?.length < 1) {
      return null;
    }
    return (
      <div className='flex gap-2 items-center'>
        <span className='text-xs leading-5 text-textSecondary'>Sources:</span>
        <div className='flex flex-wrap gap-1'>
          <Button
            key={selectedSources[0]}
            className='text-xs bg-zinc-100 text-textPrimary flex items-center gap-1 h-6 px-2 py-0.5'
            variant={'ghost'}
          >
            <span>{getSourceName(selectedSources[0])}</span>
            <Cross2Icon
              onClick={() => {
                let temp = selectedSources.filter(
                  (item) => item !== selectedSources[0]
                );
                setSelectedSources(temp);
              }}
              stroke='2'
              className='w-4 h-4 text-textPrimary-disable stroke-2'
            />
          </Button>

          {selectedSources?.length > 1 && (
            <Popover>
              <PopoverTrigger
                disabled={selectedSources.length <= 1}
                className={cn({ hidden: selectedSources.length <= 1 })}
              >
                <Button
                  key={selectedSources[0]}
                  className='text-xs bg-zinc-100 text-textPrimary flex items-center h-6 px-2 py-0.5'
                  variant={'ghost'}
                >
                  <span className='text-xs font-bold'>+</span>
                  <span>{selectedSources.length - 1}</span>
                </Button>
              </PopoverTrigger>
              <PopoverContent className='bg-white w-fit' id='weird-contnet'>
                <div className='flex flex-col gap-2'>
                  {selectedSources.slice(1).map((item) => (
                    <Button
                      key={item}
                      className='text-xs w-fit bg-zinc-100 text-textPrimary flex items-center gap-1 h-6 px-2 py-0.5'
                      variant={'ghost'}
                    >
                      <span>{getSourceName(item)}</span>
                      <Cross2Icon
                        onClick={() => {
                          let filteredSources = selectedSources.filter(
                            (ele) => ele !== item
                          );
                          setSelectedSources(filteredSources);
                        }}
                        stroke='2'
                        className='w-4 h-4 text-textPrimary-disable stroke-2'
                      />
                    </Button>
                  ))}
                </div>
              </PopoverContent>
            </Popover>
          )}
        </div>
      </div>
    );
  };

  const renderTriggerTypeFilterView = () => {
    if (selectedTriggerTypes.length < 1) {
      return null;
    }
    return (
      <div className='flex gap-2 items-center'>
        <span className='text-xs leading-5 text-textSecondary'>
          Trigger type:
        </span>
        <div className='flex flex-wrap gap-1'>
          <Button
            key={selectedTriggerTypes[0]}
            className='text-xs bg-zinc-100 text-textPrimary flex items-center gap-1 h-6 px-2 py-0.5'
            variant={'ghost'}
          >
            <span>{selectedTriggerTypes[0]}</span>
            <Cross2Icon
              onClick={() => {
                let filteredTriggers = selectedTriggerTypes.filter(
                  (ele) => ele !== selectedTriggerTypes[0]
                );
                setSelectedTriggerTypes(filteredTriggers);
              }}
              stroke='2'
              className='w-4 h-4 text-textPrimary-disable stroke-2'
            />
          </Button>
          {selectedTriggerTypes?.length > 1 && (
            <Popover>
              <PopoverTrigger
                disabled={selectedTriggerTypes.length <= 1}
                className={cn({ hidden: selectedTriggerTypes.length <= 1 })}
              >
                <Button
                  key={selectedTriggerTypes[0]}
                  className='text-xs bg-zinc-100 text-textPrimary flex items-center h-6 px-2 py-0.5'
                  variant={'ghost'}
                >
                  <span className='text-xs font-bold'>+</span>
                  <span>{selectedTriggerTypes.length - 1}</span>
                </Button>
              </PopoverTrigger>
              <PopoverContent className='bg-white w-fit'>
                <div className='flex flex-col gap-2'>
                  {selectedTriggerTypes?.slice(1).map((item) => (
                    <Button
                      key={item}
                      className='text-xs w-fit bg-zinc-100 text-textPrimary flex items-center gap-1 h-6 px-2 py-0.5'
                      variant={'ghost'}
                    >
                      <span>{item}</span>
                      <Cross2Icon
                        onClick={() => {
                          let filteredTriggers = selectedTriggerTypes.filter(
                            (ele) => ele !== item
                          );
                          setSelectedTriggerTypes(filteredTriggers);
                        }}
                        stroke='2'
                        className='w-4 h-4 text-textPrimary-disable stroke-2'
                      />
                    </Button>
                  ))}
                </div>
              </PopoverContent>
            </Popover>
          )}
        </div>
      </div>
    );
  };

  const renderStatusFilterView = () => {
    if (selectedLocalStatus.length < 1) {
      return null;
    }
    return (
      <div className='flex gap-2 items-center'>
        <span className='text-xs leading-5 text-textSecondary'>Status:</span>
        <div className='flex flex-wrap gap-1'>
          <Button
            key={selectedLocalStatus[0]}
            className='text-xs bg-zinc-100 text-textPrimary flex items-center gap-1 h-6 px-2 py-0.5'
            variant={'ghost'}
          >
            <span>{selectedLocalStatus[0]}</span>
            <Cross2Icon
              onClick={() => {
                setSelectedLocalStatus([]);
                setSelectedStatus(undefined);
              }}
              stroke='2'
              className='w-4 h-4 text-textPrimary-disable stroke-2'
            />
          </Button>
        </div>
      </div>
    );
  };

  const closeDropdown = () => {
    setTriggerTypeDropdown(false);
    setStatusDropDownOpen(false);
    setSourceDropdown(false);
  };

  return (
    <>
      <div className='flex items-center justify-between'>
        <div className='flex flex-1 items-center space-x-2'>
          <div className='flex relative items-center'>
            <Search className='absolute left-2 mt-1 h-4 w-4 text-zinc-500 transform top-1/2 -translate-y-1/2' />
            <Input
              placeholder={t('Search automations')}
              value={localSearchKey}
              onChange={(e) => setLocalSearchKey(e.target.value)}
              className='pl-7 h-8 w-[150px] lg:w-[250px]'
            />
          </div>
          <div>
            <DropdownMenu
              open={statusDropDownOpen}
              onOpenChange={closeDropdown}
            >
              <DropdownMenuTrigger
                asChild
                onClick={() => setStatusDropDownOpen(!statusDropDownOpen)}
              >
                <div className='flex items-center px-3 py-1 mt-1 rounded-md border border-dotted border-[#DFDFE2] cursor-pointer'>
                  <div>
                    <CirclePlusIcon size={15} />
                  </div>
                  <p className='text-sm font-medium ml-1'>Status</p>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                side='bottom'
                align='start'
                className='shadow-lg bg-white z-30 rounded-md p-0'
              >
                <div className='py-2 px-1'>
                  <div className='flex flex-col gap-3 p-1.5'>
                    {status.map((item, index) => (
                      <div className='flex items-center'>
                        <input
                          type='checkbox'
                          name='active'
                          readOnly
                          id={item.label}
                          checked={selectedLocalStatus.includes(item.label)}
                          onChange={(event) =>
                            handleCheckboxChange(event, 'status')
                          }
                          className={`text-green-500 outline-none border rounded cursor-pointer focus:ring-0`}
                        />
                        <label
                          htmlFor={item.label}
                          className='ml-2 cursor-pointer'
                        >
                          <div
                            className={`border border-border  py-0.5 px-2 rounded-md inline-flex items-center gap-1`}
                          >
                            <div
                              className={`h-2 w-2 rounded-full ${item.bgColor}`}
                            ></div>
                            <span
                              className={`${item.textColor} text-xs font-medium`}
                            >
                              {item.label}
                            </span>
                          </div>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                {selectedLocalStatus.length > 0 && (
                  <div
                    className='border-t p-2 flex justify-center cursor-pointer'
                    onClick={() => {
                      clearFilters('status');
                      setStatusDropDownOpen(!statusDropDownOpen);
                    }}
                  >
                    <p className=''>Clear Filters</p>
                  </div>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div>
            <DropdownMenu open={sourceDropdown} onOpenChange={closeDropdown}>
              <DropdownMenuTrigger
                asChild
                onClick={() => setSourceDropdown(!sourceDropdown)}
              >
                <div className='flex items-center px-3 py-1 mt-1 rounded-md border border-dotted border-[#DFDFE2] cursor-pointer'>
                  <div>
                    <CirclePlusIcon size={15} />
                  </div>
                  <p className='text-sm font-medium ml-1'>Sources</p>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                side='bottom'
                align='start'
                className='shadow-lg bg-white z-30 rounded-md p-0'
              >
                <div className=''>
                  <div className='py-2 px-1'>
                    <div className='flex flex-col gap-3 p-1.5'>
                      {integratedEcommerceList.map((item: EcommerceStore) => (
                        <div className='flex items-center'>
                          <input
                            type='checkbox'
                            readOnly
                            id={item.alice_store_id.toString()}
                            checked={selectedSources?.some(
                              (it) => it === item.alice_store_id
                            )}
                            onChange={(event) =>
                              handleCheckboxChange(event, 'source')
                            }
                            className={`text-green-500 outline-none border rounded cursor-pointer focus:ring-0`}
                          />
                          <label
                            htmlFor={item.alice_store_id.toString()}
                            className='ml-2 cursor-pointer'
                          >
                            <div
                              className={`border border-border  py-0.5 px-2 rounded-md text-sm font-medium flex items-center`}
                            >
                              {item.store_name || item.ecommerce_type}
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  {selectedSources.length > 0 && (
                    <div
                      className='border-t p-2 flex justify-center cursor-pointer'
                      onClick={() => {
                        clearFilters('source');
                        setSourceDropdown(false);
                      }}
                    >
                      <p>Clear Filters</p>
                    </div>
                  )}
                </div>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div>
            <DropdownMenu
              open={triggerTypeDropdown}
              onOpenChange={closeDropdown}
            >
              <DropdownMenuTrigger
                asChild
                onClick={() => setTriggerTypeDropdown(!triggerTypeDropdown)}
              >
                <div className='flex items-center px-3 py-1 mt-1 rounded-md border border-dotted border-[#DFDFE2] cursor-pointer'>
                  <div>
                    <CirclePlusIcon size={15} />
                  </div>
                  <p className='text-sm font-medium ml-1'>Trigger Type</p>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                side='bottom'
                align='start'
                className='shadow-lg bg-white z-30 rounded-md p-0'
              >
                <div>
                  <div className='py-2 px-1'>
                    <div className='flex flex-col gap-3 p-1.5'>
                      {triggerTypes.map((item, index) => (
                        <div className='flex items-center'>
                          <input
                            type='checkbox'
                            readOnly
                            id={item.label}
                            checked={selectedTriggerTypes.includes(item.label)}
                            onChange={(event) =>
                              handleCheckboxChange(event, 'type')
                            }
                            className={`text-green-500 border rounded cursor-pointer focus:ring-0`}
                          />
                          <label
                            htmlFor={item.label}
                            className='ml-2 cursor-pointer'
                          >
                            <div
                              className={`border border-border  py-0.5 px-2 rounded-md inline-flex items-center gap-1`}
                            >
                              <div
                                className={`h-2 w-2 rounded-full ${item.bgColor}`}
                              ></div>
                              <span
                                className={`${item.textColor} text-xs font-medium`}
                              >
                                {item.label}
                              </span>
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                  {selectedTriggerTypes.length > 0 && (
                    <div
                      className='border-t p-2 flex justify-center cursor-pointer'
                      onClick={() => {
                        clearFilters('type');
                        setTriggerTypeDropdown(false);
                      }}
                    >
                      <p className=''>Clear Filters</p>
                    </div>
                  )}
                </div>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          {(selectedSources.length > 0 ||
            selectedTriggerTypes.length > 0 ||
            selectedLocalStatus.length > 0) && (
            <Button
              variant={'ghost'}
              onClick={() => {
                setSelectedSources([]);
                setSelectedLocalStatus([]);
                setSelectedStatus(undefined);
                setSelectedTriggerTypes([]);
              }}
              className='flex  gap-2 items-center bg-zinc-100 h-8 px-2.5 py-1.5'
            >
              <Cross2Icon className='w-4 h-4' />
              <span className='text-xs text-textPrimary'>Clear Filter</span>
            </Button>
          )}
        </div>
        {/* {selectedRows.length > 0 ? (
          renderSelectionToolbarView()
        ) : ( */}
        <div className='flex items-center'>
          {/* export api is not ready */}
          {/* export button
            <Button size='sm' variant='outline' className='flex gap-2'>
              <Download className='w-3.5 h-3.5' />
              <span className='font-medium text-xs text-textPrimary leading-5'>
                Export
              </span>
            </Button> */}
          <Button
            disabled={
              !integratedEcommerceList || integratedEcommerceList?.length < 1
            }
            onClick={() => {
              clearStateData();

              navigate(
                `/projects/${selectedProject?.id}/workflow-automation/create`
              );
            }}
            className='ml-3 hover:bg-primary'
          >
            {<PlusIcon strokeWidth='2.5' className='text-white h-3.5 w-3.5' />}
            Create Automation
          </Button>
        </div>
        {/* )} */}

        {renderCardDeleteModal()}
      </div>
      {(selectedSources.length > 0 ||
        selectedTriggerTypes.length > 0 ||
        selectedLocalStatus.length > 0) && (
        <div className='flex items-center gap-2'>
          {renderSourcesFilterView()}
          {renderTriggerTypeFilterView()}
          {renderStatusFilterView()}
        </div>
      )}
    </>
  );
}
