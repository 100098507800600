import {
  MultiSelect,
  MultiSelectOptionBadgeView,
} from 'libraryV2/extended-ui/MultiSelect';
import { Button } from 'libraryV2/ui/button';
import { PlusCircle, X, Search } from 'lucide-react';
import { Checkbox } from 'libraryV2/ui/checkbox';
import { cn } from 'libraryV2/utils';
import {
  AnalyticsLogsSourceData,
  MessageTemplateStatusOptions,
  templateStatusOptions,
} from 'pages/reportingV2/utility/content';
import { FC } from 'react';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import { startCase } from 'lodash';
import { IHistoryColumnFilter } from './HistoryTableContainer';
import { IWhatsappTemplateAnalyticsData } from 'pages/reportingV2/interface';
import { Input } from 'libraryV2/ui/input';

interface HistoryTableHeadersProps {
  filterState: IHistoryColumnFilter;
  setFilterState: (state: IHistoryColumnFilter) => void;
  whatsappTemplateList: Pick<
    IWhatsappTemplateAnalyticsData,
    'id' | 'status' | 'name'
  >[];
}

export const TemplateDeliveryStatusView = ({
  label,
  color,
}: {
  label: string;
  color: string;
}) => {
  color = color || '#F59E0B';
  return (
    <div
      className={cn(
        'border border-border py-0.5 px-2 rounded-md w-fit text-sm font-medium flex items-center gap-1',
        `text-[${color}]`
      )}
    >
      <span
        style={{
          backgroundColor: color,
        }}
        className={cn('w-1.5 h-1.5 rounded-full mt-0.5')}
      ></span>
      <span
        style={{
          color: color,
        }}
      >
        {startCase(label)}
      </span>
    </div>
  );
};

export const HistoryTableHeaders: FC<HistoryTableHeadersProps> = ({
  filterState,
  setFilterState,
  whatsappTemplateList,
}) => {
  const selectedTemplatelist = filterState.templates;

  const handleStatusFilter = (options: typeof MessageTemplateStatusOptions) => {
    setFilterState({ ...filterState, status: options });
  };

  const handleSourceFilter = (options: typeof AnalyticsLogsSourceData) => {
    setFilterState({ ...filterState, sources: options });
  };

  const handleRemoveSourceFilter = (
    option: typeof AnalyticsLogsSourceData[number]
  ) => {
    const oldSource = filterState.sources.filter(
      (v) => v.value !== option.value
    );
    setFilterState({ ...filterState, sources: oldSource });
  };

  const handleTemplateFilter = (options: typeof selectedTemplatelist) => {
    setFilterState({ ...filterState, templates: options });
  };

  const handleRemoveTemplateFilter = (
    option: typeof selectedTemplatelist[number]
  ) => {
    const removedList = filterState.templates.filter(
      (v) => v.value !== option.value
    );
    setFilterState({ ...filterState, templates: removedList });
  };

  const handleRemoveStatusFilter = (
    option: typeof templateStatusOptions[number]
  ) => {
    const oldStatus = filterState.status.filter(
      (v) => v.value !== option.value
    );
    setFilterState({ ...filterState, status: oldStatus });
  };

  const filteredTemplateList = whatsappTemplateList.map((template) => ({
    label: template.name,
    value: template.id,
  }));

  const renderSearchInput = () => {
    return (
      <div className='flex items-center gap-2 relative'>
        <Search className='w-4 h-4 absolute transform -translate-y-1/2 left-2 top-1/2 text-textPrimary-disable' />
        <Input
          placeholder='Search with template, customer or phone'
          value={filterState.search ?? ''}
          className='ltr:pl-7 rtl:pr-7 h-8 m-0 w-[320px]'
          onChange={(event) =>
            setFilterState({
              ...filterState,
              search: event.target.value,
            })
          }
        />
      </div>
    );
  };

  const renderTemplateFilter = () => {
    return (
      <div className=''>
        <MultiSelect<typeof selectedTemplatelist[number]>
          options={filteredTemplateList}
          selectedOptions={selectedTemplatelist || []}
          onApplyFilter={handleTemplateFilter}
          onClearFilter={() => {}}
          searchInputPlaceholder='Search templates'
          renderOption={(option, isSelected) => {
            return (
              <div className='flex items-center gap-2'>
                <Checkbox
                  checked={isSelected}
                  className={cn({
                    'bg-primary text-white ring-0 border-0': isSelected,
                  })}
                />
                <TooltipProviderCustomised
                  content={option.label}
                  asChild
                  minContentLength={25}
                  side='right'
                >
                  <span className='text-sm truncate w-[170px]'>
                    {option.label}
                  </span>
                </TooltipProviderCustomised>
              </div>
            );
          }}
          getOptionValue={(option) => option.value}
          renderTrigger={() => {
            return (
              <Button
                variant={'outline'}
                className='border-dashed font-medium text-xs text-textPrimary border-border gap-2 px-2 h-8'
              >
                <PlusCircle className='w-4 h-4' />
                <span>Template</span>
              </Button>
            );
          }}
          classNames={{
            popoverContent: 'w-[240px]',
          }}
        />
      </div>
    );
  };

  const renderSourceFilter = () => {
    return (
      <div className=''>
        <MultiSelect<typeof AnalyticsLogsSourceData[number]>
          options={AnalyticsLogsSourceData}
          selectedOptions={filterState.sources}
          onApplyFilter={handleSourceFilter}
          shouldHideSearchInput={true}
          onClearFilter={() => {}}
          renderOption={(option, isSelected) => {
            return (
              <div className='flex items-center gap-2'>
                <Checkbox
                  checked={isSelected}
                  className={cn({
                    'bg-primary text-white ring-0 border-0': isSelected,
                  })}
                />
                {option.label}
              </div>
            );
          }}
          getOptionValue={(option) => option.value}
          renderTrigger={() => {
            return (
              <Button
                variant={'outline'}
                className='border-dashed text-xs font-medium text-textPrimary border-border gap-2 px-2 h-8'
              >
                <PlusCircle className='w-4 h-4' />
                <span>Source</span>
              </Button>
            );
          }}
          classNames={{
            popoverContent: 'w-[200px]',
          }}
        />
      </div>
    );
  };

  const renderStatusFilter = () => {
    return (
      <div className=''>
        <MultiSelect
          options={MessageTemplateStatusOptions}
          selectedOptions={
            filterState.status as typeof MessageTemplateStatusOptions
          }
          onApplyFilter={handleStatusFilter}
          onClearFilter={() => {}}
          shouldHideSearchInput={true}
          renderOption={(option, isSelected) => {
            return (
              <div className='flex items-center gap-2'>
                <Checkbox
                  checked={isSelected}
                  className={cn({
                    'bg-primary text-white ring-0 border-0': isSelected,
                  })}
                />
                <TemplateDeliveryStatusView
                  label={option.label}
                  color={option.color}
                />
              </div>
            );
          }}
          getOptionValue={(option) => option.value}
          renderTrigger={() => {
            return (
              <Button
                variant={'outline'}
                className='border-dashed font-medium text-xs text-textPrimary border-border gap-2 px-2 h-8'
              >
                <PlusCircle className='w-4 h-4' />
                <span>Status</span>
              </Button>
            );
          }}
          classNames={{
            commandGroup: 'h-fit max-h-fit',
            popoverContent: 'w-[200px]',
          }}
        />
      </div>
    );
  };

  const renderClearFilterButton = () => {
    const filterLength = Object.values(filterState).filter(
      (v) => Array.isArray(v) && v.length
    ).length;
    const isSearchApplied = filterState.search;
    const hasOnlySearchFilter = filterLength === 1 && isSearchApplied;

    if (hasOnlySearchFilter || filterLength === 0) return null;
    return (
      <Button
        className='bg-background-hover h-8 gap-1 text-xs'
        variant={'ghost'}
        onClick={() => {
          setFilterState({
            search: filterState.search,
            status: [],
            templates: [],
            sources: [],
          });
        }}
      >
        <X className='w-4 h-4' />
        Clear Filters
      </Button>
    );
  };

  return (
    <div className='flex flex-col w-full gap-3'>
      <div className='flex w-full items-center justify-between gap-2'>
        <div className='flex flex-grow items-center gap-3'>
          {renderSearchInput()}
          {renderTemplateFilter()}
          {renderStatusFilter()}
          {renderSourceFilter()}
          {renderClearFilterButton()}
        </div>
        <div>
          <Button variant={'outline'}>Export</Button>
        </div>
      </div>
      <div className='flex w-full'>
        <div className='flex items-center gap-2'>
          <MultiSelectOptionBadgeView
            label='Templates'
            options={selectedTemplatelist}
            getOptionLabel={(o) => o?.label}
            onRemoveOption={handleRemoveTemplateFilter}
          />
          <MultiSelectOptionBadgeView
            label='Status'
            options={(filterState.status as typeof templateStatusOptions) || []}
            getOptionLabel={(o) => o?.label}
            onRemoveOption={handleRemoveStatusFilter}
          />
          <MultiSelectOptionBadgeView
            label='Source'
            options={filterState.sources}
            getOptionLabel={(o) => o?.label}
            onRemoveOption={handleRemoveSourceFilter}
          />
        </div>
      </div>
    </div>
  );
};
