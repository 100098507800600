import { useEffect, useState } from 'react';
import AutomationHistoryHeader from './components/AutomationHistoryHeader';
import { AutomationHistoryTableContainer } from './components/automationHistoryTable';
import { MetricesCards } from './components/MetricesCards';
import { MetricesGraph } from './components/MetricesGraph';
import { DateRange } from 'react-day-picker';
import { endOfDay, subDays } from 'date-fns';
import dayjs from 'dayjs';
import useAutomationHistories from './hooks/useAutomationHistories';
import MetricesCardLoader from './components/MetricesCardLoader';
import { getDayJSFormat } from 'utilities/utils';
import { IHistoryStatus } from 'pages/automationWorkflow/interface';
import { calculateDurationInDays } from 'pages/automationWorkflow/utility/function';
import { useParams } from '@reach/router';
import useAutomationDashboard from '../automationDashboardV2/hooks/useAutomationDashboard';
import { useSelector } from 'react-redux';

const AutomationHistory = () => {
  const { flowId } = useParams();
  const { selectedUserActionFlowData } = useSelector(
    (state: any) => state.workflowAutomation
  );
  const { fetchSelectedUserActionFlowLogHistoryStatus } =
    useAutomationHistories();
  const [dateRange, setDateRange] = useState<DateRange>({
    from: endOfDay(subDays(new Date(), 7)),
    to: new Date(),
  });
  const [statusLoading, setStatusLoaing] = useState(true);
  const [cacheOff, setCacheOff] = useState<boolean>(false);
  const [statusData, setStatusData] = useState<IHistoryStatus>({});
  const [lastUsedSelectedFlow, setLastUsedSelectedFlow] = useState<number>(0);
  const [lastUpdatedSelectedFlow, setLastUpdatedSelectedFlow] =
    useState<number>(0);
  const { handleSelectedEcommerce } = useAutomationDashboard();
  useEffect(() => {
    setStatusLoaing(true);
    fetchSelectedUserActionFlowLogHistoryStatus(dateRange, cacheOff, flowId)
      .then((res) => {
        setStatusData(res?.dataSource);
        setStatusLoaing(false);
      })
      .catch((err) => {
        setStatusLoaing(false);
        console.log(err);
      });
    //eslint-disable-next-line
  }, [cacheOff, dateRange]);

  useEffect(() => {
    handleSelectedEcommerce(selectedUserActionFlowData);
    //eslint-disable-next-line
  }, []);

  const dateFormat = {
    future: 'in %s',
    past: '%s ago',
    s: 'Just now',
    m: 'Just now',
    mm: '%d min ago',
    h: '1 hour ago',
    hh: '%d hour ago',
    d: '1 day ago',
    dd: '%d day ago',
    M: '1 month ago',
    MM: '%d month ago',
    y: '1 year ago',
    yy: '%d year ago',
  };

  const getPreviousDateRange = (range: DateRange) => {
    const from = dayjs(range.from);
    const to = dayjs(range.to);

    // Calculate the duration of the current range in days
    const duration = calculateDurationInDays(range); // Inclusive of both dates

    // Subtract the duration from the current range to shift it backward
    const previousFrom = from.subtract(duration, 'day');
    const previousTo = to.subtract(duration, 'day');

    // Format the previous date range
    return `${previousFrom.format('MMM DD')} - ${previousTo.format(
      'MMM DD, YYYY'
    )}`;
  };
  return (
    <div className='relative w-full h-[100vh] bg-white border-l'>
      <div className='sticky top-0 z-10 bg-white'>
        <AutomationHistoryHeader
          dateRange={dateRange}
          setDateRange={setDateRange}
          lastUsedSelectedFlow={lastUsedSelectedFlow}
          lastUpdatedSelectedFlow={lastUpdatedSelectedFlow}
        />
      </div>

      <div className='w-full mb-2'>
        <div className='px-5 mt-2'>
          <p className='text-base text-textPrimary font-semibold'>
            Results from the automations
          </p>
          <p className='text-textSecondary text-sm font-normal'>
            {dayjs(dateRange.from).format('MMM DD')} -{' '}
            {dayjs(dateRange.to).format('MMM DD, YYYY')} compared to{' '}
            {getPreviousDateRange(dateRange)}
          </p>
        </div>
        <div className='px-5 mt-3'>
          {statusLoading ? (
            <div className='h-3 w-32 animate-pulse bg-gray-300'></div>
          ) : (
            <p className='text-textSecondary text-sm'>
              Data updated{' '}
              {getDayJSFormat((statusData?.updated_at || 0) * 1000, dateFormat)}
              <span
                onClick={() => setCacheOff(true)}
                className='pl-2 text-link cursor-pointer'
              >
                Refresh now
              </span>
            </p>
          )}
        </div>
        <div className='px-5 mt-2'>
          {statusLoading ? (
            <MetricesCardLoader />
          ) : (
            <MetricesCards dateRange={dateRange} data={statusData} />
          )}
        </div>
        <div className='px-5 mt-3'>
          <MetricesGraph
            loading={statusLoading}
            timeLines={statusData?.automation_status_timelines}
          />
        </div>
        <div className='px-5 mt-3 bg-white'>
          <AutomationHistoryTableContainer
            dateRange={dateRange}
            setLastUsedSelectedFlow={setLastUsedSelectedFlow}
            setLastUpdatedSelectedFlow={setLastUpdatedSelectedFlow}
          />
        </div>
      </div>
    </div>
  );
};

export default AutomationHistory;
