import { ColumnDef } from '@tanstack/react-table';
import * as datefns from 'date-fns';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import { Checkbox } from 'libraryV2/ui/checkbox';
import { cn } from 'libraryV2/utils';
import { Switch } from 'libraryV2/ui/switch';
import { IManageGPTAgent } from 'pages/raven/interface';
import { Avatar, AvatarFallback, AvatarImage } from 'libraryV2/ui/avatar';
import { getUserNameInitials } from 'pages/reportingV2/utility/utils';
import { AgentTableRowAction } from './AgentTableRowAction';
import { FC, useState } from 'react';
import { startCase } from 'lodash';
import { useDispatch } from 'react-redux';
import { toast } from 'libraryV2/ui/use-toast';

const defaultFallbackValue = '--';

type User = IManageGPTAgent['created_by'];

type CustomAgentStatusProps = {
  agentInfo: IManageGPTAgent;
};

export const CustomAgentStatus: FC<CustomAgentStatusProps> = ({
  agentInfo,
}) => {
  const dispatch = useDispatch();
  const isDraft = agentInfo?.status === 'draft';

  const [agentStatus, setAgentStatus] = useState(agentInfo?.status);
  const [isUpdating, setIsUpdating] = useState(false);

  const statusText = startCase(agentStatus);

  const handleStatusChange = async () => {
    if (isUpdating) return;

    const payloadValue = agentStatus === 'active' ? 'inactive' : 'active';
    setIsUpdating(true);

    try {
      const res = await dispatch?.raven?.updateSingleCustomAgent({
        id: agentInfo?.id,
        status: payloadValue,
      });

      if (res?.status) {
        setAgentStatus(res.status);
      }

      toast({
        toastType: 'success',
        duration: 2 * 1000,
        title: 'Success',
        description:
          payloadValue === 'active'
            ? `${agentInfo?.title} has been activated and is ready to use`
            : `${agentInfo?.title} has been deactivated and is now paused`,
      });
    } catch (er) {
      console.error('Error updating status:', er);
      setAgentStatus(agentInfo?.status);

      toast({
        toastType: 'destructive',
        title: 'Failed',

        description: 'Failed to change agent status.',
      });
    } finally {
      setIsUpdating(false);
    }
  };

  if (isDraft) {
    return (
      <p className='text-textPrimary text-xs font-medium px-2 py-1 bg-background-hover w-fit text-center rounded'>
        {statusText}
      </p>
    );
  }

  return (
    <div className='flex items-center gap-2'>
      <Switch
        checked={agentStatus === 'active'}
        size='md'
        onCheckedChange={handleStatusChange}
        disabled={isUpdating}
        id={`agent-active-${agentInfo?.id}`}
      />
      <p>{statusText}</p>
    </div>
  );
};

export const AgentTableColumns: ColumnDef<IManageGPTAgent, unknown>[] = [
  {
    id: 'select',
    header: ({ table }) => {
      const isRowSelected =
        table.getIsAllPageRowsSelected() ||
        (table.getIsSomePageRowsSelected() && 'indeterminate');
      return (
        <Checkbox
          checked={isRowSelected}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label='Select all'
          className={cn({
            'bg-primary translate-y-[2px] text-white ring-0 border-0':
              isRowSelected,
          })}
          IsSomePageRowsSelected={!!isRowSelected}
        />
      );
    },
    cell: ({ row }) => {
      return (
        <Checkbox
          checked={row.getIsSelected()}
          aria-label='Select row'
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          className={cn({
            'bg-primary text-white ring-0 border-0': row.getIsSelected(),
          })}
        />
      );
    },
    enableSorting: false,
    enableHiding: false,
  },
  {
    id: 'custom-agent-title',
    accessorKey: 'title',
    header: 'Custom Agents Name',
    cell: (info) => (
      <span className='block text-sm truncate text-textPrimary max-w-[240px]'>
        {info.getValue() + ''}
      </span>
    ),
  },
  {
    id: 'created_at',
    accessorKey: 'created_at',
    header: 'Created At',
    cell: (info) =>
      datefns.format(new Date(info.getValue() as string), 'MMM dd yyyy') ??
      defaultFallbackValue,
  },
  {
    id: 'created_by',
    accessorKey: 'created_by',
    header: 'Created By',
    cell: (info) => {
      const userInfo = info.getValue() as User;
      return (
        <div className='flex gap-1'>
          <Avatar className='h-5 w-5'>
            <AvatarImage src={userInfo?.avatar} />
            <AvatarFallback className='border-zinc-300 border opacity-100 text-xs p-[2px] font-medium bg-zinc-100'>
              {getUserNameInitials(userInfo?.name ?? 'User')}
            </AvatarFallback>
          </Avatar>
          <span className='text-sm font-normal text-textPrimary'>
            {userInfo?.name}
          </span>
        </div>
      );
    },
  },
  {
    id: 'updated_by',
    accessorKey: 'updated_by',
    header: 'Last Updated By',
    cell: (info) => {
      const userInfo = info.getValue() as User;
      return (
        <div className='flex gap-1'>
          <Avatar className='h-5 w-5'>
            <AvatarImage src={userInfo?.avatar} />
            <AvatarFallback className='border-zinc-300 border opacity-100 text-xs p-[2px] font-medium bg-zinc-100'>
              {getUserNameInitials(userInfo?.name ?? 'User')}
            </AvatarFallback>
          </Avatar>
          <span className='text-sm font-normal text-textPrimary'>
            {userInfo?.name}
          </span>
        </div>
      );
    },
  },
  {
    id: 'description',
    accessorKey: 'description',
    header: 'Description',
    cell: (info) => {
      const truncateLength = 60;
      const description = info.getValue() + '';
      const truncatedDescription =
        description?.length > truncateLength
          ? description.slice(0, truncateLength) + '...'
          : description;
      return (
        <TooltipProviderCustomised
          minContentLength={truncateLength}
          content={info.getValue() as string}
        >
          {truncatedDescription}
        </TooltipProviderCustomised>
      );
    },
  },
  {
    id: 'status',
    header: 'Status',
    cell: ({ row }) => <CustomAgentStatus agentInfo={row.original} />,
  },
  {
    id: 'options',
    header: '',
    cell: ({ row }) => <AgentTableRowAction agentInfo={row.original} />,
  },
];
