import moment from 'moment';
import {
  ICustomerOrderProperty,
  SendMessageRequestBodyInterface,
} from 'pages/inbox/inboxInterface';
import { connect } from 'react-redux';
import OrderStatus from './OrderStatus';
import PaymentStatus from './PaymentStatus';
import React from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  order: ICustomerOrderProperty;
  sendMessegengerChannelMessage: (
    requestBody: SendMessageRequestBodyInterface
  ) => boolean;
  setDisplayScreen: (payload: string) => void;
  setSelectedOrder: (payload: string | null) => void;
  isDirectMessageLoading: boolean;
  key: string;
  currencySymbol: string;
}

const OrderCard: React.FC<Props> = ({
  order,
  sendMessegengerChannelMessage,
  setDisplayScreen,
  setSelectedOrder,
  isDirectMessageLoading,
  key,
  currencySymbol,
}) => {
  const { t } = useTranslation();
  const [timeLeft, setTimeLeft] = React.useState<any>(null);
  const [currentOrder, setCurrentOrder] = React.useState<any>(null);

  const handleSendConfirmationLink = async (trackerLink: string) => {
    setCurrentOrder(order.id);

    let payload = {
      action: 'direct_message',
      template: null,
      audio: null,
      image: null,
      text: trackerLink,
    };
    const res = await sendMessegengerChannelMessage(payload);
    if (res) {
      setTimeLeft(3);
      setCurrentOrder(null);
    } else {
      setCurrentOrder(null);
    }
  };

  const getDateYearFormat = (timeStamp: string) => {
    if (moment(parseInt(timeStamp)).format('Do MMMM YYYY') !== 'Invalid date') {
      return moment.unix(parseInt(timeStamp)).format('Do MMMM YYYY');
    } else {
      return '- -';
    }
  };

  //NB: will be need for future implementation
  // const renderCompletebutton = () => {
  //   return (
  //     <>
  //       {!timeLeft && order?.status === 'OPEN' && (
  //         <button
  //           className='px-2 py-1 rounded-[15px] border border-gray-300
  //         capitalize bg-white hover:bg-gray-50'
  //         >
  //           Complete Order
  //         </button>
  //       )}
  //     </>
  //   );
  // };

  const renderSendTrackerButton = () => {
    return (
      <>
        {!timeLeft &&
          order?.status !== 'OPEN' &&
          order?.payment_status === 'PENDING' && (
            <button
              data-testid='send-permalink'
              className='flex items-center justify-centerm px-2 py-1 rounded-[15px]
              border border-gray-300 capitalize bg-white hover:bg-gray-50 gap-1'
              onClick={() => handleSendConfirmationLink(order?.permalink)}
              disabled={isDirectMessageLoading}
            >
              {t('send tracker link')}
              {isDirectMessageLoading && currentOrder === order.id && (
                <span
                  className='animate-spin w-4 h-4 border border-t-transparent
                      border-b-green-500 border-l-green-500 border-r-green-500 rounded-full'
                ></span>
              )}
            </button>
          )}
      </>
    );
  };

  const renderTrackerLinkSendButton = () => {
    return (
      <>
        {timeLeft && (
          <button
            className='flex items-center justify-centerm px-2 py-1 rounded-[15px]
               border border-gray-300 capitalize bg-green-100 text-green-800 gap-1'
          >
            Tracker link sent
            <CheckIcon
              data-testid='tracker-link-sent-icon'
              className='w-5 h-5 text-green-800'
            />
          </button>
        )}
      </>
    );
  };

  React.useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  return (
    <div className='relative hover:bg-gray-100 px-3 pt-2' key={key}>
      <div
        className={`${
          order.payment_status === 'PENDING' || order.status === 'OPEN'
            ? 'pb-[40px]'
            : 'pb-1'
        }  cursor-pointer`}
        onClick={() => {
          setSelectedOrder(order?.id);
          setDisplayScreen('order_details');
        }}
      >
        <div className='flex justify-start items-center text-[#0078CF] text-sm font-semibold mb-[4px] gap-2'>
          {order?.shopify_order_name || order?.ecommerce_order_id}
          {order?.status === 'OPEN' && (
            <div className='bg-blue-600 text-lg w-[5px] h-[5px] rounded-full'></div>
          )}
        </div>
        <div className='flex gap-2 mb-[4px]'>
          <div className='text-gray-600 font-normal text-sm'>
            {getDateYearFormat(order?.created_at)}
          </div>
          <div className='text-gray-600 font-normal text-2xl mt-[-14px]'>.</div>
          <div className='text-gray-600 font-normal text-sm'>
            {currencySymbol ?? ''}
            {order?.total_cost}
          </div>
        </div>
        <div className={`mb-[10px] flex gap-2`}>
          {order?.payment_status && (
            <PaymentStatus status={order?.payment_status} />
          )}
          <OrderStatus status={order?.status} />
        </div>
      </div>
      <div className='flex absolute bottom-[10px] ltr:left-0 rtl:right-0 px-3'>
        {renderSendTrackerButton()}
        {renderTrackerLinkSendButton()}
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  isDirectMessageLoading:
    state.loading.effects.inbox.sendMessegengerChannelMessage,
  currencySymbol: state.dashboard?.selectedProject?.currency_symbol,
});

const mapDispatch = (dispatch: any) => ({
  sendMessegengerChannelMessage: (
    requestBody: SendMessageRequestBodyInterface
  ) => dispatch.inbox.sendMessegengerChannelMessage(requestBody),
});

export default connect(mapState, mapDispatch)(OrderCard);
