import axios from '../../../../utilities/httpClient';
import config from '../../../../utilities/config';
import { navigate } from '@reach/router';
import { toaster } from 'evergreen-ui';

const initialState = {
  publicKey: '',
  plans: [],
  histories: [],
};

export const billing = {
  state: {
    ...initialState,
  },
  reducers: {
    updatePlans(state, payload) {
      return { ...state, plans: payload };
    },
    updateHistories(state, payload) {
      return { ...state, histories: payload };
    },
    updatePublicKey(state, payload) {
      return { ...state, publicKey: payload };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchPublicKey() {
      try {
        const res = await axios.get(config.billing.stripePublicKey());
        if (res.data.success) {
          dispatch.billing.updatePublicKey(res.data.dataSource.public_key);
          return {
            status: true,
            data: res.data.dataSource.public_key,
          }
        }
      } catch (err) {
        return {
          status: false,
        }
      }
    },

    async fetchPlans() {
      try {
        const res = await axios.get(config.billing.plans());
        if (res.data.success) {
          dispatch.billing.updatePlans(res.data.dataSource);
        }
      } catch (err) {
        console.log(err.response);
      }
    },

    async fetchHistories(teamId) {
      try {
        const res = await axios.get(config.billing.history(teamId));
        if (res.data.success) {
          dispatch.billing.updateHistories(res.data.dataSource);
        }
      } catch (err) {
        console.log(err.response);
      }
    },

    async createPaymentMethod(payload) {
      /**
       * payload = {
       *    payment_method: string,
       *    project_id: number
       * }
       */

      try {
        const res = await axios.post(
          config.billing.creatStripePaymentMethod(),
          {
            payment_method: payload.payment_method
          }
        );
        if (res.data.success) {
          dispatch.settings.fetchUserInfo();
          dispatch.auth.updateInfo();
          dispatch.dashboard.fetchTeamDetails(payload.project_id);
        }
        return res.data.success;
      } catch (err) {
        console.log(err.response);
      }
    },

    async createSubscription(payload) {
      /**
       * payload = {
       *    plan_id: int,
       *    action: string
       * }
       */

      try {
        const res = await axios.post(
          config.billing.stripeSubscription(),
          payload
        );
        if (res.data.success) {
          dispatch.settings.fetchUserInfo();
          dispatch.auth.updateInfo();
          // it will call same api twice please optimize it later when refactored. just pass data from one api and update it to its reducer
        }
        return { status: res.data.success, marketPlace: 'other' };
      } catch (err) {
        console.log(err.response);
      }
    },
    async createSubscriptionForShopify(payload) {
      /**
       * payload = {
       *    plan_id: int,
       *    action: string
       * }
       */

      try {
        const res = await axios.post(
          config.billing.handleShopifySubscription(),
          payload
        );
        if (res.data.success && !!res.data.dataSource.confirmation_url) {
          window.location.href = res.data.dataSource.confirmation_url;
        }
        return {
          status: res.data.success,
          marketPlace: 'shopify',
          confirmation_url: res.data.dataSource.confirmation_url || '',
        };
      } catch (err) {
        toaster.danger('Failed', {
          description:
            err?.response?.data?.error || 'Failed to process the request',
        });
        return {
          status: false,
          marketPlace: 'shopify',
          confirmation_url: '',
        };
      }
    },

    async confirmShopifySubscription(payload, rootState) {
      /**
       * project_id: int;
       * signature: string;
       * charge_id: str
       * */
      try {
        const res = await axios.post(
          config.billing.confirmShopifySubscription(),
          payload
        );
        if (res.data.success) {
          const currentUpdatedTeam = await dispatch.dashboard.fetchTeamDetails(
            rootState.dashboard.selectedProject.id
          );
          if (currentUpdatedTeam) {
            dispatch.settings.setSelectedProject(currentUpdatedTeam);
            dispatch.dashboard.setSelectedProject(currentUpdatedTeam);
          }
        } else {
          toaster.danger(`Failed`, { description: 'Failed to Verify Payment' });
        }
        navigate('/settings/billing');
      } catch (err) {
        toaster.danger(`Failed`, { description: 'Failed to Verify Payment' });
        navigate('/settings/billing');
      }
    },

    async manageCard(payload) {
      /**
       * payload = {
       *    payment_method: string,
       *    action: string (attach, detach, default)
       * }
       */
      try {
        const res = await axios.post(
          config.billing.updateStripePaymentMethod(),
          {
            payment_method: payload.payment_method,
            action: payload.action
          }
        );
        if (res.data.success) {
          dispatch.settings.fetchUserInfo();
          dispatch.auth.updateInfo();
          dispatch.dashboard.fetchTeamDetails(payload.project_id);
        }
        return res.data.success;
      } catch (err) {
        console.log(err.response);
      }
    },

    async requestRefund(payload) {
      /**
       * payload = {
       *    bill_id: int,
       *    reason: string
       * }
       */

      try {
        const res = await axios.post(config.billing.refund(), payload);
        if (res.data.success) {
          dispatch.billing.fetchHistories();
        }
        return res.data.success;
      } catch (err) {
        console.log(err.response);
      }
    },

    async handleTrialStart() {
      try {
        const res = await axios.post(config.billing.startTrial());
        if (res.data.success) {
          dispatch.auth.updateInfo();
        }
        return res.data.success;
      } catch (err) {
        console.log(err.response);
      }
    },
    async handleCouponCode(coupon) {
      try {
        if (coupon) {
          let res = await axios.post(config.billing.billingCoupon(), {
            coupon_code: coupon,
          });
          if (res.status === 200) {
            return res.data;
          } else {
            return null;
          }
        }
      } catch (err) {
        const mockData = {
          status: 200,
          data: {
            success: false,
          },
        };
        return mockData.data;
      }
    },
  }),
};
