import React from 'react';
import { Button } from 'library';
import { connect } from 'react-redux';
import { UserGroupIcon } from '@heroicons/react/20/solid';
import GroupCard from './GroupCard';
import useTranslation from 'components/customHooks/useTranslation';
import SettingsPageHeader from 'pages/settings/components/SettingsPageHeader';

const initialData: GroupDetailsCardInterface = {
  id: null,
  name: '',
  image: '',
  members: [],
  is_smart: false,
  should_assign_to_online_agents: true,
  should_assign_to_away_agents: false,
  should_assign_to_offline_agents: false,
};

const renderLoader = () => {
  return (
    <>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
        <div
          key={item}
          className='w-full bg-gray-100 rounded-lg h-28 animate-pulse'
        />
      ))}
    </>
  );
};

interface GroupsListProps {
  selectedTeam: SelectedTeamInterface;
  teamGroups: GroupCardInterface[];
  teamGroupsLoading: boolean;
  setGroup: (value: GroupDetailsCardInterface | null) => void;
  fetchTeamGroups: (teamId: number) => void;
  fetchTeamGroupDetails: (
    teamId: number,
    groupId: number
  ) => Promise<GroupDetailsCardInterface | null>;
}

const GroupsList: React.FC<GroupsListProps> = ({
  selectedTeam,
  teamGroups,
  teamGroupsLoading,
  setGroup,
  fetchTeamGroupDetails,
  fetchTeamGroups,
}) => {
  const { t } = useTranslation();
  React.useEffect(() => {
    if (selectedTeam) {
      fetchTeamGroups(selectedTeam.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGroupCardClick = async (id: number) => {
    const data = await fetchTeamGroupDetails(selectedTeam.id, id);
    setGroup(data);
  };

  const handleCreateGroup = () => {
    setGroup(initialData);
  };

  const renderNoTeamsAvailable = (
    teamGroupsLoading: boolean,
    teamGroups: GroupCardInterface[],
    onCreateButtonClick: () => void
  ) => {
    return (
      <div className='flex flex-col items-center justify-center w-full col-span-3 bg-gray-100 rounded-md h-52'>
        <UserGroupIcon className='w-12 h-12 text-gray-400' />
        <p className='text-sm text-gray-500 uppercase'>
          {t('No Groups Created Yet')}
        </p>
        {!teamGroupsLoading && teamGroups.length === 0 && (
          <Button
            onClick={onCreateButtonClick}
            className='mt-4'
            intent='primary'
          >
            {t('Create a Group')}
          </Button>
        )}
      </div>
    );
  };

  return (
    <>
      <SettingsPageHeader
        title="Group Management"
        subtitle='Manage your team and their access from here'
      />
      <div className='flex items-center justify-end pr-5'>
        {!teamGroupsLoading && teamGroups.length > 0 && (
          <Button className='bg-primary text-white border-none' onClick={handleCreateGroup}>{t('Create a Group')}</Button>
        )}
      </div>
      <div className='grid grid-cols-1 gap-3 mt-6 sm:grid-cols-3 px-5'>
        {/* loadingState */}
        {teamGroupsLoading && renderLoader()}

        {/* no teams available */}
        {!teamGroupsLoading &&
          teamGroups.length === 0 &&
          renderNoTeamsAvailable(
            teamGroupsLoading,
            teamGroups,
            handleCreateGroup
          )}

        {/* team cards */}
        {!teamGroupsLoading &&
          teamGroups.map((group, i) => (
            <GroupCard
              key={i}
              id={group.id}
              onCardClick={handleGroupCardClick}
              name={group.name}
              image={group.image}
              owner={group.creator?.full_name || 'Not Available'}
              memberCount={group.member_count}
              isSmart={group.is_smart}
            />
          ))}
      </div>
    </>
  );
};

const mapState = (state: any) => ({
  selectedTeam: state.dashboard.selectedProject,
  teamGroups: state.settings.teamGroups,
  teamGroupsLoading: state.loading.effects.settings.fetchTeamGroups,
});

const mapDispatch = (dispatch: any) => ({
  fetchTeamGroups: (teamId: number) =>
    dispatch.settings.fetchTeamGroups(teamId),
  fetchTeamGroupDetails: (teamId: number, groupId: number) =>
    dispatch.settings.fetchTeamGroupDetails({ teamId, groupId }),
});

const GroupsListContainer = connect(mapState, mapDispatch)(GroupsList);

export default GroupsListContainer;
