import { cn } from 'libraryV2/utils';
import { FC } from 'react';

interface RavenNavIconProps {
  size?: string;
  className?: string;
}
export const RavenNavIconDefault: FC<RavenNavIconProps> = ({
  size = '36',
  className,
}) => {
  return (
    <svg
      width={size}
      className={cn(className)}
      height={size}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_100_32)'>
        <path
          d='M0 8C0 3.58172 3.58172 0 8 0H28C32.4183 0 36 3.58172 36 8V28C36 32.4183 32.4183 36 28 36H8C3.58172 36 0 32.4183 0 28V8Z'
          fill='white'
        />
        <g opacity='0.12'>
          <g filter='url(#filter0_f_100_32)'>
            <ellipse
              cx='14.804'
              cy='5.38743'
              rx='20.6616'
              ry='14.4073'
              transform='rotate(-50.8942 14.804 5.38743)'
              fill='#416BFE'
            />
          </g>
          <g filter='url(#filter1_f_100_32)'>
            <ellipse
              cx='-2.49093'
              cy='20.8288'
              rx='20.6616'
              ry='14.4073'
              transform='rotate(-50.8942 -2.49093 20.8288)'
              fill='#FFC9A8'
            />
          </g>
          <g filter='url(#filter2_f_100_32)'>
            <ellipse
              cx='30.3997'
              cy='14.9792'
              rx='20.6616'
              ry='14.4073'
              transform='rotate(-50.8942 30.3997 14.9792)'
              fill='#6613E1'
            />
          </g>
          <g filter='url(#filter3_f_100_32)'>
            <ellipse
              cx='11.4407'
              cy='35.4704'
              rx='20.6616'
              ry='14.4073'
              transform='rotate(-50.8942 11.4407 35.4704)'
              fill='#E9176B'
            />
          </g>
          <g
            style={{ mixBlendMode: 'overlay' }}
            opacity='0.59'
            filter='url(#filter4_f_100_32)'
          >
            <ellipse
              cx='18'
              cy='-0.725342'
              rx='47'
              ry='8.02368'
              fill='#D9D9D9'
            />
          </g>
          <g
            style={{ mixBlendMode: 'overlay' }}
            opacity='0.59'
            filter='url(#filter5_f_100_32)'
          >
            <ellipse cx='18' cy='39.9758' rx='47' ry='8.02368' fill='#D9D9D9' />
          </g>
        </g>
        <g clip-path='url(#clip1_100_32)'>
          <path
            d='M16.4536 20.6252C16.3866 20.3656 16.2513 20.1288 16.0618 19.9392C15.8722 19.7497 15.6354 19.6144 15.3758 19.5474L10.7746 18.3609C10.6961 18.3387 10.627 18.2914 10.5778 18.2263C10.5286 18.1612 10.502 18.0818 10.502 18.0002C10.502 17.9186 10.5286 17.8392 10.5778 17.7741C10.627 17.709 10.6961 17.6617 10.7746 17.6394L15.3758 16.4522C15.6353 16.3853 15.8721 16.2501 16.0616 16.0607C16.2511 15.8713 16.3865 15.6346 16.4536 15.3752L17.6401 10.7739C17.6621 10.6951 17.7093 10.6257 17.7745 10.5762C17.8398 10.5268 17.9193 10.5 18.0012 10.5C18.083 10.5 18.1626 10.5268 18.2278 10.5762C18.293 10.6257 18.3403 10.6951 18.3623 10.7739L19.5481 15.3752C19.615 15.6347 19.7503 15.8716 19.9398 16.0611C20.1294 16.2507 20.3663 16.386 20.6258 16.4529L25.2271 17.6387C25.3062 17.6605 25.376 17.7077 25.4257 17.773C25.4754 17.8383 25.5023 17.9181 25.5023 18.0002C25.5023 18.0823 25.4754 18.1621 25.4257 18.2274C25.376 18.2927 25.3062 18.3399 25.2271 18.3617L20.6258 19.5474C20.3663 19.6144 20.1294 19.7497 19.9398 19.9392C19.7503 20.1288 19.615 20.3656 19.5481 20.6252L18.3616 25.2264C18.3395 25.3052 18.2923 25.3747 18.2271 25.4241C18.1619 25.4736 18.0823 25.5004 18.0004 25.5004C17.9186 25.5004 17.839 25.4736 17.7738 25.4241C17.7086 25.3747 17.6614 25.3052 17.6393 25.2264L16.4536 20.6252Z'
            stroke='#8B5CF6'
            stroke-width='1.2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M24 11.25V14.25'
            stroke='#8B5CF6'
            stroke-width='0.8'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M25.5 12.75H22.5'
            stroke='#8B5CF6'
            stroke-width='0.8'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M12 21.75V23.25'
            stroke='#8B5CF6'
            stroke-width='0.8'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M12.75 22.5H11.25'
            stroke='#8B5CF6'
            stroke-width='0.8'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </g>
      </g>
      <path
        d='M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H28C32.1421 0.5 35.5 3.85786 35.5 8V28C35.5 32.1421 32.1421 35.5 28 35.5H8C3.85786 35.5 0.5 32.1421 0.5 28V8Z'
        stroke='url(#paint0_angular_100_32)'
      />
      <defs>
        <filter
          id='filter0_f_100_32'
          x='-19.7617'
          y='-30.4394'
          width='69.1308'
          height='71.6542'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8.69725'
            result='effect1_foregroundBlur_100_32'
          />
        </filter>
        <filter
          id='filter1_f_100_32'
          x='-37.0566'
          y='-14.998'
          width='69.1308'
          height='71.6542'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8.69725'
            result='effect1_foregroundBlur_100_32'
          />
        </filter>
        <filter
          id='filter2_f_100_32'
          x='-4.16598'
          y='-20.8476'
          width='69.1308'
          height='71.6542'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8.69725'
            result='effect1_foregroundBlur_100_32'
          />
        </filter>
        <filter
          id='filter3_f_100_32'
          x='-23.125'
          y='-0.356411'
          width='69.1308'
          height='71.6542'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8.69725'
            result='effect1_foregroundBlur_100_32'
          />
        </filter>
        <filter
          id='filter4_f_100_32'
          x='-34.5'
          y='-14.249'
          width='105'
          height='27.0479'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='2.75'
            result='effect1_foregroundBlur_100_32'
          />
        </filter>
        <filter
          id='filter5_f_100_32'
          x='-34.5'
          y='26.4521'
          width='105'
          height='27.0479'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='2.75'
            result='effect1_foregroundBlur_100_32'
          />
        </filter>
        <radialGradient
          id='paint0_angular_100_32'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(18 18) rotate(90) scale(18)'
        >
          <stop offset='0.00301491' stop-color='#F0319D' />
          <stop offset='0.116739' stop-color='#F15AA4' />
          <stop offset='0.247775' stop-color='#F3A3A3' />
          <stop offset='0.387889' stop-color='#D6D0E7' />
          <stop offset='0.502267' stop-color='#8D90F5' />
          <stop offset='0.629703' stop-color='#AB60F1' />
          <stop offset='0.737951' stop-color='#7D28D9' />
          <stop offset='0.874033' stop-color='#DC9EDA' />
        </radialGradient>
        <clipPath id='clip0_100_32'>
          <path
            d='M0 8C0 3.58172 3.58172 0 8 0H28C32.4183 0 36 3.58172 36 8V28C36 32.4183 32.4183 36 28 36H8C3.58172 36 0 32.4183 0 28V8Z'
            fill='white'
          />
        </clipPath>
        <clipPath id='clip1_100_32'>
          <rect
            width='18'
            height='18'
            fill='white'
            transform='translate(9 9)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const RavenNavIconHover: FC<RavenNavIconProps> = ({
  size = '36',
  className,
}) => {
  return (
    <svg
      width={size}
      height={size}
      className={cn(className)}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_100_43)'>
        <path
          d='M0 8C0 3.58172 3.58172 0 8 0H28C32.4183 0 36 3.58172 36 8V28C36 32.4183 32.4183 36 28 36H8C3.58172 36 0 32.4183 0 28V8Z'
          fill='white'
        />
        <g opacity='0.33'>
          <g filter='url(#filter0_f_100_43)'>
            <ellipse
              cx='14.804'
              cy='5.38743'
              rx='20.6616'
              ry='14.4073'
              transform='rotate(-50.8942 14.804 5.38743)'
              fill='#416BFE'
            />
          </g>
          <g filter='url(#filter1_f_100_43)'>
            <ellipse
              cx='-2.49093'
              cy='20.8288'
              rx='20.6616'
              ry='14.4073'
              transform='rotate(-50.8942 -2.49093 20.8288)'
              fill='#FFC9A8'
            />
          </g>
          <g filter='url(#filter2_f_100_43)'>
            <ellipse
              cx='30.3997'
              cy='14.9792'
              rx='20.6616'
              ry='14.4073'
              transform='rotate(-50.8942 30.3997 14.9792)'
              fill='#6613E1'
            />
          </g>
          <g filter='url(#filter3_f_100_43)'>
            <ellipse
              cx='11.4407'
              cy='35.4704'
              rx='20.6616'
              ry='14.4073'
              transform='rotate(-50.8942 11.4407 35.4704)'
              fill='#E9176B'
            />
          </g>
          <g
            style={{ mixBlendMode: 'overlay' }}
            opacity='0.59'
            filter='url(#filter4_f_100_43)'
          >
            <ellipse
              cx='18'
              cy='-0.725342'
              rx='47'
              ry='8.02368'
              fill='#D9D9D9'
            />
          </g>
          <g
            style={{ mixBlendMode: 'overlay' }}
            opacity='0.59'
            filter='url(#filter5_f_100_43)'
          >
            <ellipse cx='18' cy='39.9758' rx='47' ry='8.02368' fill='#D9D9D9' />
          </g>
        </g>
        <g clip-path='url(#clip1_100_43)'>
          <path
            d='M16.4536 20.6252C16.3866 20.3656 16.2513 20.1288 16.0618 19.9392C15.8722 19.7497 15.6354 19.6144 15.3758 19.5474L10.7746 18.3609C10.6961 18.3387 10.627 18.2914 10.5778 18.2263C10.5286 18.1612 10.502 18.0818 10.502 18.0002C10.502 17.9186 10.5286 17.8392 10.5778 17.7741C10.627 17.709 10.6961 17.6617 10.7746 17.6394L15.3758 16.4522C15.6353 16.3853 15.8721 16.2501 16.0616 16.0607C16.2511 15.8713 16.3865 15.6346 16.4536 15.3752L17.6401 10.7739C17.6621 10.6951 17.7093 10.6257 17.7745 10.5762C17.8398 10.5268 17.9193 10.5 18.0012 10.5C18.083 10.5 18.1626 10.5268 18.2278 10.5762C18.293 10.6257 18.3403 10.6951 18.3623 10.7739L19.5481 15.3752C19.615 15.6347 19.7503 15.8716 19.9398 16.0611C20.1294 16.2507 20.3663 16.386 20.6258 16.4529L25.2271 17.6387C25.3062 17.6605 25.376 17.7077 25.4257 17.773C25.4754 17.8383 25.5023 17.9181 25.5023 18.0002C25.5023 18.0823 25.4754 18.1621 25.4257 18.2274C25.376 18.2927 25.3062 18.3399 25.2271 18.3617L20.6258 19.5474C20.3663 19.6144 20.1294 19.7497 19.9398 19.9392C19.7503 20.1288 19.615 20.3656 19.5481 20.6252L18.3616 25.2264C18.3395 25.3052 18.2923 25.3747 18.2271 25.4241C18.1619 25.4736 18.0823 25.5004 18.0004 25.5004C17.9186 25.5004 17.839 25.4736 17.7738 25.4241C17.7086 25.3747 17.6614 25.3052 17.6393 25.2264L16.4536 20.6252Z'
            stroke='#8B5CF6'
            stroke-width='1.2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M24 11.25V14.25'
            stroke='#8B5CF6'
            stroke-width='0.8'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M25.5 12.75H22.5'
            stroke='#8B5CF6'
            stroke-width='0.8'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M12 21.75V23.25'
            stroke='#8B5CF6'
            stroke-width='0.8'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M12.75 22.5H11.25'
            stroke='#8B5CF6'
            stroke-width='0.8'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </g>
      </g>
      <path
        d='M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H28C32.1421 0.5 35.5 3.85786 35.5 8V28C35.5 32.1421 32.1421 35.5 28 35.5H8C3.85786 35.5 0.5 32.1421 0.5 28V8Z'
        stroke='url(#paint0_angular_100_43)'
      />
      <defs>
        <filter
          id='filter0_f_100_43'
          x='-19.7617'
          y='-30.4394'
          width='69.1308'
          height='71.6542'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8.69725'
            result='effect1_foregroundBlur_100_43'
          />
        </filter>
        <filter
          id='filter1_f_100_43'
          x='-37.0566'
          y='-14.998'
          width='69.1308'
          height='71.6542'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8.69725'
            result='effect1_foregroundBlur_100_43'
          />
        </filter>
        <filter
          id='filter2_f_100_43'
          x='-4.16598'
          y='-20.8476'
          width='69.1308'
          height='71.6542'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8.69725'
            result='effect1_foregroundBlur_100_43'
          />
        </filter>
        <filter
          id='filter3_f_100_43'
          x='-23.125'
          y='-0.356411'
          width='69.1308'
          height='71.6542'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8.69725'
            result='effect1_foregroundBlur_100_43'
          />
        </filter>
        <filter
          id='filter4_f_100_43'
          x='-34.5'
          y='-14.249'
          width='105'
          height='27.0479'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='2.75'
            result='effect1_foregroundBlur_100_43'
          />
        </filter>
        <filter
          id='filter5_f_100_43'
          x='-34.5'
          y='26.4521'
          width='105'
          height='27.0479'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='2.75'
            result='effect1_foregroundBlur_100_43'
          />
        </filter>
        <radialGradient
          id='paint0_angular_100_43'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(18 18) rotate(90) scale(18)'
        >
          <stop offset='0.00301491' stop-color='#F0319D' />
          <stop offset='0.116739' stop-color='#F15AA4' />
          <stop offset='0.247775' stop-color='#F3A3A3' />
          <stop offset='0.387889' stop-color='#D6D0E7' />
          <stop offset='0.502267' stop-color='#8D90F5' />
          <stop offset='0.629703' stop-color='#AB60F1' />
          <stop offset='0.737951' stop-color='#7D28D9' />
          <stop offset='0.874033' stop-color='#DC9EDA' />
        </radialGradient>
        <clipPath id='clip0_100_43'>
          <path
            d='M0 8C0 3.58172 3.58172 0 8 0H28C32.4183 0 36 3.58172 36 8V28C36 32.4183 32.4183 36 28 36H8C3.58172 36 0 32.4183 0 28V8Z'
            fill='white'
          />
        </clipPath>
        <clipPath id='clip1_100_43'>
          <rect
            width='18'
            height='18'
            fill='white'
            transform='translate(9 9)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const RavenNavIconActive: FC<RavenNavIconProps> = ({
  size = '36',
  className,
}) => {
  return (
    <svg
      width={size}
      className={cn(className)}
      height={size}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_100_54)'>
        <path
          d='M0 8C0 3.58172 3.58172 0 8 0H28C32.4183 0 36 3.58172 36 8V28C36 32.4183 32.4183 36 28 36H8C3.58172 36 0 32.4183 0 28V8Z'
          fill='white'
        />
        <g filter='url(#filter0_f_100_54)'>
          <ellipse
            cx='14.5716'
            cy='4.88743'
            rx='20.6616'
            ry='14.4073'
            transform='rotate(-50.8942 14.5716 4.88743)'
            fill='#416BFE'
          />
        </g>
        <g filter='url(#filter1_f_100_54)'>
          <ellipse
            cx='-2.72335'
            cy='20.3288'
            rx='20.6616'
            ry='14.4073'
            transform='rotate(-50.8942 -2.72335 20.3288)'
            fill='#FFC9A8'
          />
        </g>
        <g filter='url(#filter2_f_100_54)'>
          <ellipse
            cx='30.1673'
            cy='14.4792'
            rx='20.6616'
            ry='14.4073'
            transform='rotate(-50.8942 30.1673 14.4792)'
            fill='#6613E1'
          />
        </g>
        <g filter='url(#filter3_f_100_54)'>
          <ellipse
            cx='11.2083'
            cy='34.9704'
            rx='20.6616'
            ry='14.4073'
            transform='rotate(-50.8942 11.2083 34.9704)'
            fill='#E9176B'
          />
        </g>
        <g
          style={{ mixBlendMode: 'overlay' }}
          opacity='0.59'
          filter='url(#filter4_f_100_54)'
        >
          <ellipse
            cx='17.7675'
            cy='-1.22534'
            rx='47'
            ry='8.02368'
            fill='#D9D9D9'
          />
        </g>
        <g
          style={{ mixBlendMode: 'overlay' }}
          opacity='0.59'
          filter='url(#filter5_f_100_54)'
        >
          <ellipse
            cx='17.7675'
            cy='39.4758'
            rx='47'
            ry='8.02368'
            fill='#D9D9D9'
          />
        </g>
        <g clip-path='url(#clip1_100_54)'>
          <path
            d='M16.4536 20.6252C16.3866 20.3656 16.2513 20.1288 16.0618 19.9392C15.8722 19.7497 15.6354 19.6144 15.3758 19.5474L10.7746 18.3609C10.6961 18.3387 10.627 18.2914 10.5778 18.2263C10.5286 18.1612 10.502 18.0818 10.502 18.0002C10.502 17.9186 10.5286 17.8392 10.5778 17.7741C10.627 17.709 10.6961 17.6617 10.7746 17.6394L15.3758 16.4522C15.6353 16.3853 15.8721 16.2501 16.0616 16.0607C16.2511 15.8713 16.3865 15.6346 16.4536 15.3752L17.6401 10.7739C17.6621 10.6951 17.7093 10.6257 17.7745 10.5762C17.8398 10.5268 17.9193 10.5 18.0012 10.5C18.083 10.5 18.1626 10.5268 18.2278 10.5762C18.293 10.6257 18.3403 10.6951 18.3623 10.7739L19.5481 15.3752C19.615 15.6347 19.7503 15.8716 19.9398 16.0611C20.1294 16.2507 20.3663 16.386 20.6258 16.4529L25.2271 17.6387C25.3062 17.6605 25.376 17.7077 25.4257 17.773C25.4754 17.8383 25.5023 17.9181 25.5023 18.0002C25.5023 18.0823 25.4754 18.1621 25.4257 18.2274C25.376 18.2927 25.3062 18.3399 25.2271 18.3617L20.6258 19.5474C20.3663 19.6144 20.1294 19.7497 19.9398 19.9392C19.7503 20.1288 19.615 20.3656 19.5481 20.6252L18.3616 25.2264C18.3395 25.3052 18.2923 25.3747 18.2271 25.4241C18.1619 25.4736 18.0823 25.5004 18.0004 25.5004C17.9186 25.5004 17.839 25.4736 17.7738 25.4241C17.7086 25.3747 17.6614 25.3052 17.6393 25.2264L16.4536 20.6252Z'
            stroke='white'
            stroke-width='1.2'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M24 11.25V14.25'
            stroke='white'
            stroke-width='0.8'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M25.5 12.75H22.5'
            stroke='white'
            stroke-width='0.8'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M12 21.75V23.25'
            stroke='white'
            stroke-width='0.8'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
          <path
            d='M12.75 22.5H11.25'
            stroke='white'
            stroke-width='0.8'
            stroke-linecap='round'
            stroke-linejoin='round'
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_f_100_54'
          x='-19.9941'
          y='-30.9394'
          width='69.1308'
          height='71.6542'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8.69725'
            result='effect1_foregroundBlur_100_54'
          />
        </filter>
        <filter
          id='filter1_f_100_54'
          x='-37.289'
          y='-15.498'
          width='69.1308'
          height='71.6542'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8.69725'
            result='effect1_foregroundBlur_100_54'
          />
        </filter>
        <filter
          id='filter2_f_100_54'
          x='-4.3984'
          y='-21.3476'
          width='69.1308'
          height='71.6542'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8.69725'
            result='effect1_foregroundBlur_100_54'
          />
        </filter>
        <filter
          id='filter3_f_100_54'
          x='-23.3574'
          y='-0.856411'
          width='69.1308'
          height='71.6542'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='8.69725'
            result='effect1_foregroundBlur_100_54'
          />
        </filter>
        <filter
          id='filter4_f_100_54'
          x='-34.7324'
          y='-14.749'
          width='105'
          height='27.0479'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='2.75'
            result='effect1_foregroundBlur_100_54'
          />
        </filter>
        <filter
          id='filter5_f_100_54'
          x='-34.7324'
          y='25.9521'
          width='105'
          height='27.0479'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='2.75'
            result='effect1_foregroundBlur_100_54'
          />
        </filter>
        <clipPath id='clip0_100_54'>
          <path
            d='M0 8C0 3.58172 3.58172 0 8 0H28C32.4183 0 36 3.58172 36 8V28C36 32.4183 32.4183 36 28 36H8C3.58172 36 0 32.4183 0 28V8Z'
            fill='white'
          />
        </clipPath>
        <clipPath id='clip1_100_54'>
          <rect
            width='18'
            height='18'
            fill='white'
            transform='translate(9 9)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
