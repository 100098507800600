import { useLocation } from '@reach/router';
import usePartner from 'components/customHooks/usePartner';
import { useDispatch, useSelector } from 'react-redux';
import { ISettingsNavItem, ISettingsNavLink } from '../interface';
import { checkPermission } from 'utilities/utils';

const useSettings = () => {
  const dispatch = useDispatch();
  const { publicKey } = useSelector((state: any) => state.billing);
  const dashboardState = useSelector((state: any) => state.dashboard);
  const { selectedProject, permission } = dashboardState;
  const { activeSettings } = useSelector((state: any) => state.settings);
  const { email, trial_remaining } = useSelector((state: any) => state.auth);
  const { isPartnerRestricted } = usePartner();
  const location = useLocation();
  const hasPermission = selectedProject ? selectedProject.is_creator : false;
  const isProUser = selectedProject
    ? selectedProject?.subscription_plan?.plan_type !== 'free' ||
      trial_remaining > 0
    : false;

  const isEnterpriseOrBusinessPlan = [
    'enterprise',
    'business',
    'appsumo',
  ].includes(selectedProject?.subscription_plan?.plan_type);

  const isOnlyEnterpriseOrBusinessPlan = ['enterprise', 'business'].includes(
    selectedProject?.subscription_plan?.plan_type
  );
  const shouldDisableBilling = isPartnerRestricted(
    'Settings/node/plan_billing'
  );

  const getPublicKey = async () => {
    dispatch.billing.fetchPublicKey();
  };

  const updateActiveSettings = (settingsId: number) => {
    dispatch.settings.updateActiveSettings(settingsId);
  };

  const updateSelectedNav = (navId: number) => {
    dispatch.dashboard.updateSelectedNav(navId);
  };

  const filterNavItemsByPlan = (navItem: ISettingsNavItem) => {
    if (navItem.title.includes('Plan & Billing')) {
      return !shouldDisableBilling && hasPermission;
    }
    if (navItem.title.includes('Developer')) {
      return isEnterpriseOrBusinessPlan;
    }
    return true;
  };
  const filterNavItemsByPermission = (items: ISettingsNavLink[]) => {
    return items
      .filter((singleItem) => {
        if (singleItem.id === 27) {
          return isOnlyEnterpriseOrBusinessPlan;
        }
        // checking permission for billing
        if (singleItem.id === 7) {
          return !shouldDisableBilling && hasPermission;
        }
        return true;
      })
      .filter((subItem) => {
        return (
          subItem.itemGlobalAccess ||
          checkPermission(permission.list, subItem.permission?.readAccess)
        );
      });
  };
  const getAccessibleAndCategorizedNavItems = (items: ISettingsNavItem[]) => {
    return items
      .filter(filterNavItemsByPlan)
      .map((groupedSettings) => ({
        ...groupedSettings,
        items: filterNavItemsByPermission(groupedSettings.items || []),
      }))
      .filter(
        (groupedSettings) =>
          groupedSettings.items && groupedSettings.items.length > 0
      );
  };
  const initializeDefaultLocationHash = () => {
    updateSelectedNav(-1);
    if (location.hash === '') {
      location.hash = '#user-settings';
    }
  };
  const scrollToHashElement = () => {
    if (location.hash) {
      const element = document.getElementById(location.hash.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return {
    publicKey,
    selectedProject,
    email,
    trialRemaining: trial_remaining,
    activeSettings,
    permissionList: permission.list || [],
    getPublicKey,
    updateActiveSettings,
    updateSelectedNav,
    hasPermission,
    isProUser,
    isEnterpriseOrBusinessPlan,
    isOnlyEnterpriseOrBusinessPlan,
    shouldDisableBilling,
    initializeDefaultLocationHash,
    scrollToHashElement,
    getAccessibleAndCategorizedNavItems,
  };
};
export default useSettings;
