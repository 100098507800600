import React from 'react';
import RibbonIcon from '../old/assets/images/ribbon.svg';
import GearIcon from '../old/assets/images/gear.svg';
import ToolboxIcon from '../old/assets/images/toolbox.svg';
import SilhouetteIcon from '../old/assets/images/silhouette.svg';
import facebookFeedIcon from '../old/assets/images/facebook_feed_icon.svg';
import facebookMessengerIcon from '../old/assets/images/fecebook_messenger_icon.svg';
import whatsAppIcon from '../old/assets/images/whatsapp_icon.svg';
import viberIcon from '../old/assets/images/viber_icon.svg';
import applicationIcon from '../old/assets/images/application_icon.svg';
import websiteIcon from '../old/assets/images/website_icon.svg';

export const authData = {
  login: {
    title: 'Sign in to your account',
    subtitle: 'Provide your registered email and password',
    loginButtonTitle: 'Login',
    loginButtonIcon: 'key',
    hook: {
      title: 'What’s new?',
      description: (
        <ul>
          <li style={{ margin: 10 }}>
            Build chatbot for free (no credit card required)
          </li>
          <li style={{ margin: 10 }}>Connect to Messenger and Viber</li>
          <li style={{ margin: 10 }}>Connect to Wit.ai for NLP</li>
        </ul>
      ),
      button: {
        title: 'Know More',
        link: 'https://www.getalice.ai/',
      },
    },
  },
  register: {
    title: 'Create a new account!',
    subtitle: 'Provide your valid email address',
    registerButtonTitle: 'Sign Up',
    resendButtonTitle: 'Resend Link',
    newsletterText: 'Subscribe to news and updates from MyAlice',
    // registerButtonIcon: 'user',
    hook: {
      title: 'Why MyAlice?',
      description: (
        <ul>
          <li style={{ margin: 10 }}>Create a chatbot for free!</li>
          <li style={{ margin: 10 }}>Connect it to any messaging channel!</li>
          <li style={{ margin: 10 }}>
            Power your chatbot with Artificial Intelligence!
          </li>
        </ul>
      ),
      button: {
        title: 'Know More',
        link: 'https://www.getalice.ai/',
      },
    },
  },
  verify: {
    title: 'Add Your New Password',
    subtitle: 'Previously used passwords are not recommended',
    forgetButtonTitle: 'Verify Now',
    forgetButtonIcon: 'endorsed',
    hook: {
      title: 'You are Almost There',
      description:
        'Provide the code sent to your mail and update your password !! Dead Simple',
      button: {
        title: 'Sign In',
        link: '/',
      },
    },
  },
  resendRegisterLink: {
    timeoutTitle: 'Verification Timed Out',
    timeoutSubTitle:
      'The verification link we sent to you has been expired. Click below to resend.',
  },
  verifyRegister: {
    title: 'Verify Your Account',
    submitButtonTitle: 'Verify Now',
    submitButtonIcon: 'endorsed',
    alreadyVerifiedTitle: 'Already Verified!',
    alreadyVerifiedSubTitle:
      'Your email is already verified! Please click below to redirect to dashboard.',
    alreadyVerifiedButtonTitle: 'Go to Dashboard',
    hook: {
      title: 'You are Almost There',
      description:
        'Provide the code sent to your mail and verify your account !! Dead Simple',
      button: {
        title: 'Sign In',
        link: '/',
      },
    },
  },
  forget: {
    title: 'Forgot your Password?',
    subtitle:
      'Please provide the email address you registered with. We will send you a verification code.',
    forgetButtonTitle: 'Send Code',
    savePassword: 'Save Password',
    // forgetButtonIcon: 'tick-circle',
    hook: {
      title: 'Forgot Password?',
      description:
        'It can happen. Relax! Just send us the mail and we will send you further instructions over there.',
      button: {
        title: 'Contact Us',
        link: 'https://getalice.ai/contact',
      },
    },
  },
  forgetInfo: {
    text: '',
    linkTitle: 'Forgot Password?',
    link: '/reset',
  },
  contactInfo: {
    text: 'Facing an issue?',
    linkTitle: 'Contact us',
    link: 'https://www.myalice.ai/support',
  },
  registerInfo: {
    text: 'Don’t have an account?',
    linkTitle: 'Sign up here.',
    link: '/register',
  },
  loginInfo: {
    text: 'Already have a MyAlice account?',
    linkTitle: 'Log in',
    link: '/',
  },
  signupConfirmationInfo: {
    text: 'By proceeding, you agree to the',
    linkTitle: 'Terms & Conditions',
    link: 'https://myalice.ai/terms/',
  },
};

export const footerData = {
  text: 'All rights reserved.',
  company: `${new Date().getFullYear()} Alice Labs Pte. Ltd.`,
  tos: {
    title: 'Terms of Services',
    link: 'https://myalice.ai/terms/',
  },
  privacy: {
    title: 'Privacy Policy',
    link: 'https://myalice.ai/privacy/',
  },
};

export const proFeatures = [
  {
    icon: RibbonIcon,
    text: 'Expand Monthly User Limit to 500',
  },
  {
    icon: SilhouetteIcon,
    text: 'Export your Data from Audience',
  },
  {
    icon: ToolboxIcon,
    text: 'Export or Import your data in Datalab',
  },
  {
    icon: GearIcon,
    text: 'Integrate your API to provide users with information stored on your server!',
  },
];

export const pricingPlans = [
  {
    name: 'Free',
    price: 0,
    features: [
      'Upto 1000 Subscribers',
      'Connection to Datalab',
      'Connection to facebook feed and messages',
      'Connection to Viber',
      'Visual flow builder',
      'Connection to wit',
      'MyAlice Branding',
    ],
  },
  {
    name: 'Pro',
    price: 10,
    features: [
      'Unlimited Subscribers *',
      'All features in Free package',
      'No MyAlice Branding',
      'API connection to any system',
      'Team management',
      'Export audience for ads',
    ],
  },
  {
    name: 'Enterprise',
    price: -1,
    features: [
      'Unlimited Subscribers',
      'All features in Pro',
      '24*7 Dedicated Support Team',
      'Marketing support to build conversation flow',
      'API middleware development for any internal system integration',
      'On premise deployment of API server',
      'wit training team',
    ],
  },
];

export const onboardingData = {
  generalInfoData: {
    title: 'Tell us about yourself',
    subtitle: 'Help us personalize your experience',
  },
  companyData: {
    title: 'Tell us about your company',
    subtitle: 'Help us personalize your experience',

    companyType: {
      buttonTitle: 'Your Industry',
      options: [
        { id: 1, name: 'Electronics & Computers' },
        { id: 2, name: 'Design & Marketing' },
        { id: 3, name: 'Fashion & Apparel' },
        { id: 4, name: 'Construction & Industrial' },
        { id: 5, name: 'Education & Learning' },
        { id: 6, name: 'Health & Beauty' },
        { id: 7, name: 'Software' },
        { id: 8, name: 'Toys & Games' },
        { id: 9, name: 'Travel & Leisure' },
        { id: 10, name: 'Books & Magazines' },
        { id: 11, name: 'Others' },
      ],
    },
    monthlySupportReach: {
      buttonTitle:
        'How many people reach out to your support team on an average day?',
      options: [
        { id: 1, name: '0-10' },
        { id: 2, name: '11-50' },
        { id: 3, name: '50-99' },
        { id: 4, name: '100+' },
      ],
    },
    companySupportSize: {
      buttonTitle: 'What is the size of your customer support team?',
      options: [
        { id: 1, name: '0-3' },
        { id: 2, name: '4-10' },
        { id: 3, name: '11-29' },
        { id: 4, name: '30+' },
      ],
    },
  },

  channelData: {
    title: 'Create Channels',
    subtitle:
      'These are the channels that will be available to your customers. You can always add these later.',
    channelList: [
      { name: 'Facebook Feed', value: 'facebook_feed', icon: facebookFeedIcon },
      {
        name: 'Facebook Messenger',
        value: 'facebook_messenger',
        icon: facebookMessengerIcon,
      },
      { name: 'Whatsapp', value: 'whatsapp_messenger', icon: whatsAppIcon },
      { name: 'Viber', value: 'viber_messenger', icon: viberIcon },
      { name: 'Application', value: 'app_messenger', icon: applicationIcon },
      { name: 'Website', value: 'webchat', icon: websiteIcon },
    ],
  },

  teamInvitationData: {
    title: 'Invite your team members',
    subtitle: 'Invite your team members now',
  },
};

export const projectActivityInfo = [
  {
    id: 'is_account_created',
    activityName: 'Create an Account',
    path: '/register',
    doc: 'https://docs.myalice.ai/getting-started/create-an-account',
  },
  {
    id: 'is_member_invited',
    activityName: 'Invite Team Members',
    path: '/settings/team-user',
    doc: 'https://docs.myalice.ai/getting-started/know-your-first-team',
  },
  {
    id: 'is_channel_connected',
    activityName: 'Connect a Channel',
    path: '/settings/channel',
    doc: 'https://docs.myalice.ai/getting-started/connect-a-channel',
  },
  {
    id: 'is_ticket_created',
    activityName: 'Create a Ticket Block',
    path: '/',
    doc: 'https://docs.myalice.ai/automate-channels/chatbot-blocks/action-blocks#ticket-block',
  },
  {
    id: 'is_ticket_resolved',
    activityName: 'Resolve a Ticket',
    path: '/',
    doc: 'https://docs.myalice.ai/inbox',
  },
  {
    id: 'is_rule_connected',
    activityName: 'Connect an Automation Rule',
    path: '/',
    doc: 'https://docs.myalice.ai/getting-started/bot-builder',
  },
  {
    id: 'is_billing_setup',
    activityName: 'Setup Billing',
    path: '/settings/billing',
    doc: 'https://docs.myalice.ai/getting-started/setup-your-billing-package',
  },
  {
    id: 'is_ecommerce_connected',
    activityName: 'Connect with E-Commerce',
    path: '/settings/marketplace',
    doc: 'https://docs.myalice.ai/getting-started/connect-your-marketplace',
  },
];

export const analytics = {
  customerMetrics: {
    dataCard: {
      0: {
        title: 'New Customers',
        toolTip:
          'Total new Customers who have been created in the platform during the selected timeframe',
      },
      1: {
        title: 'Interactive Customers',
        toolTip:
          'Total Customers who have interacted with the platform during the selected timeframe',
      },
      2: {
        title: 'Referral',
        toolTip:
          'Total Customers who have entered the platform using shared links',
      },
      3: {
        title: 'Intent',
        toolTip:
          'Total topics based on which queries being asked using the platform’s wit module',
      },
    },
    graphDataCard: {
      0: {
        title: 'Language',
        toolTip:
          'Highest used language and the comparison with other languages during the selected timeframe',
      },
      1: {
        title: 'Timezone',
        toolTip:
          'Timezone of the region from where the highest amount of users are interacting along with the comparison with other regions',
      },
      2: {
        title: 'Country',
        toolTip:
          'Shows the country from where most users are situated and comparison of the usage with other countries',
      },
    },
    customerHeatmapGrid: {
      title: 'Incoming Message Count',
      subTitle:
        'The Incoming Message Heatmap is time range is the total lifespan of your platform. The time range does not change according to the date filter',
      toolTip:
        'Heatmap indicates how engaged the users were in different hours',
    },
    gender: {
      title: 'Gender',
      toolTip: 'Ratio between males, females and other categories of users',
    },
    textVsButton: {
      title: 'Textual Message vs Button Clicks',
      toolTip:
        'Ratio of messages which were sent manually by an agent (Textual Message) against the automated messages from the bot (Postback)',
    },
  },
  automationMetrics: {
    dataCard: {
      0: {
        title: 'Tickets for Unique Customer',
        toolTip:
          'Total number of Tickets for unique customers during the selected timeframe',
      },
      1: {
        title: 'Tickets for Overall Customers',
        toolTip:
          'The ratio of Tickets to the number of total customers during the selected timeframe',
      },
      2: {
        title: 'Tickets Created',
        toolTip:
          'Total number of Tickets created by Customers in the platform during the selected timeframe',
      },
      3: {
        title: 'Tickets Resolved',
        toolTip:
          'Total number of Tickets resolved by Agents in the platform during the selected timeframe',
      },
      4: {
        title: 'Avg. Process FRT',
        toolTip:
          'The average time required by Agents to respond to a Customer from the time a ticket was first created.',
      },
    },
    textVsAutomation: {
      title: 'Incoming Texts vs Automated Responses',
      toolTip:
        'Comparison between the the number of text messages sent by agents (Textual Message) against the number of automated messages generated by the chatbot (Automation)',
    },
    tagCloud: {
      title: 'List of Tags',
      toolTip: 'List of the most used tags by Agents in the Inbox',
    },
    ticketDetails: {
      title: 'List of Tickets',
      toolTip: 'List of Tickets created in the platform with the details',
    },
  },
  agentMetrics: {
    dataCard: {
      0: {
        title: 'Avg. First Response Time',
        toolTip:
          'The average time required by Agents to respond to a Customer from the time a ticket was assigned to an agent',
      },
      1: {
        title: 'Avg. Resolution Time',
        toolTip:
          "The average time required by Agents to resolve a Customer's Ticket from the time the Agent started working on the Ticket",
      },
      2: {
        title: 'Avg. Response Time',
        toolTip:
          "The average time required by Agents to response a Customer's Ticket from the time the Agent started working on the Ticket",
      },
      3: {
        title: 'Avg. Sales Conversion',
        toolTip:
          'The Percentage of Orders placed to the Number of tickets from the time the Agent started working on the Ticket',
      },
    },
    agentDetails: {
      title: 'Agent Details',
      toolTip:
        'Necessary Information about Agents and their corresponding activities in the Inbox',
    },
  },
  CSAT: {
    dataCard: {
      0: {
        title: 'CSAT Block Sent',
        toolTip:
          'Total number of CSAT Blocks sent to Customers to rate the performance of Agents',
      },
      1: {
        title: 'Response Rate',
        toolTip:
          'The rate of responses given by Customers when CSAT Blocks are sent to them',
      },
      2: {
        title: 'Average Ratings',
        toolTip:
          'The average CSAT ratings of the performance of the Agents as given by the Customers',
      },
    },
    responseList: {
      title: 'List of Responses',
      toolTip: 'Necessary Information about CSAT Ratings with the details',
    },
    responseDistribution: {
      title: 'Response Distribution',
      toolTip: 'Distribution of CSAT Ratings given by the Customers',
    },
  },
};

export const orderData = {
  orderRefund: {
    title: 'Issue a Refund',
    description:
      'You have requested to refund the order. Once your request is completed, the order payment will be refunded, and the status will be changed to “Refunded”. The process will be carried out according to your return and refund policy.',
  },
  orderCancel: {
    title: 'Order Cancellation',
    description:
      'You have requested to cancel the order. Once your request is completed, the order will be cancelled, and the status will be changed to “Cancelled”. The process will be carried out according to your cancellation policy.',
  },
  discardOrderUpdation: {
    title: 'Discard Order Updation',
    description:
      'You have requested to discard the order updation process. The selected items in the cart will be discarded, and you will have to start over again if you want to update the order for the customer. Are you sure you want to proceed?',
  },
  discardOrderCreation: {
    title: 'Discard Order Creation',
    description:
      'You have requested to discard the order creation process. The selected items in the cart will be discarded, and you will have to start over again if you want to create the order for the customer. Are you sure you want to proceed?',
  },
  searchOrder: {
    title: 'Search All Orders',
    description: 'Search for an order with ID or email of the customer',
  },
};

export const productRecommenderData = {
  title: 'Recommend Product',
  description:
    'You can recommend products from here based on real-time customer data',
};

export const BlocksData = {
  setAttributeBlock: {
    operation: ['set', 'clear', 'increase', 'decrease'],
    operationWarning:
      'For increase by or decrease by operations, if the associated value is not numerical, the operation will be ignored.',
  },
  productDiscoveryBlock: {
    actionButtons: [
      { name: 'Add to Cart', value: 'add_to_cart' },
      { name: 'Show Variants', value: 'show_variants' },
      { name: 'Product Page', value: 'product_page' },
    ],
  },
  addToCartBlock: {
    title: 'Add to Cart',
    subTitle:
      'Add to Cart Block automatically takes the gallery of products chosen by Customers and updates their carts',
  },
  placeOrderBlock: {
    title: 'Place Order',
    subTitle:
      'Place Order Block takes the Customer’s cart data, and acquires the required Billing Information to place a new order',
    customerInformation: {
      first_name: 'First Name',
      last_name: 'Last Name',
      phone_number: 'Phone Number',
      email: 'Email',
      shipping_id: 'Shipping ID',
      shipping_address: 'Shipping Address',
      shipping_method: 'Shipping Method',
      shipping_cost: 'Shipping Cost',
      payment_method: 'Payment Method',
    },
    operationWarning:
      'The value for the following mandatory attributes are missing:',
    outputViewAttributes: ['order_id', 'payment_link', 'checkout_link'],
    placeHolder: 'Add text and variable',
    outputViewTextMaxLength: 500,
  },
  viewCartBlock: {
    title: 'View Cart',
    subTitle:
      'View Cart Block shows the gallery of products which are currently in the Customer’s cart',
    input_parameters: [],
    output_parameters: [
      'product_id',
      'product_name',
      'product_link',
      'sku',
      'variant_id',
      'quantity',
      'unit_price',
      'total_cost',
    ],
  },
  RemoveFromCartBlock: {
    title: 'Remove from Cart',
    subTitle:
      "Remove from Cart deletes the selected product which is currently in the Customer's Cart",
  },
  couponDiscoveryBlock: {
    title: 'Coupon Discovery',
    subTitle:
      'Coupon discovery block will return list of available coupons from the store when a customer asks.',
    placeHolder: 'Add text and variable',
    outputMessage:
      'Code: {{code}} \nAmount: {{amount}} \nDate Expires: {{date_expires}} \nDiscount Type: {{discount_type}}',
    woocommerce: {
      input_parameters: [
        {
          name: 'search',
          verbose: 'Search',
          type: 'string',
          default: '',
          isPinned: true,
        },
      ],
      output_parameters: [
        'id',
        'code',
        'amount',
        'discount_type',
        'date_expires',
      ],
    },
    shopify: {
      input_parameters: [],
      output_parameters: [],
    },
  },

  betterdocsBlock: {
    actionButtons: [{ name: 'Document Link', value: 'betterdocs_url' }],
    parameters: ['title', 'excerpt', 'slug', 'type', 'link'],
  },
};

export const DocLink = 'https://docs.myalice.ai/';
export const airtableSupportLink = 'https://airtable.com/shrvMCwEUGQU7TvRR';
export const myaliceSupportLink = 'https://myalice.ai/support/';

export const ecommerceParameters = {
  woocommerce: {
    input_parameters: [
      {
        name: 'page',
        verbose: 'Page',
        type: 'number',
        default: 1,
        isPinned: true,
      },
      {
        name: 'per_page',
        verbose: 'Per Page',
        type: 'number',
        default: 8,
        isPinned: true,
      },
      {
        name: 'search',
        verbose: 'Search',
        type: 'string',
        default: '',
        isPinned: false,
      },
      {
        name: 'orderby',
        verbose: 'Order By',
        type: 'string',
        has_options: true,
        options: [
          'date',
          'id',
          'include',
          'title',
          'slug',
          'price',
          'popularity',
          'rating',
        ],
        default: '',
        isPinned: false,
      },
      {
        name: 'order',
        verbose: 'Sort By',
        type: 'string',
        has_options: true,
        options: ['asc', 'desc'],
        default: '',
        isPinned: false,
      },
      {
        name: 'category',
        verbose: 'Category Id',
        type: 'number',
        default: '',
        isPinned: false,
      },
      {
        name: 'tag',
        verbose: 'Tag Id',
        type: 'number',
        default: '',
        isPinned: false,
      },
      {
        name: 'on_sale',
        verbose: 'Is On Sale',
        type: 'boolean',
        has_options: true,
        options: ['true', 'false'],
        default: false,
        isPinned: false,
      },
      {
        name: 'stock_status',
        verbose: 'Stock Status',
        type: 'boolean',
        has_options: true,
        options: ['instock', 'outofstock', 'onbackorder'],
        default: false,
        isPinned: false,
      },
      {
        name: 'status',
        verbose: 'Status',
        type: 'string',
        has_options: true,
        options: ['any', 'draft', 'pending', 'private', 'publish'],
        default: '',
        isPinned: false,
      },
      {
        name: 'type',
        verbose: 'Type',
        type: 'string',
        has_options: true,
        options: ['simple', 'grouped', 'external', 'variable'],
        default: '',
        isPinned: false,
      },
      {
        name: 'sku',
        verbose: 'SKU',
        type: 'string',
        default: '',
        isPinned: false,
      },
      {
        name: 'featured',
        verbose: 'Is Featured',
        type: 'boolean',
        has_options: true,
        options: ['true', 'false'],
        default: false,
        isPinned: false,
      },
      {
        name: 'shipping_class',
        verbose: 'Shipping Class Id',
        type: 'number',
        default: '',
        isPinned: false,
      },
      {
        name: 'tax_class',
        verbose: 'Tax Class',
        type: 'string',
        has_options: true,
        options: ['standard', 'reduced-rate', 'zero-rate'],
        default: '',
        isPinned: false,
      },
      {
        name: 'show_variations',
        verbose: 'Show Variations',
        type: 'boolean',
        has_options: true,
        options: ['true', 'false'],
        default: true,
        isPinned: false,
      },
    ],
    output_parameters_for_regular_product: [
      'name',
      'permalink',
      'date_created',
      'type',
      'status',
      'price',
      'regular_price',
      'sale_price',
      'on_sale',
      'stock_status',
      'stock_quantity',
      'average_rating',
    ],
    output_parameters_for_variants: [
      'name',
      'permalink',
      'date_created',
      'type',
      'status',
      'price',
      'regular_price',
      'sale_price',
      'on_sale',
      'stock_status',
      'stock_quantity',
      'average_rating',
    ],
  },
  shopify: {
    input_parameters: [
      {
        name: 'first',
        verbose: 'First',
        type: 'number',
        default: 8,
        isPinned: true,
      },
      {
        name: 'after',
        verbose: 'After',
        type: 'string',
        default: '{{x_product_cursor}}',
        isPinned: true,
      },
      // {name:"tag", verbose:"Tag Id", type:"string", default: '', isPinned:false},
      {
        name: 'title',
        verbose: 'Title',
        type: 'string',
        default: '',
        isPinned: false,
      },
      {
        ame: 'product_type',
        verbose: 'Product Type',
        type: 'string',
        default: '',
        isPinned: false,
      },
      // {name:"price", verbose:"Price", type:"string", default: '', isPinned:false},
      {
        name: 'published_status',
        verbose: 'Published Status',
        type: 'boolean',
        has_options: true,
        options: ['published', 'unpublished', 'any'],
        default: false,
        isPinned: false,
      },
      {
        name: 'status',
        verbose: 'Status',
        type: 'string',
        has_options: true,
        options: ['active', 'draft', 'archived'],
        default: '',
        isPinned: false,
      },
      {
        name: 'show_variations',
        verbose: 'Show Variations',
        type: 'boolean',
        has_options: true,
        options: ['true', 'false'],
        default: true,
        isPinned: false,
      },
    ],
    output_parameters_for_regular_product: [
      'id',
      'title',
      'featuredImage',
      'productType',
      'priceRange',
      'onlineStorePreviewUrl',
      'totalInventory',
      'totalVariants',
    ],
    output_parameters_for_variants: [
      'id',
      'displayName',
      'inventoryQuantity',
      'price',
      'sku',
      'weight',
      'onlineStorePreviewUrl',
    ],
  },
};

export const onBoardingContents = {
  dashboard: {
    //ecommerce contents
    1: {
      video: '',
      documentation:
        'https://docs.myalice.ai/myalice-ecommerce/connect-ecommerce',
    },

    //automation contents
    2: {
      video:
        'https://www.youtube.com/watch?v=Hn_fdQ5osGs&list=PL_EdxcvIGFEbUN0tqYUE84t-bc1QekV72',
      documentation:
        'https://docs.myalice.ai/chatbot-automation/create-automation-sequences',
    },

    //integration contents
    3: {
      video: '',
      documentation:
        'https://docs.myalice.ai/connect-social-channels/connect-social-media',
    },

    //team contents
    4: {
      video: '',
      documentation: 'https://docs.myalice.ai/get-started/create-team',
    },
  },
};

export const dashboardmarketingData = {
  small_cards: [
    {
      title: 'All of your conversations in one place',
      image_link:
        'https://res.cloudinary.com/myalice/image/upload/v1668489589/MyAlice%20Dashboard/Icon_2.svg',
      summary:
        'Bring all your favorite customer channels under one inbox for seamless customer support.',
      link_url: 'inbox',
      link_text: 'Go to Inbox',
      is_internal_link: true,
    },
    {
      title: 'More integrations = More Superpower',
      image_link:
        'https://res.cloudinary.com/myalice/image/upload/v1668489548/MyAlice%20Dashboard/Icon.svg',
      summary:
        'Easy to install but unlocked to match your every needs. Including all of your favorite social channels, livechat, email, ecommerce and more!',
      link_url: 'apps',
      link_text: 'Explore Apps',
      is_internal_link: true,
    },
  ],
  large_cards: [
    {
      title: 'WooCommerce Gets Conversational',
      image_link:
        'https://uploads-ssl.webflow.com/61fe1678647f8be52a35ec7a/6231b1e81b5de4157197f4ea_woocommerce-chatbot-myalice.png',
      summary:
        'Turn your WooCommerce store into a conversational platform with MyAlice live chat plugin and multichannel helpdesk.',
      link_url: 'https://myalice.ai/blog/woocommerce-helpdesk/',
      link_text: 'Read the blog',
      is_internal_link: false,
    },
    {
      title: 'Shopify Gets Conversational',
      image_link:
        'https://uploads-ssl.webflow.com/61fe1678647f8be52a35ec7a/6231b4abdf29f304021c5a65_productmatch.png',
      summary:
        'Release Alert for Store Owners: MyAlice is on Shopify App Store',
      link_url: 'https://myalice.ai/blog/shopify-helpdesk/',
      link_text: 'Read the blog',
      is_internal_link: false,
    },
  ],
};

export const dropdownDefaultValue = {
  country: 'Singapore',
  currency_name: 'Dollar',
  phone_code: '+65',
  id: 191,
  country_iso_code: 'SG',
  iso_alpha3: 'SGP',
  iso_numeric: 702,
  currency_code: 'SGD',
  currency_symbol: '$',
  flag_base64:
    'iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAYAAACaq43EAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo3NzkxOEIzQzE3ODcxMUUyQTcxNDlDNEFCRkNENzc2NiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo3NzkxOEIzRDE3ODcxMUUyQTcxNDlDNEFCRkNENzc2NiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjc3OTE4QjNBMTc4NzExRTJBNzE0OUM0QUJGQ0Q3NzY2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjc3OTE4QjNCMTc4NzExRTJBNzE0OUM0QUJGQ0Q3NzY2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+k5fIFwAAAflJREFUeNrslk1rE1EUhp97Z5Jp0dTEL7S0GEFcKK4CLgouunARQcEK4kKqtTsVi8WFFUS6EDdiUXDvX9CFgkJXirjQoggiKoqSGIuYtpOk6SRzxzMTK/0BlwrSA5d5mYF5zrnnPXdG1fYMloBeHO3TDjEfv0CXh96Vh6AFLVmOA0phKTKyym4itIb6YsZ8LZEeG8U9WiS4fodw5m0CVZl1Hbgx1uA6uYRSaWUWb+oa3s2rmDfvaD16gNq2la57t1D5fsz7T7C01EnAQiRgU6qQOnyQ9PkRwulnNMcnSZ86g9qUJXw4DY1FUieHUH3bE20NTL2BPrA/ke37j1Ebs6TODic9DqbuEs3+JHX6OFrAkV+3AnaXhVo2T896onKZ5vCYtMCg9+6TLQ5ojl6ClIvanLNYcXc34fOXnUwGBzBUcAYKeLcnJYkfeDcu4544gvn23Zq7nStbdk6Ia73wxQxazOQOFVGu9PbJU6jVBVYmdn0kjo9+VVHptA1uoPzewoK8OENdIFWp7uIF3GOHaJ6bIHz9Cr1jN1Gl0gHmZJvbbRtgX9UKxTkRG2RMDEGA+fBZ3JxD5/uIag2bB8fK9s4rM79QFZGNp/nvIzEVMS+e2SiyDY4PgjlX9WScFTdWKxzNP4o18Br4/wPHXyf/z++Iv0rMhPVbgAEAAN6l96444okAAAAASUVORK5CYII=',
};

export const plansProperty = {
  free_plan: {
    name: 'free',
    min_team_member: 1,
    max_team_member: 2,
  },
  premium_plan: {
    name: 'premium',
    min_team_member: 5,
    max_team_member: null,
  },
  business_plan: {
    name: 'business',
    min_team_member: 5,
    max_team_member: null,
  },
  enterprise_plan: {
    name: 'enterprise',
    min_team_member: 10,
    max_team_member: null,
  },
};

export const UBroadcastProperty = {
  broadcastStatus: {
    scheduled: {
      value: 'scheduled',
      backgroundColor: 'bg-yellow-100',
      textColor: 'text-yellow-800 font-medium',
    },
    draft: {
      value: 'draft',
      backgroundColor: 'bg-gray-100',
      textColor: 'text-gray-800 font-medium',
    },
    ongoing: {
      value: 'ongoing',
      backgroundColor: 'bg-[#DBEAFE]',
      textColor: 'text-blue-800 font-medium',
    },
    completed: {
      value: 'completed',
      backgroundColor: 'bg-[#D1FAE5]',
      textColor: 'text-[#065F46] font-medium',
    },
    failed: {
      value: 'failed',
      backgroundColor: 'bg-[#FEE2E2]',
      textColor: 'text-[#991B1B] font-medium',
    },
  },
  perPageData: 50,
  audienceStatus: {
    Scheduled: {
      value: 'Scheduled',
      backgroundColor: 'bg-gray-100',
      textColor: 'text-gray-800 font-medium',
    },
    Queued: {
      value: 'Queued',
      backgroundColor: 'bg-[#FEF3C7]',
      textColor: 'text-yellow-800 font-medium',
    },
    Delivered: {
      value: 'Delivered',
      backgroundColor: 'bg-[#D1FAE5]',
      textColor: 'text-[#065F46] font-medium',
    },
    Sent: {
      value: 'Sent',
      backgroundColor: 'bg-[#D1FAE5]',
      textColor: 'text-[#065F46] font-medium',
    },
    Seen: {
      value: 'Seen',
      backgroundColor: 'bg-purple-100',
      textColor: 'text-purple-800 font-medium',
    },
    Replied: {
      value: 'Replied',
      backgroundColor: 'bg-indigo-100',
      textColor: 'text-indigo-800 font-medium',
    },
    Failed: {
      value: 'Failed',
      backgroundColor: 'bg-[#FEE2E2]',
      textColor: 'text-[#991B1B] font-medium',
    },
  },
};

export const UBroadcastStatusCheck = (status) => {
  return (
    <>
      {UBroadcastProperty.broadcastStatus[status] && (
        <div
          className={`py-1 px-3 ${UBroadcastProperty.broadcastStatus[status].backgroundColor} ${UBroadcastProperty.broadcastStatus[status].textColor} rounded-[15px] text-center capitalize`}
        >
          {UBroadcastProperty.broadcastStatus[status].value}
        </div>
      )}
      {!UBroadcastProperty.broadcastStatus[status] && (
        <div className='py-1 px-3 bg-red-500 text-white rounded-[15px] text-center'>
          - -
        </div>
      )}
    </>
  );
};

export const UGetInfoSvgTooptipIcon = (
  description,
  customSvgCss = '',
  customTitleCss = '',
  customCssForArrow = ''
) => {
  return (
    <div className='relative group rtl:order-first'>
      <div className='invisible group-hover:visible '>
        <div
          className={`absolute normal-case left-[-10px] bottom-[23px] w-[255px] text-xs leading-4 text-white ${customTitleCss}`}
        >
          <p className='bg-gray-600 px-2 py-1 rounded-[3px] inline-block'>
            {description}
          </p>
        </div>
        <span
          className={`absolute right-[2px] bottom-[17px] w-[0px] h-[0px] border-l-[7px] border-r-[7px] border-t-[10px]
                      border-l-transparent border-r-transparent border-t-gray-600 ${customCssForArrow}`}
        ></span>
      </div>

      <svg
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
        strokeWidth={1.5}
        stroke='currentColor'
        className={`w-3.5 h-3.5 m-0.5 ${customSvgCss}`}
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'
        />
      </svg>
    </div>
  );
};

export const UGetCssClassForAudience = (status) => {
  return !!UBroadcastProperty.audienceStatus[status]
    ? `${UBroadcastProperty.audienceStatus[status].backgroundColor}
  ${UBroadcastProperty.audienceStatus[status].textColor}`
    : `${UBroadcastProperty.audienceStatus.Scheduled.backgroundColor} ${UBroadcastProperty.audienceStatus.Scheduled.textColor}`;
};
