'use client';
import { Card, CardContent } from 'libraryV2/ui/card';

import { FC } from 'react';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import { Info } from 'lucide-react';
import { useSelector } from 'react-redux';
import { DateRange } from 'react-day-picker';
import { IHistoryStatus } from 'pages/automationWorkflow/interface';
import { calculateDurationInDays } from 'pages/automationWorkflow/utility/function';

interface MetricesCardsProps {
  dateRange: DateRange;
  data: IHistoryStatus;
}

interface ICardContent {
  id: number;
  name: string;
  current_count: number;
  previous_count: number;
  growth_percentage: number;
  TooltipContent: string;
}

export const MetricesCards: FC<MetricesCardsProps> = ({ dateRange, data }) => {
  const { selectedUserActionFlowData } = useSelector(
    (state: any) => state.workflowAutomation
  );
  const metricesData = [
    {
      id: 1,
      name: 'Automation Triggered',
      current_count: data?.automation_triggered?.current_value || 0,
      previous_count: data?.automation_triggered?.previous_value,
      growth_percentage: data?.automation_triggered?.growth_percentage || 0,
      TooltipContent: 'Total amount of automation triggered by customers. ',
    },
    {
      id: 2,
      name: 'Automation succeed',
      current_count: data?.automation_succeeded?.current_value || 0,
      previous_count: data?.automation_succeeded?.previous_value,
      growth_percentage: data?.automation_succeeded?.growth_percentage || 0,
      TooltipContent: 'Total amount of automations run successfully.',
    },

    {
      id: 3,
      name: 'Automation failed',
      current_count: data?.automation_failed?.current_value || 0,
      previous_count: data?.automation_failed?.previous_value,
      growth_percentage: data?.automation_failed?.growth_percentage || 0,
      TooltipContent: 'Total amount of automations failed.',
    },
    {
      id: 4,
      name: 'Total Recovered Carts',
      current_count: data?.total_recovered_carts?.current_value || 0,
      previous_count: data?.total_recovered_carts?.previous_value,
      growth_percentage: data?.total_recovered_carts?.growth_percentage || 0,
      TooltipContent:
        'Number of abandoned carts that have been recovered successfully.',
    },
    {
      id: 5,
      name: 'Total Sales',
      current_count: data?.total_sales?.current_value || 0,
      previous_count: data?.total_sales?.previous_value,
      growth_percentage: data?.total_sales?.growth_percentage || 0,
      TooltipContent: 'Amount of total sales generated from orders.',
    },
    {
      id: 6,
      name: 'Conversion Rate',
      current_count: data?.conversion_rate?.current_value || 0,
      previous_count: data?.conversion_rate?.previous_value,
      growth_percentage: data?.conversion_rate?.growth_percentage || 0,
      TooltipContent:
        'Customers converted to order from total automations sent.',
    },
  ];

  const renderCard = (item: ICardContent) => {
    return (
      <Card key={item.id} className='w-full h-28'>
        <CardContent className='mt-3'>
          <div>
            <div className='flex items-center justify-start gap-1'>
              <span className='text-sm font-medium text-textPrimary'>
                {item?.name}
              </span>
              <TooltipProviderCustomised content={item.TooltipContent}>
                <Info size={13} />
              </TooltipProviderCustomised>
            </div>
            <div>
              <span className='text-2xl font-bold text-textPrimary'>
                {item?.current_count}
              </span>
            </div>
            <div>
              <span className='text-textSecondary text-xs'>
                <span
                  className={
                    item?.growth_percentage > 0
                      ? 'text-green-500 font-semibold'
                      : item?.growth_percentage < 0
                      ? 'text-red-500 font-semibold'
                      : 'text-textPrimary font-semibold'
                  }
                >
                  {item?.growth_percentage?.toFixed(2)}%
                </span>{' '}
                from{' '}
                <span className='text-textPrimary font-semibold'>
                  {item?.previous_count}
                </span>{' '}
                of previous{' '}
                <span className='text-textPrimary font-semibold'>
                  {calculateDurationInDays(dateRange)}
                </span>{' '}
                days
              </span>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  };

  return (
    <div>
      <div className='text-xs text-md flex flex-col gap-2 w-full rounded-md '>
        {selectedUserActionFlowData?.trigger_name === 'Cart Abandoned' ? (
          <div className='pl-0 overflow-y-hidden w-full'>
            <div className='overflow-x-auto flex flex-nowrap gap-2 pb-1 scrollbar-hide'>
              {metricesData.map((item) => (
                <div className='min-w-[23%]'>{renderCard(item)}</div>
              ))}
            </div>
          </div>
        ) : (
          <div className='flex items-center justify-between gap-3 w-full mt-2'>
            {metricesData.slice(0, 3).map((item) => renderCard(item))}
          </div>
        )}
      </div>
    </div>
  );
};
