import {
  Button,
  React,
  ArrowLeftIcon,
  useAutomationWorkflow,
  useAutomationLog,
  dayjs,
} from '../../../export';

interface Props {}

const AutomationLogHeader: React.FC<Props> = () => {
  const { automationNodes } = useAutomationWorkflow();
  const triggerNodeCode = automationNodes[0].data?.trigger_name || '';
  const defaultUserName = 'Anonymous User';
  const { selectedLogSummary } = useAutomationLog();
  const userName = selectedLogSummary?.customer_name || defaultUserName;
  const summaryTriggeredAt = Number(selectedLogSummary?.triggered_time);
  const formatedTriggerTime =
    summaryTriggeredAt && !isNaN(summaryTriggeredAt)
      ? dayjs.unix(summaryTriggeredAt).format('MMM D, YYYY [at] hh:mm A')
      : 'Invalid Date';

  return (
    <div className='flex items-center justify-between w-full px-4 py-3 bg-white border-b border-gray-200 ltr:border-l rtl:border-r h-[52px]'>
      <div className='flex items-center gap-3 justify-center'>
        <Button
          className='py-2 pl-2 pr-2 bg-green-100'
          icon={<ArrowLeftIcon className='h-5 text-green-500' />}
          onClick={() => {
            window.history.back();
          }}
        />
        <div className=' text-zinc-900 text-sm'>
          <p>
            <span className='font-semibold'>{userName}</span>
            <span className='text-zinc-500'>{' triggered '}</span>
            <span className='font-semibold'>{triggerNodeCode}</span>
          </p>
          <p className='text-zinc-500 font-medium text-xs'>
            {formatedTriggerTime}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AutomationLogHeader;
