import { useDispatch, useSelector } from 'react-redux';

export const useCustomAgentTools = () => {
  const availableToken = useSelector(
    (state: any) => state?.raven?.availableTokens
  );
  const usableToken = useSelector((state: any) => state?.raven?.usableTokens);
  const dispatch = useDispatch();
  const scrapeWebsiteContent = async ({ url }: { url: string }) => {
    if (!url?.length) {
      return {};
    }
    try {
      const payload = {
        domain: url,
        embed_model_id: '0677a6bb-a17a-71c5-8000-3fe9e28f008d',
        same_prefix_only: false,
        remove_params: true,
        skip_prefix_list: [],
      };
      const result = await dispatch?.raven?.fetchWebsitePageList(payload);
      return result;
    } catch (error) {
      return {};
    }
  };

  const handleTrainSingleDocument = async (documentId: number) => {
    return dispatch?.raven?.trainSingleDocument({ documentId });
  };
  const hasEnoughToken = (token: number) => {
    return usableToken >= token;
  };
  const updateUsableToken = (token: number) => {
    dispatch?.raven?.updateStateData({
      key: 'usableTokens',
      value: token,
    });
  };
  const updateAvailableToken = (token: number) => {
    dispatch?.raven?.updateStateData({
      key: 'availableTokens',
      value: token,
    });
  };
  return {
    availableToken,
    usableToken,
    updateAvailableToken,
    updateUsableToken,
    hasEnoughToken,
    scrapeWebsiteContent,
    handleTrainSingleDocument,
  };
};
