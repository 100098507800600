import {
  React,
  useActions,
  useSelector,
  ConditionHeader,
  XMarkIcon,
} from 'pages/automationWorkflow/export';
import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuGroup,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
} from 'libraryV2/ui/dropdown-menu';
import { IChildrenUiScreen } from 'pages/automationWorkflow/interface';

const AddTagOnOrder = () => {
  const [addTagValue, setAddTagValue] = React.useState('');
  // Extract required action functions from the custom hook
  const {
    updateConditionallyRenderingActionsUI,
    updateSelectedSaveActionsData,
  } = useActions();

  const { triggerInfo, selectedSaveActionsData } = useSelector(
    (state: any) => state.workflowAutomation
  );

  const { jinja, ...newTriggerInfo } = triggerInfo;

  const { currentScreen, extranalData } =
    selectedSaveActionsData?.eCommerce?.addTagsOnOrder;

  const updateAddTagOnOrderVariablePayload = (
    leftValue: string,
    triggerName: string,
    triggerRightValue: string,
    typeName: string
  ) => {
    const triggerInfoValueObj = triggerInfo[leftValue].filter(
      (item: any) => item?.name === triggerName
    );

    const updatedAddTagOnOrderPayload = {
      ...extranalData?.payload,
      [typeName]: [
        ...(extranalData?.payload[typeName] || []),
        {
          ...triggerInfoValueObj[0],
          name: triggerRightValue.replace(/{{|}}/g, ''),
          triggerValue: leftValue,
        },
      ],
      plain_tag: [
        ...extranalData?.payload?.plain_tag,
        `{{${triggerInfoValueObj[0]?.name}}}`,
      ],
    };

    const updatedActionData = {
      eCommerce: {
        addTagsOnOrder: {
          ...selectedSaveActionsData?.eCommerce?.addTagsOnOrder,
          extranalData: {
            ...selectedSaveActionsData?.eCommerce?.addTagsOnOrder?.extranalData,
            payload: updatedAddTagOnOrderPayload,
          },
        },
      },
      general: {
        delay: { valueOfDelay: null },
      },
      whatsapp: { selectedTemplate: null },
    };

    updateSelectedSaveActionsData(updatedActionData);
  };

  const updateAddTagDataOnOrder = (name: string, value: string) => {
    const updatedAddTagOnCustomerPayload = {
      ...extranalData?.payload,
      [name]: [...(extranalData?.payload[name] || []), value],
    };

    const updatedStoredData = {
      ...extranalData,
      payload: updatedAddTagOnCustomerPayload,
    };
    const updatedActionData = {
      eCommerce: {
        addTagsOnOrder: {
          ...selectedSaveActionsData?.eCommerce?.addTagsOnOrder,
          extranalData: updatedStoredData,
        },
      },
      general: {
        delay: { valueOfDelay: null },
      },
      whatsapp: { selectedTemplate: null },
    };
    updateSelectedSaveActionsData(updatedActionData);
  };

  const removeSelectedAddTagsOnOrder = (index: number, value: string) => {
    // Get the current add_tag array
    const addPlainTagsArray = extranalData?.payload?.plain_tag || [];
    const addVariableTagsArray = extranalData?.payload?.variable_tag || [];

    // Remove braces from the value
    const removeBraces = value.replace(/{{|}}/g, '');

    // Find the index of the item in add_variable array
    const variableIndex = addVariableTagsArray.findIndex(
      (item: any) => item?.name === removeBraces
    );

    // Check if the element at the specified index matches the given value
    if (addPlainTagsArray[index] === value) {
      // Remove the element from the add_tag array
      const updatedAddTagsArray = [
        ...addPlainTagsArray.slice(0, index),
        ...addPlainTagsArray.slice(index + 1),
      ];

      // Remove the element from the add_variable array if it exists
      const updatedAddVariablesArray =
        variableIndex !== -1
          ? [
              ...addVariableTagsArray.slice(0, variableIndex),
              ...addVariableTagsArray.slice(variableIndex + 1),
            ]
          : addVariableTagsArray;

      // Update the payload with the modified arrays
      const updatedAddTagPayload = {
        ...extranalData?.payload,
        plain_tag: updatedAddTagsArray,
        variable_tag: updatedAddVariablesArray,
      };

      const updatedActionData = {
        eCommerce: {
          addTagsOnOrder: {
            ...selectedSaveActionsData?.eCommerce?.addTagsOnOrder,
            extranalData: {
              ...selectedSaveActionsData?.eCommerce?.addTagsOnOrder
                ?.extranalData,
              payload: updatedAddTagPayload,
            },
          },
        },
        general: {
          delay: { valueOfDelay: null },
        },
        whatsapp: { selectedTemplate: null },
      };
      updateSelectedSaveActionsData(updatedActionData);
    }
  };

  const renderConditionallyAddTagsTypeView = (
    addTagData: IChildrenUiScreen
  ) => {
    switch (addTagData?.type) {
      case 'Tag_textInput':
        return (
          <div className='p-4 bg-white rounded mb-6'>
            <p className='text-gray-600 font-medium text-sm mb-1'>
              {addTagData?.label || ''}{' '}
              {addTagData?.required && (
                <span className='text-red-400 text-sm'>*</span>
              )}
            </p>
            <input
              value={addTagValue}
              type={addTagData?.['input-type']}
              className='w-full py-2 px-3 shadow-sm border border-gray-300 rounded-md text-sm text-gray-700 focus:ring-primary focus:border-primary focus:outline-none'
              placeholder='Value'
              onChange={(value: React.ChangeEvent<HTMLInputElement>) =>
                setAddTagValue(value.target.value)
              }
              onKeyDown={(value: any) => {
                if (value.key === 'Enter' && value.target.value.length !== 0) {
                  updateAddTagDataOnOrder(addTagData.name, addTagValue);
                  setAddTagValue('');
                }
              }}
            />
            {Array.isArray(extranalData?.payload?.[addTagData.name]) && (
              <div className='grid grid-cols-4 gap-3 mt-2'>
                {extranalData?.payload?.[addTagData.name].map(
                  (tagValue: string, index: number) => (
                    <div
                      key={index}
                      className='text-gray-800 text-sm font-medium bg-gray-100 rounded-xl px-2 py-1'
                    >
                      <div className='flex justify-between items-center'>
                        <div className='pr-2'>{tagValue}</div>
                        <div
                          className='cursor-pointer'
                          onClick={() =>
                            removeSelectedAddTagsOnOrder(index, tagValue)
                          }
                        >
                          <XMarkIcon className='text-gray-400 w-4 h-4' />
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
            <p className='text-gray-500 text-sm'>{addTagData?.description}</p>
          </div>
        );
      case 'variable_dropdown_from_trigger':
        return (
          <div className='p-4 bg-white rounded mb-6'>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div className='py-2 px-4 text-gray-500 text-sm w-full text-left bg-white border rounded-md cursor-pointer focus:outline-none'>
                  <span className='px-2 py-1 text-sm font-regular text-gray-500'>
                    Add Variable
                  </span>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                align='start'
                className='shadow-lg bg-white z-30 w-64 divide-y divide-gray-100 rounded-md'
              >
                <DropdownMenuGroup asChild>
                  <>
                    {Object.keys(newTriggerInfo).map(
                      (item: string, categoryIndex: number) => (
                        <DropdownMenuSub key={categoryIndex}>
                          <DropdownMenuSubTrigger className='w-full border-none pt-0'>
                            <div
                              className={`${
                                Object.keys(newTriggerInfo).length - 1 ===
                                categoryIndex
                                  ? 'border-none pb-0'
                                  : 'border-b pb-2'
                              } w-full`}
                            >
                              <div
                                className={` w-full flex justify-between items-center cursor-pointer hover:bg-gray-100 py-1 text-gray-700 rounded-md`}
                              >
                                <h3 className='ml-3 font-medium text-base'>
                                  {item}
                                </h3>
                              </div>
                            </div>
                          </DropdownMenuSubTrigger>
                          <DropdownMenuPortal>
                            <DropdownMenuSubContent className='bg-white z-40 shadow-md h-full max-h-96 overflow-y-auto mb-3'>
                              {newTriggerInfo[item].length !== 0 &&
                                newTriggerInfo[item].map(
                                  (triggerValue: any, childIndex: number) => (
                                    <DropdownMenuItem
                                      key={childIndex}
                                      className='pt-0'
                                    >
                                      <div
                                        className='border-b p-2 pt-1 w-full'
                                        onClick={() => {
                                          updateAddTagOnOrderVariablePayload(
                                            item,
                                            triggerValue?.name,
                                            `{{${triggerValue?.name}}}`,
                                            addTagData.name
                                          );
                                        }}
                                      >
                                        <div
                                          className={` flex justify-between items-center px-4 py-2 cursor-pointer hover:bg-gray-100 rounded-md`}
                                        >
                                          <div className='text-gray-600'>{`{{${triggerValue?.name}}}`}</div>
                                        </div>
                                      </div>
                                    </DropdownMenuItem>
                                  )
                                )}
                              {newTriggerInfo[item].length === 0 && (
                                <div className='p-2'>
                                  <div
                                    className={` bg-white flex justify-between items-center px-4 py-2 cursor-pointer hover:bg-gray-100 rounded-md`}
                                  >
                                    <div className='text-gray-600'>
                                      No Variable found
                                    </div>
                                  </div>
                                </div>
                              )}
                            </DropdownMenuSubContent>
                          </DropdownMenuPortal>
                        </DropdownMenuSub>
                      )
                    )}
                  </>
                </DropdownMenuGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <ConditionHeader
        title={currentScreen?.title}
        previousUIAction={() =>
          updateConditionallyRenderingActionsUI('actionElement')
        }
      />
      <div
        className='bg-gray-50 p-4 overflow-auto'
        style={{ height: 'calc(100vh - 196px)' }}
      >
        {currentScreen.layout.children[0].children.map(
          (item: IChildrenUiScreen, index: number) => (
            <div key={index}>{renderConditionallyAddTagsTypeView(item)}</div>
          )
        )}
      </div>
    </div>
  );
};

export default AddTagOnOrder;
