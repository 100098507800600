import { z } from 'zod';
export const dummyDocumentdata = [
  {
    name: 'Tech design requirements.pdf',
    size: 2000000,
    type: 'pdf',
    isTrained: false,
    tokenRequired: 20,
    id: 234234232302389,
  },
  {
    name: 'Project Proposal.docx',
    size: 1500000,
    type: 'docx',
    isTrained: true,
    tokenRequired: 15,
    id: 234234232302390,
  },
  {
    name: 'User Guide.txt',
    size: 500000,
    type: 'txt',
    isTrained: false,
    tokenRequired: 5,
    id: 234234232302391,
  },
  {
    name: 'Presentation Slides.pptx',
    size: 3000000,
    type: 'pdf',
    isTrained: true,
    tokenRequired: 25,
    id: 234234232302392,
  },
  {
    name: 'Data Analysis Report.xlsx',
    size: 2500000,
    type: 'doc',
    isTrained: false,
    tokenRequired: 30,
    id: 234234232302393,
  },
  {
    name: 'Meeting Notes.pdf',
    size: 800000,
    type: 'pdf',
    isTrained: true,
    tokenRequired: 10,
    id: 234234232302394,
  },
];

export const baseToolSchema = z.object({
  id: z.string(),
  type: z.enum(['api', 'document', 'website']),
  isOpen: z.boolean(),
});

const headerSchema = z.object({
  key: z.string().min(1, 'Key is required'),
  value: z.string().min(1, 'Value is required'),
});

export const apiToolSchema = baseToolSchema.extend({
  type: z.literal('api'),
  data: z.object({
    method: z.enum(['GET', 'POST', 'PUT', 'DELETE']),
    url: z.string().url('Invalid URL format'),
    headers: z.array(headerSchema),
    requestData: z.string().optional(),
  }),
});

export const documentToolSchema = baseToolSchema.extend({
  type: z.literal('document'),
  data: z.object({
    title: z.string().min(1, 'Title is required'),
    modelUsed: z.string().min(1, 'This field is required'),
    description: z.string().min(1, 'Description is required'),
    documents: z.array(z.any()).min(1, 'This field is required'),
  }),
});

export const websiteToolSchema = baseToolSchema.extend({
  type: z.literal('website'),
  data: z.object({
    title: z.string().min(1, 'Title is required'),
    description: z.string().min(1, 'Description is required'),
    url: z.string().url('Invalid URL format'),
    pages: z.array(z.string()).min(1, 'Select at least one page.'),
  }),
});

// Combined schema
const toolSchema = z.discriminatedUnion('type', [
  apiToolSchema,
  documentToolSchema,
  websiteToolSchema,
]);
// final form schema
export const customAgentformSchema = z.object({
  tools: z.array(toolSchema),
});

export type CustomAgentToolFormData = z.infer<typeof customAgentformSchema>;
