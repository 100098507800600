import { FC } from 'react';

interface RavenKnowleadgeProps {}

export const RavenKnowleadge: FC<RavenKnowleadgeProps> = () => {
  return (
    <>
      {/* will be added soon once the tutorials are ready */}
      <h1>Watch how to master Raven</h1>
    </>
  );
};
