import { useParams, Link } from '@reach/router';
import { Button } from 'libraryV2/ui/button';
import { SquarePen } from 'lucide-react';
import { FC } from 'react';

interface AvailableAgentsProps {}

interface ISingleAavailableAgentCard {
  title: string;
  description: string;
  onActive: () => void;
}

const SingleAavailableAgentCard: FC<ISingleAavailableAgentCard> = ({
  title = '',
  description = '',
  onActive,
}) => {
  return (
    <div
      className='flex flex-col gap-2 max-w-[300px]'
      style={{
        border: 'solid 1px transparent',
        borderRadius: '16px',
        backgroundImage: `linear-gradient(white,white), linear-gradient(244.35deg, #FCD1F8 0%, #DAEEFC 100.74%)`,
        backgroundOrigin: 'border-box',
        backgroundClip: 'content-box, border-box',
      }}
    >
      <div className='p-5 flex flex-col gap-4'>
        <div className='space-y-2'>
          <h4 className='text-xl text-textPrimary leading-5 font-semibold'>
            {title}
          </h4>
          <p className='text-sm text-textSecondary leading-5 font-medium'>
            {description}
          </p>
        </div>
        <Button onClick={onActive} className='w-fit h-8'>
          Active
        </Button>
      </div>
    </div>
  );
};

export const AvailableAgents: FC<AvailableAgentsProps> = () => {
  const { projectId } = useParams();
  return (
    <div className='flex-1 p-6 space-y-5 hidden'>
      <div className='flex justify-between'>
        <h1 className='text-textPrimary leading-6 font-bold text-xl'>
          Available Agents
        </h1>
        {/* @ts-ignore */}
        <Link
          to={`/projects/${projectId}/raven/create-custom-agent`}
          className='text-textPrimary flex items-center px-4 h-8 gap-2 border border-border font-medium text-sm hover:bg-primary hover:text-white rounded-md'
        >
          <SquarePen className='w-4 h-4' />
          <span>Create Custom Agents</span>
        </Link>
      </div>
      <div className='flex gap-3 flex-wrap'>
        <SingleAavailableAgentCard
          title='Chatbot Agent'
          description='An intelligent agent designed to handle tickets with efficiency and a human-like touch.'
          onActive={() => null}
        />
      </div>
    </div>
  );
};
