import { Button } from 'libraryV2/ui/button';
import { useCustomAgentTools } from 'pages/raven/hooks/useCustomAgentTools';
import { FC, useState } from 'react';
import RavenTrainButtonBg from 'pages/raven/assets/RavenTrainButtonBg.svg';
import { cn } from 'libraryV2/utils';
interface DocumentTrainButtonProps {
  document: any;
  updateDocumentTrainStatus: (documentId: number, status?: boolean) => void;
}

export const DocumentTrainButton: FC<DocumentTrainButtonProps> = ({
  document,
  updateDocumentTrainStatus,
}) => {
  const {
    availableToken,
    usableToken,
    hasEnoughToken,
    updateUsableToken,
    updateAvailableToken,
  } = useCustomAgentTools();
  const tokenRequired = Number(document?.tokenRequired);
  const isTrained = !!document?.isTrained;
  const [isTraining, setIsTraining] = useState(false);
  const { handleTrainSingleDocument } = useCustomAgentTools();

  const shouldHide =
    availableToken < tokenRequired && usableToken < tokenRequired;

  const shouldDisable =
    availableToken >= tokenRequired && usableToken < tokenRequired;

  const handleTrainDocument = async () => {
    console.log(`training token `, document, tokenRequired, '💎', usableToken);
    if (!hasEnoughToken(tokenRequired)) {
      return;
    }
    try {
      updateUsableToken(usableToken - tokenRequired);
      setIsTraining(true);
      await handleTrainSingleDocument(document?.id);
      updateDocumentTrainStatus(document?.id, true);
      updateAvailableToken(availableToken - tokenRequired);
    } catch (error) {
      // on error revert usable token
      updateUsableToken(usableToken + tokenRequired);
    } finally {
      setIsTraining(false);
    }
  };

  if (isTrained) {
    return (
      <div className='h-6 flex items-center text-textPrimary bg-background-hover px-2.5 rounded-md'>
        Trained
      </div>
    );
  }

  if (isTraining) {
    return (
      <div
        style={{
          backgroundImage: `url(${RavenTrainButtonBg})`,
          backgroundSize: 'cover',
        }}
        className='h-6 flex items-center text-textPrimary bg-background-hover px-2.5 rounded-md'
      >
        <div className='loading-dots'></div>
      </div>
    );
  }

  return (
    <Button
      type='button'
      onClick={async () => handleTrainDocument()}
      style={{
        backgroundImage: `url(${RavenTrainButtonBg})`,
        backgroundSize: 'cover',
      }}
      size={'sm'}
      disabled={shouldDisable}
      className={cn('h-6', {
        hidden: shouldHide,
      })}
    >
      Train
    </Button>
  );
};
