import {
  toast,
  useDispatch,
  useSelector,
  UtilityContents,
  UtilityFunctions,
} from 'pages/automationWorkflow/export';
import {
  NodeType,
  IUserFlowTableDataTypes,
  toggleUserActionFlowTypes,
  EcommerceStore,
  IUserFlowDataRequestParam,
} from 'pages/automationWorkflow/interface';
import { generateQueryString } from 'pages/automationWorkflow/utility/function';

const useAutomationDashboard = () => {
  const dispatch = useDispatch();
  const { platformList, selectedProject } = useSelector(
    (state: any) => state.dashboard
  );
  const {
    integratedEcommerceList,
    userActionFlowList,
    selectedUserActionFlowData,
    createdTotalWorkflowAutomation,
    automationMetricsData,
    workflowDashboardTableOffset,
  } = useSelector((state: any) => state.workflowAutomation);

  // Destructure required state loading from the workflowAutomation
  const {
    fetchUserActionFlowList: isFetchUserActionFlowListLoading,
    updateSelectedAutomationFlow: isUpdateSelectedAutomationFlowLoading,
  } = useSelector((state: any) => state.loading.effects.workflowAutomation);

  const fetchIntegratedEcommerceData = async (): Promise<boolean> => {
    const { id } = selectedProject;
    await dispatch.workflowAutomation.fetchMultiEcommerceData(id);
    return true;
  };

  const getConnectedEcommerce = (id: number) => {
    if (!integratedEcommerceList || integratedEcommerceList?.length < 1)
      return null;

    return (
      integratedEcommerceList.find(
        (ecommerce: EcommerceStore) => ecommerce?.alice_store_id === id
      ) ?? null
    );
  };

  const fetchUserActionFlowData = async (
    requestParam: IUserFlowDataRequestParam
  ) => {
    const { id, currency_code } = selectedProject;

    const param = {
      project_id: id,
      offset: requestParam?.offset,
      limit:
        requestParam?.limit ||
        UtilityContents?.UserActionFlowGetterApiDataLimit,
      currency_code: currency_code,
      start_datetime: requestParam.from,
      end_datetime: requestParam?.to,
      sources: requestParam.sources,
      trigger_types: requestParam.triggerTypes,
      status: requestParam.status,
      cache_off: requestParam.cacheOff,
      user_flow_name: requestParam.userFlowName,
    };
    const queryString = generateQueryString(param);
    await dispatch.workflowAutomation.fetchUserActionFlowList({
      queryString,
      projectId: id,
    });
  };

  const updateSelectedUserActionFlowData = (
    flowData: IUserFlowTableDataTypes | null
  ) => {
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedUserActionFlowData',
      value: flowData && Object.keys(flowData).length ? flowData : {},
    });
  };

  const resetPrimitiveStateData = () => {
    updateSelectedUserActionFlowData(null);
    updateWorkflowModalVisibility(null);
  };

  const fetchSelectedActionFlowData = (flowIdInfo: IUserFlowTableDataTypes) => {
    dispatch.workflowAutomation.fetchSelectedActionFlow(flowIdInfo?.id);
  };

  const cloneUserActionFlow = async (flowIdInfo: IUserFlowTableDataTypes) => {
    try {
      const getRes = await dispatch.workflowAutomation.cloneUserWorkflow({
        flowId: flowIdInfo.id,
        projectId: selectedProject?.id,
        trigger_match_unique_ref: flowIdInfo.trigger_match_unique_ref,
      });
      if (getRes?.success === true) {
        dispatch.workflowAutomation.updateUserActionFlowList([
          getRes?.dataSource,
          ...userActionFlowList,
        ]);
        dispatch.workflowAutomation.updatePrimitiveStateData({
          key: 'createdTotalWorkflowAutomation',
          value: createdTotalWorkflowAutomation + 1,
        });
        toast({
          title: 'Workflow duplicated successfully',
          description: 'Created new workflow successfully',
        });
      }
    } catch (err) {
      toast({
        title: 'Failed to duplicate workflow',
        description: 'Please try again after some time',
        toastType: 'destructive',
      });
      console.log(err);
    }
  };

  const editSelectedUserWorkflowActivation = async (
    payload: toggleUserActionFlowTypes
  ) => {
    try {
      dispatch.workflowAutomation.updateUserActionFlowList(
        userActionFlowList?.map((item: any) =>
          item?.id === payload?.flowId
            ? {
                ...item,
                is_active: payload?.is_active,
              }
            : item
        )
      );
      const getUpdateRes =
        await dispatch.workflowAutomation.updateSelectedUserWorkflowInfo({
          payload,
          projectId: selectedProject?.id,
        });
      if (getUpdateRes && Object.keys(getUpdateRes?.dataSource).length !== 0) {
        toast({
          title: 'Workflow updated successfully',
          description: 'Workflow status changed successfully',
        });
      }
    } catch (err) {
      dispatch.workflowAutomation.updateUserActionFlowList(
        userActionFlowList?.map((item: any) =>
          item?.id === payload?.flowId
            ? {
                ...item,
                is_active: !payload?.is_active,
              }
            : item
        )
      );
      toast({
        title: 'Workflow update failed',
        description: 'Please try again after some time',
        toastType: 'destructive',
      });
      console.log(err);
    }
  };

  const handleViewSelectedAutomationHistories = (
    updateData: IUserFlowTableDataTypes
  ) => {
    updateSelectedUserActionFlowData(updateData);
  };

  //Handle Whatsapp Template
  const handleWhatsappTemplate = async () => {
    const tempChannelList = platformList
      .filter((channel: any) => channel?.platform_type === 'whatsapp_bsp')
      .map((channel: any) => ({
        label: channel?.title,
        id: channel?.id,
      }));

    dispatch.workflowAutomation.updateChannelList(tempChannelList);
  };

  const handleSelectedEcommerce = async (
    flowIdInfo: IUserFlowTableDataTypes
  ) => {
    const selectedEcommerce: EcommerceStore | null = isNaN(
      flowIdInfo?.trigger_match_unique_ref
    )
      ? null
      : getConnectedEcommerce(flowIdInfo?.trigger_match_unique_ref);

    if (!selectedEcommerce) {
      toast({
        title: 'No Active Ecommerce Found',
        description: 'Please connect an ecommerce to activate',
      });
      return;
    }

    await dispatch.workflowAutomation.updateSelectedEcommerce(
      selectedEcommerce
    );
  };

  const handleUpdatedSelectedUserFlowData = async (
    flowIdInfo: IUserFlowTableDataTypes
  ) => {
    const selectedEcommerce: EcommerceStore | null = isNaN(
      flowIdInfo?.trigger_match_unique_ref
    )
      ? null
      : getConnectedEcommerce(flowIdInfo?.trigger_match_unique_ref);

    if (!selectedEcommerce) {
      toast({
        title: 'No Active Ecommerce Found',
        description: 'Please connect an ecommerce to activate',
      });
      return;
    }

    await dispatch.workflowAutomation.updateSelectedEcommerce(
      selectedEcommerce
    );

    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'isAutomationEditable',
      value: true,
    });
    handleWhatsappTemplate();

    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedTriggerTab',
      value: selectedEcommerce?.ecommerce_type,
    });
    const selectedAutomationNodeData =
      await dispatch.workflowAutomation.fetchSelectedActionFlow({
        flowId: flowIdInfo?.id,
        projectId: selectedProject?.id,
      });
    if (selectedAutomationNodeData.length) {
      const selectedTriggerData = selectedAutomationNodeData.filter(
        (workFlowData: any) => workFlowData?.type === NodeType.TRIGGER
      );
      dispatch.workflowAutomation.updatePrimitiveStateData({
        key: 'selectedTrigger',
        value: selectedTriggerData[0]?.data,
      });
      //Fetch Sepecific Executor Data
      if (selectedTriggerData.length !== 0) {
        dispatch.workflowAutomation.fetchAutomationViewData({
          selectedEcommerce,
          projectId: selectedProject?.id,
        });
        dispatch.workflowAutomation.fetchSelectedTriggerDetails({
          ecommerceName: selectedEcommerce,
          selectedTriggerCode: selectedTriggerData[0]?.data?.trigger_code,
          projectId: selectedProject?.id,
        });
      }
    }
  };
  const handleDeleteWorkflowConfirmation = (
    updateData: IUserFlowTableDataTypes
  ) => {
    updateWorkflowModalVisibility('Delete');
    updateSelectedUserActionFlowData(updateData);
  };

  const handleDeleteSelctedActionWorkflow = async () => {
    try {
      await dispatch.workflowAutomation.deleteSelectedUserActionWorkflow({
        flowId: selectedUserActionFlowData?.id,
        projectId: selectedProject?.id,
        trigger_match_unique_ref:
          selectedUserActionFlowData?.trigger_match_unique_ref ?? '',
      });

      // If the deletion is successful, update UI and userActionFlowList
      let tempCreatedUserWorkflowList: any[] = [];
      for (let i = 0; i < userActionFlowList.length; i++) {
        if (selectedUserActionFlowData?.id !== userActionFlowList[i].id) {
          tempCreatedUserWorkflowList.push(userActionFlowList[i]);
        }
      }
      dispatch.workflowAutomation.updateUserActionFlowList(
        tempCreatedUserWorkflowList
      );
      updateWorkflowModalVisibility(null);
    } catch (err: any) {
      // Handle errors
      updateWorkflowModalVisibility(null);
    }
  };

  const updateWorkflowModalVisibility = (name: string | null) => {
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'selectedUserWorkflowMode',
      value: name !== null ? name : null,
    });
  };

  const updateWorkflowPrimitiveDataAfterRename = (
    flowInfo: IUserFlowTableDataTypes
  ) => {
    updateWorkflowModalVisibility('Rename');
    updateSelectedUserActionFlowData(flowInfo);
  };

  const handleSelectedUserWorkflowName = (value: string) => {
    const updatedSelectedUserActionFlowData = {
      ...selectedUserActionFlowData,
      name: value,
    };
    updateSelectedUserActionFlowData(updatedSelectedUserActionFlowData);
  };

  const editSelectedUserWorkflowRename = async () => {
    try {
      const payload = {
        flowId: selectedUserActionFlowData?.id,
        name: selectedUserActionFlowData?.name,
        trigger_code: selectedUserActionFlowData?.trigger_code,
        is_active: selectedUserActionFlowData?.is_active,
        trigger_match_unique_ref:
          selectedUserActionFlowData?.trigger_match_unique_ref ?? '',
      };
      const getUpdateRes =
        await dispatch.workflowAutomation.updateSelectedUserWorkflowInfo({
          payload,
          projectId: selectedProject?.id,
        });

      if (getUpdateRes && Object.keys(getUpdateRes?.dataSource).length !== 0) {
        toast({
          title: 'Workflow updated successfully',
          description: 'Workflow renamed successfully',
        });
        const updatedData = UtilityFunctions.updateUserWorkflowListAfterRename(
          userActionFlowList,
          getUpdateRes
        );
        dispatch.workflowAutomation.updateUserActionFlowList(updatedData);
      }
      updateWorkflowModalVisibility(null);
    } catch (err) {
      console.log(err);
      toast({
        variant: 'destructive',
        title: 'Workflow update failed',
        description: 'Please try agin after some time',
      });
      resetPrimitiveStateData();
    }
  };

  const updateWorkflowDashboardTableOffset = (data: number) => {
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'workflowDashboardTableOffset',
      value: data,
    });
    // fetchUserActionFlowData(data);
  };

  return {
    createdTotalWorkflowAutomation,
    handleSelectedEcommerce,
    fetchIntegratedEcommerceData,
    handleViewSelectedAutomationHistories,
    fetchUserActionFlowData,
    fetchSelectedActionFlowData,
    handleSelectedUserWorkflowName,
    updateWorkflowModalVisibility,
    handleDeleteWorkflowConfirmation,
    handleUpdatedSelectedUserFlowData,
    isFetchUserActionFlowListLoading,
    handleDeleteSelctedActionWorkflow,
    editSelectedUserWorkflowActivation,
    updateWorkflowDashboardTableOffset,
    updateWorkflowPrimitiveDataAfterRename,
    editSelectedUserWorkflowRename,
    isUpdateSelectedAutomationFlowLoading,
    getConnectedEcommerce,
    cloneUserActionFlow,
    automationMetricsData,
    workflowDashboardTableOffset,
    selectedProject,
    updateSelectedUserActionFlowData,
  };
};

export default useAutomationDashboard;
