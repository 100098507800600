import React, { useEffect } from 'react';
import config from './config';
import Button from '../library/button';

const appId = config.misc.facebookAppId();

const WhatsAppLogin = ({
  handleWhatsappToken,
  hasGuidelines = true,
  buttonText = 'Create Whatsapp Channel',
  currentDomain = '',
}) => {
  // const handleResponse

  const defaultConfiguration = {
    config_id: '438409838952403',
    response_type: 'code',
    override_default_response_type: true,
    extras: {
      featureType: '',
      setup: {},
      sessionInfoVersion: '3',
    },
  }

  const omniHubPartnerConfiguration = {
    config_id: '438409838952403',
    response_type: 'code',
    override_default_response_type: true,
    extras: {
      featureType: '',
      setup: {
        solutionID: '375280538711715',
      },
      sessionInfoVersion: '3',
    },
  }

  const getConfiguration = () => {
    switch (currentDomain) {
      case 'app.iomnihub.ai':
        return omniHubPartnerConfiguration;
      default:
        return defaultConfiguration;
    }
  };

  const wabaId = React.useRef('');
  const phoneNumberId = React.useRef('');

  const setFbAsyncInit = () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: appId,
        cookie: true, // enable cookies
        xfbml: true, // parse social plugins on this page
        version: 'v16.0', //Graph API version
      });
    };
  };
  const loadSdkAsynchronously = () => {
    ((d, s, id) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = `https://connect.facebook.net/en_US/sdk.js`;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  };

  // Add event listener for session logging
  const messageEventListener = (event) => {
    if (event.origin !== 'https://www.facebook.com' && event.origin !== 'https://web.facebook.com') return;
    try {
      const eventData = JSON.parse(event.data);
      if (eventData.type === 'WA_EMBEDDED_SIGNUP') {
        wabaId.current = eventData.data.waba_id;
        phoneNumberId.current = eventData.data.phone_number_id;
      }
    } catch {
      console.log('failed message event: ', event.data);
    }
  };

  useEffect(() => {
    setFbAsyncInit();
    loadSdkAsynchronously();
    window.addEventListener('message', messageEventListener);
    // Cleanup on unmount
    return () => {
      window.removeEventListener('message', messageEventListener);
    };
  },[]);

  const launchWhatsAppSignup = () => {
    // Conversion tracking code
    window.fbq &&
      window.fbq('trackCustom', 'WhatsAppOnboardingStart', {
        appId: appId,
        feature: 'whatsapp_embedded_signup',
      });

    // Launch Facebook login
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          const code = response.authResponse.code;
          const signupData = {
            'wabaId': wabaId?.current,
            'phoneNumberId': phoneNumberId?.current,
            'code': code,
          }
          try {
            handleWhatsappToken(signupData);
          } catch (err) {
            console.log(err.response);
          }
          //Use this token to call the debug_token API and get the shared WABA's ID
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      getConfiguration()
    );
  };

  return (
    <>
      {hasGuidelines && (
        <div className='p-6'>
          <h1 className='text-base font-medium'>
            Before you apply for verification:
          </h1>
          <p className='mt-3 ml-6 text-base'>
            You may need to provide the following information for your WhatsApp
            Business Channel and have them ready to use.
          </p>
          <ul className='mt-3 ml-6 text-base text-gray-800 list-disc list-outside'>
            <li className='my-2'>
              Your business’s display name, the one which will be shown to your
              customers.
            </li>
            <li className='my-2'>Legal business name of your business.</li>
            <li className='my-2'>Official address of your business.</li>
          </ul>
          <br />
          <h1 className='text-base font-medium'>
            Create WhatsApp Business Channel
          </h1>
          <p className='mt-3 ml-6 text-base'>
            You’ll be redirected to Facebook Business Manager. Complete all the
            steps they provide. After completion, apply for verification from
            here.
          </p>
          <p className='mt-3 ml-6 text-base'>
            If you need help regarding any step, you may contact support. Our
            support team will guide you through the process.
          </p>
        </div>
      )}
      <div className='flex items-center justify-center'>
        <Button
          className='flex justify-center'
          intent='primary'
          onClick={() => launchWhatsAppSignup()}
        >
          {buttonText}
        </Button>
      </div>
    </>
  );
};

export default WhatsAppLogin;
