import { toast } from 'libraryV2/ui/use-toast';
import {
  format,
  DataTable,
  useEffect,
  DataLabHeader,
  UTILITY_METHODS,
  createDynamicColumns,
  Plus,
  Button,
  React,
  DateRange,
} from './../export';

import useDatalab from '../hooks/useDatalab';
import notFoundImage from '../../../assets/icons/emptyPageIcon.svg';
import useTranslation from 'components/customHooks/useTranslation';
import { navigate } from '@reach/router';
import { DatalabTableLoader } from './component/DatalabTableLoader';
import { Upload, Download } from 'lucide-react';
import DatalabSideSheet from './component/DatalabSideSheet';
import { IConvertDate, IDataLabField, SampleRow } from '../interface';
import {
  convertTimestampToDate,
  convertToCSV,
  downloadCSV,
  generateSampleData,
} from '../utils';

const DataLabTable = () => {
  const {
    isLoading,
    labId,
    projectId,
    lab_fields,
    pageChange,
    clearState,
    datalabEntries,
    hasReadAccess,
    hasWriteAccess,
    hasDeleteAccess,
    isDatalabLoading,
    deleteBulkDatalabList,
    importDatalabFile,
    fetchDataLabInfo,
    fetchDatalabInfoData,
    fetchDatalabEntriesData,
    selectedDatalabInfo,
    isFilterApplied,
    isSearchApplied,
    updateDateRange,
    totalRowCount,
  } = useDatalab();
  const fileInputRef = React.useRef<HTMLInputElement | null>(null);
  const [isSheetOpen, setIsSheetOpen] = React.useState(false);
  const [defaultDateRange, setDefaultDateRange] = React.useState<DateRange | undefined>({
    from: undefined,
    to: undefined,
  });
  const { t } = useTranslation();

  useEffect(() => {
    getEntries();
    return clearState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getEntries = async () => {
    try {
      const res = await fetchDatalabInfoData();
      if (res.success) {
        await getDatalabEntriesData(
          convertTimestampToDate(res?.data?.created_at)
        );
      }
    } catch (err) {
      toast({ variant: 'destructive', description: 'Failed to fetch!' });
    }
  };
  const getDatalabEntriesData = async (date: IConvertDate) => {
    let today = UTILITY_METHODS.getThirtyDaysDateRange();
    updateDateRange(date.convertedDate, today.initialToDate);
    setDefaultDateRange({ from: date.convertedDate, to: today.initialToDate});
    let start = format(date.convertedDate, 'yyyy-MM-dd');
    let end = format(today.initialToDate, 'yyyy-MM-dd');
    let dateRange = { start: start, end: end };
    await fetchDatalabEntriesData({
      dateRange,
      pageChange,
    });
  };

  const handleImportClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const success = await importDatalabFile(labId, file);
      if (success) {
        fetchDataLabInfo();
      }
    }
  };

  const handleNewDataClick = () => {
    setIsSheetOpen(true);
  };

  const toggleSheetOpen = () => {
    setIsSheetOpen(!isSheetOpen);
  };

  const handleDownloadSampleDataClick = () => {
    const labFields: IDataLabField[] = selectedDatalabInfo.lab_fields;
    const sampleRowData: SampleRow[] = generateSampleData(labFields);
    const csv = convertToCSV(sampleRowData);
    downloadCSV(csv, 'Demo Datalab Data.csv');
  };

  const addActionsColumn = false;
  const columns = createDynamicColumns(
    labId,
    datalabEntries,
    lab_fields,
    hasReadAccess,
    hasWriteAccess,
    hasDeleteAccess,
    deleteBulkDatalabList,
    addActionsColumn
  );

  const renderNoInputFound = () => {
    return (
      <div className='flex flex-col items-center justify-center  text-center min-h-[93vh]'>
        <img src={notFoundImage} alt='emptySearchIcon' className='h-24 mb-4' />
        <h3 className='mb-2 text-lg font-semibold text-textPrimary'>
          {t('No Inputs Found')}
        </h3>
        <span className='mb-2 text-zinc-500 font-medium'>
          {t('To add and view the data, you need to create inputs first.')}
        </span>
        <Button
          onClick={() =>
            navigate(`/projects/${projectId}/data-lab/edit/${labId}`)
          }
          className='mt-4 flex items-center font-normal bg-primary text-white px-4 py-2 rounded-md hover:bg-primary'
        >
          <Plus className='w-4 h-4 text-white ltr:mr-2 rtl:ml-2 mr-2' />
          {t('Create New Inputs')}
        </Button>
      </div>
    );
  };

  const renderNoEntriesFound = () => {
    return (
      <div className='flex flex-col items-center justify-center  text-center min-h-[93vh]'>
        <img src={notFoundImage} alt='emptySearchIcon' className='h-24 mb-4' />
        <h3 className='mb-2 text-lg font-semibold text-textPrimary'>
          {t('No Entries Available')}
        </h3>
        <span className='mb-2 text-zinc-500 font-medium'>
          {t('There are no entries available for the selected time range.')}
        </span>
        <span className='mb-2 text-zinc-500 font-medium'>
          {t('Please adjust the time filter or add new data to get started.')}
        </span>
        <div className='flex items-center justify-center mt-3'>
          <div>
            <input
              type='file'
              accept='.csv'
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <Button
              // size='sm'
              variant='outline'
              disabled={!hasWriteAccess}
              onClick={handleImportClick}
              className={
                hasWriteAccess ? 'flex gap-2' : 'cursor-not-allowed flex gap-2'
              }
            >
              <Upload className='w-4 h-4' />
              <span className='font-medium text-xs text-textPrimary leading-5'>
                Import
              </span>
            </Button>
          </div>
          <div>
            <Button
              onClick={handleNewDataClick}
              disabled={!hasWriteAccess}
              className='ml-2 flex items-center font-normal bg-primary text-white px-4 rounded-md hover:bg-primary'
            >
              <Plus className='w-4 h-4 text-white ltr:mr-2 rtl:ml-2 mr-2' />
              {t('Create New Entry')}
            </Button>
          </div>
        </div>
        <div
          className='flex items-center mt-3 text-link cursor-pointer'
          onClick={handleDownloadSampleDataClick}
        >
          <Download className='w-3.5 h-3.5 mr-1' />
          {t('Download sample data')}
        </div>
      </div>
    );
  };

  const isRenderNoEntriesFound = React.useMemo(() => {
    return (
      datalabEntries.length <= 0 &&
      totalRowCount <= 0 &&
      !isFilterApplied &&
      !isSearchApplied &&
      !isLoading
    );
  }, [
    datalabEntries,
    isFilterApplied,
    isSearchApplied,
    isLoading,
    totalRowCount,
  ]);

  const renderNewDatalabList = () => (
    <>
      <div className='relative flex flex-col'>
        <div className='sticky top-0 z-10 bg-white'>
          <DataLabHeader defaultDateRange={defaultDateRange} />
        </div>
        <div className='flex-grow  p-4 bg-white ml-[1px] min-h-[93vh]'>
          {isDatalabLoading ? (
            <DatalabTableLoader />
          ) : lab_fields?.length <= 0 ? (
            renderNoInputFound()
          ) : isRenderNoEntriesFound ? (
            renderNoEntriesFound()
          ) : (
            <DataTable data={datalabEntries} columns={columns} />
          )}
        </div>
      </div>
      <DatalabSideSheet
        isOpen={isSheetOpen}
        datalabEntries={{}}
        datalabShape={selectedDatalabInfo}
        header='Enter new Data'
        mode='CREATE'
        onClose={toggleSheetOpen}
      />
    </>
  );

  return renderNewDatalabList();
};

export default DataLabTable;
