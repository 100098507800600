import useTranslation from 'components/customHooks/useTranslation';
import { Row } from '@tanstack/react-table';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'libraryV2/ui/dropdown-menu';
import { Button } from 'libraryV2/ui/button';
import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { EyeIcon, HistoryIcon } from 'lucide-react';
import { IWhatsappTemplateAnalyticsData } from 'pages/reportingV2/interface';
import useWhatsappMetrics from '../../../hooks/useWhatsappMetrics';

interface TemplateOverviewTableRowActionProps<TData> {
  row: Row<TData>;
  OnCloseDropdown?: () => void;
}

export function TemplateOverviewTableRowAction<TData>({
  row,
  OnCloseDropdown,
}: TemplateOverviewTableRowActionProps<TData>) {
  const { updateSelectedTemplateAnalytics, updateCurrentWhatsappMetricsTab } =
    useWhatsappMetrics();
  const { t, isRtlLanguage } = useTranslation();

  const handleViewHistory = async () => {
    updateSelectedTemplateAnalytics(
      row.original as IWhatsappTemplateAnalyticsData
    );
    updateCurrentWhatsappMetricsTab('whatsapp-template-history');
  };

  const handleViewInsights = async () => {};

  const handleOnOpenChange = (value: boolean) => {
    if (!value) {
      OnCloseDropdown && OnCloseDropdown();
    }
  };

  return (
    <>
      <DropdownMenu onOpenChange={handleOnOpenChange}>
        <DropdownMenuTrigger asChild>
          <Button
            variant='ghost'
            className='flex h-8 w-8 p-0 pt-1 data-[state=open]:bg-muted focus:border-traparent'
          >
            <DotsHorizontalIcon className='w-4 h-4 text-[#18181B]' />
            <span className='sr-only'>Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align={isRtlLanguage ? 'start' : 'end'}
          className='w-fit bg-white'
        >
          <DropdownMenuItem
            className='ltr:text-left rtl:text-right gap-2 items-center hover:bg-gray-100 text-textPrimary rtl:justify-end hidden'
            onClick={handleViewInsights}
          >
            <EyeIcon strokeWidth={2.5} className='w-4 h-4' />
            {t('View Insights')}
          </DropdownMenuItem>
          <DropdownMenuItem
            className='ltr:text-left rtl:text-right flex gap-2 items-center hover:bg-gray-100 rtl:justify-end'
            onClick={handleViewHistory}
          >
            <HistoryIcon strokeWidth={2.5} className='w-4 h-4' />
            {t('Template History')}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
