import { Modal } from 'library';
import {
  React,
  Button,
  useSelector,
  ArrowLeftIcon,
  EditablePencilIcon,
  SwitchWithRightLabel,
  useAutomationWorkflow,
  EditableTextComponent,
  useCreateWorkflowServices,
  useUpdateWorkflowServices,
} from '../../../../export';
import _ from 'lodash';
import { INode } from 'pages/automationWorkflow/interface';
import { getSanitizedNodeList } from 'pages/automationWorkflow/utility/function';

const ReactWorkflowHeader = () => {
  const {
    isAutomationEditable,
    selectedUserActionFlowData,
    automationNodes,
    automationEdges,
    triggerInfo,
  } = useSelector((state: any) => state.workflowAutomation);
  const [isOpenConfirmBackModal, setIsOpenConfirmBackModal] =
    React.useState(false);
  const [automationName, setAutomationName] = React.useState<string>(
    selectedUserActionFlowData?.name || 'Untitled Automation'
  );
  const [isNewWorkFlowActive, setIsNewWorkFlowActive] = React.useState<boolean>(
    selectedUserActionFlowData?.is_active || false
  );
  const [savedNodes] = React.useState<INode[]>(
    JSON.parse(JSON.stringify(automationNodes))
  );
  const [savedEdges] = React.useState(
    JSON.parse(JSON.stringify(automationEdges))
  );
  const { updatePrimitiveStateData } = useAutomationWorkflow();

  const { isUpdateSelectedAutomationFlowLoading } = useUpdateWorkflowServices();

  const {
    createAutomationFlow,
    updateSelectedAutomationData,
    isCreateAutomationFlowLoading,
  } = useCreateWorkflowServices();

  const sanitizeExtraData = (nodeList: INode[]) => {
    const withoutConditionActionButton = nodeList?.filter(
      (item: INode) => item?.type !== 'condition_action-button'
    );
    const withoutExtraDataInNode = withoutConditionActionButton.map(
      (item: any, index: number) => {
        if (index === 0) {
          if (item.type === 'trigger-button') {
            return {
              data: item?.data,
              id: item?.id,
              position: item?.position,
              type: item?.type,
            };
          } else {
            return {
              data: item?.data,
              deletable: item?.deletable,
              id: item?.id,
              node_type: item?.node_type,
              position: item?.position,
              type: item?.type,
              height: 0,
              width: 0,
            };
          }
        }
        return {
          data: item?.data,
          id: item?.id,
          node_type: item?.node_type,
          position: item?.position,
          type: item?.type,
        };
      }
    );
    return withoutExtraDataInNode;
  };

  const isWorkFlowChanged = () => {
    const globalNodesWithoutExtraData = sanitizeExtraData(automationNodes);
    const savedNodesWithoutExtraData = sanitizeExtraData(savedNodes);

    const sanitizedGlobalNodes = getSanitizedNodeList(
      globalNodesWithoutExtraData,
      triggerInfo
    );
    const sanitizedSavedNodes = getSanitizedNodeList(
      savedNodesWithoutExtraData,
      triggerInfo
    );

    return !(
      _.isEqual(sanitizedGlobalNodes, sanitizedSavedNodes) &&
      _.isEqual(savedEdges, automationEdges)
    );
  };

  const renderAutomationWorkflowCreateHeaderView = () => {
    return (
      <>
        <div className='flex items-center justify-center'>
          <>
            <Button
              className='py-2 pl-2 pr-2 bg-green-100'
              icon={<ArrowLeftIcon className='h-5 text-green-500' />}
              onClick={() => {
                if (isWorkFlowChanged()) {
                  setIsOpenConfirmBackModal(true);
                } else {
                  window.history.back();
                }
              }}
            />
            <EditableTextComponent
              editableName={automationName}
              editIcon={<EditablePencilIcon />}
              handleOnEnterPress={(value: string) => {
                setAutomationName(
                  value.length !== 0 ? value : 'Untitled Automation'
                );
                if (isAutomationEditable) {
                  updatePrimitiveStateData({
                    key: 'selectedUserActionFlowData',
                    value: {
                      ...selectedUserActionFlowData,
                      name: value,
                    },
                  });
                }
              }}
            />
          </>
        </div>
        <div className='flex items-center justify-center'>
          <div className='mr-6'>
            <SwitchWithRightLabel
              isEnabled={isNewWorkFlowActive}
              label={isNewWorkFlowActive ? 'active' : 'inactive'}
              onChange={() => {
                setIsNewWorkFlowActive(!isNewWorkFlowActive);
                if (isAutomationEditable) {
                  updatePrimitiveStateData({
                    key: 'selectedUserActionFlowData',
                    value: {
                      ...selectedUserActionFlowData,
                      is_active: !selectedUserActionFlowData?.is_active,
                    },
                  });
                }
              }}
            />
          </div>
          <Button
            intent='primary'
            className='py-2 pl-2 pr-2'
            onClick={() => {
              if (isAutomationEditable && selectedUserActionFlowData?.id) {
                updateSelectedAutomationData(selectedUserActionFlowData?.id);
              } else {
                createAutomationFlow(automationName, isNewWorkFlowActive);
              }
            }}
            isLoading={
              isCreateAutomationFlowLoading ||
              isUpdateSelectedAutomationFlowLoading
            }
            isDisabled={!isWorkFlowChanged()}
          >
            {isAutomationEditable ? 'Update Automation' : 'Save Automation'}
          </Button>
        </div>
      </>
    );
  };

  const renderConfirmBackChildrenView = () => {
    return (
      <div className='text-gray-500 text-sm p-4'>
        Please save before leaving this page, otherwise you will lose your
        unsaved modifications.
      </div>
    );
  };

  return (
    <>
      <div className='absolute top-0 left-0 z-10 flex items-center justify-between w-full px-4 py-3 bg-white border-b border-gray-200 ltr:border-l rtl:border-r h-[52px]'>
        {renderAutomationWorkflowCreateHeaderView()}
      </div>
      {isOpenConfirmBackModal && (
        <Modal
          open={isOpenConfirmBackModal}
          handleClose={() => {
            setIsOpenConfirmBackModal(false);
          }}
          confirmText={`Save Workflow`}
          cancelText={`Continue without save`}
          handleCancel={() => {
            setIsOpenConfirmBackModal(false);
            window.history.back();
          }}
          handleConfirm={() => {
            if (isAutomationEditable && selectedUserActionFlowData?.id) {
              updateSelectedAutomationData(selectedUserActionFlowData?.id);
            } else {
              createAutomationFlow(automationName, isNewWorkFlowActive);
            }
          }}
          children={renderConfirmBackChildrenView()}
          title='Save Automation?'
          width='w-[400px]'
          height='auto'
          description=''
        />
      )}
    </>
  );
};

export default ReactWorkflowHeader;
