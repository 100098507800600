import React, { useState } from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from 'libraryV2/ui/alert-dialog';

import { Button as ShadcnButton } from '../../../../../../libraryV2/ui/button';

import Button from '../../../../components/Button';
import chevronLeftIcon from '../../../../assets/images/chevronLeftIcon.svg';
import editIcon from '../../../../assets/images/editIcon.svg';
import { InputText, useActions, useNavigate, useTriggerActions } from 'pages/datalab/export';
import { truncate } from 'pages/datalab/datalabFormBuilder/utils';
import useTranslation from 'components/customHooks/useTranslation';
import { ChevronRightIcon } from 'lucide-react';

const Navbar = () => {
  const { t, isRtlLanguage } = useTranslation();
  const [editable, setEditable] = useState(false);
  const { getDataLabData, getController, updateDataLabData, getUnsavedTrack } =
    useActions();
  const {
    updateDataLabInfo,
    updateDataLabField,
    updateUnsaveTracker,
    createDataLabField,
  } = useTriggerActions();
  const navigate = useNavigate();
  const formData = getDataLabData();
  const [formTitle, setFormTitle] = useState(formData?.title || '');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const { projectId, labId } = getController();

  const hanleOnEditFormTitle = () => {
    setEditable(!editable);
  };

  const handleOnFormTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormTitle(event.target.value);
  };

  const handleOnBlur = () => {
    setEditable(false);

    if (formData?.title !== formTitle) {
      updateDataLabData({ title: formTitle });
      updateDataLabInfo(projectId, labId);
    }
  };

  const handleOnBackClick = () => {
    const unsavedTrack = getUnsavedTrack();
    if (unsavedTrack?.isDirty) {
      setShowConfirmDialog(true);
    } else {
      navigate(`/projects/${projectId}/data-lab`);
    }
  };

  const handleOnExit = () => {
    updateUnsaveTracker({ isDirty: false, field: {} });
    window.history.back();
  };

  const handleOnSave = () => {
    const unsavedTrack = getUnsavedTrack();
    const field = unsavedTrack.field;
    const isNewField =
      typeof field?.id === 'string' &&
      field?.id.startsWith('random') &&
      !field?.slug;
    if (isNewField) {
      createDataLabField(labId, field);
    } else {
      updateDataLabField(labId, field?.id, field);
    }
    window.history.back();
  };

  return (
    <>
      <div>
        <div className='fixed h-[52px] flex items-center bg-white border-b border-l border-s px-2 w-full z-20'>
          <div className=' flex items-center justify-between'>
            <div className='flex flex-1 items-center'>
              <div className='flex flex-shrink-0 items-center'>
                {isRtlLanguage ? (
                  <ShadcnButton onClick={handleOnBackClick} variant='outline'>
                    <ChevronRightIcon />
                  </ShadcnButton>
                ) : (
                  <Button
                    iconOnly
                    icon={chevronLeftIcon}
                    onClick={handleOnBackClick}
                  />
                )}
              </div>
              <div className='ltr:ml-4 rtl:mr-4'>
                <div className='flex items-center ltr:space-x-2 rtl:ml-2'>
                  {editable ? (
                    <InputText
                      name='form-title'
                      value={formTitle}
                      onChange={handleOnFormTitleChange}
                      onBlur={handleOnBlur}
                      focus
                    />
                  ) : (
                    <p
                      className={'bg-black-900 text-black-200 font-bold'}
                      title={formTitle}
                    >
                      {truncate(formTitle, 15)}
                    </p>
                  )}

                  <div onClick={hanleOnEditFormTitle} className='rtl:mr-2'>
                    <img
                      src={editIcon}
                      alt='edit'
                      className='w-4 h-4 cursor-pointer'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AlertDialog open={showConfirmDialog} onOpenChange={setShowConfirmDialog}>
        <AlertDialogTrigger asChild>
          <div />
        </AlertDialogTrigger>
        <AlertDialogContent className='bg-white rtl:text-right'>
          <AlertDialogHeader className='rtl:text-right'>
            <AlertDialogTitle>{t('Exit Without Saving?')}</AlertDialogTitle>
            <AlertDialogDescription>
              {t('You have unsaved changes. Are you sure you want to exit?')}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className='rtl:justify-start ltr:space-x-2 rtl:ml-2'>
            <AlertDialogCancel
              onClick={handleOnExit}
              className='bg-red-50 hover:bg-red-100 text-red-600'
            >
              {t('Exit Without Saving')}
            </AlertDialogCancel>
            <AlertDialogAction
              className='bg-[#04B25F] text-white hover:bg-[#078E4F] rtl:mr-2'
              onClick={handleOnSave}
            >
              {t('Save & Exit')}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default Navbar;
