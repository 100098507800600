import { FC } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'libraryV2/ui/dialog';
import { Button } from 'libraryV2/ui/button';
import { useCreateCustomAgent } from 'pages/raven/hooks/useCreateCustomAgent';
import { Link, useNavigate, useParams } from '@reach/router';

interface ActiveAgentModalProps {
  customAgentId: string;
}
export const ActiveAgentModal: FC<ActiveAgentModalProps> = ({
  customAgentId,
}) => {
  const { projectId } = useParams();
  const mainPageUrl = `/projects/${projectId}/raven/manage-custom-agents`;
  const navigate = useNavigate();
  const { customAgentFormData, updateCustomAgentStatus } =
    useCreateCustomAgent();
  const handleActiveCustomAgent = async () => {
    updateCustomAgentStatus(customAgentId, 'active')
      .then(() => {
        navigate(mainPageUrl);
      })
      .catch(console.log);
  };
  if (customAgentFormData?.status === 'active') {
    return (
      // @ts-ignore
      <Link to={mainPageUrl} className='py-1.5 px-3 rounded-md'>
        Go to Main Page
      </Link>
    );
  }
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant='outline'
          className='text-sm font-medium text-textPrimary'
        >
          Go to Main Page
        </Button>
      </DialogTrigger>
      <DialogContent className='sm:max-w-[425px] bg-white'>
        <DialogHeader>
          <DialogTitle className='font-semibold text-textPrimary leading-6'>
            Activate Your Custom Agent
          </DialogTitle>
          <DialogDescription className='text-sm text-textSecondary leading-5'>
            You can activate your custom agent to use it immediately or choose
            to skip activation and enable it later from the main table.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className='flex items-center gap-2'>
          {/* @ts-ignore */}
          <Link
            to={mainPageUrl}
            className='py-1.5 px-3 rounded-md bg-background-hover'
          >
            Skip
          </Link>
          <Button onClick={handleActiveCustomAgent} type='button'>
            Active Now
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
