import { FC, useState } from 'react';
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription,
} from 'libraryV2/ui/form';

import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from 'libraryV2/ui/collapsible';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'libraryV2/ui/select';

import { UseFieldArrayReturn, useFormContext } from 'react-hook-form';
import { CustomAgentToolFormData } from '../toolsSchema';
import { Input } from 'libraryV2/ui/input';
import { Textarea } from 'libraryV2/ui/textarea';

import { ChevronDownIcon, ChevronUpIcon, Paperclip, X } from 'lucide-react';
import { Separator } from 'libraryV2/ui/separator';
import { cn } from 'libraryV2/utils';
import { useSelector } from 'react-redux';
import { IGptModel } from 'pages/raven/interface';
import { DocumentToolUploader } from './DocumentToolUploader';
import { ManageUploadedDocuments } from './ManageUploadedDocuments';

interface DocumentToolProps {
  tooldata: any;
  index: number;
  fieldArrayInstance: UseFieldArrayReturn<{
    tools: any;
  }>;
}

export const DocumentTool: FC<DocumentToolProps> = ({
  tooldata,
  index,
  fieldArrayInstance,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(tooldata?.isOpen);
  const formInstance = useFormContext<CustomAgentToolFormData>();
  const availableEmbedModelList: IGptModel[] = useSelector(
    (state: any) => state?.raven?.availableEmbedModelList || []
  );
  const uploadedDocumentList =
    formInstance.getValues(`tools.${index}.data.documents`) || [];

  const handleDeleteUploadedDocument = async (documentId: number) => {
    formInstance.setValue(
      `tools.${index}.data.documents`,
      uploadedDocumentList.filter((document) => document?.id !== documentId),
      {
        shouldValidate: true,
      }
    );
    return true;
  };

  const updateDocumentTrainStatus = (
    documentId: number,
    status: boolean = true
  ) => {
    formInstance.setValue(
      `tools.${index}.data.documents`,
      uploadedDocumentList.map((document) =>
        document?.id === documentId
          ? { ...document, isTrained: status }
          : document
      ),
      {
        shouldValidate: true,
      }
    );
  };

  const renderCollapsibleTrigger = () => {
    return (
      <>
        <div className='h-6 w-6 flex items-center justify-center rounded border border-border-soft'>
          <Paperclip className='w-4 h-4 text-textPrimary-disable' />
        </div>
        <span className='text-sm font-semibold text-textPrimary'>
          Upload Documents
        </span>
        {isCollapsed ? (
          <ChevronUpIcon className='w-5 h-5 text-textPrimary-disable' />
        ) : (
          <ChevronDownIcon className='w-5 h-5 text-textSecondary-disable' />
        )}

        <button
          onClick={(e) => {
            e.stopPropagation();
            fieldArrayInstance.remove(index);
          }}
          className='h-8 w-8 flex ml-auto items-center justify-center rounded-md bg-red-50'
        >
          <X className='w-4 h-4 text-red-500' strokeWidth={2.5} />
        </button>
      </>
    );
  };

  return (
    <Collapsible
      open={isCollapsed}
      onOpenChange={setIsCollapsed}
      className='rounded-md border border-border-soft'
    >
      <CollapsibleTrigger className='flex w-full gap-2 items-center py-2.5 px-4 group'>
        {renderCollapsibleTrigger()}
      </CollapsibleTrigger>
      <CollapsibleContent className='bg-background-container rounded-md'>
        <div className='p-4'>
          <div className='bg-white space-y-3 p-4 rounded-md'>
            <div className='flex w-full gap-2'>
              <FormField
                control={formInstance.control}
                name={`tools.${index}.data.title`}
                render={({ field }) => (
                  <FormItem className='flex-1 space-y-1.5'>
                    <FormLabel>Tool Title</FormLabel>
                    <span className='text-red-500'>*</span>
                    <FormControl>
                      <Input
                        {...field}
                        type='text'
                        className='w-full h-9 p-2 rounded-md'
                        placeholder='Enter tool title'
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={formInstance.control}
                name={`tools.${index}.data.modelUsed`}
                render={({ field }) => (
                  <FormItem className='flex-1 space-y-1.5'>
                    <FormLabel>
                      Model Used
                      <span className='text-red-500'>*</span>
                    </FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger
                          disabled={availableEmbedModelList?.length === 0}
                          className='w-full focus:ring-primary'
                        >
                          <SelectValue placeholder='Select a model' />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent className='bg-white mt-1'>
                        <SelectGroup>
                          {availableEmbedModelList?.map((model) => (
                            <SelectItem value={model?.id}>
                              {model?.title}
                            </SelectItem>
                          ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>

                    <FormDescription
                      className={cn('text-textSecondary font-normal', {
                        hidden: !!Object.keys(formInstance.formState.errors)
                          .length,
                      })}
                    ></FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={formInstance.control}
              name={`tools.${index}.data.description`}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Tool description</FormLabel>
                  <span className='text-red-500'>*</span>
                  <FormControl>
                    <Textarea
                      {...field}
                      style={{ resize: 'vertical' }}
                      autoResize={false}
                      className='border-border shadow-sm focus:border-green-500 min-h-[80px] focus-visible:ring-0'
                      placeholder="Explain the tool's purpose"
                      rows={3}
                    />
                  </FormControl>
                  <FormDescription
                    className={cn('text-textSecondary font-normal', {
                      hidden: !!Object.keys(formInstance.formState.errors)
                        .length,
                    })}
                  >
                    Briefly describe how this tool will support the agent's
                    task.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <Separator />
        <div className='p-4'>
          <div className='bg-white p-4 rounded space-y-3'>
            <DocumentToolUploader
              shouldDisable={false}
              index={index}
              toolData={tooldata}
            />
            {Array.isArray(uploadedDocumentList) &&
            uploadedDocumentList.length ? (
              <>
                <p className='text-sm text-textPrimary font-medium'>
                  Uploaded documents
                </p>
                <ManageUploadedDocuments
                  uploadedDocumentList={uploadedDocumentList}
                  onDeleteUploadedDocument={handleDeleteUploadedDocument}
                  updateDocumentTrainStatus={updateDocumentTrainStatus}
                />
              </>
            ) : null}
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};
