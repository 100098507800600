import { XMarkIcon } from '@heroicons/react/20/solid';
import useTranslation from 'components/customHooks/useTranslation';

import { ChevronRight } from 'lucide-react';
import {
  ISavedReplyAttachemnt,
  SavedRepliesInterface,
  TPlatforms,
} from 'pages/inbox/inboxInterface';
import { analyzeSavedReplyPlatformRestrictions } from 'pages/inbox/utils/functions';
import { ISavedReplyApiResponse } from 'pages/settings/savedReplies/utils';
import React from 'react';
import { emailChannels } from 'utilities/utils';
import openBoxIcon from '../../../../assets/images/openBox.svg';
import SavedReplyPreview from './SavedReplyPreview';

interface Props {
  inputEvent: any;
  inputValue: string;
  bottom?: string;
  savedReplies: SavedRepliesInterface[];
  platformType: TPlatforms;
  setInputValue: (payload: string) => void;
  setIsSavedReplyOpened: (value: boolean) => void;
  setSavedReplyAttachments:
    | React.Dispatch<React.SetStateAction<ISavedReplyAttachemnt[]>>
    | ((newList: ISavedReplyAttachemnt[]) => void);
}

const SavedReplies: React.FC<Props> = ({
  inputEvent,
  inputValue,
  savedReplies,
  bottom = 'bottom-[21.5%]',
  setInputValue,
  platformType,
  setIsSavedReplyOpened,
  setSavedReplyAttachments,
}) => {
  const { t } = useTranslation();
  const [hoveredPreviewIndex, setHoveredPreviewIndex] =
    React.useState<number>(0);
  const elements = React.useRef<any>([]);

  const insertSavedReplyAtCursor = (
    text: string,
    savedReplyText: string,
    cursorPosition: number
  ) => {
    const hashIndex = text.lastIndexOf('#', cursorPosition);

    if (hashIndex !== -1) {
      // Get text before the # and after the cursor
      const textBeforeHash = text.substring(0, hashIndex);
      const textAfterCursor = text.substring(cursorPosition);

      // Combine parts with the saved reply text
      return textBeforeHash + savedReplyText + textAfterCursor;
    }

    // If no # found, insert at cursor position
    return (
      text.substring(0, cursorPosition) +
      savedReplyText +
      text.substring(cursorPosition)
    );
  };

  const handleSavedReplyOnSelect = (reply: SavedRepliesInterface) => {
    setSavedReplyAttachments(savedReplyRestrictions?.allowedAttachments);

    const inputId = emailChannels.includes(platformType)
      ? 'email-reply-input'
      : 'reply-input';
    const textArea = document.getElementById(inputId) as HTMLTextAreaElement;

    if (textArea) {
      const cursorPosition = textArea?.selectionStart;
      const newValue = insertSavedReplyAtCursor(
        inputValue,
        reply?.text,
        cursorPosition
      );
      setInputValue(newValue);

      // Restore cursor position after the inserted reply
      setTimeout(() => {
        const newCursorPosition = cursorPosition + reply?.text?.length;
        textArea.focus();
        textArea.setSelectionRange(newCursorPosition, newCursorPosition);
      }, 0);
    }

    setIsSavedReplyOpened(false);
  };

  const savedReplyRestrictions = analyzeSavedReplyPlatformRestrictions(
    platformType,
    savedReplies[hoveredPreviewIndex]?.attachments || []
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!event) return;

    if (event.key === 'Enter') {
      event.preventDefault();

      const target = event.target as HTMLTextAreaElement;
      const cursorPosition = target?.selectionStart;
      const savedReplyText = savedReplies[hoveredPreviewIndex]?.text;

      if (savedReplyText) {
        setSavedReplyAttachments(savedReplyRestrictions?.allowedAttachments);
        const newValue = insertSavedReplyAtCursor(
          inputValue,
          savedReplyText,
          cursorPosition
        );
        setInputValue(newValue);

        setTimeout(() => {
          const newCursorPosition = cursorPosition + savedReplyText?.length;
          target.focus();
          target.setSelectionRange(newCursorPosition, newCursorPosition);
        }, 0);

        setIsSavedReplyOpened(false);
      }
    }

    if (event.key === 'ArrowDown') {
      setHoveredPreviewIndex(
        Math.min(hoveredPreviewIndex + 1, elements.current.length - 1)
      );
    }
    if (event.key === 'ArrowUp') {
      setHoveredPreviewIndex(Math.max(hoveredPreviewIndex - 1, 0));
    }
  };

  const renderHeaderElementsView = () => {
    return (
      <div className='flex justify-between p-3'>
        <p className='w-[60%] text-gray-800 font-semibold text-sm mb-2'>
          {t('Saved Replies')}
        </p>
        <div className='w-[40%] flex justify-end'>
          <XMarkIcon
            className='w-5 h-5 text-gray-500 cursor-pointer'
            onClick={() => {
              setIsSavedReplyOpened(false);
            }}
          />
        </div>
      </div>
    );
  };

  const renderSavedReplyElementView = () => {
    return (
      <div className='max-h-[260px] overflow-auto mb-3 w-[270px]'>
        {savedReplies.map((reply: SavedRepliesInterface, index: number) => (
          <div
            key={index}
            ref={(element) => (elements.current[index] = element)}
          >
            <div
              className={`py-[12px] flex ${
                hoveredPreviewIndex === index ? 'bg-gray-200' : ''
              } p-3 `}
              onClick={() => {
                handleSavedReplyOnSelect(reply);
              }}
              onMouseEnter={() => {
                setHoveredPreviewIndex(index);
              }}
            >
              <p className='w-[80%] text-gray-900 font-normal text-sm cursor-pointer'>
                # {reply.title}
              </p>
              <div className='w-[20%] flex justify-end'>
                <ChevronRight className='w-5 h-5 text-gray-500 cursor-pointer rtl:rotate-180' />
              </div>
            </div>
            {hoveredPreviewIndex === index && (
              <SavedReplyPreview
                savedReply={reply as ISavedReplyApiResponse}
                platformWarningMessage={
                  savedReplyRestrictions.warningMessage as string
                }
              />
            )}
          </div>
        ))}
      </div>
    );
  };

  const renderEmptySavedRepliesView = () => {
    return (
      <div className='mx-3 my-16'>
        <span className='flex justify-center'>
          <img src={openBoxIcon} alt='not-ticket' />
        </span>
        <div className='flex justify-center mt-3 text-sm text-center text-gray-500'>
          <span>
            Oops! You have no saved replies.
            <br />
            Better start now than never.
          </span>
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    handleKeyDown(inputEvent);
    // eslint-disable-next-line
  }, [inputEvent]);

  return (
    <>
      {savedReplies.length === 0 &&
      inputValue.length > 1 &&
      inputValue.includes('#') ? null : (
        <div className={`absolute z-10 ${bottom}`}>
          <div className='relative bg-white border border-gray-200 rounded-md'>
            <div className='w-full'>
              <div>
                {renderHeaderElementsView()}
                {!!savedReplies &&
                  savedReplies.length !== 0 &&
                  renderSavedReplyElementView()}
                {!!savedReplies &&
                  savedReplies.length === 0 &&
                  renderEmptySavedRepliesView()}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SavedReplies;
