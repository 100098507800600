import React from 'react';
import Button from 'library/button';
import LanguageOptionsDropdown from './LanguageOptionsDropdown';
import useTranslation from 'components/customHooks/useTranslation';

import { connect } from 'react-redux';
import SettingsPageHeader from 'pages/settings/components/SettingsPageHeader';

interface DashboardPreferencesProps {
  selectedTeamId: number;
  selectedLanguage: string;
  languageUpdateLoading: boolean;
  editUserLanguageInfo: (payload: { dashboard_language: string }) => boolean;
}

const DashboardPreferences: React.FC<DashboardPreferencesProps> = ({
  editUserLanguageInfo,
  selectedLanguage,
  selectedTeamId,
  languageUpdateLoading,
}) => {
  const { t } = useTranslation();

  const [clickedOnLanguage, setClickedOnLanguage] = React.useState(false);
  const [userLanguage, setUserLanguage] = React.useState(selectedLanguage);

  const handleLanguageSetting = async () => {
    let payload = { dashboard_language: userLanguage };
    const response = await editUserLanguageInfo(payload);
    if (response) setClickedOnLanguage(false);
  };

  const renderLanguagePreferenceView = () => {
    return (
      <>
        <SettingsPageHeader 
          title='Dashboard Preferences' 
        />
        <div className='flex flex-col gap-3 pb-6 px-5'>
          <p className='font-medium text'>{t('Dashboard Language')}</p>
          <p className='font-normal text-gray-500 text'>
            {t(
              'Changing the language settings will affect your dashboard only.'
            )}
          </p>
          <LanguageOptionsDropdown
            userLanguage={userLanguage}
            setUserLanguage={setUserLanguage}
            setClickedOnLanguage={setClickedOnLanguage}
          />
          {clickedOnLanguage && (
            <Button
              className={`w-20 bg-primary text text-white px-5`}
              onClick={() => {
                handleLanguageSetting();
              }}
              isDisabled={languageUpdateLoading}
              isLoading={languageUpdateLoading}
            >
              {t('Save')}
            </Button>
          )}
        </div>
      </>
    );
  };

  return (
    <>
      <div className='w-full h-auto'>
        {renderLanguagePreferenceView()}
      </div>
    </>
  );
};

const mapState = (state: any) => ({
  isUsingNewInbox: state.settings.isUsingNewInbox,
  selectedTeamId: state.dashboard.selectedProject.id || null,
  selectedLanguage: state.auth.dashboard_language,
  languageUpdateLoading: state.loading.effects.settings.editUserLanguageInfo,
});

const mapDispatch = (dispatch: any) => ({
  editUseNewInbox: (projectId: number, isUsingNewInbox: boolean) =>
    dispatch.settings.editUseNewInbox({
      projectId,
      isUsingNewInbox,
    }),
  updateUseNewInbox: (data: any) => {
    dispatch.settings.updateUseNewInbox(data);
  },
  editUserLanguageInfo: (payload: { dashboard_language: string }) =>
    dispatch.settings.editUserLanguageInfo(payload),
});

export default connect(mapState, mapDispatch)(DashboardPreferences);
