import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  IFormFieldComponent,
  Input,
  getHookFormRules,
  useFormContext,
  useState,
} from '../../export';

const NumberField: React.FC<IFormFieldComponent> = ({
  data,
  formActionType,
  valuePath,
}) => {
  const shouldDisableInput = formActionType === 'VIEW';
  const currentFieldPath = valuePath.length
    ? valuePath + '.' + data.slug
    : data.slug;

  const hookForm = useFormContext();

  const { name: label, placeholder } = data;

  const isFormViewMode = formActionType === 'VIEW';

  const [fieldValue, setFieldValue] = useState(
    hookForm.getValues(currentFieldPath) ?? ''
  );

  const renderNumberFieldView = () => {
    return (
      <div className='border border-zinc-200 space-y-2 rounded-md p-3'>
        <p className='text-xs text-zinc-500'>
          {data?.label_agent || data.name}
        </p>
        <p className='text-zinc-900 text-sm'>
          {hookForm.getValues(currentFieldPath) || (
            <span className='text-zinc-500'> (Empty) </span>
          )}
        </p>
      </div>
    );
  };

  const renderNumberField = () => (
    <FormField
      control={hookForm.control}
      name={currentFieldPath}
      disabled={data.is_readonly}
      rules={getHookFormRules(data)}
      render={({ field }) => {
        return (
          <FormItem>
            <FormLabel>{data?.label_agent || label}</FormLabel>
            {data.is_required ? (
              <span className='text-destructive'>*</span>
            ) : null}
            <FormControl>
              <Input
                disabled={shouldDisableInput}
                type='number'
                placeholder={placeholder}
                {...field}
                onChange={(event) => {
                  const rawValue = event.target.value;
                  if (
                    Number.isNaN(rawValue) ||
                    rawValue === '' ||
                    rawValue === undefined
                  ) {
                    setFieldValue('');
                    return field.onChange('');
                  }
                  if (!Number.isNaN(rawValue)) {
                    setFieldValue(rawValue);
                    return field.onChange(+event.target.value);
                  } else {
                    setFieldValue('');
                  }
                }}
                onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                  e.currentTarget.blur()
                }
                value={fieldValue}
                className='focus:border-green-500 border-gray-300 focus-visible:ring-0'
              />
            </FormControl>
            <FormDescription
              className={
                data.help_text ? 'visible text-zinc-500 text-sm' : 'hidden'
              }
            >
              {data.help_text}
            </FormDescription>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );

  return isFormViewMode ? renderNumberFieldView() : renderNumberField();
};

export default NumberField;
