import { Position, useReactFlow, getConnectedEdges, useStore } from 'reactflow';
import {
  React,
  useMemo,
  CustomHandle,
  useCallback,
  NodeDecorator,
  UtilityContents,
  useAutomationWorkflow,
  useReactFlowGraphServices,
  CircleAlert,
  CircleCheck,
  DelayActionNode,
  DiscountActionNode,
  WhatsappActionNode,
  AddTagsOrderActionNode,
  AddTagsCustomerActionNode,
  RemoveTagsOrderActionNode,
  RemoveTagsCustomerActionNode,
} from '../../../../export';

interface Props {
  id: string;
  type: string;
  data: any;
  position: { x: number; y: number };
}

const selector = (s: any) => ({
  nodeInternals: s.nodeInternals,
  edges: s.edges,
});

const ActionNode: React.FC<Props> = ({ id, type, data, position }) => {
  const { updatePrimitiveStateData, isViewLog } = useAutomationWorkflow();
  const { nodeInternals, edges } = useStore(selector);

  const actionResult =
    data?.component_computation_info?.current_component_state;

  const isHandleConnectable = useMemo(() => {
    const node = nodeInternals.get(id);
    const connectedEdges = getConnectedEdges([node], edges);

    return connectedEdges.length < 2;
    //@ts-ignore
  }, [nodeInternals, edges, id]);

  const {
    automationNodes,
    addConditionOrActionButton,
    shouldRenderConditionAndActionButton,
  } = useReactFlowGraphServices();

  const { deleteElements } = useReactFlow();

  const onDelete = useCallback(() => {
    deleteElements({ nodes: [{ id }] });
  }, [id, deleteElements]);

  const handleOnClick = useCallback(
    (sourcehandleId) => {
      updatePrimitiveStateData({ key: 'selectedNodeId', value: id });
      updatePrimitiveStateData({
        key: 'selectedSourceHandle',
        value: null,
      });
      if (
        shouldRenderConditionAndActionButton(id, automationNodes) &&
        isHandleConnectable
      ) {
        addConditionOrActionButton(id, automationNodes);
      }
    },
    // eslint-disable-next-line
    [automationNodes]
  );

  const renderActionLogResultIcon = () => {
    if (!isViewLog) {
      return null;
    }
    switch (actionResult) {
      case 'success': {
        return (
          <CircleCheck className='h-4 self-center flex-shrink-0 w-4 text-[#04A056]' />
        );
      }
      case 'failed': {
        return (
          <CircleAlert className='h-4 self-center flex-shrink-0 w-4 text-red-500' />
        );
      }
      case 'unknown':
      case 'pending': {
        return (
          <CircleCheck className='h-4 self-center flex-shrink-0 w-4 text-[#F59E0B]' />
        );
      }
      default:
        return (
          <CircleCheck className='h-4 self-center flex-shrink-0 w-4 text-[#A1A1AA]' />
        );
    }
  };

  return (
    <NodeDecorator nodeType={'action-node'} nodeId={id} onDelete={onDelete}>
      <div className='w-auto h-auto mb-2'>
        <CustomHandle
          type='target'
          isConnectable={2}
          position={Position.Top}
          id={`targetHandle_${id}`}
          className={UtilityContents.NodeTopMiddleHandleClass}
        />
        <div className={UtilityContents.CildrenClass}>
          <div className='w-full mb-3'>
            <div
              onClick={() => {
                if (isViewLog) {
                  return;
                }
                handleOnClick(`success_sourceHandle_${id}`);
              }}
            >
              {data?.general?.delay?.valueOfDelay !== null && (
                <DelayActionNode
                  data={data}
                  ResultIcon={renderActionLogResultIcon()}
                />
              )}
              {data?.whatsapp?.selectedTemplate !== null && (
                <WhatsappActionNode
                  data={data}
                  ResultIcon={renderActionLogResultIcon()}
                />
              )}
              {data?.eCommerce?.discount?.component && (
                <DiscountActionNode
                  data={data}
                  ResultIcon={renderActionLogResultIcon()}
                />
              )}
              {data?.eCommerce?.addTagsOnCustomer?.extranalData?.payload && (
                <AddTagsCustomerActionNode
                  ResultIcon={renderActionLogResultIcon()}
                  data={data}
                />
              )}
              {data?.eCommerce?.addTagsOnOrder?.extranalData?.payload && (
                <AddTagsOrderActionNode
                  ResultIcon={renderActionLogResultIcon()}
                  data={data}
                />
              )}
              {data?.eCommerce?.removeTagsOnCustomer?.extranalData?.payload && (
                <RemoveTagsCustomerActionNode
                  ResultIcon={renderActionLogResultIcon()}
                  data={data}
                />
              )}
              {data?.eCommerce?.removeTagsOnOrder?.extranalData?.payload && (
                <RemoveTagsOrderActionNode
                  ResultIcon={renderActionLogResultIcon()}
                  data={data}
                />
              )}
            </div>
          </div>
        </div>
        <CustomHandle
          type='source'
          isConnectable={2}
          position={Position.Bottom}
          id={`success_sourceHandle_${id}`}
          className={UtilityContents.NodeBottomMiddleHandleClass}
        />
      </div>
    </NodeDecorator>
  );
};

export default ActionNode;
