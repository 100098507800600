import {
  cn,
  useAutomationWorkflow,
  React,
  ActionNodeDetailsLog,
  ActionNodeDetailsLogCard,
} from 'pages/automationWorkflow/export';
import { IAutomationLogResult } from 'pages/automationWorkflow/interface';
import { mapPlainTagsToDynamicTags } from 'pages/automationWorkflow/utility/function';

interface AddTagsOrderActionNodeProps {
  ResultIcon: JSX.Element | null;
  data: any;
}

export const AddTagsOrderActionNode: React.FC<AddTagsOrderActionNodeProps> = ({
  ResultIcon,
  data,
}) => {
  const { isViewLog } = useAutomationWorkflow();
  const addTagData = data?.eCommerce?.addTagsOnOrder;

  const renderDetailsLog = () => {
    const logResult: IAutomationLogResult = data.component_computation_info;
    const status = logResult?.current_component_state;
    const errorMessage = status === 'failed' && logResult?.result?.pop();

    const tagList = mapPlainTagsToDynamicTags({
      webhookData: logResult?.webhook_data || {},
      plainTags: addTagData?.extranalData?.payload?.plain_tag || [],
    });

    return (
      <ActionNodeDetailsLogCard
        date={logResult?.ends_at}
        errorMessage={errorMessage}
        cardTitle='Add Tag on Order'
        ResultIcon={ResultIcon}
        render={() => {
          return (
            <div>
              {Object.keys(tagList).map((key, idx) => (
                <div key={key + idx} className='grid grid-cols-2 p-2'>
                  <div className='flex justify-between pr-3'>
                    <span className='text-zinc-400 text-sm leading-5 font-medium'>
                      {key}
                    </span>
                    <span className='w-fit'>:</span>
                  </div>
                  <span
                    className={cn('text-left font-normal text-zinc-900', {
                      'text-zinc-400': tagList[key] === '(Static)',
                    })}
                  >
                    {tagList[key]}
                  </span>
                </div>
              ))}
            </div>
          );
        }}
      />
    );
  };

  return (
    <div>
      <div className='flex items-center mb-3'>
        <div className='mr-3'>
          <img
            src={addTagData?.component?.component_icon}
            alt={addTagData?.component?.component_name}
          />
        </div>
        <div className='grid grid-flow-row gap-1'>
          <div className='text-sm font-semibold leading-4 text-gray-500'>
            {addTagData?.component?.component_name}
          </div>
          <div
            className={cn([
              'text-xs leading-4 text-gray-400',
              isViewLog ? 'hidden' : '',
            ])}
          >
            (Change)
          </div>
        </div>
      </div>
      {isViewLog ? (
        <ActionNodeDetailsLog render={renderDetailsLog} actionData={data} />
      ) : null}
      <div className='mt-3 flex gap-2 ltr:pl-3 rtl:pr-3 border border-gray-200 rounded-md'>
        {isViewLog ? ResultIcon : null}
        {addTagData?.extranalData?.payload?.plain_tag.length > 0 && (
          <div className='py-3'>
            <p className='text-sm text-gray-500 font-normal'>
              Add tags (
              {addTagData?.extranalData?.payload?.plain_tag.map(
                (tag: string, index: number) => (
                  <span className='font-medium text-gray-700' key={index}>
                    {tag}{' '}
                    {addTagData?.extranalData?.payload?.plain_tag.length - 1 !==
                      index && <span>,</span>}
                  </span>
                )
              )}
              )
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
