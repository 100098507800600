import { useEffect, useState } from 'react';
import {
  React,
  ReactFlowIndex,
  GenericSideSheet,
  // GenericAlertModal,
  ReactWorkflowHeader,
  useSelector,
  useTriggerActions,
} from '../../export';
import { useParams } from '@reach/router';
import useAutomationDashboard from '../automationDashboardV2/hooks/useAutomationDashboard';
import { Loader2 } from 'lucide-react';

interface Props {}
const AutomationWorkflow: React.FC<Props> = () => {
  const [loading, setLoading] = useState(false);
  const { flowId } = useParams();
  const { selectedUserActionFlowData } = useSelector(
    (state: any) => state.workflowAutomation
  );
  const { handleUpdatedSelectedUserFlowData, fetchIntegratedEcommerceData } =
    useAutomationDashboard();
  const { fetchAutomationViewData } = useTriggerActions();

  useEffect(() => {
    if (flowId) {
      setLoading(true);
      handleUpdatedSelectedUserFlowData(selectedUserActionFlowData)
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    } else {
      fetchAutomationViewData();
      fetchIntegratedEcommerceData();
    }
    //eslint-disable-next-line
  }, []);

  const renderLoadingView = () => {
    return (
      <div className='relative w-full h-full prevent-select flex flex-col '>
        {/* <AutomationLogHeader /> */}
        <div className='flex-grow w-full h-auto flex justify-center items-center'>
          <Loader2 size={48} className='animate-spin text-green-400' />
        </div>
      </div>
    );
  };
  return (
    <div className='h-[100vh] w-full flex justify-center items-center'>
      {loading ? (
        renderLoadingView()
      ) : (
        <div className='relative w-full h-full prevent-select'>
          <ReactWorkflowHeader />
          <ReactFlowIndex />
          <GenericSideSheet />
          {/* <GenericAlertModal /> */}
        </div>
      )}
    </div>
  );
};

export default AutomationWorkflow;
