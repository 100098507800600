import useTranslation from 'components/customHooks/useTranslation';
import { LoaderCircle, Plus } from 'lucide-react';
import React from 'react';
import emptyDataIcon from '../../../assets/icons/emptyPageIcon.svg';
import WebhookModal, { IWebhookColumnData } from './components/webhookModal';
import { columns } from './components/webhookTable/Column';
import WebhooksTable from './components/webhookTable/WebhooksTable';
import { useWebhooks } from './hooks/useWebhooks';
import SettingsPageHeader from '../components/SettingsPageHeader';

const WebhookSettings = () => {
  const { webhookList, clearWebhookData, fetchWebhookBots, isWebhookLoading } =
    useWebhooks();
  const { t } = useTranslation();
  const [openCreateModal, setOpenCreateModal] = React.useState(false);

  React.useEffect(() => {
    const fetchBots = async () => await fetchWebhookBots();

    clearWebhookData();
    fetchBots();
    // eslint-disable-next-line
  }, []);

  const renderEmptyState = () => {
    return (
      <div className='flex justify-center flex-col gap-4 items-center h-[500px]'>
        <img src={emptyDataIcon} alt='emptyDataIcon' />
        <div className='flex flex-col gap-1 items-center justify-center'>
          <p className='text-gray-900 text-lg font-bold'>
            {t('No Webhooks Found')}
          </p>
          <p className='text-textSecondary text-sm'>
            {t("You don't have any webhooks created at this moment.")}
          </p>
        </div>

        <button
          className='py-2 px-3 bg-[#04B25F] text-white capitalize
            rounded-lg flex gap-2 items-center'
          onClick={() => setOpenCreateModal(true)}
        >
          <Plus className='w-4 h-4 text-white' />
          {t('Create New Webhook')}
        </button>
      </div>
    );
  };

  const renderLoadingView = () => {
    return (
      <>
        {renderPageHeader()}
        <div className='w-full px-6 flex flex-col gap-3 h-[calc(100vh-8px)]'>
          <div className='flex-grow h-full w-full flex justify-center items-center'>
            <LoaderCircle
              strokeWidth={2}
              className='text-green-400 w-12 h-12 animate-spin'
            />
          </div>
        </div>
      </>
    );
  };

  const renderPageHeader = () => {
    return (
      <SettingsPageHeader
        title='Webhook'
        subtitle='Webhooks let apps communicate automatically. When an event occurs in one app, like a ticket created, a webhook sends a message to another app, helping automate tasks and keep systems updated.'
        docUrl='https://developers.myalice.ai/webhook/overview'
        docUrlText='Learn more'
      />
    );
  };

  if (isWebhookLoading) {
    return renderLoadingView();
  }
  return (
    <>
      {renderPageHeader()}
      <div className='px-5 w-full'>
        <div>
          {webhookList && webhookList.length > 0 ? (
            <div className='flex-grow h-full w-full'>
              <WebhooksTable
                columns={columns}
                data={webhookList as IWebhookColumnData[]}
              />
            </div>
          ) : (
            renderEmptyState()
          )}
        </div>

        {openCreateModal && (
          <WebhookModal
            actionType='CREATE'
            isOpen={openCreateModal}
            setIsOpen={setOpenCreateModal}
          />
        )}
      </div>
    </>
  );
};

export default WebhookSettings;
