import { FC } from 'react';
import { RavenKnowleadge } from '../RavenKnowleadge';
import { Button } from 'libraryV2/ui/button';
import { ArrowRight, Sparkle } from 'lucide-react';
import { AvailableAgents } from './AvailableAgents';
import { Link } from '@reach/router';
interface RavenDashboardProps {}

export const RavenDashboard: FC<RavenDashboardProps> = () => {
  const renderCustomAgentCard = () => {
    return (
      <div
        className='flex flex-col p-5 w-full h-auto bg-no-repeat bg-cover gap-4 rounded-2xl'
        style={{
          background:
            'radial-gradient(264.37% 141.42% at 0% 0%, #F337E6 0%, #823DD8 50.03%, #63B1F1 100%)',
        }}
      >
        <div className='space-y-2'>
          <h4 className='text-lg font-bold leading-6 text-white'>Agent</h4>
          <p className='text-sm text-white font-medium'>
            Create and manage custom agents to handle customer interactions
            24/7.
          </p>
          <a
            href='https://docs.chatwoot.com/docs/raven'
            target='_blank'
            rel='noreferrer noopener'
            className='underline font-medium text-sm text-blue-200'
          >
            Learn More
          </a>
        </div>
        {/* @ts-ignore outdated type defs */}
        <Link
          to='manage-custom-agents'
          className='bg-white flex items-center gap-1 w-fit py-1 px-1.5 h-8 rounded-md'
        >
          <span className='text-textPrimary text-xs font-medium'>
            Manage Custom Agents
          </span>
          <ArrowRight className='w-4 h-4 text-textPrimary' />
        </Link>
      </div>
    );
  };
  const renderCopilotAgentCard = () => {
    return (
      <div
        className='flex flex-col w-full h-auto bg-no-repeat bg-cover gap-3 rounded-2xl border-solid border border-[#96E6F0]'
        style={{
          background:
            'linear-gradient(244deg, rgba(150, 230, 240, 0.42) 0%, rgba(219, 246, 208, 0.42) 100.74%)',
        }}
      >
        <div className='p-5 text-textPrimary flex flex-col gap-4'>
          <div className='space-y-2'>
            <h4 className='text-lg font-bold leading-6'>Copilot</h4>
            <p className='text-sm font-medium text-textSecondary'>
              Generate responses, adjust tone, and translate messages
              effortlessly.
            </p>
            <a
              href='https://docs.chatwoot.com/docs/raven'
              target='_blank'
              rel='noreferrer noopener'
              className='underline font-medium text-sm text-blue-600'
            >
              Learn More
            </a>
          </div>
          <Button
            variant={'ghost'}
            disabled
            size={'sm'}
            className='bg-indigo-50 flex gap-1 w-fit h-8 shadow-none disabled:opacity-100'
          >
            <span className='text-indigo-400 font-semibold'>Coming Soon!</span>
            <Sparkle className='w-4 h-4 text-indigo-400' />
          </Button>
        </div>
      </div>
    );
  };
  const renderInteligenceAgentCard = () => {
    return (
      <div
        className='flex flex-col w-full h-auto bg-no-repeat bg-cover gap-3 border-solid rounded-2xl border border-[#F5BCF0]'
        style={{
          background: 'linear-gradient(244deg, #FCD1F8 0%, #DCF1FF 100.74%)',
        }}
      >
        <div className='p-5 text-textPrimary flex flex-col gap-4'>
          <div className='space-y-2'>
            <h4 className='text-lg font-bold leading-6'>Intelligence</h4>
            <p className='text-sm font-medium text-textSecondary'>
              Get summaries, performance reports, and actionable
              recommendations.
            </p>
            <a
              href='https://docs.chatwoot.com/docs/raven'
              target='_blank'
              rel='noreferrer noopener'
              className='underline font-medium text-sm text-blue-600'
            >
              Learn More
            </a>
          </div>
          <Button
            variant={'ghost'}
            disabled
            size={'sm'}
            className='bg-indigo-50 flex gap-1 w-fit h-8 shadow-none disabled:opacity-100'
          >
            <span className='text-indigo-400 font-semibold'>Coming Soon!</span>
            <Sparkle className='w-4 h-4 text-indigo-400' />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      {/* page header */}
      <div className='w-full h-[52px] border-border-soft border-b flex items-center px-4'>
        <h1 className='text-textPrimary text-xl font-bold'>Raven</h1>
      </div>
      {/* page content */}
      <div className='border-b border-border-soft  flex flex-1'>
        <div className='flex flex-col w-full'>
          <div
            className='p-6 flex flex-col gap-5 border-border-soft'
            style={{
              background: 'linear-gradient(180deg, #F0FDF4 0%, #FFF 100%)',
            }}
          >
            <div>
              <h2 className='text-textPrimary text-xl font-bold'>
                Powering Your Workflow with{' '}
                <span className='text-primary'>Raven!</span>
              </h2>
              <p className='text-textSecondary text-sm pt-1.5'>
                Unlock the potential of AI to streamline tasks, automate
                interactions,
                <br /> and gain actionable insights - all with Raven by your
                side.
              </p>
            </div>
            <div className='raven-gpt-cards-container flex flex-wrap gap-4'>
              <div className='relative max-w-[300px] sm:w-full flex-grow min-w-0'>
                {renderCustomAgentCard()}
              </div>
              <div className='relative max-w-[300px] flex-grow min-w-0'>
                {renderCopilotAgentCard()}
              </div>
              <div className='relative max-w-[300px] flex-grow min-w-0'>
                {renderInteligenceAgentCard()}
              </div>
            </div>
          </div>
          <div>
            <AvailableAgents />
          </div>
        </div>
        <div className='hidden flex-col w-1/3 border-l border-border'>
          <RavenKnowleadge />
        </div>
      </div>
    </>
  );
};
