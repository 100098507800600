import { FC } from 'react';
import { cn } from 'libraryV2/utils';
import { WhatsappReportingTab } from 'pages/reportingV2/interface';
import { WaMetricsTemplateHistory } from './TemplateHistory';
import { WaMetricsAccountOverview } from './AccountOverview';
import { WaMetricsTemplateOverview } from './TemplateOverView';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'libraryV2/ui/tabs';

export const WhatsappMetricsTabs: {
  value: WhatsappReportingTab;
  label: string;
}[] = [
  {
    value: 'whatsapp-account-overview',
    label: 'WA Accounts Overview',
  },
  {
    value: 'whatsapp-template-overview',
    label: 'Templates Overview',
  },
  {
    value: 'whatsapp-template-history',
    label: 'Templates History',
  },
];

interface WhatsappMetricTabProps {
  currentWhatsappMetricsTab: WhatsappReportingTab;
  updateCurrentWhatsappMetricsTab: (tab: WhatsappReportingTab) => void;
}

export const WhatsappMetricTabs: FC<WhatsappMetricTabProps> = ({
  currentWhatsappMetricsTab,
  updateCurrentWhatsappMetricsTab,
}) => {
  return (
    <Tabs value={currentWhatsappMetricsTab} className='bg-white p-0 relative'>
      <TabsList className='flex  gap-2 w-full rounded-none h-[49px] sticky bg-white z-10 top-[52px] px-4 py-0 border-b justify-start border-border'>
        {WhatsappMetricsTabs.map((tab) => (
          <TabsTrigger
            className={cn(
              'text-sm text-textPrimary h-full p-0 px-2 leading-5 font-medium',
              currentWhatsappMetricsTab === tab.value &&
                'border-0 border-b-2 border-textPrimary rounded-none'
            )}
            value={tab.value}
            onClick={() => {
              updateCurrentWhatsappMetricsTab(tab.value);
            }}
          >
            {tab.label}
          </TabsTrigger>
        ))}
      </TabsList>

      <TabsContent
        value='whatsapp-account-overview'
        className='flex-1 w-full p-0 m-0'
      >
        <div
          data-testid='account-overview-container'
          className='w-full bg-white flex flex-1 flex-col gap-6 relative'
        >
          <WaMetricsAccountOverview />
        </div>
      </TabsContent>
      <TabsContent
        value='whatsapp-template-overview'
        className='flex-1 w-full p-0 m-0'
      >
        <div
          data-testid='template-overview-container'
          className='w-full bg-white flex flex-col gap-6 relative'
        >
          <WaMetricsTemplateOverview />
        </div>
      </TabsContent>
      <TabsContent
        value='whatsapp-template-history'
        className='flex-1 w-full p-0 m-0'
      >
        <div
          data-testid='template-history-container'
          className='w-full bg-white  flex flex-col gap-6 relative'
        >
          <WaMetricsTemplateHistory />
        </div>
      </TabsContent>
    </Tabs>
  );
};
