import useTranslation from 'components/customHooks/useTranslation';
import { FC } from 'react';
import {
  Checkbox,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  DefaultAvatarIcon,
  DropdownField,
  LOCAL_UTILS,
  TextWithTooltip,
  usePolicy,
  useSLA,
} from '../../export';

interface Props {
  selectedAgentsList: Array<string | number>;
  handleAgentListUpdate: (updatedList: Array<number | string>) => void;
}

const AgentSelection: FC<Props> = ({
  selectedAgentsList,
  handleAgentListUpdate,
}) => {
  const { t } = useTranslation();
  const { agentList, authEmail } = useSLA();
  const { inputFieldClass } = usePolicy();
  const currentAgent = agentList.find((agent:any) => agent.admin.email === authEmail);
  // Function to determine the badge color based on the agent's status
  const renderAgentStatusBadgeColor = (status: string) => {
    if (status === 'online') {
      return 'bg-green-400';
    } else if (status === 'away') {
      return 'bg-yellow-400';
    } else return 'bg-[#D1D5DB]';
  };

  const DefaultAgent = () => (
    <CommandItem asChild key={-1} value={t('Assigned Agent')}>
      <div className='flex items-center space-x-2'>
        <Checkbox
          id={'assigned_agent'}
          checked={selectedAgentsList.includes(-1)}
          className={`flex-shrink-0 w-[4.5%] rtl:ml-2 ${
            selectedAgentsList.includes(-1)
              ? 'bg-primary text-white border-transparent'
              : ''
          }`}
          onCheckedChange={() => {
            let copiedAgentList = [...selectedAgentsList];
            const index = copiedAgentList.indexOf(-1);
            if (index !== -1) {
              copiedAgentList.splice(index, 1);
            } else {
              copiedAgentList.push(-1);
            }
            handleAgentListUpdate(copiedAgentList);
          }}
        />
        <div className='relative'>
          <img
            alt='agents'
            loading='lazy'
            className='flex-shrink-0 w-5 h-5 rounded-full'
            src={DefaultAvatarIcon}
          />
          <span
            className={`absolute -bottom-1 -right-1 t-0 inline-block w-2.5 h-2.5 ${renderAgentStatusBadgeColor(
              currentAgent?.admin?.status || ''
            )} border-2 border-white rounded-full`}
          />
        </div>
        <div className='flex items-center w-[80%] space-x-2'>
          <label
            htmlFor={'assigned_agent'}
            className={`flex-grow text-sm font-normal leading-none cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-textPrimary w-2/3`}
          >
            {t('You')}
          </label>
        </div>
      </div>
    </CommandItem>
  );

  const filteredAgentList = authEmail
    ? agentList.filter((agent: any) => agent.admin.email !== authEmail)
    : agentList;

  const AgentSelectionComponet = () => (
    <Command>
      <CommandInput placeholder={t('Search')} />
      <CommandList asChild>
        <CommandEmpty className=' text-textPrimary py-6 text-sm text-center'>
          {t('No results found.')}
        </CommandEmpty>
        <CommandGroup>
          {/* Render DefaultAgent first */}
          <DefaultAgent />

          {filteredAgentList.map((agent: any, index: number) => (
            <CommandItem asChild key={index} value={agent?.admin?.full_name}>
              <div className='flex items-center space-x-2'>
                <Checkbox
                  id={agent?.admin?.id}
                  checked={selectedAgentsList.includes(agent?.admin?.id)}
                  className={`flex-shrink-0 w-[4.5%] rtl:ml-2 ${
                    selectedAgentsList.includes(agent?.admin?.id)
                      ? 'bg-primary text-white border-transparent'
                      : ''
                  }`}
                  onCheckedChange={() => {
                    let copiedAgentList = [...selectedAgentsList];
                    const index = copiedAgentList.indexOf(agent?.admin?.id);
                    if (index !== -1) {
                      copiedAgentList.splice(index, 1);
                    } else {
                      copiedAgentList.push(agent?.admin?.id);
                    }
                    handleAgentListUpdate(copiedAgentList);
                  }}
                />
                <div className='relative'>
                  <img
                    alt='agents'
                    loading='lazy'
                    className='flex-shrink-0 w-5 h-5 rounded-full'
                    src={agent?.admin?.avatar || DefaultAvatarIcon}
                  />
                  <span
                    className={`absolute -bottom-1 -right-1 t-0 inline-block w-2.5 h-2.5 ${renderAgentStatusBadgeColor(
                      agent?.admin?.status || ''
                    )} border-2 border-white rounded-full`}
                  />
                </div>
                <div className='flex items-center w-[80%] space-x-2'>
                  <label
                    htmlFor={agent?.admin?.id}
                    className={`flex-grow text-sm rtl:text-right font-normal leading-none cursor-pointer peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-textPrimary w-2/3`}
                  >
                    <TextWithTooltip
                      text={agent?.admin?.full_name}
                      dataFor={agent?.admin?.full_name}
                      dataTip={agent?.admin?.full_name}
                      shouldShowTooltip={agent?.admin?.full_name.length > 37}
                      className='truncate'
                    />
                  </label>
                  <label className=' text-textSecondary-disable'>
                    {t(agent?.role?.name)}
                  </label>
                </div>
              </div>
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );

  return (
    <DropdownField
      childrenName='person'
      popOverContentClass='w-96'
      buttonClass={`${inputFieldClass}`}
      children={<AgentSelectionComponet />}
      value={LOCAL_UTILS.getAgentNameList(selectedAgentsList, agentList)}
    />
  );
};

export default AgentSelection;
