import useTranslation from 'components/customHooks/useTranslation';
import { AutosizeTextarea } from 'libraryV2/ui/autosize-textarea';
import { Button } from 'libraryV2/ui/button';
import { Input } from 'libraryV2/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'libraryV2/ui/select';
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from 'libraryV2/ui/sheet';
import { toast } from 'libraryV2/ui/use-toast';
import { cn } from 'libraryV2/utils';
import {
  FileText,
  Globe,
  InfoIcon,
  PlayCircleIcon,
  Trash,
  User,
} from 'lucide-react';
import React, { useState } from 'react';
import defaultMediaImage from '../../assets/images/defaultMediaImage.svg';
import useSavedReplies from '../hooks/useSavedReplies';
import {
  ATTACHMENTS_BUTTONS,
  ATTACHMENTS_MIME_TYPES,
  bytesToSize,
  getAttachmentFormat,
  getReplySheetContents,
  initialSavedReplyState,
  MAX_KEYWORD_LENGTH,
  MAX_SAVED_REPLY_ATTACHMENT_COUNT,
  MAX_SAVED_REPLY_LENGTH,
  REPLY_TRIGGER_CHAR,
  TFileUploadResult,
} from '../utils';
import SavedReplyAttachmentUploader from './SavedReplyUploader';
import ViewSavedReply from './ViewSavedReply';

interface SavedReplySheetProps {
  actionType: 'CREATE' | 'EDIT' | 'VIEW';
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  data?: ISavedReply;
}
export interface ISavedReplyAttachment {
  id?: string;
  type: 'image' | 'file' | 'video';
  name: string;
  size: number;
  url: string;
}

export type AttachmentType = ISavedReplyAttachment['type'];

export interface ISavedReply {
  id?: number;
  keyword: string;
  replyText: string;
  attachments: ISavedReplyAttachment[];
  visibleTo: 'only_me' | 'everyone';
  project?: {
    id: number;
    name: string;
  };
}

const SavedReplySheet: React.FC<SavedReplySheetProps> = ({
  actionType,
  isOpen,
  setIsOpen,
  data,
}) => {
  const { t } = useTranslation();

  const [savedReplyData, setSavedReplyData] = useState<ISavedReply>(
    data?.keyword ? data : (initialSavedReplyState as ISavedReply)
  );
  const [attachmentType, setAttachmentType] = useState<AttachmentType>('image');
  const {
    isAdminUser,
    createNewSavedReply,
    savedReplySheetAction,
    setSavedReplySheetAction,
    editSavedReply,
  } = useSavedReplies();

  const sheetContents = getReplySheetContents(savedReplySheetAction);

  const handleKeyword = (keyword: string) => {
    let newKeyword = keyword;
    if (!newKeyword.startsWith(REPLY_TRIGGER_CHAR)) {
      newKeyword = `${REPLY_TRIGGER_CHAR} ${newKeyword}`;
    }
    if (newKeyword === REPLY_TRIGGER_CHAR) {
      newKeyword = '# ';
    }
    setSavedReplyData((old) => ({ ...old, keyword: newKeyword }));
  };

  const handleReplyText = (text: string) => {
    setSavedReplyData((old) => ({ ...old, replyText: text }));
  };

  const appendAttachment = (
    attachmentObj: TFileUploadResult | null,
    attachmentType: AttachmentType
  ) => {
    const attachment = getAttachmentFormat(attachmentObj, attachmentType);
    if (!attachment) {
      return;
    }
    setSavedReplyData((old) => ({
      ...old,
      attachments: [...old.attachments, attachment],
    }));
  };

  const handleDeleteAttachment = (id: string) => {
    setSavedReplyData((old) => ({
      ...old,
      attachments: old.attachments.filter((attachment) => attachment.id !== id),
    }));
  };

  const shouldDisableSubmitButton = () => {
    return (
      !savedReplyData.keyword ||
      !savedReplyData.replyText ||
      !(savedReplyData.keyword.length > 2) ||
      savedReplyData.keyword.length > MAX_KEYWORD_LENGTH ||
      savedReplyData.replyText.length > MAX_SAVED_REPLY_LENGTH
    );
  };

  const renderAttachmentButtons = () => {
    return (
      <div className='flex gap-2'>
        {ATTACHMENTS_BUTTONS.map((attachmentButton) => (
          <Button
            key={attachmentButton.value}
            onClick={() =>
              setAttachmentType(attachmentButton.value as AttachmentType)
            }
            variant={'ghost'}
            className={cn(
              'border border-[#DFDFE2] px-3 py-1.5 text-zinc-900 flex items-center justify-center gap-1.5',
              attachmentType === attachmentButton.value
                ? 'bg-zinc-100 text-zinc-900'
                : 'bg-white text-zinc-900'
            )}
          >
            {attachmentButton.icon}
            <span>{t(attachmentButton.label)}</span>
          </Button>
        ))}
      </div>
    );
  };

  const renderSingleAttachements = (attachment: ISavedReplyAttachment) => {
    const fileName = attachment.name;
    const fileSize = bytesToSize(attachment.size);
    switch (attachment.type) {
      case 'image':
        return (
          <>
            <div className='w-[64px] flex-grow-0 border-r border-zinc-200'>
              <img
                className='h-full w-full rounded-l-xl object-cover'
                src={attachment.url}
                alt={`${fileName}`}
                onError={(e) => {
                  e.currentTarget.onerror = null;
                  e.currentTarget.src = defaultMediaImage;
                }}
              />
            </div>
            <div className='flex flex-grow flex-col justify-center max-w-[70%] self-center text-sm font-semibold leading-5'>
              <p className='text-zinc-900 truncate'>{fileName}</p>
              <p className='text-xs text-zinc-500'>{fileSize}</p>
            </div>
          </>
        );

      case 'video':
        return (
          <>
            <div className='relative h-full w-[64px] flex-shrink-0 cursor-pointer overflow-hidden rounded-l-xl border-r border-zinc-200'>
              <video className='h-full w-full object-cover'>
                <source src={attachment.url} />
              </video>
              <div className='absolute inset-0 bg-black/40 flex justify-center items-center h-full w-full'>
                <PlayCircleIcon className='w-6 h-6 text-white' />
              </div>
            </div>
            <div className='flex flex-col flex-grow justify-center self-center max-w-[70%] text-sm font-semibold leading-5'>
              <p className='text-zinc-900 truncate'>{fileName}</p>
              <p className='text-xs text-zinc-500 w-fit'>{fileSize}</p>
            </div>
          </>
        );

      case 'file':
        return (
          <>
            <div className='w-[64px]  flex justify-center items-center flex-grow-0 border-r border-zinc-200'>
              <FileText className='w-6 h-6 text-zinc-900' />
            </div>
            <div className='flex-grow flex flex-col justify-center self-center max-w-[70%] text-sm font-semibold leading-5'>
              <p className='text-zinc-900 truncate'>{fileName}</p>
              <p className='text-xs text-zinc-500'>{fileSize}</p>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const handleAttachmentUpload = (
    fileUploadResult: TFileUploadResult,
    type: AttachmentType
  ) => {
    if (!fileUploadResult || !type) {
      return;
    }
    appendAttachment(fileUploadResult, type);
  };

  const handleCreateSavedReply = () => {
    createNewSavedReply(savedReplyData)
      .then((res) => {
        if (res?.success) {
          setIsOpen(false);
          setSavedReplyData(initialSavedReplyState as ISavedReply);
          toast({
            duration: 3 * 1000,
            // @ts-expect-error
            title: <p className='text-green-500'>{t('Saved Reply Created')}</p>,
            description: t('Your saved reply has been successfully created.'),
          });
        }
      })
      .catch(() => {
        toast({
          duration: 3 * 1000,
          // @ts-expect-error
          title: (
            <p className='text-red-500'>{t('Unable to create Save reply')}</p>
          ),
          description: t(
            'Something went wrong while creating save reply. Please try again'
          ),
        });
      });
  };

  const onPrimaryAction = () => {
    switch (savedReplySheetAction) {
      case 'CREATE': {
        handleCreateSavedReply();
        return;
      }
      case 'EDIT': {
        editSavedReply(savedReplyData).then((res) => {
          if (res?.success) {
            setIsOpen(false);
          }
        });
        return;
      }
      case 'VIEW': {
        setSavedReplySheetAction('EDIT');
        return;
      }
      default:
        return;
    }
  };

  const renderReplySheetCreateEdit = () => {
    return (
      <div className=' flex flex-col gap-2'>
        <div id='saved-reply-keyword'>
          <p className='text-zinc-900 font-medium'>
            {t('Keyword')} <span className='text-red-500'>*</span>
          </p>
          <div className='relative'>
            <Input
              maxLength={MAX_KEYWORD_LENGTH}
              value={savedReplyData.keyword}
              onChange={(e) => handleKeyword(e.target.value)}
              placeholder={`${REPLY_TRIGGER_CHAR} ${t('Enter a keyword')}`}
              className='focus:border-green-500 focus-visible:ring-0 ltr:pr-20 rtl:pl-20'
            />
            <span className='absolute text-xs font-medium top-1/2 -translate-y-1/2 ltr:right-3 rtl:left-3 w-fit text-zinc-900 bg-zinc-100 px-2 rounded-md'>
              {savedReplyData?.keyword?.length}/{MAX_KEYWORD_LENGTH}
            </span>
          </div>
        </div>
        <div id='saved-reply-text'>
          <p className='text-zinc-900 font-medium'>
            {t('Saved Reply')} <span className='text-red-500'>*</span>
          </p>
          <div className='relative mt-1'>
            <AutosizeTextarea
              minHeight={150}
              maxLength={MAX_SAVED_REPLY_LENGTH}
              value={savedReplyData.replyText}
              onChange={(e) => handleReplyText(e.target.value)}
              placeholder={t('Write your saved reply here')}
              className='focus:border-green-500 border-gray-200 focus-visible:ring-0 pb-8'
            />
            <span className='absolute text-xs font-medium bottom-2 ltr:right-4 rtl:left-4 w-fit text-zinc-900 bg-zinc-100 px-2 rounded-md'>
              {savedReplyData?.replyText?.length}/{MAX_SAVED_REPLY_LENGTH}
            </span>
          </div>
        </div>
        <div id='saved-reply-attachments' className='flex flex-col gap-3'>
          <div>
            <p className='text-zinc-900 font-medium mb-2'>
              {t('Upload Attachments')}
            </p>
            {renderAttachmentButtons()}
          </div>
          <div>
            <SavedReplyAttachmentUploader
              file={''}
              shouldDisable={
                savedReplyData.attachments.length >=
                MAX_SAVED_REPLY_ATTACHMENT_COUNT
              }
              fileType={attachmentType}
              handleClear={() => ''}
              handleFileChange={(url) =>
                handleAttachmentUpload(url, attachmentType)
              }
              fileMimeTypes={ATTACHMENTS_MIME_TYPES[attachmentType]}
            />
          </div>

          <div className='flex flex-col gap-3'>
            {savedReplyData?.attachments?.map((attachment) => (
              <div
                key={attachment.id}
                className='group flex h-[64px] gap-3 rounded-xl border border-zinc-200 hover:bg-zinc-50'
              >
                {renderSingleAttachements(attachment)}
                <div
                  className='ltr:pr-2 rtl:pl-2 hidden group-hover:flex items-center justify-end cursor-pointer'
                  onClick={() => handleDeleteAttachment(attachment.id!)}
                >
                  <Trash className='w-[18px] h-[18px] text-red-500' />
                </div>
              </div>
            ))}
          </div>
          <div
            className={cn(
              'flex gap-2 p-4 rounded-md border border-zinc-200 text-amber-600',
              {
                hidden: !savedReplyData.attachments.length,
              }
            )}
          >
            <div>
              <InfoIcon className='w-5 h-5  text-amber-600' />
            </div>
            <div>
              <p className='font-medium text-sm leading-5'>
                {t('Attachment Sending Restriction')}
              </p>
              <ul className='list-inside list-disc marker:text-amber-800 text-amber-700 ltr:pl-2 rtl:pr-2'>
                <li>{t('Facebook Feed only support images.')}</li>
                <li>{t("Viber doesn't support videos.")}</li>
                <li>{t("Instagram Feed doesn't support any attachments.")}</li>
              </ul>
            </div>
          </div>
        </div>

        <div id='saved-reply-visible-to' className='flex flex-col gap-1'>
          <p className='text-zinc-900'>
            {t('Visible to')}
            <span className='text-red-500'>*</span>
          </p>
          <Select
            disabled={isAdminUser === false}
            value={savedReplyData.visibleTo}
            onValueChange={(value) =>
              setSavedReplyData((old) => ({
                ...old,
                visibleTo: value as ISavedReply['visibleTo'],
              }))
            }
          >
            <SelectTrigger className='w-full rtl:flex-row-reverse focus:ring-green-500 text-zinc-900'>
              <SelectValue
                className='text-zinc-900 text-sm'
                placeholder={t('Select an option')}
              />
            </SelectTrigger>
            <SelectContent className='mt-1 bg-white'>
              <SelectItem
                className='hover:bg-zinc-100 rounded-md rtl:flex-row-reverse'
                value='only_me'
              >
                <div className='flex gap-2 items-center text-zinc-900'>
                  <User size={18} className='rtl:order-1' />
                  <span>{t('Only me')}</span>
                </div>
              </SelectItem>
              <SelectItem
                className='hover:bg-zinc-100 rounded-md rtl:flex-row-reverse'
                value='everyone'
              >
                <div className='flex gap-2 items-center text-zinc-900'>
                  <Globe size={18} className='rtl:order-1' />
                  <span>{t('Everyone in the team')}</span>
                </div>
              </SelectItem>
            </SelectContent>
          </Select>
          <p
            className={cn('text-zinc-500 text-sm leading-5', {
              hidden: !isAdminUser,
            })}
          >
            {t('Only admins can create saved reply for everyone.')}
          </p>
        </div>
      </div>
    );
  };

  return (
    <Sheet
      key={savedReplySheetAction + data?.keyword}
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
        setSavedReplyData(initialSavedReplyState as ISavedReply);
      }}
    >
      <SheetContent
        className='bg-white sm:max-w-[480px] overflow-auto flex flex-col'
        onOpenAutoFocus={(e) => {
          e.preventDefault();
        }}
        onInteractOutside={(e) => e.preventDefault()}
      >
        <SheetHeader>
          <SheetTitle className='text-zinc-900 rtl:text-right text-lg font-semibold'>
            {t(sheetContents.title)}
          </SheetTitle>
          <SheetDescription className='text-sm rtl:text-right text-zinc-500'>
            {t(sheetContents.description)}
          </SheetDescription>
        </SheetHeader>
        {savedReplySheetAction === 'VIEW' ? (
          <ViewSavedReply savedReply={data!} />
        ) : (
          renderReplySheetCreateEdit()
        )}
        <SheetFooter className='text-zinc-900 mt-auto rtl:flex-row-reverse'>
          <SheetClose asChild>
            <Button variant={'outline'} className='shadow-none' type='button'>
              {t(sheetContents.secondaryActionText)}
            </Button>
          </SheetClose>
          <Button
            disabled={actionType !== 'VIEW' && shouldDisableSubmitButton()}
            type='button'
            onClick={onPrimaryAction}
            className='disabled:bg-zinc-100 disabled:text-zinc-400 disabled:opacity-100 disabled:shadow-none disabled:cursor-not-allowed bg-[#04B25F]'
          >
            {t(sheetContents.primaryActionText)}
          </Button>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
};

export default SavedReplySheet;
