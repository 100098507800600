import config from 'utilities/config';
import axios from 'utilities/httpClient';

const initialState = {
  customAgentList: [],
  totalCustomAgentCount: 0,
  currentTeamAgent: {
    teamId: 0,
    agentList: [],
  },
  availableTokens: 0,
  usableTokens: 0,
  availableModelList: [],
  availableEmbedModelList: [],
  customAgentFormError: [],
  customAgentFormStatus: {
    hasUnSavedData: false,
    isInvalid: false,
  },
  customAgentFormData: {
    id: '',
    status: 'draft',
    name: '',
    public_description: '',
    backstory: '',
    role: '',
    model_used: '',
    memory_usages: 5,
    memory_limit: 100,
    temperature: 0.0,
    top_p: 0.0,
    tools: {},
  },
};

export const raven = {
  state: {
    ...initialState,
  },
  reducers: {
    /**
     * Updates state data by merging new key-value pairs into the existing state.
     * Accepts either a single key-value pair or an array of key-value pairs.
     * @example
     * // Single update
     * updateStateData( { key: 'name', value: 'John' })
     * @example
     * // Multiple updates
     * updateStateData([
     *   { key: 'name', value: 'John' },
     *   { key: 'age', value: 30 }
     * ])
     */
    updateStateData(state, payload) {
      const stateObject = {};
      if (Array.isArray(payload)) {
        // Handle array payload
        for (let i = 0; i < payload.length; i++) {
          stateObject[payload[i].key] = payload[i].value;
        }
      } else if (payload && payload?.key) {
        // Handle single object payload
        stateObject[payload.key] = payload.value;
      }

      return { ...state, ...stateObject };
    },
    resetCreateCustomAgentFormData(oldState) {
      return {
        ...oldState,
        customAgentFormData: initialState['customAgentFormData'],
      };
    },
    resetStateData(state) {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchRavenCustomAgentList(payload) {
      try {
        const response = await axios.get(config.raven.getAgentList(payload));
        const customAgentList = response.data?.custom_agents;
        const totalAgentCount = response.data?.count;
        dispatch.raven.updateStateData([
          {
            key: 'customAgentList',
            value: customAgentList,
          },
          {
            key: 'totalCustomAgentCount',
            value: totalAgentCount,
          },
        ]);
      } catch (error) {
        console.log('error', error);
      }
    },
    async fetchTeamAgentList(teamId) {
      try {
        const response = await axios.get(config.raven.getTeamAgentList(teamId));
        const teamAgentList = response.data?.dataSource;
        dispatch.raven.updateStateData({
          key: 'currentTeamAgent',
          value: {
            teamId: teamId,
            agentList: teamAgentList || [],
          },
        });
      } catch (error) {
        console.log('error', error);
      }
    },
    async deleteCustomAgent(payload) {
      const customAgentIds = Array.isArray(payload?.customAgentIds)
        ? payload?.customAgentIds
        : [payload?.customAgentIds];

      try {
        return await axios.post(
          config.raven.deleteBulkCustomAgent(),
          customAgentIds
        );
      } catch (er) {
        console.log(er);
        return false;
      }
    },
    async deleteSingleCustomAgent(agentId, rootState) {
      try {
        await axios.delete(config.raven.deleteSingleCustomAgent(agentId));
        const customAgentList = rootState?.raven?.customAgentList?.filter(
          (agent) => agent?.id !== agentId
        );
        const totalCustomAgentCount = rootState?.raven?.totalCustomAgentCount;
        dispatch.raven.updateStateData([
          {
            key: 'customAgentList',
            value: customAgentList,
          },
          {
            key: 'totalCustomAgentCount',
            value: totalCustomAgentCount - 1,
          },
        ]);
        return true;
      } catch (er) {
        console.log(er);
        throw er;
      }
    },
    async createSingleCustomAgent(payload) {
      try {
        const response = await axios.post(
          config.raven.createCustomAgent(),
          payload
        );
        const agentId = response?.data?.id;
        return { id: agentId };
      } catch (er) {
        console.log(er);
        throw er;
      }
    },
    async updateSingleCustomAgent(payload) {
      try {
        const clonedCustomAgentData = structuredClone(payload);
        delete clonedCustomAgentData?.id;
        const response = await axios.put(
          config.raven.updateCustomAgent(payload?.id),
          clonedCustomAgentData
        );
        return response?.data;
      } catch (er) {
        console.log(er);
        throw er;
      }
    },
    async fetchSingleCustomAgent(agentId) {
      try {
        const response = await axios.get(
          config.raven.fetchSingleCustomAgent(agentId)
        );
        return response?.data;
      } catch (er) {
        console.log(er);
        throw er;
      }
    },
    async fetchGptModelList() {
      try {
        const response = await axios.get(config.raven.getGptModelList());
        dispatch.raven.updateStateData({
          key: 'availableModelList',
          value: response?.data || [],
        });
      } catch (er) {
        console.log(er);
        throw er;
      }
    },
    async fetchEmbedModelList() {
      try {
        const response = await axios.get(config.raven.getEmbedModelList());
        dispatch.raven.updateStateData({
          key: 'availableEmbedModelList',
          value: response?.data || [],
        });
      } catch (er) {
        console.log(er);
        throw er;
      }
    },
    async fetchAgentChatHistory(agentId) {
      try {
        const response = await axios.get(
          config.raven.getSpecificAgentChatHistory(agentId)
        );
        return response?.data;
      } catch (er) {
        console.log(er);
        throw er;
      }
    },
    async deleteAgentChatHistory(agentId) {
      try {
        await axios.delete(
          config.raven.deleteSpecificAgentChatHistory(agentId)
        );
        return true;
      } catch (er) {
        console.log(er);
        return false;
      }
    },
    async fetchWebsitePageList(payload) {
      try {
        const response = await axios.post(config.raven.crawlWebsite(), payload);
        return response?.data;
      } catch (error) {
        throw error;
      }
    },
    async trainSingleDocument(payload) {
      //mocking the response
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(true);
        }, 3 * 1000);
      });
    },
  }),
};

export default raven;
