import { FC, useEffect, useRef, useState } from 'react';
import { AgentMessageLoading } from './AgentMessageLoading';
import { ScrollArea } from 'libraryV2/ui/scroll-area';
import { Button } from 'libraryV2/ui/button';
import { cn } from 'libraryV2/utils';
import { ArrowUp } from 'lucide-react';
import { AutosizeTextarea } from 'libraryV2/ui/autosize-textarea';
import { useDispatch } from 'react-redux';
import { SingleTestChatMessage } from './SingleTestChatMessage';
import { formatTestMessageData } from 'pages/raven/utils/utility';
import { TestMessageSystemUpdate } from './TestMessageSystemUpdate';
import { ravenTestAgentWebsocketUrl } from 'utilities/config';
interface ChatMessagesProps {
  customAgentId: string;
  isChatHistoryCleared: boolean;
  setIsAgentConnected: (status: boolean) => void;
}

export const ChatMessages: FC<ChatMessagesProps> = ({
  customAgentId,
  setIsAgentConnected,
  isChatHistoryCleared,
}) => {
  const dispatch = useDispatch();
  const [messageList, setMessageList] = useState<
    typeof formatTestMessageData[]
  >([]);
  const [newMessage, setNewMessage] = useState('');
  const scrollViewportRef = useRef(null);
  const lastMessageRef = useRef<HTMLDivElement>(null);
  const [isAgentThinking, setIsAgentThinking] = useState(false);
  const websocketServerLocation = `${ravenTestAgentWebsocketUrl}/api/v1/chat_test/ws/send_message/${customAgentId}/`;
  const socketRef = useRef<WebSocket | null>(null);
  const scrollToBottom = () => {
    if (lastMessageRef?.current) {
      lastMessageRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const appendNewMessage = () => {
    if (
      !socketRef.current ||
      socketRef.current?.readyState !== WebSocket.OPEN ||
      !newMessage?.length
    ) {
      return;
    }
    socketRef.current.send(
      JSON.stringify({ content: newMessage, role: 'user' })
    );
    setNewMessage('');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      if (event.shiftKey) {
        // Allow new line when Shift+Enter is pressed
        return;
      } else {
        // Prevent default Enter key behavior and send the message
        event.preventDefault();
        appendNewMessage();
      }
    }
  };

  const renderSingleMessage = (message: any) => {
    const formatedData = message;
    if (formatedData?.source === 'user') {
      return (
        <SingleTestChatMessage
          messageText={formatedData?.content}
          messageSource={formatedData?.source}
          time={formatedData?.time}
          key={formatedData?.id}
        />
      );
    }
    return (
      <>
        <TestMessageSystemUpdate systemData={formatedData?.systemUpdates} />
        <SingleTestChatMessage
          messageText={formatedData?.content}
          messageSource={formatedData?.source}
          time={formatedData?.time}
          key={formatedData?.id}
        />
      </>
    );
  };

  useEffect(() => {
    // Create a WebSocket connection
    if (!socketRef?.current) {
      socketRef.current = new WebSocket(websocketServerLocation);
    }

    // Handle incoming messages
    socketRef.current.onmessage = (event) => {
      const isUserMessage = JSON.parse(event.data)?.role === 'user';
      if (!isUserMessage) {
        setIsAgentThinking(false);
      } else {
        setIsAgentThinking(true);
      }
      // @ts-expect-error
      setMessageList((prevMessages) => [
        ...prevMessages,
        formatTestMessageData(JSON.parse(event?.data || {})),
      ]);
    };

    // Handle connection errors
    socketRef.current.onerror = (error) => {
      setIsAgentThinking(false);
      setIsAgentConnected(false);
      console.error('WebSocket error:', error);
    };
    socketRef.current.onopen = (error) => {
      setIsAgentConnected(true);
    };
    // Handle connection closure
    socketRef.current.onclose = () => {
      setIsAgentThinking(false);
      setIsAgentConnected(false);
    };

    // Clean up on component unmount
    return () => {
      socketRef?.current?.close();
      socketRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch chat history
  useEffect(() => {
    dispatch.raven?.fetchAgentChatHistory(customAgentId).then((res: any) => {
      const formatedData = res?.map((v: any) => formatTestMessageData(v));
      setMessageList(formatedData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChatHistoryCleared]);

  useEffect(() => {
    scrollToBottom();
  }, [messageList]);
  return (
    <>
      <ScrollArea
        ref={scrollViewportRef}
        type='auto'
        className='flex-1 min-h-0'
      >
        <div className='py-4 ltr:pr-4 rtl:pl-4'>
          <div className='flex flex-1 flex-col gap-4 flex-grow'>
            {messageList.map((message: any) => renderSingleMessage(message))}
            {isAgentThinking && <AgentMessageLoading />}
          </div>
          <div ref={lastMessageRef} />
        </div>
      </ScrollArea>
      <div className='mt-2 flex gap-2 w-full items-center'>
        <AutosizeTextarea
          maxHeight={200}
          value={newMessage}
          className='py-1.5 px-2.5 focus-visible:ring-0 border-border focus:border-primary'
          onKeyDown={handleKeyDown}
          onChange={(e) => setNewMessage(e.currentTarget.value)}
          placeholder='Ask your agent...'
          minHeight={40}
        />
        <Button
          variant={'ghost'}
          className=' bg-background-hover'
          onClick={appendNewMessage}
        >
          <ArrowUp
            className={cn('w-full h-full text-textPrimary', {
              'text-textPrimary-disable': !newMessage.trim().length,
            })}
          />
        </Button>
      </div>
    </>
  );
};
