import { useNavigate } from '@reach/router';
import { Button } from 'libraryV2/ui/button';
import { ChevronLeft } from 'lucide-react';
import { FC } from 'react';
import { RavenAgentTableContainer } from './ravenAgentTable/AgentTableContainer';

interface ManageAgentProps {}

export const ManageRavenAgents: FC<ManageAgentProps> = () => {
  const navigate = useNavigate();

  return (
    <div
      data-testid='raven-manage-agent-container'
      className='h-full w-full flex-1 min-h-[calc(100vh-2px)] flex flex-col bg-white ltr:border-l rtl:border-r border-border'
    >
      <div className='w-full h-[52px] border-border-soft border-b flex gap-3 items-center px-4'>
        <Button
          variant={'outline'}
          className='h-8 w-8 text-textPrimary p-0'
          onClick={() => navigate(-1)}
        >
          <ChevronLeft className='h-4 w-4 text-textPrimary' />
        </Button>
        <h1 className='text-textPrimary text-xl leading-7 font-bold'>
          Manage Custom Agents
        </h1>
      </div>

      <div className='flex flex-col gap-4 p-4 flex-1'>
        <RavenAgentTableContainer />
      </div>
    </div>
  );
};
