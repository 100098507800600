import 'old/pages/settings/assets/scss/settings.scss';
import { SidebarProvider } from 'libraryV2/ui/sidebar';
import { SettingsSidebar } from './components/SettingsSidebar';
import { FC, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import useSettings from './hooks/useSettings';

interface SettingsV2Props {
  publicKey: string;
  children: React.ComponentElement<any, any>;
}
const SettingsV2: FC<SettingsV2Props> = ({ children }) => {
  const {
    publicKey,
    getPublicKey
  } = useSettings();

  useEffect(() => {
    const fetchPublicKey = async () => {
      await getPublicKey();
    }
    fetchPublicKey();
    // eslint-disable-next-line
  }, [])

  // This is a hacky solution to remove parent component scrollbar from child.
  // It directly modifies the overflow property of the '.scrollbar__remove' element.
  // Please avoid making this a habit, as direct DOM manipulation can lead to maintenance issues.
  useEffect(() => {
    const scrollabarDiv: any = document.querySelector('.scrollbar__remove');
    if (scrollabarDiv) {
      scrollabarDiv.style.overflowY = 'hidden';
    }
  }, []);

  return (
    <div className='layout__wrapper ml-[1px] bg-white'>
      <SidebarProvider>
        <SettingsSidebar />
      </SidebarProvider>
      {!!publicKey && (
        <Elements stripe={loadStripe(publicKey)}>
          <div className='layout__content'>
            {children}
          </div>
        </Elements>
      )}
    </div>
  );
};

export default SettingsV2;
