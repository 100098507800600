import axios from '../../../../utilities/httpClient';
import config from '../../../../utilities/config';
import { toast } from 'libraryV2/ui/use-toast';

const initialState = {
  agentRoleList: [],
  agentAccessList: [],
  selectedRole: null,
};

export const settingsUserManagementConfig = {
  state: {
    ...initialState,
  },
  reducers: {
    updateAgentRoleList(state, payload) {
      return { ...state, agentRoleList: payload };
    },
    updateAgentAccessList(state, payload) {
      return { ...state, agentAccessList: payload };
    },
    updateSelectedRole(state, payload) {
      return { ...state, selectedRole: payload };
    },
    clearState() {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchAgentroleList(payload) {
      try {
        const res = await axios.get(config.settings.agentRoles(payload));
        if (res.status === 200) {
          dispatch.settingsUserManagementConfig.updateAgentRoleList(
            res.data.dataSource
          );
          return {
            status: true,
            data: res.data,
          };
        }
      } catch (err) {
        toast({
          title: (
            <p className='text-red-500'>Unable to fetch Agent roles data</p>
          ),
          description: `Something went wrong while fetching agent roles data. Please try again`,
        });
        return {
          status: false,
          res: null,
        };
      }
    },
    async fetchAgentAccessList(payload) {
      try {
        const res = await axios.get(config.settings.agentAccess(payload));
        if (res.status === 200) {
          dispatch.settingsUserManagementConfig.updateAgentAccessList(
            res.data.dataSource?.filter((u) => !u.admin?.is_llm_agent) ?? []
          );
          return {
            status: true,
            data: res.data,
          };
        }
      } catch (err) {
        toast({
          title: (
            <p className='text-red-500'>Unable to fetch Agent access data</p>
          ),
          description: `Something went wrong while fetching agent access data. Please try again`,
        });
        return {
          status: false,
          res: null,
        };
      }
    },
    async sendMultipleInvitations(payload, state) {
      try {
        const res = await axios.post(
          config.settings.sendMultipleInvitation(payload.teamId),
          payload.requestBody
        );
        if (res.data.success) {
          toast({
            title: (
              <p className='text-[#04B25F]'>Invitation Sent Successfully</p>
            ),
            description: `Your invitation has been sent to the selected ${state.settingsUserManagementConfig.selectedRole?.name}(s).`,
          });
          return {
            status: true,
          };
        } else {
          toast({
            title: (
              <p className='text-red-500'>
                Unable to Invite{' '}
                {state.settingsUserManagementConfig.selectedRole?.name}(s)
              </p>
            ),
            description: `${res.data.error}`,
          });
          return {
            status: false,
            res: null,
          };
        }
      } catch (err) {
        toast({
          title: (
            <p className='text-red-500'>
              Unable to Invite{' '}
              {state.settingsUserManagementConfig.selectedRole?.name}(s)
            </p>
          ),
          description: `${err.response.data.error}`,
        });
        return {
          status: false,
          res: null,
        };
      }
    },
    async changeUserRole(payload) {
      try {
        const res = await axios.patch(
          config.settings.userActionInTeam(payload.teamid, payload.userId),
          { role_id: payload.role_id }
        );
        if (res.status === 200) {
          toast({
            title: (
              <p className='text-[#04B25F]'>User Role Changed Successfully</p>
            ),
            description: `User role has been changed`,
          });
          return {
            status: true,
            data: res.data,
          };
        }
      } catch (err) {
        toast({
          title: <p className='text-red-500'>Unable to Change Agent Role</p>,
          description: `${err.response.data.error}`,
        });
        return {
          status: false,
          res: null,
        };
      }
    },
    async deleteUserFromTeam(payload) {
      try {
        const res = await axios.delete(
          config.settings.userActionInTeam(payload.teamId, payload.userId)
        );

        if (res.data.success) {
          toast({
            title: <p className='text-[#04B25F]'>Delete Successful</p>,
            description: 'Selected user has been permanently deleted.',
          });
          return {
            status: true,
          };
        }
      } catch (err) {
        toast({
          title: (
            <p className='text-red-500'>Unable to Delete User from Team</p>
          ),
          description: `${err.response.data.error}`,
        });
        return false;
      }
    },
    async deleteMultipleUserFromTeam(payload) {
      try {
        const res = await axios.delete(
          config.settings.deleteMultipleUsersFromTeam(payload.teamId),
          {
            data: { ids: payload.ids },
          }
        );

        if (res.data.success) {
          toast({
            title: <p className='text-[#04B25F]'>Delete Successful</p>,
            description: 'Selected user(s) has been permanently deleted.',
          });
          return {
            status: true,
          };
        }
      } catch (err) {
        toast({
          title: (
            <p className='text-red-500'>Unable to Delete User(s) from Team</p>
          ),
          description: `Something went wrong while deleting user(s) form team. Please try again`,
        });
        return false;
      }
    },
  }),
};
