import { Button } from 'libraryV2/ui/button';
import { FileText } from 'lucide-react';
import { useCustomAgentTools } from 'pages/raven/hooks/useCustomAgentTools';
import { FC, useState } from 'react';
import RavenTrainButtonBg from 'pages/raven/assets/RavenTrainButtonBg.svg';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'libraryV2/ui/table';

interface ManageWebsitePageListProps {}
const dummyData = [
  {
    invoice: 'INV001',
    paymentStatus: 'Paid',
    totalAmount: '$250.00',
    paymentMethod: 'Credit First Card',
  },
  {
    invoice: 'INV002',
    paymentStatus: 'Pending',
    totalAmount: '$150.00',
    paymentMethod: 'PayPal',
  },
];
export const ManageWebsitePageList: FC<ManageWebsitePageListProps> = () => {
  const [crawledWebsiteList] = useState(dummyData);
  const { scrapeWebsiteContent } = useCustomAgentTools();
  const renderWebsitePageTable = () => {
    return (
      <Table className='w-full'>
        <TableHeader className='sticky top-0 z-10'>
          <TableRow>
            <TableHead>Pages</TableHead>
            <TableHead className='w-[150px]'>AI tokens required</TableHead>
            <TableHead className='w-[150px] ltr:text-right px-4 rlt:text-left'>
              Status
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {crawledWebsiteList.map((website, index) => (
            <TableRow key={index + website.invoice}>
              <TableCell className='font-medium'> {website.invoice} </TableCell>
              <TableCell className='text-left'>
                {website?.totalAmount}
              </TableCell>
              <TableCell className='flex w-full justify-end'>
                <Button
                  type='button'
                  size={'sm'}
                  style={{
                    backgroundImage: `url(${RavenTrainButtonBg})`,
                    backgroundSize: 'cover',
                  }}
                  className='h-6'
                >
                  Train
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };
  return (
    <div className='w-full flex flex-col gap-3'>
      <div className='w-full flex justify-end'>
        <Button
          type='button'
          size={'sm'}
          style={{
            backgroundImage: `url(${RavenTrainButtonBg})`,
            backgroundSize: 'cover',
          }}
          onClick={async () => {
            scrapeWebsiteContent({ url: 'https://myalice.ai' });
          }}
          className='gap-2'
        >
          <FileText className='w-4 h-4 text-white' />
          Fetch Links
        </Button>
      </div>
      <div className='border border-border relative rounded-lg h-[200px] overflow-y-auto'>
        {renderWebsitePageTable()}
      </div>
    </div>
  );
};
