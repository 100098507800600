import { CheckCircleIcon } from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';
import { chatheadDataProps } from 'pages/integration/interface';
import { useEffect, useRef, useState } from 'react';
import { handleImageUpload } from 'utilities/utils';
import defaultMediaImage from '../../../../../../../../assets/images/default-product-image.svg';
import { DefaultIcons } from '../../../utils/ChatHeadIcons';

interface Props {
  size: string;
  iconId: number;
  bgColor: string;
  iconColor: string;
  chatHeadText: string;
  customImageUrl: string;

  handleChange: (data: chatheadDataProps[]) => void;
  updateCustomIcon: (url: string) => void;
}

const ChatHeadIcons: React.FC<Props> = ({
  size,
  iconId,
  bgColor,
  iconColor,
  chatHeadText,
  customImageUrl,
  handleChange,
  updateCustomIcon,
}) => {
  const { t } = useTranslation();
  const [strokeColor, setStrokeColor] = useState<string>(iconColor);
  const [userIcon, updateUserIcon] = useState<any>('');
  const [iconIds, setIconIds] = useState<number[]>([1, 2, 3]);

  useEffect(() => {
    setStrokeColor(iconColor);
    if (customImageUrl && customImageUrl.length > 3) {
      iconIds[3] = 4;
      setIconIds([...iconIds]);
      updateUserIcon(customImageUrl);
    } else {
      setIconIds(iconIds.filter((id) => id !== 4));
      updateUserIcon('');
    }
    // eslint-disable-next-line
  }, [iconColor, customImageUrl]);

  useEffect(() => {
    if ((size === 'large' || size === 'text_only') && iconId === 4) {
      handleIconSelection(1);
    }
    //eslint-disable-next-line
  }, [size]);

  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleClick = () => {
    if (!!inputRef.current) inputRef.current.click();
  };

  const handleFileChange = async (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    handleImageUpload(fileObj, (res: string) => {
      iconIds[3] = 4;
      setIconIds([...iconIds]);
      updateCustomIcon(res);
      updateUserIcon(res);
    });
  };

  const handleIconSelection = (id: number) => {
    let updatedDataList: chatheadDataProps[] = [];
    if (id === 4) {
      updatedDataList.push({
        key: 'is_chat_head_logo_custom',
        value: true,
      });
      updatedDataList.push({
        key: 'chat_head_id',
        value: id,
      });
      updatedDataList.push({
        key: 'chat_head_url',
        value: userIcon,
      });
    } else {
      updatedDataList.push({
        key: 'is_chat_head_logo_custom',
        value: false,
      });
      updatedDataList.push({
        key: 'chat_head_id',
        value: id,
      });
    }

    handleChange(updatedDataList);
  };

  const iconViewWithText = (id: number) => {
    if (size === 'small') {
      return <DefaultIcons id={id} color={strokeColor} />;
    } else if (size === 'large') {
      return (
        <div className='flex gap-2 items-center'>
          <DefaultIcons
            id={id}
            color={strokeColor}
            classNames={'w-6 h-6 inline'}
          />
          <span
            className='inline text-sm font-medium'
            style={{ color: iconColor }}
          >
            {chatHeadText}
          </span>
        </div>
      );
    } else {
      return (
        <div className='inline-block'>
          <span
            className='inline text-sm font-medium'
            style={{ color: iconColor }}
          >
            {chatHeadText}
          </span>
        </div>
      );
    }
  };

  const iconView = () => {
    if (size === 'small' || size === 'large') {
      return iconIds.map((id: number) => {
        if (id !== 4) {
          return (
            <div
              key={id}
              onClick={() => {
                handleIconSelection(id);
              }}
              className={`relative inline-block ltr:mr-2 rtl:ml-2  ${
                size === 'small'
                  ? ' p-3 rounded-2xl'
                  : 'px-3 py-[18px] rounded-3xl'
              } shadow-sm`}
              style={{ backgroundColor: bgColor }}
            >
              {iconViewWithText(id)}

              <div
                className={`absolute bg-transparent p-0 rounded-full ${
                  iconId === id ? 'block' : 'hidden'
                } ${
                  size === 'small'
                    ? 'right-[-3px] top-[-3px]'
                    : 'right-[-2px] top-[-2px]'
                }`}
              >
                <CheckCircleIcon
                  height={size === 'small' ? 16 : 20}
                  width={size === 'small' ? 16 : 20}
                  fill={bgColor}
                  stroke={'white'}
                />
              </div>
            </div>
          );
        } else {
          return (
            <div
              key={id}
              onClick={() => {
                handleIconSelection(id);
              }}
              className={`relative  w-12 h-12 rounded-2xl shadow-sm ${
                size === 'small' ? 'inline-block' : 'hidden'
              }`}
            >
              <img
                src={userIcon}
                className='w-full h-full rounded-2xl '
                alt='c-icon'
                onError={(e) => {
                  e.currentTarget.src = defaultMediaImage;
                }}
              />
              <div
                className={`absolute bg-transparent p-0 rounded-full right-[-5px] top-[-5px] ${
                  iconId === id ? 'block' : 'hidden'
                }`}
              >
                <CheckCircleIcon
                  height={16}
                  width={16}
                  fill={bgColor}
                  stroke={'white'}
                />
              </div>
            </div>
          );
        }
      });
    } else {
      return (
        <div
          key={100}
          onClick={() => {
            handleIconSelection(1);
          }}
          className={`relative inline-block ltr:mr-2 rtl:ml-2 px-3 py-[18px] rounded-3xl shadow-sm`}
          style={{ backgroundColor: bgColor }}
        >
          {iconViewWithText(1)}

          <div
            className={`absolute bg-transparent p-0 rounded-full block right-[-2px] top-[-2px]`}
          >
            <CheckCircleIcon
              height={20}
              width={20}
              fill={bgColor}
              stroke={'white'}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <label className='flex mb-4 text-sm font-medium text-gray-700'>
        {t('Chat Head Type')}
      </label>
      <div className='flex w-full'>{iconView()}</div>

      <br />
      <br />

      <div className={`${size === 'small' ? 'block' : 'hidden'}`}>
        <input
          className='hidden'
          ref={inputRef}
          type='file'
          accept='image/*'
          onChange={handleFileChange}
        />
        <button
          type='button'
          onClick={handleClick}
          className='px-3 py-2 mb-3 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none'
        >
          {userIcon ? t('Change') : t('Upload')}
        </button>
        <button
          type='button'
          className={`text-red-500 ml-2 font-medium text-sm leading-5 ${
            iconId === 4 ? 'inline-block' : 'hidden'
          }`}
          onClick={() => {
            updateCustomIcon('');
            handleIconSelection(1);
          }}
        >
          {t('remove')}
        </button>
        <br />
        <span className='text-xs text-gray-500'>
          {t('Min. 80x80, Max 250x250')}{' '}
        </span>
      </div>
    </div>
  );
};

export default ChatHeadIcons;
