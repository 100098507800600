import React from 'react';
import GroupsCRUD from '../component/team-group/GroupsCRUD';
import GroupsList from '../component/team-group/GroupsList';

const TeamGroupSettings: React.FC<{}> = () => {
  const [currentGroup, setGroup] =
    React.useState<GroupDetailsCardInterface | null>(null);
  
  return (
    <div>
      {!currentGroup && <GroupsList setGroup={setGroup} />}
      <div className='px-5 my-7'>
        {currentGroup && (
          <GroupsCRUD currentGroup={currentGroup} setGroup={setGroup} />
        )}
      </div>
    </div>
  );
};

export default TeamGroupSettings;
