import { ICustomAgentFormSteps } from 'pages/raven/interface';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { toast } from 'libraryV2/ui/use-toast';
import { Button } from 'libraryV2/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'libraryV2/ui/form';
import { Input } from 'libraryV2/ui/input';
import { FC } from 'react';
import { cn } from 'libraryV2/utils';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'libraryV2/ui/select';
import { Slider } from 'libraryV2/ui/slider';
import { useCreateCustomAgent } from 'pages/raven/hooks/useCreateCustomAgent';
const ConfigurationFormSchema = z.object({
  model_used: z.string().min(1, {
    message: 'This field is required.',
  }),
  memory_usages: z.coerce
    .number()
    .nonnegative("Memory usages can't be less then 0")
    .max(100, {
      message: "Memory usages can't be more then 100",
    }),
  memory_limit: z.coerce.number().nonnegative().gte(0).lte(2000),
  temperature: z.coerce.number().nonnegative().max(1.0),
  top_p: z.number().nonnegative().max(1.0),
});

interface ConfigurationProps {
  step: ICustomAgentFormSteps;
  onNextStep: () => void;
  onPreviousStep: () => void;
}
export const Configuration: FC<ConfigurationProps> = ({
  step,
  onNextStep,
  onPreviousStep,
}) => {
  const {
    customAgentFormData,
    avialableGptAgentList,
    updateCustomAgent,
    updateCustomAgentFormData,
  } = useCreateCustomAgent();

  // form hook setup
  const form = useForm<z.infer<typeof ConfigurationFormSchema>>({
    resolver: zodResolver(ConfigurationFormSchema),
    mode: 'onChange',
    defaultValues: {
      model_used: customAgentFormData?.model_used || '',
      memory_usages: customAgentFormData?.memory_usages || 5,
      memory_limit: customAgentFormData?.memory_limit || 100,
      temperature: customAgentFormData?.temperature || 0.0,
      top_p: customAgentFormData?.top_p || 0.0,
    },
  });

  function onSubmit(data: z.infer<typeof ConfigurationFormSchema>) {
    updateCustomAgent({
      gptmodel_id: data?.model_used,
      memory_limit: data?.memory_limit,
      temperature: data?.temperature,
      top_p: data?.top_p,
      past_conversation_no: data?.memory_usages,
    })
      .then((res) => {
        toast({
          duration: 3 * 1000,
          toastType: 'success',
          title: 'Save Changes',
          description: (
            <p className='text-textPrimary text-xs'>
              Your changes have been saved successfully.
            </p>
          ),
        });
        onNextStep();
        updateCustomAgentFormData({ ...customAgentFormData, ...data });
      })
      .catch(() => {
        toast({
          toastType: 'destructive',
          title: 'Failed',
          description: (
            <p className='text-textPrimary text-xs'>
              Failded to save changes. Please try again.
            </p>
          ),
        });
      });
  }
  function onDraftSave() {
    const data = form.getValues();
    updateCustomAgent({
      gptmodel_id: data?.model_used,
      memory_limit: data?.memory_limit,
      temperature: data?.temperature,
      top_p: data?.top_p,
      past_conversation_no: data?.memory_usages,
    })
      .then((res) => {
        toast({
          duration: 3 * 1000,
          toastType: 'success',
          title: 'Draft Saved',
          description: (
            <p className='text-textPrimary text-xs'>
              Your changes have been saved successfully.
            </p>
          ),
        });
        updateCustomAgentFormData({ ...customAgentFormData, ...data });
      })
      .catch(() => {
        toast({
          toastType: 'destructive',
          title: 'Failed',
          description: (
            <p className='text-textPrimary text-xs'>
              Failded to save changes. Please try again.
            </p>
          ),
        });
      });
  }

  const renderSliderInput = ({
    fieldLabel = '',
    isRequired = false,
    hint = '',
    step = 3,
    min = 0,
    max = 2000,
    valueKey = '',
  }) => {
    return (
      <FormField
        control={form.control}
        // @ts-ignore
        name={valueKey}
        render={({ field }) => (
          <FormItem>
            <FormLabel className='w-full flex justify-between leading-5 pb-1'>
              <div>
                {fieldLabel}
                {isRequired && <span className='text-red-500'>*</span>}
              </div>
              <div>{field?.value + ''}</div>
            </FormLabel>

            <FormControl>
              <Slider
                value={[+field?.value]}
                onValueChange={(e) => {
                  field.onChange(e.pop());
                }}
                max={max}
                min={min}
                step={step}
                thumbClass='bg-white border-textPrimary border-2'
                rangeClass='bg-textPrimary'
                trackClass='bg-zinc-200 h-1'
                className={cn('w-full')}
              />
            </FormControl>

            <FormDescription
              className={cn('text-textSecondary font-normal', {
                hidden: !!form.formState.errors[field.name],
              })}
            >
              {hint}
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
    );
  };

  const renderConfigurationForm = () => {
    return (
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className='w-full space-y-4'
        >
          <FormField
            control={form.control}
            name='model_used'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Model Used
                  <span className='text-red-500'>*</span>
                </FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger className='w-full focus:ring-primary'>
                      <SelectValue placeholder='Select a model' />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent className='bg-white'>
                    <SelectGroup>
                      {avialableGptAgentList?.map((model) => (
                        <SelectItem value={model?.id}>
                          {model?.title}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>

                <FormDescription
                  className={cn('text-textSecondary font-normal', {
                    hidden: !!form.formState.errors[field.name],
                  })}
                >
                  Choose a model to determine how your custom agent processes
                  information and generates responses.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='memory_usages'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Memory usages</FormLabel>
                <FormControl>
                  <Input
                    placeholder='Enter memory usages'
                    className='border-border'
                    type='number'
                    {...field}
                    onWheel={(event) => event.currentTarget.blur()}
                  />
                </FormControl>

                <FormDescription
                  className={cn('text-textSecondary font-normal', {
                    hidden: !!form.formState.errors[field.name],
                  })}
                >
                  Set how many past conversations the agent will use to generate
                  contextually relevant responses.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          {renderSliderInput({
            fieldLabel: 'Memory Limit',
            isRequired: false,
            hint: 'Set the maximum memory size for how much context the agent retains. Higher values allow for more data to be stored.',
            step: 10,
            max: 2000,
            min: 0,
            valueKey: 'memory_limit',
          })}
          {renderSliderInput({
            fieldLabel: 'Temperature (Creativity)',
            isRequired: false,
            hint: 'Adjust the randomness of responses. Lower values make answers more focused whereas higher values make them more creative.',
            step: 0.1,
            max: 1.0,
            min: 0.0,
            valueKey: 'temperature',
          })}
          {renderSliderInput({
            fieldLabel: 'Top P',
            isRequired: false,
            hint: 'Adjust the diversity of responses. Lower values focus on safer choices; higher values allow more varied outputs.',
            step: 0.1,
            max: 1.0,
            min: 0.0,
            valueKey: 'top_p',
          })}
          <div className='w-full flex justify-between pt-2'>
            <div>
              <Button
                onClick={() => onPreviousStep()}
                type='button'
                variant={'ghost'}
                className='bg-background-hover'
              >
                Back
              </Button>
            </div>
            <div className='flex gap-2'>
              <Button onClick={onDraftSave} type='button' variant={'outline'}>
                Save as Draft
              </Button>
              <Button
                type='submit'
                disabled={!form.formState.isValid}
                className={'disabled:opacity-75 disabled:cursor-not-allowed'}
              >
                Save & Continue
              </Button>
            </div>
          </div>
        </form>
      </Form>
    );
  };

  const renderFormHeader = () => {
    return (
      <div>
        <h2 className='font-bold text-xl mb-1.5'>Configuration</h2>
        <p className='text-sm text-textSecondary'>
          Adjust the model, behavior, and memory settings to optimize your
          custom agent.
        </p>
      </div>
    );
  };

  return (
    <div
      data-testid='raven-general-info-section'
      className='flex flex-col gap-6 w-full h-auto flex-1 max-w-2xl mx-auto pt-6 sm:px-6'
    >
      {renderFormHeader()}
      <hr />
      {renderConfigurationForm()}
    </div>
  );
};
