import useTranslation from 'components/customHooks/useTranslation';
import Picker from 'emoji-picker-react';
import React from 'react';
import { Popover } from '../../../../../../library/popover/Popover';
import { emailChannels, feedChannels } from '../../../../../../utilities/utils';
import useInboxDatalab from '../../../../hooks/useInboxDatalab';
import UppyFileUploader from '../../../../utils/UppyFileUploader';

import { SelectedTeamInterface } from 'index';
import useCommentEdit from 'pages/inbox/hooks/useCommentEdit';
import {
  CustomerReplyBlockInterface,
  SendMessageRequestBodyInterface,
  TicketInterface,
  UppyAwsS3SuccessfulResponse,
} from 'pages/inbox/inboxInterface';
import ReactTooltip from 'react-tooltip';
import {
  AudioIcon,
  DocumentIcon,
  ImageIcon,
  VideoIcon,
} from '../../../../assets/iconComponent/AttachmentIconSet';
import {
  AttachmentIcon,
  AudioRecordIcon,
  DatalabIcon,
  EcommerceIcon,
  // BagIcon,
  EmojiIcon,
  SavedArrowUturnLeftIcon,
  WhatsappRectangleGroupIcon,
} from '../../../../assets/iconComponent/ChatboxReplyOptionIcons';

type IReplyOptions = IReplyOption | ISeperatorOption;

interface IReplyOption {
  icon: React.ReactNode;
  type: 'option';
  toolTip: string;
  name: string;
  shouldRender: boolean;
}

interface ISeperatorOption {
  type: 'separator';
  shouldRender: boolean;
}

interface Props {
  selectedProject: SelectedTeamInterface;
  selectedTicket: TicketInterface;
  chatboxActiveNav: string;
  inputValue: string;
  isSavedReplyOpened: boolean;
  handleChildClick: () => void;
  isDatalabListOpened: boolean;
  setIsDatalabListOpened: (value: boolean) => void;
  setIsSavedReplyOpened: (value: boolean) => void;
  handleEcommerceIconClick: () => void;
  handleWhatsapptemplatePopover: () => void;
  handleMessengerChannelMessage: (
    messageRequestBody: SendMessageRequestBodyInterface
  ) => void;
  handleFeedChannelMessage: (
    messageRequestBody: SendMessageRequestBodyInterface
  ) => void;
  updateShowUpgradeModal: (payload: boolean) => void;
  popoverBottomPosition?: string;
  shouldDisbaleChatboxArea: () => boolean;
  setInputValue: (value: string) => void;
  handleUppyAttachmentFiles?: (
    attachmentList: UppyAwsS3SuccessfulResponse
  ) => void;
  conversationBlockDataForReply?: CustomerReplyBlockInterface | null;
  handleConversationBlockDataForReply?: (value: null) => void;
}

const ChatboxReplyOptions: React.FC<Props> = ({
  inputValue,
  selectedProject,
  selectedTicket,
  chatboxActiveNav,
  handleChildClick,
  isSavedReplyOpened,
  setInputValue,
  isDatalabListOpened,
  setIsDatalabListOpened,
  setIsSavedReplyOpened,
  handleUppyAttachmentFiles,
  handleFeedChannelMessage,
  shouldDisbaleChatboxArea,
  handleEcommerceIconClick,
  handleWhatsapptemplatePopover,
  handleMessengerChannelMessage,
  updateShowUpgradeModal,
  popoverBottomPosition = 'bottom-52',
  conversationBlockDataForReply,
  handleConversationBlockDataForReply,
}) => {
  const { t } = useTranslation();
  const { commentEditData } = useCommentEdit();
  const { updateInboxDatalabStateData } = useInboxDatalab();
  const planType = selectedProject?.subscription_plan?.plan_type;
  const [uppyUploadType, setUppyUploadType] = React.useState<null | string>(
    null
  );

  const attachmentOptions = [
    {
      icon: <ImageIcon />,
      name: 'Image',
      mimeType: 'image',
      shouldRender: true,
    },
    {
      icon: <VideoIcon />,
      name: 'Video',
      mimeType: 'video',
      shouldRender: true,
    },
    {
      icon: <AudioIcon />,
      name: 'Audio',
      mimeType: 'audioLocal',
      shouldRender: !['facebook_feed', 'instagram_feed'].includes(
        selectedTicket?.customer_platform_type
      ),
    },
    {
      icon: <DocumentIcon />,
      name: 'Document',
      mimeType: 'application',
      shouldRender: !['facebook_feed', 'instagram_feed'].includes(
        selectedTicket?.customer_platform_type
      ),
    },
  ];
  const separatorOption: ISeperatorOption = {
    type: 'separator',
    shouldRender: true,
  };
  const emojiOption: IReplyOption = {
    icon: <EmojiIcon />,
    type: 'option',
    name: 'emoji',
    shouldRender: true,
    toolTip: 'Add emotion to your chats',
  };
  const datalabOption: IReplyOption = {
    icon: <DatalabIcon />,
    name: 'datalab',
    type: 'option',
    toolTip: 'Collect important customer information efficiently',
    shouldRender: !['premium'].includes(planType),
  };
  const savedReplyOption: IReplyOption = {
    icon: <SavedArrowUturnLeftIcon />,
    shouldRender: true,
    type: 'option',
    toolTip: 'Send pre-written responses for common questions',
    name: 'savedReply',
  };
  const attachmentOption: IReplyOption = {
    icon: <AttachmentIcon />,
    name: 'attachment',
    type: 'option',
    toolTip: 'Share photos, videos, documents, or audio',
    shouldRender: true,
  };

  const audioRecordOption: IReplyOption = {
    icon: <AudioRecordIcon />,
    name: 'audio',
    type: 'option',
    toolTip: 'Record or upload an audio message',
    shouldRender: true,
  };
  const ecommerceOption: IReplyOption = {
    icon: <EcommerceIcon />,
    name: 'ecommerce',
    type: 'option',
    toolTip: 'Help customers order or add products to their cart',
    shouldRender: true,
  };
  const whatsappTemplateOption: IReplyOption = {
    icon: <WhatsappRectangleGroupIcon />,
    name: 'whatsappTemplate',
    type: 'option',
    toolTip: 'Send pre-approved Whatsapp templates',
    shouldRender: selectedTicket?.customer_platform_type === 'whatsapp_bsp',
  };

  let feedReplyOption: IReplyOptions[] = [
    emojiOption,
    {
      ...attachmentOption,
      shouldRender:
        selectedTicket?.customer_platform_type === 'facebook_feed' &&
        chatboxActiveNav !== 'Private Reply',
    },
    separatorOption,
    datalabOption,
    separatorOption,
    savedReplyOption,
  ];

  let messengerChannelReplyOptions: IReplyOptions[] = [
    emojiOption,
    attachmentOption,
    {
      ...audioRecordOption,
      shouldRender: !['facebook_feed', 'instagram_feed'].includes(
        selectedTicket?.customer_platform_type
      ),
    },
    separatorOption,
    ecommerceOption,
    datalabOption,
    separatorOption,
    savedReplyOption,
    whatsappTemplateOption,
  ];

  let emailChannelReplyOptions: IReplyOptions[] = [
    emojiOption,
    attachmentOption,
    separatorOption,
    datalabOption,
    separatorOption,
    savedReplyOption,
  ];

  let commentEditOptions: IReplyOptions[] = [emojiOption];

  const getReplyOptions = (): IReplyOptions[] => {
    let platfromType = selectedTicket?.customer_platform_type;
    let optionList = [];
    switch (platfromType) {
      case 'facebook_feed':
      case 'instagram_feed':
        optionList = commentEditData?.isEditing
          ? commentEditOptions
          : feedReplyOption;
        break;
      case 'gmail':
      case 'custom_email':
      case 'office365':
        optionList = emailChannelReplyOptions;
        break;
      default:
        optionList = messengerChannelReplyOptions;
    }

    const renderableOptionList = optionList.filter((v) => v.shouldRender);

    let finalOptionList = renderableOptionList.filter((option, index) => {
      if (index === 0) {
        return true;
      }
      if (
        option.type === 'separator' &&
        option.type === renderableOptionList[index - 1].type
      ) {
        return false;
      }
      return true;
    });
    return finalOptionList;
  };

  const shoulDisableReplyOption = (optionName: string) => {
    const projectPlanType = selectedProject?.subscription_plan?.name;
    switch (optionName) {
      case 'savedReply':
        return projectPlanType === 'free';
      case 'datalab':
        return false;
      // return projectPlanType !== 'enterprise' ;
      case 'ecommerce':
      case 'audio':
      case 'whatsappTemplate':
      default:
        return false;
    }
  };

  const handlePopoverOnclickEvent = (optionName: string) => {
    switch (optionName) {
      case 'audio':
      case 'audioLocal':
        setUppyUploadType('audio');
        break;
      case 'ecommerce':
        updateInboxDatalabStateData('selectedDatalabId', null);
        updateInboxDatalabStateData('isRightBarOpenedForDatalab', false);
        handleEcommerceIconClick();
        break;
      case 'whatsappTemplate':
        handleWhatsapptemplatePopover();
        break;
      case 'emoji':
        break;
      case 'savedReply':
        if (!shoulDisableReplyOption('savedReply')) {
          if (!isSavedReplyOpened) {
            const inputId = emailChannels.includes(
              selectedTicket?.customer_platform_type
            )
              ? 'email-reply-input'
              : 'reply-input';
            const textArea = document.getElementById(
              inputId
            ) as HTMLTextAreaElement;

            if (textArea) {
              const cursorPosition = textArea?.selectionStart;
              const textBeforeCursor = inputValue.substring(0, cursorPosition);
              const textAfterCursor = inputValue.substring(cursorPosition);

              setInputValue(textBeforeCursor + '#' + textAfterCursor);
              setIsSavedReplyOpened(true);

              // Restore cursor position after the #
              setTimeout(() => {
                textArea.focus();
                textArea.setSelectionRange(
                  cursorPosition + 1,
                  cursorPosition + 1
                );
              }, 0);
            }
          } else {
            setIsSavedReplyOpened(false);
          }
          handleChildClick();
        }
        break;
      case 'datalab':
        setIsDatalabListOpened(!isDatalabListOpened);
        break;
      case 'attachment':
        if (selectedTicket?.customer_platform_type === 'facebook_feed')
          setUppyUploadType('image');
        break;
      default:
        console.log('onclick option only enabled for audio record');
    }
  };

  const getPopoverChildren = (name: string) => {
    switch (name) {
      case 'emoji':
        return (
          <Picker
            onEmojiClick={(event: any, emojiElem: any) => {
              const inputId = emailChannels.includes(
                selectedTicket?.customer_platform_type
              )
                ? 'email-reply-input'
                : 'reply-input';
              let target: any = document.getElementById(inputId);

              if (target.hasOwnProperty('setRangeText')) {
                //if setRangeText function is supported by current browser
                target.setRangeText(emojiElem.emoji);
              } else {
                target.focus();
                document.execCommand(
                  'insertText',
                  false /*no UI*/,
                  emojiElem.emoji
                );
              }
            }}
            disableAutoFocus={true}
            disableSkinTonePicker={true}
            preload={true}
          />
        );
      case 'attachment':
        if (selectedTicket?.customer_platform_type === 'facebook_feed')
          return null;
        else
          return (
            <div className='flex flex-col w-52'>
              {attachmentOptions.map(
                (attachmentOption, index) =>
                  attachmentOption.shouldRender && (
                    <button
                      key={index}
                      onClick={() => {
                        if (planType === 'free' && [1, 2].includes(index)) {
                          updateShowUpgradeModal(true);
                        } else {
                          setUppyUploadType(attachmentOption.mimeType);
                        }
                      }}
                      className='px-[13.5px] p-[8px] gap-[10px] flex items-center hover:bg-gray-100 cursor-pointer w-full'
                    >
                      <div>{attachmentOption.icon}</div>
                      <span className='text-gray-600 text-sm'>
                        {t(attachmentOption.name)}
                      </span>
                      {planType === 'free' && [1, 2].includes(index) && (
                        <span className='ml-[90px] px-1 py-0.5 items-center font-medium text-xs text-blue-800 bg-blue-100 rounded'>
                          Pro
                        </span>
                      )}
                    </button>
                  )
              )}
            </div>
          );
      // TODO: uppy modal won't open inside popover
      case 'audio':
        return null;
      case 'ecommerce':
        return null;
      case 'savedReply':
        return null;
      case 'whatsappTemplate':
        return null;
      default:
        return null;
    }
  };

  const handleSendMessage = (
    messageRequestBody: SendMessageRequestBodyInterface
  ) => {
    feedChannels.includes(selectedTicket?.customer_platform_type)
      ? handleFeedChannelMessage(messageRequestBody)
      : handleMessengerChannelMessage(messageRequestBody);
  };

  const renderReplyOption = () => {
    return getReplyOptions().map((replyOption: IReplyOptions, index) => {
      if (!replyOption.shouldRender) {
        return null;
      }
      if ('type' in replyOption && replyOption?.type === 'separator') {
        return (
          <div className='border border-gray-300 border-l-0 border-y-0 border-r-1 h-4 mb-1'></div>
        );
      }
      return (
        <Popover className='relative' key={index}>
          <Popover.Button disabled={shouldDisbaleChatboxArea()}>
            <div
              data-for={replyOption.name}
              data-tip={t(replyOption.toolTip)}
              className={
                shoulDisableReplyOption(replyOption.name) ||
                shouldDisbaleChatboxArea()
                  ? 'cursor-not-allowed relative'
                  : 'cursor-pointer'
              }
              onClick={() => {
                if (!(replyOption.name === 'savedReply')) {
                  setIsSavedReplyOpened(false);
                }
                handlePopoverOnclickEvent(replyOption.name);
              }}
            >
              {shoulDisableReplyOption(replyOption.name) && (
                <span className='inline-flex absolute items-center bottom-1.5 rounded-md bg-blue-100 px-2 py-px text-[9px] font-medium text-blue-800'>
                  Pro
                </span>
              )}
              {replyOption.icon}
            </div>
            {/* @ts-ignore */}
            <ReactTooltip
              key={index}
              id={replyOption.name}
              place='top'
              type='dark'
              effect='float'
            />
          </Popover.Button>
          <Popover.Panel
            className={`fixed z-20 origin-top bg-white rounded-md ${popoverBottomPosition} drop-shadow-xl`}
          >
            {getPopoverChildren(replyOption.name)}
          </Popover.Panel>
        </Popover>
      );
    });
  };

  return (
    <>
      <div className='flex items-center gap-3'>{renderReplyOption()}</div>
      <UppyFileUploader
        open={!!uppyUploadType}
        planType={
          !!selectedProject ? selectedProject?.subscription_plan?.name : 'free'
        }
        platformType={selectedTicket?.customer_platform_type}
        fileType={uppyUploadType || 'application'}
        sendMessage={handleSendMessage}
        getAttachmentFiles={handleUppyAttachmentFiles as () => void}
        handleUppyModalClose={() => {
          setUppyUploadType(null);
          if (!!handleConversationBlockDataForReply)
            handleConversationBlockDataForReply(null);
        }}
        conversationId={conversationBlockDataForReply?.payload}
      />
    </>
  );
};
export default ChatboxReplyOptions;
