import { useDispatch, useSelector } from 'react-redux';
import {
  IGptModel,
  IManageGPTAgent,
  TCreateCustomAgentFormData,
} from '../interface';
import { useParams } from '@reach/router';

export const useCreateCustomAgent = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();

  const customAgentFormData = useSelector(
    (state: any) => state.raven?.customAgentFormData
  );

  const availableToken = useSelector(
    (state: any) => state.raven?.availableToken
  );

  const updateCustomAgentFormData = (newData: TCreateCustomAgentFormData) => {
    dispatch.raven.updateStateData({
      key: 'customAgentFormData',
      value: { ...customAgentFormData, ...newData },
    });
  };

  const createCustomAgent = async (payload: Partial<IManageGPTAgent>) => {
    return dispatch.raven?.createSingleCustomAgent({
      ...payload,
      team_id: projectId,
    });
  };

  const updateCustomAgent = async (payload: Partial<IManageGPTAgent>) => {
    return dispatch.raven?.updateSingleCustomAgent({
      ...payload,
      id: customAgentFormData?.id,
    });
  };

  const avialableGptAgentList: IGptModel[] = useSelector(
    (state: any) => state?.raven?.availableModelList
  );

  const fetchGptModelList = async () => {
    return dispatch.raven?.fetchGptModelList();
  };

  const deleteAgentChatHistory = async (agentId: string): Promise<boolean> => {
    return dispatch.raven.deleteAgentChatHistory(agentId);
  };

  const updateCustomAgentStatus = async (
    agentId: string,
    status: 'draft' | 'active' | 'inactive'
  ) => {
    return dispatch.raven?.updateSingleCustomAgent({
      status: status,
      id: agentId,
    });
  };

  const hasEnoughToken = (token: number) => {
    return availableToken >= token;
  };

  const saveDraftCustomAgentForm = async () => {};

  return {
    customAgentFormData,
    updateCustomAgentFormData,
    updateCustomAgent,
    saveDraftCustomAgentForm,
    createCustomAgent,
    fetchGptModelList,
    hasEnoughToken,
    availableToken,
    avialableGptAgentList,
    updateCustomAgentStatus,
    deleteAgentChatHistory,
  };
};
