import React, { useEffect, useRef, useState } from 'react';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import TicketQueryComponent from './TicketQueryDropDown';
import { connect } from 'react-redux';
import { ticketFilterDefaultValue } from 'pages/inbox/utils/contents';
import {
  ISavedFilterData,
  ITicketQueueData,
  TicketFilterInterface,
} from 'pages/inbox/inboxInterface';
import { classNames } from 'utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  isDisabled: boolean;
  ticketLoading: boolean;
  totalTicketCount: number;
  hasSupervisorAccess: boolean;
  savedFilterList: ISavedFilterData[];
  ticketQueueData: ITicketQueueData;
  handleClosedTicketQueue: (queue: string) => void;
  handleTicketQueueOnChange: (queue: string) => void;
  handleFilterClick: (filterData: TicketFilterInterface) => void;
  updateSateData: (key: string, value: UpdateStateDataType) => void;
  ticketsBadgeCount: TicketsBadgeCountType;
  selectedProject: SelectedTeamInterface;
  fetchTicketsBadgeCount: (projectId: number) => boolean;
}

const TicketQueueV2: React.FC<Props> = ({
  isDisabled,
  ticketLoading,
  ticketQueueData,
  totalTicketCount,
  savedFilterList,
  updateSateData,
  hasSupervisorAccess,
  handleFilterClick,
  handleClosedTicketQueue,
  handleTicketQueueOnChange,
  ticketsBadgeCount,
  selectedProject,
  fetchTicketsBadgeCount,
}) => {
  const [selectedNumber, updateSelectedNumber] = useState<number>(
    hasSupervisorAccess ? 0 : 4
  );
  const [selectedQueueString, setSelectedQueueString] = useState<string>('');
  const [isDropdownOn, setIsDropdownOn] = useState(false);
  const dropdownRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (ticketQueueData?.type === '') {
      let queue = hasSupervisorAccess ? 'all' : 'self';
      setSelectedQueueString(queue === 'all' ? 'All Assigned' : 'My Assigned');
      updateSateData('ticketQueue', {
        type: queue,
        isResolved: false,
        privateView: '',
      });
    }
    if (ticketQueueData?.type === 'private') {
      setSelectedQueueString(ticketQueueData?.privateView);
      savedFilterList.forEach((data: ISavedFilterData, index: number) => {
        if (data.name === ticketQueueData?.privateView) {
          updateSelectedNumber(9 + index);
        }
      });
      return;
    }
    const secondString = ticketQueueData?.isResolved ? 'Closed' : 'Assigned';
    if (ticketQueueData?.type === 'all') {
      setSelectedQueueString(`All ${secondString}`);
      updateSelectedNumber(ticketQueueData?.isResolved ? 3 : 0);
    } else if (ticketQueueData?.type === 'self') {
      setSelectedQueueString(`My ${secondString}`);
      updateSelectedNumber(ticketQueueData?.isResolved ? 6 : 4);
    } else if (ticketQueueData?.type === 'bot') {
      setSelectedQueueString('Bot');
      updateSelectedNumber(8);
    } else {
      setSelectedQueueString(`Unassigned`);
      updateSelectedNumber(1);
    }
    // eslint-disable-next-line
  }, [ticketQueueData]);

  const clearData = () => {
    updateSateData('ticketFilterData', ticketFilterDefaultValue);
    updateSateData('previouslySavedFilterData', {
      oldData: ticketFilterDefaultValue,
      updateAble: false,
      indexOfSavedData: -1,
    });
  };

  const handleQueueChangeEvent = (
    queryString: string,
    selectedNumber: number
  ) => {
    handleTicketQueueOnChange(queryString);
    updateSateData('ticketIsResolvedStatus', 0);
    updateSateData('selectedQueueType', queryString);
    updateSelectedNumber(selectedNumber);
  };

  const handleCloseQueueSelection = (
    queryString: string,
    selectedNumber: number
  ) => {
    handleClosedTicketQueue(queryString);
    updateSateData('ticketIsResolvedStatus', 1);
    updateSateData('selectedQueueType', queryString);
    updateSelectedNumber(selectedNumber);
  };

  const handlePrivateViewSelection = (
    filterData: TicketFilterInterface,
    num: number,
    label: string
  ) => {
    updateSateData('previouslySavedFilterData', {
      oldData: filterData,
      updateAble: true,
      indexOfSavedData: num - 8,
    });
    updateSateData('ticketIsResolvedStatus', 0);
    updateSateData('ticketFilterData', filterData);
    handleFilterClick(filterData);
    updateSateData('ticketQueue', {
      type: 'private',
      isResolved: false,
      privateView: label,
    });
  };

  // This is a hacky solution to remove the scroll bar.
  // It directly modifies the overflow property of the '.scrollbar__remove' element.
  // Please avoid making this a habit, as direct DOM manipulation can lead to maintenance issues.
  React.useEffect(() => {
    const scrollabarDiv: any = document.querySelector('.scrollbar__remove');
    if (scrollabarDiv) {
      scrollabarDiv.style.overflowY = 'hidden';
    }
  }, []);

  useEffect(() => {
    // Checking click action for ticket count API call
    function handleClickOutside(e: any) {
      // @ts-ignore
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsDropdownOn(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button
          disabled={isDisabled}
          className={classNames(
            'relative w-auto min-w-[10vw] inline-flex justify-between w-50 px-4 py-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-md shadow-sm  focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary',
            isDisabled ? 'bg-gray-200' : 'bg-white  hover:bg-gray-50'
          )}
          ref={dropdownRef}
          onClick={() => {
            if (!isDropdownOn) {
              fetchTicketsBadgeCount(selectedProject?.id);
              setIsDropdownOn(true);
            } else {
              setIsDropdownOn(false);
            }
          }}
        >
          <div className='flex gap-2'>
            <span>{t(selectedQueueString)}</span>
            {!ticketLoading && (
              <span
                className={`px-2 font-medium text-gray-800 bg-green-100 rounded ${
                  totalTicketCount === -1 ? 'hidden' : 'inline'
                }`}
              >
                {totalTicketCount}
              </span>
            )}
          </div>
          <ChevronDownIcon
            className='w-5 h-5 ltr:ml-2 rtl:mr-2 -mr-1'
            aria-hidden='true'
          />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute z-20 w-48 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='py-1'>
            <Menu.Item>
              {({ active, close }) => (
                <TicketQueryComponent
                  isUserAdmin={hasSupervisorAccess}
                  selectedNumber={selectedNumber}
                  selectedProject={selectedProject}
                  savedFilterList={savedFilterList}
                  handleOnQueryChange={(
                    queryString: string,
                    label: string,
                    num: number
                  ) => {
                    clearData();
                    handleQueueChangeEvent(queryString, num);
                    setSelectedQueueString(label);
                    close();
                  }}
                  handlerFilterDataClick={(
                    filterData: TicketFilterInterface,
                    label: string,
                    num: number
                  ) => {
                    handlePrivateViewSelection(filterData, num, label);
                    close();
                  }}
                  handleClosedTicketQueue={(
                    queryString: string,
                    label: string,
                    num: number
                  ) => {
                    clearData();
                    handleCloseQueueSelection(queryString, num);
                    setSelectedQueueString(label);
                    close();
                  }}
                  ticketsBadgeCount={ticketsBadgeCount}
                />
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
const mapState = (state: any) => ({
  ticketQueueData: state.inbox.ticketQueue,
  savedFilterList: state.inbox.savedFilterList,
  ticketsBadgeCount: state.inbox.ticketsBadgeCount,
  selectedProject: state.dashboard.selectedProject,
});
const mapDispatch = (dispatch: any) => ({
  updateSateData: (key: string, value: UpdateStateDataType) =>
    dispatch.inbox.updateSateData({ key, value }),
  fetchTicketsBadgeCount: (projectId: number) =>
    dispatch.inbox.fetchTicketsBadgeCount(projectId),
});
export default connect(mapState, mapDispatch)(TicketQueueV2);
