import usePartner from 'components/customHooks/usePartner';
import { Position, Tooltip } from 'evergreen-ui';
import React from 'react';
import teamSwitchIcon from '../../../assets/icons/team.png';
import aliceIcon from '../../../assets/images/aliceLogo.svg';
import logoLight from '../../../assets/images/logo-light-bg.svg';

interface Prop {
  origin: 'header' | 'navbar';
  teamName?: string;
  teamAvatar?: string | null;
  toggleShowTeams?: Function;
  showTeams?: boolean;
}

const LogoArea: React.FC<Prop> = ({
  origin,
  teamName,
  teamAvatar,
  toggleShowTeams,
  showTeams,
}) => {
  const { currentPartnerInfo, isPartnerRestricted } = usePartner();
  const shouldDisableAliceIcon =
    currentPartnerInfo?.type === 'white-label' ||
    isPartnerRestricted('LogoArea/function/renderDefaultLogo');

  const hasOwnLogo = !!teamAvatar && teamAvatar.length !== 0;

  const renderDefaultLogo = () => {
    if (shouldDisableAliceIcon && currentPartnerInfo?.icon) {
      return currentPartnerInfo.icon;
    } else if (
      (currentPartnerInfo && !shouldDisableAliceIcon) ||
      !currentPartnerInfo?.domain
    ) {
      return (
        <img
          id='teamImage'
          className={`w-10 h-10 inline-block rounded-md border ${
            showTeams ? 'border-primary' : 'border-gray-200'
          } ${!!teamAvatar && teamAvatar.length !== 0 ? '' : 'p-1'}`}
          src={aliceIcon}
          alt='team_avatar'
        />
      );
    }
    return null;
  };
  return (
    <>
      {/* Logo area */}
      {origin === 'navbar' && (
        <div
          id='teamlogo'
          className='cursor-pointer'
          onClick={() => (toggleShowTeams ? toggleShowTeams() : null)}
        >
          {/* @ts-ignore */}
          <Tooltip content={teamName} position={Position.RIGHT}>
            <div className='items-center justify-center hidden bg-white md:flex'>
              <div className='relative inline-flex justify-center w-12 h-12 rounded-lg align-center drop-shadow-sm'>
                {hasOwnLogo ? (
                  <img
                    id='teamImage'
                    className={`w-9 h-9 inline-block rounded-md border ${
                      showTeams ? 'border-primary' : 'border-gray-200'
                    } ${!!teamAvatar && teamAvatar.length !== 0 ? '' : 'p-1'}`}
                    src={teamAvatar}
                    alt='team_avatar'
                    onError={(e) => {
                      e.currentTarget.src = aliceIcon;
                    }}
                  />
                ) : (
                  renderDefaultLogo()
                )}
                <img
                  className='absolute bottom-0 right-0 w-4 h-4'
                  src={teamSwitchIcon}
                  alt='teamSwitchIcon'
                />
              </div>
            </div>
          </Tooltip>
        </div>
      )}
      {origin === 'header' && (
        <div className='absolute p-4 cursor-pointer'>
          <div className='flex items-center justify-center w-16 h-16 bg-white md:hidden focus:outline-none md:w-20'>
            <img className='h-8 mx-auto' src={logoLight} alt='alice labs pte' />
          </div>
        </div>
      )}
    </>
  );
};

export default LogoArea;
