import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import useTranslation from 'components/customHooks/useTranslation';
import { cn } from 'libraryV2/utils';
import { FileText } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import {
  handleFileUpload,
  TFileUploadResult,
} from 'pages/settings/savedReplies/utils';
import ProgressCircle from 'pages/settings/savedReplies/components/ProgressCircle';
import { AllowedDocumentMimeTypes } from 'pages/raven/utils/content';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'libraryV2/ui/form';
import { useFormContext } from 'react-hook-form';
interface Props {
  shouldDisable?: boolean;
  toolData: any;
  index: number;
}

export const DocumentToolUploader: React.FC<Props> = ({
  toolData,
  index,
  shouldDisable = false,
}) => {
  const formInstance = useFormContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [sizeLimitExceeded, setSizetLimitExceeded] = useState<boolean>(false);
  const [uploadFailed, setUploadFailed] = useState<boolean>(false);
  const uploadFailedErrorMessage = t(
    'Failed to upload file. Please try again!'
  );
  const maxAllowedFileSize = 50 * 1024 * 1024;

  const fileSizeLimitError = `Document size should be less than 50MB & type should be .txt, .pdf, .doc or .docx`;

  const uploadCallback = (url: TFileUploadResult | null) => {
    if (!url) {
      setUploadFailed(true);
    } else {
      formInstance.setValue(
        `tools.${index}.data.documents`,
        toolData?.data?.documents ? [...toolData.data.documents, url] : [url],
        { shouldValidate: true }
      );
      setUploadFailed(false);
    }
    setLoading(false);
  };

  const handleUploadProgress = useCallback((progressEvent: ProgressEvent) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setUploadProgress(percentCompleted);
  }, []);

  const uploadWrapper = (file: File) => {
    handleFileUpload(file, uploadCallback, handleUploadProgress);
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: AllowedDocumentMimeTypes,
    onDrop: (files: File[]) => {
      setLoading(true);
      uploadWrapper(files[0]);
      setSizetLimitExceeded(false);
    },
    disabled: shouldDisable,
    onDropRejected: () => {
      setSizetLimitExceeded(true);
      setLoading(false);
    },
    multiple: false,
    maxSize: maxAllowedFileSize,
  });

  const renderHeaderUploadGuide = () => {
    return (
      <>
        <div className='py-2 px-2 border rounded-lg'>
          <FileText className='text-zinc-900 w-6 h-6' />
        </div>
        <p className='text-center text-sm text-textPrimary'>
          Upload or drag & drop your file here
        </p>
        <p className='w-[80%] text-textPrimary-disable text-center text-xs mt-2'>
          Allowed file type is .txt, .pdf, .doc or .docx
        </p>
        <p className='w-[80%] text-textPrimary-disable text-center text-xs'>
          Maximum file size is 50MB
        </p>
      </>
    );
  };

  useEffect(() => {
    setSizetLimitExceeded(false);
    setUploadFailed(false);
  }, [toolData?.id]);

  return (
    <FormField
      name={`tools.${index}.data.documents`}
      control={formInstance.control}
      disabled={shouldDisable}
      render={({ field: hookField }) => {
        return (
          <FormItem>
            <FormLabel>
              Upload documents
              <span className='text-red-500'>*</span>
            </FormLabel>
            <FormControl {...getRootProps()}>
              <div
                className={cn(
                  'border flex flex-col items-center justify-center cursor-default border-dashed rounded-md p-6',
                  {
                    'cursor-not-allowed': shouldDisable,
                  }
                )}
              >
                {!loading && (
                  <Fragment>
                    <input {...hookField} {...getInputProps()} value={''} />
                    {renderHeaderUploadGuide()}
                  </Fragment>
                )}
                {loading && <ProgressCircle progress={uploadProgress} />}
              </div>
            </FormControl>
            {sizeLimitExceeded || uploadFailed ? (
              <p className='flex items-center text-red-500 text-sm mt-1'>
                <span className='mr-1'>
                  <InformationCircleIcon className='h-4 w-4' />
                </span>
                {uploadFailed ? uploadFailedErrorMessage : fileSizeLimitError}
              </p>
            ) : null}

            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};

export default DocumentToolUploader;
