import { ISettingsNavItem } from './interface';
import { User, MessageSquare, Users, LayoutGrid } from 'lucide-react';
export const settingsNavData: ISettingsNavItem[] = [
  {
    title: 'User Information',
    url: '#',
    icon: User,
    isActive: true,
    globalAccess: true,
    items: [
      {
        id: 0,
        title: 'User Information',
        url: '/settings/profile#user-settings',
        itemGlobalAccess: true,
        isPro: false,
      },
      {
        id: 1,
        title: 'Change Password',
        url: '/settings/profile#password',
        itemGlobalAccess: true,
        isPro: false,
      },
      {
        id: 20,
        title: 'Dashboard Preferences',
        url: '/settings/dashboard-preferences',
        itemGlobalAccess: true,
        isPro: false,
        permission: {
          readAccess: 'read:settings',
          writeAccess: 'write:settings',
        },
      },
      {
        id: 16,
        title: 'Business Hours',
        url: '/settings/business-hour',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings_business_hour',
          writeAccess: 'write:settings_business_hour',
        },
      },
    ],
  },
  {
    title: 'Inbox Settings',
    url: '#',
    icon: MessageSquare,
    isActive: true,
    globalAccess: true,
    items: [
      {
        id: 27,
        title: 'Ticket Configurations',
        url: '/settings/ticket-config',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings_ticket_configuration',
          writeAccess: 'write:settings_ticket_configuration',
        },
      },
      {
        id: 28,
        title: 'Inbox Preferences',
        url: '/settings/inbox-preferences',
        itemGlobalAccess: true,
        isPro: false,
        permission: {
          readAccess: 'read:conversation',
          writeAccess: 'write:conversation',
        },
      },
      {
        id: 29,
        title: 'SLA Configurations',
        url: '/settings/sla-settings',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings_sla_configuration',
          writeAccess: 'write:settings_sla_configuration',
        },
      },
      {
        id: 15,
        title: 'Notification Settings',
        url: '/settings/notification',
        itemGlobalAccess: true,
        isPro: false,
        permission: {
          readAccess: 'read:conversation',
          writeAccess: 'write:conversation',
        },
      },
      {
        id: 21,
        title: 'Chatbot Preferences ',
        url: '/settings/chatbot-settings',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings',
          writeAccess: 'write:settings',
        },
      },
      {
        id: 19,
        title: 'Saved Replies',
        url: '/settings/saved-replies',
        itemGlobalAccess: false,
        isPro: true,
        permission: {
          readAccess: 'read:settings_saved_reply',
          writeAccess: 'write:settings_saved_reply',
        },
      },
      {
        id: 17,
        title: 'Tags',
        url: '/settings/tags',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings_tag',
          writeAccess: 'write:settings_tag',
        },
      },
    ],
  },
  {
    title: 'Team Settings',
    url: '#',
    icon: Users,
    isActive: true,
    globalAccess: false,
    items: [
      {
        id: 2,
        title: 'Team Management',
        url: '/settings/team-info',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings_team_information',
          writeAccess: 'write:settings_team_information',
        },
      },
      {
        id: 3,
        title: 'User Management',
        url: '/settings/team-user',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings_user_management',
          writeAccess: 'write:settings_user_management',
        },
      },
      {
        id: 4,
        title: 'Group Management',
        url: '/settings/team-group',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings_group_management',
          writeAccess: 'write:settings_group_management',
        },
      },
    ],
  },
  {
    title: 'Others',
    url: '#',
    icon: LayoutGrid,
    isActive: true,
    globalAccess: false,
    items: [
      {
        id: 7,
        title: 'Plan & Billing',
        url: '/settings/billing',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings',
          writeAccess: 'write:settings',
        },
      },
      {
        id: 18,
        title: 'Webhook',
        url: '/settings/webhook',
        itemGlobalAccess: false,
        isPro: false,
        permission: {
          readAccess: 'read:settings',
          writeAccess: 'write:settings',
        },
      },
    ],
  },
];
