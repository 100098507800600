import { Card, CardContent } from 'libraryV2/ui/card';

const MetricesCardLoader = () => {
  const automationMetrics = [
    {
      name: 'Automation Triggered',
    },
    {
      name: 'Automation Succeeded',
    },
    {
      name: 'Automation Failed',
    },
  ];
  return (
    <div className='flex items-center justify-between gap-3 w-full mt-2 px-4'>
      {automationMetrics.map((item, index) => (
        <Card key={index} className='w-full h-28'>
          <CardContent className='mt-3'>
            <div>
              <div>
                <span className='text-sm font-medium text-textPrimary'>
                  {item?.name}
                </span>
              </div>
              <div className='w-14 h-6 bg-gray-200 rounded mt-2 animate-pulse'></div>
              <div className='w-52 h-4 bg-gray-200 mt-2 rounded animate-pulse'></div>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default MetricesCardLoader;
