import { FC, useEffect } from 'react';
import CsatDataCards from './components/CsatDataCards';
import CustomerSatisfactionSummary from './components/CustomerSatisfactionSummary';
import CsatOverview from './components/DetailedCsatOverview';
import useCsatReporting from 'pages/reporting/hooks/useCsatReporting';
import { toast } from 'libraryV2/ui/use-toast';
import { LockClosedIcon } from '@heroicons/react/24/solid';
import TranslationWrapper from 'components/customHooks/useTranslationWrapper';
import Button from 'library/button';
import { navigate } from '@reach/router';
import { selectedProjectProps } from 'pages/integration/interface';

interface CsatMetricsProps {
  selectedProject: selectedProjectProps;
}

const UpgradeOverlay = () => {
  return (
    <div
      className='z-10 absolute w-[calc(100vw-62px)] h-[calc(100vh-63px);] ltr:border-l rtl:border-r'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div className='flex flex-col items-center justify-center h-full bg-[#ffffffe6]'>
        <LockClosedIcon className='w-10 h-10 p-2.5 m-5 text-blue-900 bg-blue-100 rounded-[50%]' />
        <p className='text-sm font-medium text-gray-900'>
          <TranslationWrapper text='Upgrade to Premium' />
        </p>
        <p className='text-sm font-normal text-gray-500'>
          <TranslationWrapper text='Add Superpowers to your support arsenal!' />
        </p>
        <Button
          onClick={() => navigate('/settings/billing')}
          className='button-alice__gradient m-5'
        >
          <TranslationWrapper text='Upgrade Now' />
        </Button>
      </div>
    </div>
  );
};

const CsatMetrics: FC<CsatMetricsProps> = ({ selectedProject }) => {
  const isProjectFreePlan =
    selectedProject?.subscription_plan?.plan_type === 'free';
  const { fetchAllCsatData, shouldFetchData, platformsToFilter, dateRange } =
    useCsatReporting();

  useEffect(() => {
    if (!shouldFetchData) return;
    fetchAllCsatData(true).catch((e) => {
      toast({
        variant: 'destructive',
        // @ts-expect-error Just bad typing
        title: <p className='text-red-400'>{'Failed'}</p>,
        description: 'Failed to get CSAT reporting data.',
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformsToFilter, dateRange]);

  const renderCsatMetrics = () => (
    <div
      data-testid='csat-matrics-container'
      className='w-full bg-white p-6 flex flex-col gap-6 relative border-border ltr:border-l rtl:border-r'
    >
      <div data-testid='csat-data-cards' className='w-full h-auto'>
        <CsatDataCards />
      </div>
      <div data-testid='csat-summary' className='w-full'>
        <CustomerSatisfactionSummary />
      </div>
      <CsatOverview />
    </div>
  );

  return isProjectFreePlan ? <UpgradeOverlay /> : renderCsatMetrics();
};

export default CsatMetrics;
