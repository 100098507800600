import React from 'react';
import usePartner from 'components/customHooks/usePartner';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import useTranslation from 'components/customHooks/useTranslation';
import WhatsAppLogin from '../../../../../utilities/WhatsappConnection';

interface Props {
  processWhatsappResponse: (response: any) => any;
  loading: boolean;
  type: string;
}

const Step1: React.FC<Props> = ({
  processWhatsappResponse,
  loading = false,
  type,
}) => {
  const { t } = useTranslation();
  const { currentDomain } = usePartner();
  return (
    <>
      <div className='p-6'>
        {loading && (
          <div className='flex flex-col items-center justify-center '>
            <ArrowPathIcon className='w-20 h-20 text-primary hover:text-primary-hover animate-reverse-spin' />
            <span>{t('fetching page list...')}</span>
          </div>
        )}
        {!loading && (
          <>
            {/* <img
              src={whatsappLogo}
              className='m-auto text-center'
              alt={'whatsapp_logo'}
            /> */}
            <p className='mt-3 text-xl rtl:text-right font-medium'>
              {t('Connect Your Whatsapp Number!')}
            </p>
            <p className='mt-3 text-sm rtl:text-right font-normal text-gray-00 mb-4'>
              {t(
                'Connect your facebook business manager and valid phone number to whatsapp business api and starts using whatsapp from myalice.'
              )}
            </p>
            <ul className='list-disc text-sm rtl:text-right font-normal leading-7 pl-4 pr-4'>
              <li>
                {t(
                  "If you're already using the number in WhatsApp, you will need to disconnect it from WhatsApp first."
                )}
              </li>
              <li>
                {t(
                  "If you don't want to lose any data, use our plugin to backup your WhatsApp chat histories."
                )}
              </li>
              <li>
                {t(
                  'Later you can add the backup to retrieve your chat in MyAlice. For onboarding support,'
                )}{' '}
                <a
                  href='https://myalice.ai/support/'
                  target='_blank'
                  rel='noreferrer'
                  className='text-blue-500'
                >
                  {t('contact us')}
                </a>
                .
              </li>
            </ul>
            <br />
            <div className='flex justify-center'>
              <WhatsAppLogin
                buttonText={t('Connect Whatsapp')}
                hasGuidelines={false}
                handleWhatsappToken={processWhatsappResponse}
                currentDomain={currentDomain}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default Step1;
