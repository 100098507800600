import { Button } from 'libraryV2/ui/button';
import { Form } from 'libraryV2/ui/form';
import { Edit, Loader2, Trash2 } from 'lucide-react';
import React from 'react';
import { useForm } from 'react-hook-form';

import { toast } from 'libraryV2/ui/use-toast';
import useDatalab from 'pages/datalab/hooks/useDatalab';
import {
  formatDatalabFormEntryUpdate,
  formatDatalabNewEntryRequestPayload,
  getDefaultFieldsValue,
  mapFormValues,
} from 'pages/datalab/utils';
import {
  Fragment,
  SingleFormField,
  format,
  useState,
} from './../../export';

import useTranslation from 'components/customHooks/useTranslation';
import { capitalize } from 'lodash';

interface EditViewEntriesProps {
  datalabEntries?: any;
  datalabShape: any;
  onClose: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  formAction: 'EDIT' | 'VIEW' | 'CREATE';
}

type DatalabFormProps = EditViewEntriesProps;

const DatalabForm: React.FC<DatalabFormProps> = ({
  formAction,
  datalabShape,
  datalabEntries,
  onClose,
  onDelete,
  onEdit,
}) => {
  const { t } = useTranslation();
  const { submitDatalabNewEntries, labId, updateDatalabEntry } = useDatalab();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const isFormViewMode = formAction === 'VIEW';
  const isFormEditMode = formAction === 'EDIT';
  const isFormCreateMode = formAction === 'CREATE';

  const defaultFormValues = getDefaultFieldsValue(datalabShape?.lab_fields);

  const finalFormValues = isFormCreateMode
    ? defaultFormValues
    : mapFormValues(datalabEntries, defaultFormValues);

  const datalabHookForm = useForm({
    mode: 'onChange',
    defaultValues: finalFormValues,
  });
  const { dirtyFields, touchedFields, isValid} = datalabHookForm.formState;
  const shouldSubmitButtonDisableOnEdit = Object.keys(dirtyFields).length > 0 || Object.keys(touchedFields).length > 0;

  const onSubmit = async (values: Record<string, any>) => {
    setIsSubmitLoading(true);
    if (isFormCreateMode) {
      const requestPayload = formatDatalabNewEntryRequestPayload(values);
      submitDatalabNewEntries({ formId: labId, payload: requestPayload })
        .then((res) => {
          onClose();
          toast({
            description: t('Successfully added new entry.'),
          });
        })
        .catch((er) => {
          toast({
            description: t('Failed to add new entry!'),
          });
        })
        .finally(() => {
          setIsSubmitLoading(false);
        });
    } else if (isFormEditMode) {
      const payload = formatDatalabFormEntryUpdate(
        structuredClone(datalabEntries),
        values
      );
      //@ts-ignore
      payload['labId'] = labId;
      updateDatalabEntry({
        formId: labId,
        fieldId: datalabEntries.id,
        payload: payload,
      })
        .then(() => {
          onClose();
          toast({
            // @ts-expect-error Just bad typing
            title: <p className='text-green-400'>{t('Saved Changes')}</p>,
            description: t('All changes has been saved successfully.'),
          });
        })
        .catch((er) => {
          toast({
            // @ts-expect-error Just bad typing
            title: <p className='text-green-400'>{t('Failed to update!')}</p>,
            description: t(new Error(er).message || 'Failed to update entry!'),
          });
        })
        .finally(() => {
          setIsSubmitLoading(false);
        });
    }
  };

  const handleSubmitClick = () => {
    datalabHookForm.trigger().then(() => {
      datalabHookForm.handleSubmit(onSubmit)();
    });
  };

  const renderFormFields = () => {
    const fields = datalabShape?.lab_fields;
    if (!fields || !Array.isArray(fields) || fields.length === 0) {
      return null;
    }
    return fields.map((field) => {
      return (
        <Fragment key={field.id}>
          <SingleFormField
            formActionType={formAction}
            data={field}
            valuePath=''
          />
        </Fragment>
      );
    });
  };

  const renderDataLabForm = () => (
    <Form {...datalabHookForm}>
      <form
        onSubmit={datalabHookForm.handleSubmit(onSubmit)}
        className='space-y-3 px-6 min-h-[85vh]'
      >
        {renderFormFields()}
      </form>

      <div className='px-6 py-3 mt-8 flex items-center justify-end border-t sticky bottom-0 bg-white'>
        {isFormCreateMode && renderDatalabCreateButtons()}
        {isFormViewMode && renderDatalabViewButtons()}
        {isFormEditMode && renderDatalabEditButtons()}
      </div>
    </Form>
  );

  const renderDatalabViewButtons = () => {
    return (
      <div className='w-full flex items-center justify-between'>
        <Button
          variant={'outline'} size={'sm'}
          className='bg-[#FFF0F0] text-[#ff0000] hover:text-[#ff0000] border-0 shadow-none'
          onClick={onDelete}
        >
          <Trash2 className='mr-2 h-4 w-4' />
          Delete
        </Button>
        <Button variant={'outline'} onClick={onEdit} size={'sm'}
          className='ml-2'>
          <Edit className='mr-2 h-4 w-4' />
          Edit Entry
        </Button>
      </div>
    );
  };
  const renderDatalabEditButtons = () => {
    return (
      <>
        <Button variant={'outline'} size={'sm'} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant={'default'}
          size={'sm'}
          className='ml-2'
          onClick={handleSubmitClick}
          disabled={!shouldSubmitButtonDisableOnEdit}
        >
          {datalabShape?.button?.name || 'Save changes'}
          {isSubmitLoading ? <Loader2 className='size-5 animate-spin ml-1' /> : null}
        </Button>
      </>
    );
  };

  const renderDatalabCreateButtons = () => {
    return (
      <>
        <Button variant={'outline'} size={'sm'} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant={'default'}
          size={'sm'}
          className='ml-2'
          onClick={handleSubmitClick}
          disabled={!isValid}
        >
          {isSubmitLoading ? <Loader2 className='size-5 animate-spin' /> : null}
          {datalabShape?.button?.name || 'Submit'}
        </Button>
      </>
    );
  }
  const renderDatalabViewForm = () => {
    return (
      <>
      <div className='flex flex-col gap-4 px-6'>
        <div className='flex flex-col gap-3'>
          <h5 className='font-medium leading-5 text-normal text-zinc-900'>
            {t('General Details')}
          </h5>
          <div
            id='container'
            className='bg-gray-100 border border-dashed rounded-md'
          >
            {[
              'id',
              'source',
              'created_at',
              'created_by',
              'last_updated_at',
              'last_updated_by',
            ].map((v, idx) => {
              const currentValue = datalabEntries[v];
              const timedValue =
                v.endsWith('_at') && parseInt(currentValue)
                  ? format(
                      new Date(parseInt(currentValue) * 1000),
                      'MMM d, yyyy h:mm a'
                    )
                  : null;
              const infoValule =
                typeof currentValue !== 'string'
                  ? currentValue?.toString()
                  : currentValue;
              return (
                <div key={v + idx} className='grid grid-cols-2 p-2'>
                  <div className='flex justify-between ltr:pr-3 rtl:pl-3'>
                    <span className='text-zinc-400'>
                      {t(capitalize(v.replaceAll('_', ' ')))}
                    </span>
                    <span className='w-fit'>:</span>
                  </div>
                  <span className='text-left text-zinc-900'>
                    {timedValue ? timedValue : infoValule}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className='flex flex-col gap-3'>
          <h3 className='font-medium leading-5 text-normal text-zinc-900 mb-3'>
            {t('Form Entries')}
          </h3>
        </div>
      </div>
       {renderDataLabForm()}
      </>
    );
  };
  return isFormViewMode ? renderDatalabViewForm() : renderDataLabForm();
};

export default DatalabForm;
