import useTranslation from 'components/customHooks/useTranslation';
import { upperCase } from 'lodash';
import {
  capitalizeFirstLetter,
  DragTypes,
  platinum_color,
  React,
  useActions,
  useTriggerActions,
  RadioLayoutListIcon,
} from 'pages/datalab/export';
import { IFormField } from 'pages/datalab/interface';
import { useDrag } from 'react-dnd';

interface IProps {
  field: IFormField;
}

const SingleItem: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { field } = props;
  const { getController, getSelectedFieldId, getLastAddedFormField } = useActions();
  const { createDataLabField, addTempFieldToLabFields } = useTriggerActions();
  const { labId } = getController();
  const selectedFieldId = getSelectedFieldId();
  const lastField = getLastAddedFormField();
  
  const handleFieldClick = () => {
    // field click functionality here
    // add field to the canvas
    if (field.type === 'group') {
      createDataLabField(labId, field);
    } else {
      addTempFieldToLabFields({ ...field, parent_id: null });
    }
  };

  const [{ isDragging }, drag] = useDrag({
    type: DragTypes.FIELD,
    item: { field, type: DragTypes.FIELD },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      className={`h-[100px] w-[110px] bg-white grid justify-center items-center border rounded-md border-[${platinum_color}] hover:bg-gray-50`}
      ref={drag}
      style={{ cursor: isDragging ? 'grabbing' : 'pointer' }}
      onClick={handleFieldClick}
    >
      <div
        className='grid items-center justify-center'
        style={{ visibility: isDragging ? 'hidden' : 'visible' }}
      >
        <span
          className={
            selectedFieldId && lastField?.name === '' ? 'm-auto text-textPrimary-disable' : 'm-auto'
          }
        >
          {field.type === 'singleselect' ? (
            <RadioLayoutListIcon
              color={selectedFieldId && lastField?.name === '' ? '#A1A1AA' : '#18181B'}
            />
          ) : (
            field.icon
          )}
        </span>
        <span
          className={
            selectedFieldId && lastField?.name === ''
              ? 'mt-3 font-medium text-textPrimary-disable'
              : 'mt-3 font-medium'
          }
        >
          {field.type === 'url'
            ? t(upperCase(field.type))
            : t(capitalizeFirstLetter(field.type))}
        </span>
      </div>
    </div>
  );
};

export default SingleItem;
