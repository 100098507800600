import { Row } from '@tanstack/react-table';
import useTranslation from 'components/customHooks/useTranslation';
import { Popover, PopoverContent, PopoverTrigger } from 'libraryV2/ui/popover';
import { ScrollArea } from 'libraryV2/ui/scroll-area';
import { CheckIcon, ChevronDown } from 'lucide-react';
import React, { useState } from 'react';
import useRoleDropdown from '../../hooks/useRoleDropdown';
import useUserManagement from '../../hooks/useUserManagement';
import { IUserRole, IUserRoleChangeRequestType } from '../../interface';
import RoleName from '../common/RoleName';
interface UserRoleDropdownProps {
  roleName: string;
  row: Row<any>;
}
export const UserRoleDropdown: React.FC<UserRoleDropdownProps> = ({
  roleName,
  row,
}) => {
  const { agentRoleList, selectedProject, hasWriteAccess } =
    useUserManagement();
  const { updateUserRole, updateLocalAgentAccessList } = useRoleDropdown();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const isCreator = row.original.id === selectedProject?.creator.id;

  const roleDetails = (name: string): string | JSX.Element => {
    switch (name) {
      case 'Admin':
        return <>{t('Admin have access to everything.')}</>;
      case 'Supervisor':
        return (
          <>
            {t('Supervisors cannot access Team\n')}
            {t('Settings and integrations.')}
          </>
        );
      case 'Developer':
        return (
          <>
            {t('Developers cannot access Inbox, ')}
            {t('customers and integrations.')}
          </>
        );
      case 'Marketeer':
        return (
          <>
            {t('Marketers cannot access Inbox, API,')}
            {t('Datalab, Customers, and Integrations.')}
          </>
        );
      case 'Agent':
        return <>{t('Agents have access to the Inbox only')}</>;
      default:
        return '';
    }
  };

  const handleItemClick = async (role: IUserRole) => {
    setIsOpen(false);
    const requestPayload: IUserRoleChangeRequestType = {
      teamid: selectedProject?.id,
      userId: row.original?.id,
      role_id: role.id,
    };

    try {
      const response = await updateUserRole(requestPayload);
      if (response && response.status) {
        updateLocalAgentAccessList(response.data.dataSource);
      } else {
        console.error('Unexpected response:', response);
      }
    } catch (error) {
      console.error('Error updating user role:', error);
    }
  };

  return (
    <>
      {hasWriteAccess ? (
        <Popover open={isOpen} onOpenChange={setIsOpen} modal={true}>
          <PopoverTrigger
            onClick={() => {
              if (isCreator) {
                setIsOpen(false);
              } else {
                setIsOpen((isOpen) => !isOpen);
              }
            }}
            disabled={isCreator}
            className={`inline-flex gap-1 rtl:text-right items-center rtl:flex-row-reverse ${
              isCreator ? 'cursor-not-allowed' : ''
            }`}
          >
            {isCreator ? (
              t('Owner')
            ) : (
              <>
                {roleName === 'Marketer' ? t('Marketer') : t(roleName)}{' '}
                <ChevronDown className='w-4 h-4' />
              </>
            )}
          </PopoverTrigger>
          <PopoverContent
            align='center'
            sideOffset={4}
            collisionPadding={16}
            className='bg-white rtl:text-right rtl:flex-row-reverse p-2 max-h-[330px] overflow-y-auto'
          >
            <ScrollArea className='rtl:flex-row-reverse'>
              {agentRoleList &&
                agentRoleList.map((role: IUserRole) => (
                  <div
                    key={role.name}
                    className='flex cursor-pointer rtl:text-right rtl:justify-between ltr:items-start rtl:items-end rtl:flex-row-reverse gap-1 p-2 rounded-lg hover:bg-gray-100'
                    onClick={async () => handleItemClick(role)}
                  >
                    {roleName === role.name ? (
                      <span className='ltr:pl-1 rtl:order-1 rtl:text-right rtl:pr-1'>
                        <CheckIcon className='w-4 h-4' />
                      </span>
                    ) : (
                      <span className='px-2 rtl:order-1 rtl:text-right'></span>
                    )}

                    <div className='flex flex-col ltr:items-start rtl:items-end justify-start gap-1'>
                      <RoleName role={role} roleName={roleName} />
                      <p className='text-xs rtl:text-right text-gray-400'>
                        {roleName === 'Marketer'
                          ? roleDetails('Marketer')
                          : roleDetails(role.name)}
                      </p>
                    </div>
                  </div>
                ))}
            </ScrollArea>
          </PopoverContent>
        </Popover>
      ) : (
        <p>{t(roleName)}</p>
      )}
    </>
  );
};
