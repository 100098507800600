import { useDispatch, useSelector } from 'react-redux';
import { ReportingTab } from '../interface';
import {
  appendQueryParamsToUrl,
  parseDateWithFallback,
} from '../utility/utils';
import { checkPermission, getQueryParameters } from 'utilities/utils';
import { IReprtingPlatforms } from '../component/Nav/ReportingPlatformMultiSelect';
import { reportingTabs } from '../component/Nav/ReportingMatricesTabs';
const readAccess = 'read:analytics';

export const useReporting = () => {
  // selectors
  const dispatch = useDispatch();
  const platformsToFilter: IReprtingPlatforms[] =
    useSelector((state: any) => state?.reportingV2?.platformsToFilter) ?? [];
  const dateRange = useSelector((state: any) => state?.reportingV2?.dateRange);

  const projectList = useSelector((state: any) => state?.dashboard?.projects);

  const permissionList = useSelector(
    (state: any) => state?.dashboard?.permission?.list
  );

  const selectedProject = useSelector(
    (state: any) => state?.dashboard?.selectedProject
  );
  const platformList = useSelector(
    (state: any) => state?.dashboard?.platformList
  );

  const selectedReportingViewMode = useSelector(
    (state: any) => state?.reportingV2?.selectedReportingViewMode
  );

  const currentReportingTab: ReportingTab = useSelector(
    (state: any) => state?.reportingV2?.currentTab
  );

  const isReportingLoading = useSelector(
    (state: any) => state?.loading?.models?.dashboard
  );

  const updateDateRange = (dateRange: any) =>
    dispatch.reportingV2.updateStateData({
      key: 'dateRange',
      value: dateRange,
    });

  const isProjectPlanFree = useSelector(
    (state: any) =>
      state.dashboard.selectedProject?.subscription_plan?.plan_type
  );

  const currentWhatsappMetricsTab = useSelector(
    (state: any) => state?.reportingV2?.currentWhatsappMetricsTab
  );

  const updateStateData = (key: string, value: any) =>
    dispatch.reportingV2.updateStateData({ key, value });

  const updateCurrentReportingTab = (tabName: ReportingTab) => {
    dispatch.reportingV2.updateStateData({
      key: 'currentTab',
      value: tabName,
    });
    appendQueryParamsToUrl({
      tab: tabName,
    });
  };

  const setSelectedProject = async (project: any) =>
    await dispatch.dashboard.setSelectedProject(project);

  // functions
  const hasPermissionToAccessProject = (projectId: number) => {
    // check if the project is in the list of agents' projects
    const project = projectList.find((p: any) => p?.id === projectId);
    return !!project;
    // if (project) return true;
    // // if we can fetch agent list means the agent is in the project
    // const { error } = await dispatch.reportingV2.fetchAgentList(project.id);
    // return !error;
  };

  const handleUrlQueryParams = (
    currentPlatformList: IReprtingPlatforms[] = []
  ) => {
    if (
      !Array.isArray(currentPlatformList) ||
      currentPlatformList.length === 0
    ) {
      return;
    }
    // validate & set current reporting tab
    const tabNameSlug = getQueryParameters('tab');
    const hasValidTab = reportingTabs.find((v) => v.value === tabNameSlug);
    const tabName = hasValidTab?.value || 'customer-metrics';

    // validate & set platforms
    let urlQueryPlatforms: IReprtingPlatforms[] = [];
    const hasPlatforms = getQueryParameters('platforms');
    // if tab is csat update in a specific state
    if (hasPlatforms) {
      const platformIds = hasPlatforms.split(',');
      urlQueryPlatforms = currentPlatformList.filter((p) =>
        platformIds.includes(p?.id?.toString())
      );
      if (tabName === 'whatsapp-metrics') {
        urlQueryPlatforms = urlQueryPlatforms.filter(
          (p) => p.platform_type === 'whatsapp_bsp'
        );
      }
    }

    // validate & set date range
    const hasFromDate = getQueryParameters('from');
    const hasToDate = getQueryParameters('to');
    const fromDate = parseDateWithFallback(hasFromDate, 7);
    const toDate = parseDateWithFallback(hasToDate, 0, 'end');

    const queries = {
      tab: tabName,
      platforms: urlQueryPlatforms,
      from: fromDate,
      to: toDate,
    };
    appendQueryParamsToUrl({
      ...queries,
      platforms: urlQueryPlatforms?.map((p) => p.id).join(','),
    });
    return queries;
  };

  const updateProjectInformation = async (projectId: number) => {
    // check if the project is in the list of agents' projects
    const project = (projectList as any[]).find((p) => projectId === p?.id);
    if (!project) {
      return false;
    }
    let projectInfo = selectedProject;
    // if the project is different from the selected project, update the selected project
    if (projectId !== selectedProject?.id) {
      projectInfo = await setSelectedProject(project);
    }
    const hasPermission = checkPermission(permissionList, readAccess);
    if (hasPermission) {
      return projectInfo;
    }
    return false;
  };

  // reducers
  const clearReportingState = () => dispatch.reportingV2.clearState();
  const setSelectedPlatform = async (platform: any) =>
    dispatch.dashboard.setSelectedPlatform(platform);

  const updatePlatformsToFilter = (plist: any[]) => {
    if (!Array.isArray(plist)) {
      return;
    }
    dispatch.reportingV2.updateStateData({
      key: 'platformsToFilter',
      value: plist,
    });
  };
  // effects

  return {
    currentReportingTab,
    isProjectPlanFree,
    isReportingLoading,
    selectedProject,
    updatePlatformsToFilter,
    projectList,
    setSelectedProject,
    currentWhatsappMetricsTab,
    updateCurrentReportingTab,
    clearReportingState,
    platformsToFilter,
    updateDateRange,
    handleUrlQueryParams,
    permissionList,
    dateRange,
    selectedReportingViewMode,
    platformList,
    updateStateData,
    updateProjectInformation,
    setSelectedPlatform,
    hasPermissionToAccessProject,
  };
};
