import {
  dayjs,
  useDispatch,
  useSelector,
  UtilityContents,
} from 'pages/automationWorkflow/export';
import { IUserActionFlowLogParam } from 'pages/automationWorkflow/interface';
import { DateRange } from 'react-day-picker';

const useAutomationHistories = () => {
  const dispatch = useDispatch();
  const { selectedProject } = useSelector((state: any) => state.dashboard);

  // Destructure required state loading from the workflowAutomation
  const {
    getSelectedUserActionFlowLogList: isGetSelectedUserActionFlowLogListLoading,
  } = useSelector((state: any) => state.loading.effects.workflowAutomation);

  const { selectedUserActionFlowData } = useSelector(
    (state: any) => state.workflowAutomation
  );

  const fetchSelectedUserActionFlowLogData = async (
    payload: IUserActionFlowLogParam
  ) => {
    try {
      const { id } = selectedProject;

      const params = {
        user_action_flow_id: payload.user_action_flow_id,
        offset: payload.offset,
        limit:
          payload.limit || UtilityContents?.UserActionFlowGetterApiDataLimit,
        start_datetime: payload.start_datetime,
        end_datetime: payload.end_datetime,
        recovered_status: payload.recovered_status,
        status: payload.status,
        unique_identifier_value: payload.unique_identifier_value,
      };

      const searchParams = new URLSearchParams();

      Object.entries(params).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          // Handle arrays by appending each value with the same key
          value.forEach((val) => searchParams.append(key, String(val)));
        } else if (value !== undefined && value !== null) {
          // Handle other non-array values
          searchParams.append(key, String(value));
        }
      });

      const queryString = searchParams.toString();

      const getRes =
        await dispatch.workflowAutomation.getSelectedUserActionFlowLogList({
          queryString,
          projectId: id,
        });

      return getRes;
    } catch (err) {
      throw err;
    }
  };

  const fetchSelectedUserActionFlowLogHistoryStatus = async (
    dateRange: DateRange,
    cache_off: boolean,
    flowId: number
  ) => {
    try {
      const { id, currency_code } = selectedProject;
      const params = {
        user_action_flow_id: flowId,
        currency_code: currency_code,
        start_datetime: dayjs(dateRange.from).toISOString(),
        end_datetime: dayjs(dateRange.to).toISOString(),
        cache_off: cache_off,
      };

      const searchParams = new URLSearchParams();

      Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          searchParams.append(key, String(value));
        }
      });

      const queryString = searchParams.toString();
      const getRes =
        await dispatch.workflowAutomation.getSelectedActionFlowHistoryStatus({
          queryString,
          projectId: id,
        });
      if (getRes?.success === true) {
        return getRes;
      } else {
        return false;
      }
    } catch (err) {
      throw err;
    }
  };

  const updateHistoryTableOffset = (data: number) => {
    dispatch.workflowAutomation.updatePrimitiveStateData({
      key: 'historyTableOffset',
      value: data,
    });
  };

  return {
    updateHistoryTableOffset,
    fetchSelectedUserActionFlowLogData,
    isGetSelectedUserActionFlowLogListLoading,
    selectedUserActionFlowData,
    fetchSelectedUserActionFlowLogHistoryStatus,
  };
};

export default useAutomationHistories;
