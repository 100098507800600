import { useRef, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { flexRender, Table as TableType } from '@tanstack/react-table';
import notFoundImg from 'assets/icons/emptySearchIcon.svg';
import useTranslation from 'components/customHooks/useTranslation';
import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from 'libraryV2/ui/table';

import { TemplateOverviewTableRowAction } from './TemplateOverviewTableRowAction';
import { Button } from 'libraryV2/ui/button';
import {
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from '@radix-ui/react-icons';

interface DataTableProps<TData> {
  table: TableType<TData>;
  offset: number;
  setOffset: (offset: number) => void;
  isLoading: boolean;
}

export function DataTable<TData>({
  table,
  offset,
  isLoading,
  setOffset,
}: DataTableProps<TData>) {
  const pageLimit = 10;
  const [hoveredRow, setHoveredRow] = useState(null);
  const [ellipsisStyles, setEllipsisStyles] = useState({
    top: 0,
    height: 0,
  });
  const tableRef = useRef(null);

  const handleMouseEnter = (event: any, row: any) => {
    setHoveredRow(row);

    // Get the position of the hovered row relative to the table
    const rowRect = event.currentTarget.getBoundingClientRect();
    //@ts-ignore
    const tableRect = tableRef?.current?.getBoundingClientRect();
    const topPosition = rowRect.top - tableRect.top;
    setEllipsisStyles({
      top: topPosition,
      height: rowRect.height - 5,
    });
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const renderTableNoDataFound = () => {
    const isFilterApplied =
      table
        .getState()
        .columnFilters.filter((f) => (f.value as string | string[]).length)
        .length > 1;
    const isSearchApplied =
      table?.getColumn('template-name')?.getFilterValue() || '';
    const hasOnlySearchFilter = !isFilterApplied && isSearchApplied;

    return (
      <TableRow>
        <TableCell
          colSpan={table.getHeaderGroups()[0].headers.length}
          className='h-auto text-center'
        >
          <div className='flex items-center justify-center w-full py-32'>
            <div className='flex flex-col items-center max-w-xs'>
              <img src={notFoundImg} alt='empty' />
              <h6 className='pt-2 text-zinc-900 text-lg font-semibold'>
                No Results Found
              </h6>
              <p className='pt-2 pb-3 text-center text-zinc-500'>
                {hasOnlySearchFilter
                  ? 'Please search with different keyword.'
                  : 'Please search with different keyword or filter.'}
              </p>
            </div>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  const renderTableLoadingView = (numberOfRows = 10) => {
    const tableHeaders = table.getFlatHeaders().map((h) => h.id);
    return Array(numberOfRows)
      .fill(0)
      .map((_, index) => (
        <TableRow key={index}>
          {tableHeaders.map((v) => (
            <TableCell key={v} colSpan={1} style={{ padding: '6px 8px' }}>
              <div className='w-auto py-4  bg-zinc-100 animate-pulse rounded'></div>
            </TableCell>
          ))}
        </TableRow>
      ));
  };

  return (
    <div className='relative'>
      <div className='border rounded-md' ref={tableRef}>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={header.id}
                    colSpan={header.colSpan}
                    className='text-sm text-textSecondary font-medium'
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
                <TableHead></TableHead>
              </TableRow>
            ))}
          </TableHeader>
          {!!isLoading ? (
            renderTableLoadingView()
          ) : (
            <TableBody>
              {table.getRowModel().rows?.length
                ? table.getRowModel().rows.map((row, index) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && 'selected'}
                      className={`cursor-pointer bg-white h-12 ${
                        //@ts-ignore
                        hoveredRow?.id === row.id ? 'bg-gray-50' : ''
                      }`}
                      onMouseEnter={(event) => handleMouseEnter(event, row)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <>
                          <TableCell
                            key={cell.id}
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        </>
                      ))}
                    </TableRow>
                  ))
                : renderTableNoDataFound()}
            </TableBody>
          )}
        </Table>

        <DataTablePagination
          table={table}
          limit={pageLimit}
          offset={offset}
          onPageChange={setOffset}
        />
      </div>

      {/* Fixed ellipsis on the right side, dynamically positioned based on hovered row */}
      {hoveredRow !== null && (
        <div
          className='absolute right-1 mt-0.5 w-[60px] flex justify-center items-center'
          style={{
            top: ellipsisStyles.top,
            backgroundImage:
              'linear-gradient(270deg, rgb(250, 250, 250) 70%, rgba(244, 244, 245, 0) 100%)',
            width: '60px',
            height: `${ellipsisStyles.height}px`,
          }}
          onMouseEnter={() => setHoveredRow(hoveredRow)} // Prevent hiding when hovering over the ellipsis
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <TemplateOverviewTableRowAction row={hoveredRow} />
        </div>
      )}
    </div>
  );
}

interface DataTablePaginationProps<TData> {
  table: TableType<TData>;
  limit: number;
  offset: number;
  onPageChange: (newOffset: number) => void;
}

export function DataTablePagination<TData>({
  table,
  limit,
  offset,
  onPageChange,
}: DataTablePaginationProps<TData>) {
  const { t } = useTranslation();

  const totalRowCount = table.getRowCount();
  const currentlyShowingRowCount = offset + table.getRowModel().rows.length;
  const totalPageCount = table.getPageCount();

  const handlePageChange = (newOffset: number) => {
    onPageChange(newOffset);
  };

  const currentPage = Math.ceil(offset / limit) + Number(!!totalPageCount);

  return (
    <div className='flex items-center justify-between px-2 py-3 bg-white rounded-b-md'>
      <div className='flex-1 text-sm text-muted-foreground'>
        {`${currentlyShowingRowCount} of ${totalRowCount} row(s) is showing.`}
      </div>
      <div className='flex items-center space-x-6 lg:space-x-8'>
        <div className='flex items-center justify-center text-sm font-medium'>
          {`${t('Page')} ${currentPage} ${t('of')} ${totalPageCount}`}
        </div>
        <div className='flex items-center space-x-2'>
          <Button
            variant='outline'
            className='hidden w-8 h-8 p-0 lg:flex rtl:order-4'
            onClick={() => handlePageChange(0)}
            disabled={offset === 0}
          >
            <span className='sr-only'>{t('Go to first page')}</span>
            <DoubleArrowLeftIcon className='w-4 h-4' />
          </Button>
          <Button
            variant='outline'
            className='w-8 h-8 p-0 rtl:order-3'
            onClick={() => handlePageChange(offset - limit)}
            disabled={offset === 0}
          >
            <span className='sr-only'>{t('Go to previous page')}</span>
            <ChevronLeftIcon className='w-4 h-4' />
          </Button>
          <Button
            variant='outline'
            className='w-8 h-8 p-0 rtl:order-2'
            onClick={() => handlePageChange(offset + limit)}
            disabled={offset + limit >= totalRowCount}
          >
            <span className='sr-only'>{t('Go to next page')}</span>
            <ChevronRightIcon className='w-4 h-4' />
          </Button>
          <Button
            variant='outline'
            className='hidden w-8 h-8 p-0 lg:flex'
            onClick={() =>
              handlePageChange((Math.ceil(totalRowCount / limit) - 1) * limit)
            }
            disabled={offset + limit >= totalRowCount}
          >
            <span className='sr-only'>{t('Go to last page')}</span>
            <DoubleArrowRightIcon className='w-4 h-4' />
          </Button>
        </div>
      </div>
    </div>
  );
}
