'use client';

import { ColumnDef } from '@tanstack/react-table';
import { Badge } from 'libraryV2/ui/badge';
import { Checkbox } from 'libraryV2/ui/checkbox';
import { DataTableColumnHeader } from 'pages/datalab/export';
import { DefaultAvatarIcon } from 'pages/settings/slaSettings/export';
import { ISelectedProject, IUserManagement } from '../../interface';
import { getRelativeTime } from '../../utils';
import { UserRoleDropdown } from './UserRoleDropdown';

import TranslationWrapper from 'components/customHooks/useTranslationWrapper';

import { Avatar, AvatarFallback, AvatarImage } from 'libraryV2/ui/avatar';
import RowActions from './RowActions';
import RowCheckbox from './RowCheckbox';

export const createDymanicColumns = (
  selectedProject: ISelectedProject
): ColumnDef<IUserManagement>[] => {
  const isCreator = (row: any) =>
    row.original.id === selectedProject?.creator.id;

  return [
    {
      id: 'select',
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && 'indeterminate')
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label='Select all'
          className={`translate-y-[2px] ${
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && 'indeterminate')
              ? 'bg-primary text-white ring-0 border-0'
              : ''
          }`}
        />
      ),
      cell: ({ row }) => {
        return (
          <RowCheckbox
            rowId={row.id}
            row={row}
            isSelected={row.getIsSelected()}
            toggleSelected={(value) => row.toggleSelected(!!value)}
          />
        );
      },
    },
    {
      accessorKey: 'agents',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          className='text-zinc-500 font-medium max-w-[170px]'
          title='Agents'
        />
      ),
      cell: ({ row, column }) => {
        const agentName: string = row.getValue('agents');
        const { avatar, is_confirmed } = row.original;
        return (
          <div className='flex items-center gap-2'>
            <Avatar className='h-6 w-6 rounded-full border border-gray-200 bg-gray-50 object-cover '>
              <AvatarImage src={avatar} alt='Rounded avatar' />
              <AvatarFallback>
                <img
                  src={DefaultAvatarIcon}
                  alt='Rounded avatar'
                  className='object-cover border border-gray-100 rounded-full h-7 w-7'
                />
              </AvatarFallback>
            </Avatar>
            <span className='max-w-[150px] text-sm truncate text-zinc-900'>
              {agentName}
              {!is_confirmed && (
                <Badge
                  variant='outline'
                  className='ltr:ml-2 rtl:mr-2 bg-[#F4F4F5] text-[#18181B] font-medium border-none'
                >
                  <TranslationWrapper text='Pending' />
                </Badge>
              )}
            </span>
          </div>
        );
      },
    },
    {
      accessorKey: 'email',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          className='text-zinc-500 font-medium'
          title='Email'
        />
      ),
      cell: ({ row }) => {
        const text: string = row.getValue('email');
        return (
          <div className='flex'>
            <span className='max-w-[400px] text-sm truncate font-normal'>
              {text}
            </span>
          </div>
        );
      },
    },
    {
      accessorKey: 'last_log_in',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          className='text-zinc-500 font-medium'
          title='Last log in'
        />
      ),
      cell: ({ row }) => {
        const last_login: string = row.getValue('last_log_in');
        return (
          <div className='flex'>
            <span className='max-w-[400px] text-sm truncate font-normal'>
              {getRelativeTime(last_login)}
            </span>
          </div>
        );
      },
    },
    {
      accessorKey: 'user_role',
      accessorFn: (row) => row?.user_role?.name,
      filterFn: (row, columnId, filterValue) => {
        const roleName = row?.original?.user_role?.name?.toLowerCase();
        if (filterValue.includes('owner')) {
          return roleName === 'admin' && isCreator(row);
        }
        if (filterValue.includes('admin')) {
          return roleName === 'admin' && !isCreator(row);
        }
        return filterValue.includes(roleName);
      },
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          className='text-zinc-500 font-medium'
          title='User role'
        />
      ),
      cell: ({ row }) => {
        const roleName: string = row.getValue('user_role');
        return <UserRoleDropdown roleName={roleName} row={row} />;
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        const agent: IUserManagement = row.original;

        return (
          // actions for each row
          <RowActions row={agent} />
        );
      },
    },
  ];
};
