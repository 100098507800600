import { Table } from '@tanstack/react-table';
import {
  MultiSelect,
  MultiSelectOptionBadgeView,
} from 'libraryV2/extended-ui/MultiSelect';
import { Button } from 'libraryV2/ui/button';
import { Checkbox } from 'libraryV2/ui/checkbox';
import { Input } from 'libraryV2/ui/input';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import { cn } from 'libraryV2/utils';
import { startCase } from 'lodash';
import { PlusCircle, Search, X } from 'lucide-react';

import { FC } from 'react';
import {
  statusColors,
  templateStatusOptions,
  templateTemplateTypeOptions,
  type TemplateStatus,
} from 'pages/reportingV2/utility/content';
import { whatsappMessageTemplateProps } from 'pages/integration/interface';

interface TemplateOverviewTableHeaderProps<TData> {
  table: Table<TData>;
  whatsappTemplateList: whatsappMessageTemplateProps[];
}

export const TemplateStatusView = ({ status }: { status: TemplateStatus }) => {
  const color = statusColors[status] || statusColors.disabled;
  return (
    <div
      className={cn(
        'border border-border py-0.5 px-2 rounded-md w-fit text-sm font-medium flex items-center gap-1',
        `text-[${color}]`
      )}
    >
      <span
        className={cn('w-1.5 h-1.5 rounded-full mt-0.5', `bg-[${color}]`)}
      ></span>
      {startCase(status)}
    </div>
  );
};

export const TemplateOverviewTableHeader: FC<
  TemplateOverviewTableHeaderProps<any>
> = ({ table, whatsappTemplateList }) => {
  const handleStatusFilter = (options: typeof templateStatusOptions) => {
    table.getColumn('template-status')?.setFilterValue(options);
  };

  const handleTypeFilter = (options: typeof templateTemplateTypeOptions) => {
    table.getColumn('template-type')?.setFilterValue(options);
  };

  const handleTemplateFilter = (
    options: typeof templateTemplateTypeOptions
  ) => {
    table.getColumn('template-id')?.setFilterValue(options);
  };

  const handleRemoveStatusFilter = (
    option: typeof templateStatusOptions[number]
  ) => {
    table
      .getColumn('template-status')
      ?.setFilterValue((currentOptions: typeof templateStatusOptions) =>
        currentOptions.filter((o) => o.value !== option.value)
      );
  };

  const handleRemoveTypeFilter = (
    option: typeof templateTemplateTypeOptions[number]
  ) => {
    table
      .getColumn('template-type')
      ?.setFilterValue((currentOptions: typeof templateTemplateTypeOptions) =>
        currentOptions.filter((o) => o.value !== option.value)
      );
  };

  const filteredTemplateList = whatsappTemplateList.map((template) => ({
    label: template.name,
    value: template.id,
  }));

  const renderSearchInput = () => {
    return (
      <div className='flex items-center gap-2 relative'>
        <Search className='w-4 h-4 absolute transform -translate-y-1/2 left-2 top-1/2 text-textPrimary-disable' />
        <Input
          placeholder='Search with template name or ID'
          value={
            (table?.getColumn('template-name')?.getFilterValue() as string) ??
            ''
          }
          className='ltr:pl-7 rtl:pr-7 h-8 m-0 w-[290px]'
          onChange={(event) =>
            table
              ?.getColumn('template-name')
              ?.setFilterValue(event.target.value)
          }
        />
      </div>
    );
  };

  const renderTemplateFilter = () => {
    const idFilter =
      table.getState().columnFilters.find((f) => f.id === 'template-id')
        ?.value || [];

    return (
      <div className=''>
        <MultiSelect
          options={filteredTemplateList as typeof templateTemplateTypeOptions}
          selectedOptions={idFilter as typeof templateTemplateTypeOptions}
          onApplyFilter={handleTemplateFilter}
          onClearFilter={() => {}}
          searchInputPlaceholder='Search templates'
          renderOption={(option, isSelected) => {
            return (
              <div className='flex items-center gap-2'>
                <Checkbox
                  checked={isSelected}
                  className={cn({
                    'bg-primary text-white ring-0 border-0': isSelected,
                  })}
                />
                <TooltipProviderCustomised
                  content={option.label}
                  asChild
                  minContentLength={25}
                  side='right'
                >
                  <span className='text-sm truncate w-[170px]'>
                    {option.label}
                  </span>
                </TooltipProviderCustomised>
              </div>
            );
          }}
          getOptionValue={(option) => option.value}
          renderTrigger={() => {
            return (
              <Button
                variant={'outline'}
                className='border-dashed font-medium text-xs text-textPrimary border-border gap-2 px-2 h-8'
              >
                <PlusCircle className='w-4 h-4' />
                <span>Template</span>
              </Button>
            );
          }}
          classNames={{
            popoverContent: 'w-[240px]',
          }}
        />
      </div>
    );
  };

  const renderTemplateTypeFilter = () => {
    const typeFilter =
      table.getState().columnFilters.find((f) => f.id === 'template-type')
        ?.value || [];
    return (
      <div className=''>
        <MultiSelect
          options={templateTemplateTypeOptions}
          selectedOptions={typeFilter as typeof templateTemplateTypeOptions}
          onApplyFilter={handleTypeFilter}
          onClearFilter={() => {}}
          renderOption={(option, isSelected) => {
            return (
              <div className='flex items-center gap-2'>
                <Checkbox
                  checked={isSelected}
                  className={cn({
                    'bg-primary text-white ring-0 border-0': isSelected,
                  })}
                />
                {option.label}
              </div>
            );
          }}
          getOptionValue={(option) => option.value}
          renderTrigger={() => {
            return (
              <Button
                variant={'outline'}
                className='border-dashed text-xs font-medium text-textPrimary border-border gap-2 px-2 h-8'
              >
                <PlusCircle className='w-4 h-4' />
                <span>Type</span>
              </Button>
            );
          }}
          classNames={{
            popoverContent: 'w-[200px]',
          }}
        />
      </div>
    );
  };

  const renderStatusFilter = () => {
    const statusFilter =
      table.getState().columnFilters.find((f) => f.id === 'template-status')
        ?.value || [];
    return (
      <div className=''>
        <MultiSelect
          options={templateStatusOptions}
          shouldHideSearchInput
          selectedOptions={statusFilter as typeof templateStatusOptions}
          onApplyFilter={handleStatusFilter}
          onClearFilter={() => {}}
          renderOption={(option, isSelected) => {
            return (
              <div className='flex items-center gap-2'>
                <Checkbox
                  checked={isSelected}
                  className={cn({
                    'bg-primary text-white ring-0 border-0': isSelected,
                  })}
                />
                <TemplateStatusView
                  status={option.value as unknown as TemplateStatus}
                />
              </div>
            );
          }}
          getOptionValue={(option) => option.value}
          renderTrigger={() => {
            return (
              <Button
                variant={'outline'}
                className='border-dashed font-medium text-xs text-textPrimary border-border gap-2 px-2 h-8'
              >
                <PlusCircle className='w-4 h-4' />
                <span>Status</span>
              </Button>
            );
          }}
          classNames={{
            commandGroup: 'h-fit max-h-[400px]',
            popoverContent: 'w-[240px]',
          }}
        />
      </div>
    );
  };

  const renderClearFilterButton = () => {
    const filterLength = table
      .getState()
      .columnFilters.filter(
        (f) => (f.value as string | string[]).length
      ).length;
    const isSearchApplied =
      table?.getColumn('template-name')?.getFilterValue() || '';
    const hasOnlySearchFilter = filterLength === 1 && isSearchApplied;

    if (hasOnlySearchFilter || filterLength === 0) return null;
    return (
      <Button
        className='bg-background-hover h-8 gap-1 text-xs'
        variant={'ghost'}
        onClick={() => {
          table.resetColumnFilters();
        }}
      >
        <X className='w-4 h-4' />
        Clear Filters
      </Button>
    );
  };

  return (
    <div className='flex flex-col w-full gap-2'>
      <div className='flex w-full items-center justify-between gap-2'>
        <div className='flex w-fit items-center gap-3'>
          {renderSearchInput()}
          {renderTemplateFilter()}
          {renderStatusFilter()}
          {renderTemplateTypeFilter()}
          {renderClearFilterButton()}
        </div>
        <div>
          <Button variant={'outline'}>Export</Button>
        </div>
      </div>
      <div className='flex w-full'>
        <div className='flex items-center gap-2'>
          <MultiSelectOptionBadgeView
            label='Status'
            options={
              (table
                .getState()
                .columnFilters.find((f) => f.id === 'template-status')
                ?.value as typeof templateStatusOptions) || []
            }
            getOptionLabel={(o) => o?.label}
            onRemoveOption={handleRemoveStatusFilter}
          />
          <MultiSelectOptionBadgeView
            label='Type'
            options={
              (table
                .getState()
                .columnFilters.find((f) => f.id === 'template-type')
                ?.value as typeof templateTemplateTypeOptions) || []
            }
            getOptionLabel={(o) => o?.label}
            onRemoveOption={handleRemoveTypeFilter}
          />
        </div>
      </div>
    </div>
  );
};
