import { useState } from 'react';
import {
  Button,
  Calendar,
  CalendarDays,
  DateRange,
  Popover,
  PopoverContent,
  PopoverTrigger,
  cn,
  format,
} from '../../export';

import { PopoverClose } from '@radix-ui/react-popover';
import useTranslation from 'components/customHooks/useTranslation';
import useDatalab from 'pages/datalab/hooks/useDatalab';
import { Skeleton } from 'libraryV2/ui/skeleton';

interface CalendarDateRangePickerProps {
  className?: string;
  defaultDateRange: DateRange | undefined;
}

export function CalendarDateRangePicker({
  className,
  defaultDateRange
}: CalendarDateRangePickerProps) {
  const { t } = useTranslation();
  const { updateDateRangeFilter } = useDatalab();

  const [date, setDate] = useState<DateRange | undefined>({
    from: undefined,
    to: undefined,
  });

  const handleDateRangeApply = () => {
    if (date && date.from && date.to) {
      updateDateRangeFilter(date.from, date.to);
    }
  };

  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id='date'
            variant={'outline'}
            className={cn(
              'w-[260px] justify-start ltr:text-left rtl:text-right font-normal',
              !date && 'text-muted-foreground'
            )}
          >
            <CalendarDays className='w-4 h-4 ltr:mr-2 rtl:ml-2' />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, 'LLL dd, y')} -{' '}
                  {format(date.to, 'LLL dd, y')}
                </>
              ) : (
                format(date.from, 'LLL dd, y')
              )
            ) : (
              <>
               {defaultDateRange?.from && defaultDateRange?.to ? (
                 <>
                   {format(defaultDateRange.from, 'LLL dd, y')} -{' '}
                   {format(defaultDateRange.to, 'LLL dd, y')}
                 </>
               ) : (
                 <Skeleton className="h-4 w-[240px] bg-[#F4F4F5] rounded-sm" />
               )}
             </>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-auto p-0 bg-white' align='end'>
          <Calendar
            initialFocus
            mode='range'
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
          />
          <div className='flex px-4 py-2 ltr:flex-end rtl:flex-start'>
            <PopoverClose asChild>
              <Button onClick={handleDateRangeApply} className='text-white'>
                {t('Apply')}
              </Button>
            </PopoverClose>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
