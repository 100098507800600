import { FC } from 'react';
import { HistoryTableContainer } from './HistoryTable/HistoryTableContainer';
import useWhatsappMetrics from '../../hooks/useWhatsappMetrics';
import dayjs from 'dayjs';

interface WaMetricsTemplateHistoryProps {}

export const WaMetricsTemplateHistory: FC<
  WaMetricsTemplateHistoryProps
> = () => {
  const { dateRange } = useWhatsappMetrics();
  const formattedStartDate = dayjs(dateRange.from).format('Do MMM YYYY');
  const formattedEndDate = dayjs(dateRange.to).format('Do MMM YYYY');

  return (
    <div className='p-6 flex flex-col gap-4'>
      <div className='space-y-1.5'>
        <h1 className='text-textPrimary font-semibold text-base'>
          Template History
        </h1>
        <p className='text-textSecondary font-normal text-sm'>
          Showing history from {formattedStartDate} - {formattedEndDate}
        </p>
      </div>
      <HistoryTableContainer />
    </div>
  );
};
