import { DataTable } from 'libraryV2/ui/data-table';
import { FC, useEffect, useState } from 'react';
import { templateHistoryTableColumns } from './Columns';
import useWhatsappMetrics from '../../../hooks/useWhatsappMetrics';
import { cn, useDebounce } from 'pages/settings/slaSettings/export';
import { formatAnalyticsState } from 'pages/reportingV2/utility/utils';
import { HistoryTableHeaders } from './HistoryTableHeaders';
import { IWhatsappTemplateAnalyticsData } from 'pages/reportingV2/interface';

interface HistoryTableContainerProps {}

type FilterOption = {
  label: string;
  value: string;
};

export interface IHistoryColumnFilter {
  search: string;
  templates: FilterOption[];
  status: FilterOption[];
  sources: FilterOption[];
}

export const HistoryTableContainer: FC<HistoryTableContainerProps> = () => {
  const {
    dateRange,
    platformsToFilter,
    fetchTemplateHistoryData,
    templateAnalyticsLogs,
    templateAnalyticsLogsTotalData,
    fetchTemplateList,
    selectedTemplateAnalytics,
    updateSelectedTemplateAnalytics,
    whatsappTemplateData,
    isTemplateHistoryDataLoading,
  } = useWhatsappMetrics();

  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  // lazy initialize
  const [columnFilters, setColumnFilters] = useState<IHistoryColumnFilter>(
    () => {
      const templateIds = selectedTemplateAnalytics?.id
        ? [
            {
              label: selectedTemplateAnalytics.name,
              value: selectedTemplateAnalytics.id,
            },
          ]
        : [];
      return {
        templates: templateIds,
        status: [],
        sources: [],
        search: '',
      };
    }
  );
  const debounceFilters = useDebounce(columnFilters, 1000);

  useEffect(() => {
    const payload = formatAnalyticsState();
    fetchTemplateHistoryData({
      ...payload,
      offset,
      limit,
      templateFilter: selectedTemplateAnalytics?.id || '',
    });
    return () => {
      updateSelectedTemplateAnalytics(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, dateRange, platformsToFilter, limit, debounceFilters]);

  // fetch template list by platform, need it in table filter
  useEffect(() => {
    const currentWaChannelId = platformsToFilter[0]?.id + '';
    const statePlatformId = whatsappTemplateData?.platformId + '';
    if (currentWaChannelId && currentWaChannelId !== statePlatformId) {
      fetchTemplateList(+currentWaChannelId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformsToFilter, dateRange]);

  const handleLimitChange = async (newLimit: number) => {
    setLimit(newLimit);
  };

  const handlePageUpdate = async (newOffset: number) => {
    // await handlePageChange(newOffset, limit);
    setOffset(newOffset);
  };

  return (
    <div className='w-full flex flex-col gap-3'>
      <HistoryTableHeaders
        whatsappTemplateList={
          whatsappTemplateData.templateList as Pick<
            IWhatsappTemplateAnalyticsData,
            'status' | 'id' | 'name'
          >[]
        }
        filterState={columnFilters}
        setFilterState={setColumnFilters}
      />

      <DataTable
        columns={templateHistoryTableColumns}
        data={templateAnalyticsLogs}
        hasPagination={true}
        dataLoading={isTemplateHistoryDataLoading}
        paginationProps={{
          limit: limit,
          offset: offset,
          totalRowCount: templateAnalyticsLogsTotalData,
          onLimitChange: handleLimitChange,
          onPageChange: handlePageUpdate,
        }}
      />
      <div
        className={cn('h-10 w-full ', {
          hidden: limit !== 10,
        })}
      ></div>
    </div>
  );
};
