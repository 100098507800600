import { FC } from 'react';

export const AutomationDashboardLoader: FC = () => {
  return (
    <div className='bg-white ml-[1px] min-h-[93vh]'>
      <div className='px-4 mt-3'>
        <div className='h-4 w-[246px] animate-pulse bg-gray-200 rounded mt-2' />
        <div className='h-4 w-[300px] animate-pulse bg-gray-200 rounded mt-2' />
      </div>

      <div className='flex items-center gap-2 px-4 mt-3'>
        {[0, 1, 2].map((index: number) => (
          <div key={index} className='w-full h-28 rounded-md border pt-4 pl-3'>
            <div className='h-4 w-28 animate-pulse bg-gray-200 rounded' />
            <div className='h-4 w-20 animate-pulse bg-gray-200 rounded mt-2' />
            <div className='h-4 w-[246px] animate-pulse bg-gray-200 rounded mt-2' />
          </div>
        ))}
      </div>

      <div className='flex-grow  px-4 '>
        <div className='flex items-start justify-between space-x-4 py-4 animate-pulse'>
          <div className='flex space-x-2'>
            <div className='h-8 w-[246px] bg-gray-200 rounded-md'></div>
            <div className='h-8 w-[76px] bg-gray-200 rounded-md'></div>
          </div>
          <div className=' flex space-x-2'>
            <div className='h-8 w-[78px] bg-gray-200 rounded-md'></div>
            <div className='h-8 w-[78px] bg-gray-200 rounded-md'></div>
            <div className='h-8 w-[130px] bg-gray-200 rounded-md'></div>
          </div>
        </div>
        <div className='border rounded'>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((index: number) => (
            <div
              key={index}
              className='w-full p-4 mx-auto border-b  border-gray-100'
            >
              <div className='flex items-center space-x-4 animate-pulse'>
                <div className='w-5 h-5 bg-gray-200 rounded-full flex-shrink-0' />
                <div className='w-[15%] h-5 bg-gray-200 rounded' />
                <div className='w-[15%] h-5 bg-gray-200 rounded' />
                <div className='w-[10%] h-5 bg-gray-200 rounded' />
                <div className='w-[25%] h-5 bg-gray-200 rounded' />
                <div className='w-[10%] h-5 bg-gray-200 rounded' />
                <div className='w-[10%] h-5 bg-gray-200 rounded' />
                <div className='w-[10%] h-5 bg-gray-200 rounded' />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
