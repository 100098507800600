import { LockClosedIcon } from '@heroicons/react/24/solid';
import { Link } from '@reach/router';
import useTranslation from 'components/customHooks/useTranslation';
import { LoaderCircle } from 'lucide-react';
import React from 'react';
import EmptySavedRepliesPage from './components/EmptySavedRepliesPage';
import { columns } from './components/savedRepliesTable/Column';
import SavedRepliesTable from './components/savedRepliesTable/SavedRepliesTable';
import useSavedReplies from './hooks/useSavedReplies';
import SettingsPageHeader from '../components/SettingsPageHeader';
interface SavedRepliesProps {}

const SavedReplies: React.FC<SavedRepliesProps> = () => {
  const { t } = useTranslation();
  const {
    selectedProject,
    savedReplies,
    userInfo,
    getSavedReplyList,
    isLoading,
  } = useSavedReplies();
  const isFreeUser = selectedProject?.subscription_plan?.plan_type === 'free';
  const isProjectCreator = selectedProject?.creator?.email === userInfo.email;

  const renderUpgradeToPremiumView = () => {
    return (
      <div className='w-full'>
        <div className='flex flex-col p-4 w-full align-center text-sm h-[100vh] justify-center align-center canned-response-div'>
          <div className='relative rounded-full bg-blue-100 h-[48px] w-[48px] flex justify-center align-center mb-5'>
            <LockClosedIcon className='w-[20px] h-[20px]' fill='#1E3A8A' />
          </div>
          <p className='text-gray-900'>{t('Upgrade to Premium')}</p>
          <p className='text-gray-500 '>
            {t('Add Superpower to your support arsenal!')}
          </p>
          {!isProjectCreator && (
            <p className='font-normal text-sm text-gray-500'>
              {t('Contact your team owner to upgrade subscription plan.')}
            </p>
          )}
          {isProjectCreator && (
            // @ts-ignore
            <Link
              to='/settings/billing'
              className='mt-5 inline-flex items-center text-center px-4 py-2 text-sm rounded-md border shadow-sm border-transparent bg-primary
                    hover:bg-primary-hover text-white focus:outline-none transition ease-out duration-200'
            >
              {t('Upgrade Now')}
            </Link>
          )}
        </div>
      </div>
    );
  };

  const renderPageHeader = () => {
    return (
     <>
      <SettingsPageHeader
        title='Saved Replies'
        subtitle="Saved Replies let you store frequently used messages with a keyword,
          making customer interactions quicker and more consistent. Access them
          in the inbox by pressing # or clicking the saved replies icon. "
        docUrl='https://docs.myalice.ai/myalice-inbox/chat-features'
        docUrlText='Learn More'
      />
     </>
    );
  };
  const renderEmptySavedReplyView = () => (
    <>
      {renderPageHeader()}
      <div className='w-full flex flex-col gap-10 p-6 h-[calc(100vh-8px)]'>
        <div className='flex-grow h-full w-full'>
          <EmptySavedRepliesPage />
        </div>
      </div>
    </>
  );
  const renderSavedReplyView = () => {
    if (!savedReplies || savedReplies.length === 0) {
      return renderEmptySavedReplyView();
    }
    return (
      <>
        {renderPageHeader()}
        <div className='w-full flex flex-col gap-3 h-[calc(100vh-8px)]'>
          <div className='flex-grow px-5 h-full w-full'>
            <SavedRepliesTable data={savedReplies} columns={columns} />
          </div>
        </div>
      </>
    );
  };

  React.useEffect(() => {
    const fetchSavedReplies = async () => {
      await getSavedReplyList();
    };
    fetchSavedReplies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLoadingView = () => {
    if (!savedReplies || savedReplies.length === 0) {
      return renderEmptySavedReplyView();
    }
    return (
      <div className='w-full flex flex-col gap-3 p-6 h-[calc(100vh-8px)]'>
        <div className='flex-grow h-full w-full flex justify-center items-center'>
          <LoaderCircle
            strokeWidth={2}
            className='text-green-400 w-12 h-12 animate-spin'
          />
        </div>
      </div>
    );
  };
  if (isLoading) {
    return renderLoadingView();
  }
  if (isFreeUser) {
    return renderUpgradeToPremiumView();
  } else {
    return renderSavedReplyView();
  }
};

export default SavedReplies;
