import { useSelector, useDispatch } from 'react-redux';
import { NodeType } from './interface';
import { useParams } from '@reach/router';

type primitiveDataType = null | string | number | boolean;

const useAutomationWorkflow = () => {
  const dispatch = useDispatch();
  const workflowAutomationData = useSelector(
    (state: any) => state.workflowAutomation
  );
  const { historyId } = useParams();
  //isViewLog is true while route has historyId
  const isViewLog: boolean = historyId ? true : false;

  const isValidNodeType = (): boolean => {
    let selectedNodeType = workflowAutomationData?.selectedNodeType;
    return Object.values(NodeType).includes(selectedNodeType as NodeType);
  };

  const updatePrimitiveStateData = (updatedData: {
    key: string;
    value: primitiveDataType;
  }) => {
    dispatch.workflowAutomation.updatePrimitiveStateData(updatedData);
  };

  const clearStateData = () => {
    dispatch.workflowAutomation.clearState();
  };

  const handleSideSheetClose = () => {
    updatePrimitiveStateData({ key: 'showSlider', value: false });
    updatePrimitiveStateData({ key: 'selectedNodeType', value: null });
  };

  const fetchWhatsappTemplateVariables = async (
    channelId: string,
    templateId: string
  ) => {
    const res = await dispatch.workflowAutomation.fetchWhatsappTemplateDetails({
      channelId,
      templateId,
    });
    if (!!res) {
      return res;
    } else return [];
  };

  const getWhatsappTemplateById = (id: string) => {
    const whatsappTemplateList = workflowAutomationData?.whatsappTemplateList;
    const filterList =
      !!whatsappTemplateList && whatsappTemplateList.length > 0
        ? whatsappTemplateList.filter((template: any) =>
            template?.id.includes(id)
          )
        : [];
    return filterList.length > 0 ? filterList[0] : null;
  };

  const getSelectedWhatsappTemplateData = () => {
    const { whatsapp: { selectedTemplate = null } = {} } =
      workflowAutomationData?.selectedSaveActionsData;

    return !!selectedTemplate
      ? getWhatsappTemplateById(selectedTemplate?.id ?? '')
      : null;
  };

  const getDistinctIntegratedEcommerce = () => {
    const ecommerceTypes = [
      ...new Set(
        workflowAutomationData.integratedEcommerceList.map(
          (item: any) => item.ecommerce_type
        )
      ),
    ];

    return ecommerceTypes ?? [];
  };

  const updateSelectedEcommerce = (ecommerce: any) => {
    if (!ecommerce) return;
    dispatch.workflowAutomation.updateSelectedEcommerce(ecommerce);
  };

  return {
    isViewLog,
    clearStateData,
    isValidNodeType,
    handleSideSheetClose,
    getWhatsappTemplateById,
    updatePrimitiveStateData,
    ...workflowAutomationData,
    updateSelectedEcommerce,
    getDistinctIntegratedEcommerce,
    fetchWhatsappTemplateVariables,
    getSelectedWhatsappTemplateData,
  };
};

export default useAutomationWorkflow;
