'use client';
import { Cross2Icon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';
import useTranslation from 'components/customHooks/useTranslation';
import { Button } from 'libraryV2/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from 'libraryV2/ui/dialog';
import { Input } from 'libraryV2/ui/input';
import { PlusIcon, Search, Trash } from 'lucide-react';
import { useState } from 'react';
import useSavedReplies from '../../hooks/useSavedReplies';
import SavedReplySheet, { ISavedReply } from '../SavedReplySheet';

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
}

export function DataTableToolbar<TData>({
  table,
}: DataTableToolbarProps<TData>) {
  const { t } = useTranslation();

  const [deleteModal, setDeleteModal] = useState(false);
  const isFiltered = table.getState().columnFilters.length > 0;
  const selectedRows = table.getSelectedRowModel().rows.map((r) => r.original);
  const [open, setOpen] = useState(false);
  const { setSavedReplySheetAction, deleteSavedReply } = useSavedReplies();

  const handleBulkDelete = () => {
    const selectedReplies = table
      .getSelectedRowModel()
      .rows.map((v) => v.original) as ISavedReply[];
    const replyIds = selectedReplies.map((r) => r?.id) as number[];
    deleteSavedReply(replyIds).then(() => {
      table.resetRowSelection();
      setDeleteModal(false);
    });
  };
  const renderCardDeleteModal = () => {
    return (
      <Dialog open={deleteModal} onOpenChange={setDeleteModal}>
        <DialogContent
          onOpenAutoFocus={(e) => e.preventDefault()}
          hideCloseButton={true}
          className='sm:max-w-[425px] bg-white'
        >
          <DialogTitle className='text-zinc-900 rtl:text-right text-lg font-semibold leading-7'>
            {t('Delete')} {selectedRows.length} {t('Saved Reply??')}
          </DialogTitle>

          <p className='text-zinc-500 rtl:text-right text-sm font-normal'>
            {t('This will permanently delete')} {selectedRows.length}{' '}
            {t('Saved Reply. Are you sure you want to delete?')}
          </p>

          <DialogFooter className='rtl:justify-start'>
            <DialogClose>
              <Button
                type='button'
                variant='default'
                className='bg-[#F4F4F5] rtl:ml-2 text-zinc-900 shadow-none hover:text-zinc-900 hover:bg-[#F4F4F5]'
              >
                {t('No')}
              </Button>
            </DialogClose>

            <Button
              onClick={() => handleBulkDelete()}
              type='button'
              variant='destructive'
              className='bg-[#FF0000] text-white text-sm font-medium'
            >
              {t('Yes! Delete')}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  };

  const renderSelectionToolbarView = () => {
    return (
      <div className='flex gap-2'>
        <Button
          size='sm'
          variant='outline'
          className='flex gap-2 text-white bg-[#FF0000] hover:bg-red-600 hover:text-white'
          disabled={!selectedRows.length}
          onClick={() => setDeleteModal(true)}
        >
          <Trash className='w-4 h-4' />
          <span>{t('Delete')}</span>
        </Button>

        <Button
          size='sm'
          variant='outline'
          className='flex gap-2'
          onClick={() => table.resetRowSelection()}
        >
          <span>{t('Cancel')}</span>
        </Button>
      </div>
    );
  };

  return (
    <div className='flex items-center justify-between'>
      <div className='flex flex-1 items-center space-x-2'>
        <div className='flex relative items-center'>
          <Search className='absolute ltr:left-2 rtl:right-2 mt-1 h-4 w-4 text-zinc-500 transform top-1/2 -translate-y-1/2' />
          <Input
            placeholder={t('Search saved replies')}
            value={(table.getColumn('title')?.getFilterValue() as string) ?? ''}
            onChange={(event) =>
              table.getColumn('title')?.setFilterValue(event.target.value)
            }
            className='ltr:pl-7 rtl:pr-7 h-8 w-[150px] lg:w-[250px] rtl:ml-2'
          />
        </div>
        {isFiltered && (
          <Button
            variant='ghost'
            onClick={() => table.resetColumnFilters()}
            className='h-8 px-2 mt-[5px] lg:px-3 bg-primary text-white'
          >
            {t('Reset')}
            <Cross2Icon className='ltr:ml-2 rtl:mr-2 h-4 w-4' />
          </Button>
        )}
      </div>
      {selectedRows.length > 0 ? (
        renderSelectionToolbarView()
      ) : (
        <Button
          className='text-white hover:text-white bg-primary gap-1'
          onClick={() => {
            setOpen(true);
            setSavedReplySheetAction('CREATE');
          }}
        >
          <PlusIcon strokeWidth='2.5' className='text-white h-4 w-4' />
          <span>{t('Create New Saved Reply')}</span>
        </Button>
      )}
      {open && (
        <SavedReplySheet
          actionType='CREATE'
          isOpen={open}
          setIsOpen={setOpen}
        />
      )}
      {renderCardDeleteModal()}
    </div>
  );
}
