import { Table } from '@tanstack/react-table';
import useTranslation from 'components/customHooks/useTranslation';
import { Button } from 'libraryV2/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'libraryV2/ui/dialog';
import { PlusIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import useInviteTeamMembers from '../../hooks/useInviteTeamMembers';
import useUserManagement from '../../hooks/useUserManagement';
import MultipleEmailInput from './MultipleEmailInput';
import { UserRoleSelect } from './UserRoleSelect';

interface InviteTeamMembersProps<TData> {
  table?: Table<TData>;
}
const InviteTeamMembers: React.FC<InviteTeamMembersProps<any>> = ({
  table,
}) => {
  const { t } = useTranslation();
  const {
    setEmails,
    emails,
    emailInputValue,
    handleEmailInputChange,
    handleEmailsChange,
    setEmailInputValue,
    setFormState,
    setAndSyncSelectedRole,
    handleInviteTeamMemberSubmit,
    selectedRoleData,
    clearSelectedRoleData,
    getFormValidationStatus,
  } = useInviteTeamMembers();
  const { getRemainningTeamMemberCount } = useUserManagement();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!open) {
      resetData();
    }
    // eslint-disable-next-line
  }, [open]);

  const handleCancel = () => {
    resetData();
  };
  const resetData = () => {
    setFormState([]);
    setEmails([]);
    setEmailInputValue('');
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild disabled={getRemainningTeamMemberCount() <= 0}>
        <Button className='text-white bg-primary hover:bg-primary-hover'>
          <PlusIcon className='text-white h-4 w-4 ltr:mr-[2.5px] rtl:ml-[2.5px]' />
          {t('Add New Member')}
        </Button>
      </DialogTrigger>
      <DialogContent className='sm:max-w-[425px] bg-white'>
        <DialogHeader className='rtl:text-right'>
          <DialogTitle className='rtl:text-right'>
            {t('Invite Team Members')}
          </DialogTitle>
          <DialogDescription className='subtitle-regular rtl:text-right'>
            {t('Enter emails to invite your team members.')}{' '}
            {t('You may add')}
            &nbsp;
            <span className='font-semibold'>
              {getRemainningTeamMemberCount() >= 0
                ? getRemainningTeamMemberCount()
                : 0}
            </span>
            &nbsp;{t('more members.')}
          </DialogDescription>
        </DialogHeader>
        <div className='grid gap-1.5 pb-4'>
          <div className='items-center'>
            <MultipleEmailInput
              emails={emails}
              emailInputValue={emailInputValue}
              handleEmailInputChange={handleEmailInputChange}
              handleEmailsChange={handleEmailsChange}
              setEmailInputValue={setEmailInputValue}
              setFormState={setFormState}
            />
          </div>
          <span className='subtitle-regular mt-1.5 mb-2'>
            {t(
              'To invite multiple team members make sure you have a comma or a space after every email.'
            )}
          </span>

          <UserRoleSelect setAndSyncSelectedRole={setAndSyncSelectedRole} />
        </div>
        <DialogFooter className='rtl:flex-row-reverse'>
          <DialogClose asChild>
            <Button type='button' onClick={handleCancel} variant='outline'>
              {t('Cancel')}
            </Button>
          </DialogClose>
          <Button
            type='submit'
            onClick={async () => {
              if (selectedRoleData && getFormValidationStatus()) {
                await handleInviteTeamMemberSubmit();
                clearSelectedRoleData();
                table?.resetRowSelection();
                setOpen(false);
              }
            }}
            disabled={
              !selectedRoleData ||
              !getFormValidationStatus() ||
              emails.length === 0
            }
            variant={`${
              getFormValidationStatus() && selectedRoleData
                ? 'ghost'
                : 'outline'
            }`}
            className={`${
              getFormValidationStatus() &&
              selectedRoleData &&
              emails.length !== 0
                ? 'text-white bg-primary'
                : 'bg-[#F4F4F5] text-[#A1A1AA]'
            }`}
          >
            {t('Invite')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default InviteTeamMembers;
