import { Input } from 'libraryV2/ui/input';
import { Plus, Search, ToggleLeft, Trash, User, X } from 'lucide-react';
import DeleteAlert from 'pages/datalab/datalabList/component/DeleteAlert';
import { FC, useState } from 'react';
import {
  ICustomAgentColumnFilter,
  ICurrentTeamAgentInfo,
} from 'pages/raven/interface';
import { Link, useParams } from '@reach/router';

import { Button } from 'libraryV2/ui/button';
import {
  MultiSelect,
  MultiSelectOptionBadgeView,
} from 'libraryV2/extended-ui/MultiSelect';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import { Checkbox } from 'libraryV2/ui/checkbox';
import { cn } from 'libraryV2/utils';
import { ManageCustomAgentStatus } from 'pages/raven/utils/content';
import { Avatar, AvatarFallback, AvatarImage } from 'libraryV2/ui/avatar';
import { getUserNameInitials } from 'pages/reportingV2/utility/utils';
import useTranslation from 'components/customHooks/useTranslation';
import { toast } from 'libraryV2/ui/use-toast';

interface AgentTableToolbarProps {
  filterState: ICustomAgentColumnFilter;
  setFilterState: React.Dispatch<
    React.SetStateAction<ICustomAgentColumnFilter>
  >;
  currentTeamAgentInfo: ICurrentTeamAgentInfo['agentList'];
  rowSelection: Record<string, boolean>;
  onClearRowSelection: () => void;
  handleBulkDelete: () => Promise<boolean>;
}

export const AgentTableToolbar: FC<AgentTableToolbarProps> = ({
  filterState,
  setFilterState,
  currentTeamAgentInfo,
  rowSelection,
  onClearRowSelection,
  handleBulkDelete,
}) => {
  const { projectId } = useParams();
  const isSelectionMode = !!Object.keys(rowSelection).length;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { t } = useTranslation();

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await handleBulkDelete();
      toast({
        // @ts-expect-error just bad type defination!
        title: <p className='text-green-400'>Delete Successful</p>,
        description: (
          <p className='text-textPrimary text-xs'>
            Custom agent has been permanently deleted.
          </p>
        ),
      });
    } catch (error) {
      console.error('Error deleting entry:', error);
      toast({
        // @ts-expect-error just bad type defination!
        title: <p className='text-red-500'>Failed to delete</p>,
        description: (
          <p className='text-textPrimary text-xs'>
            Unable to delete the custom agent.
          </p>
        ),
      });
    } finally {
      setIsDeleting(false);
      setIsDeleteModalOpen(false);
    }
  };

  const renderSearchInput = () => {
    return (
      <div className='flex items-center gap-2 relative'>
        <Search className='w-4 h-4 absolute transform -translate-y-1/2 left-2 top-1/2 text-textPrimary-disable' />
        <Input
          placeholder='Search'
          value={filterState?.search ?? ''}
          className='ltr:pl-7 rtl:pr-7 h-8 m-0 w-[290px]'
          onChange={(event) =>
            setFilterState({ ...filterState, search: event.target.value })
          }
        />
      </div>
    );
  };

  const handleTemplateFilter = (agents: ICurrentTeamAgentInfo['agentList']) => {
    setFilterState((old) => ({ ...old, agents: agents }));
  };

  const handleCustomAgentStatusFilter = (
    status: typeof ManageCustomAgentStatus
  ) => {
    setFilterState((old) => ({ ...old, status: status }));
  };

  const handleRemoveAgentFilter = (
    deletedAgent: ICurrentTeamAgentInfo['agentList'][number]
  ) => {
    const removedAgents = filterState.agents.filter(
      (agent) => agent?.email !== deletedAgent?.email
    );
    setFilterState((old) => ({ ...old, agents: removedAgents }));
  };

  const handleRemoveStatusFilter = (removedStatus: { value: string }) => {
    const newStatus = filterState.status.filter(
      (stat) => stat?.value !== removedStatus?.value
    );
    setFilterState((old) => ({ ...old, status: newStatus }));
  };

  const renderTeamAgentFilterMenu = () => {
    let agentFilterData = filterState.agents;

    return (
      <MultiSelect<typeof currentTeamAgentInfo[number]>
        options={currentTeamAgentInfo}
        selectedOptions={agentFilterData || []}
        onApplyFilter={handleTemplateFilter}
        onClearFilter={() => {}}
        searchInputPlaceholder='Search Agent'
        renderOption={(option, isSelected) => {
          return (
            <div className='flex items-center gap-2'>
              <Checkbox
                checked={isSelected}
                className={cn({
                  'bg-primary text-white ring-0 border-0': isSelected,
                })}
              />
              {/* <div className='flex gap-1 items-center flex-shrink-0'> */}
              <Avatar className='h-5 w-5'>
                <AvatarImage src={option?.avatar} />
                <AvatarFallback className='border-zinc-300 border opacity-100 text-xs p-[2px] font-medium bg-zinc-100'>
                  {getUserNameInitials(option?.full_name ?? 'User')}
                </AvatarFallback>
              </Avatar>
              {/* </div> */}
              <TooltipProviderCustomised
                content={option?.full_name}
                asChild
                minContentLength={25}
                side='right'
              >
                <span className='text-sm truncate w-[170px]'>
                  {option?.full_name}
                </span>
              </TooltipProviderCustomised>
            </div>
          );
        }}
        getOptionValue={(option) => option?.email}
        renderTrigger={() => {
          return (
            <Button
              variant={'outline'}
              className='border-dashed font-medium text-xs text-textPrimary border-border gap-2 px-2 h-8'
            >
              <User strokeWidth={2.5} className='w-4 h-4' />
              <span>Agents</span>
            </Button>
          );
        }}
        classNames={{
          popoverContent: 'w-[250px]',
        }}
      />
    );
  };

  const renderCustomAgentStatusFilterMenu = () => {
    let statusFilterData = filterState.status;

    return (
      <MultiSelect<typeof ManageCustomAgentStatus[number]>
        options={ManageCustomAgentStatus}
        selectedOptions={statusFilterData || []}
        onApplyFilter={handleCustomAgentStatusFilter}
        onClearFilter={() => {}}
        shouldHideSearchInput={false}
        renderOption={(option, isSelected) => {
          return (
            <div className='flex items-center gap-2'>
              <Checkbox
                checked={isSelected}
                className={cn({
                  'bg-primary text-white ring-0 border-0': isSelected,
                })}
              />
              <TooltipProviderCustomised
                content={option?.label}
                asChild
                minContentLength={25}
                side='right'
              >
                <span className='text-sm truncate w-[170px]'>
                  {option?.label}
                </span>
              </TooltipProviderCustomised>
            </div>
          );
        }}
        getOptionValue={(option) => option?.value}
        renderTrigger={() => {
          return (
            <Button
              variant={'outline'}
              className='border-dashed font-medium text-xs text-textPrimary border-border gap-2 px-2 h-8'
            >
              <ToggleLeft strokeWidth={2.5} className='w-4 h-4' />
              <span>Status</span>
            </Button>
          );
        }}
      />
    );
  };

  const renderClearFilterButton = () => {
    const hasOtherFilters =
      !!filterState?.agents?.length || !!filterState?.status?.length;
    // const isSearchApplied = !!filterState?.search?.length;

    if (!hasOtherFilters) return null;
    return (
      <Button
        className='bg-background-hover h-8 gap-1 text-xs'
        variant={'ghost'}
        onClick={() => {
          setFilterState((old) => ({
            search: old.search,
            agents: [],
            status: [],
          }));
        }}
      >
        <X className='w-4 h-4' />
        Clear Filters
      </Button>
    );
  };

  const renderBulkDeleteOption = () => {
    if (isSelectionMode) {
      return (
        <div className='flex gap-2 items-center'>
          <Button
            size={'sm'}
            className='gap-2 text-white bg-red-500 hover:bg-red-600'
            onClick={() => setIsDeleteModalOpen(true)}
          >
            <Trash className='w-4 h-4' />
            <span>Delete</span>
          </Button>
          <Button
            variant={'outline'}
            size={'sm'}
            className='gap-2 text-textPrimary'
            onClick={onClearRowSelection}
          >
            <span>Cancel</span>
          </Button>
        </div>
      );
    }

    return (
      // @ts-ignore
      <Link
        to={`/projects/${projectId}/raven/create-custom-agent`}
        className='text-xs font-medium bg-primary flex px-4 h-8 items-center rounded-md gap-1 text-white hover:bg-primary-hover'
      >
        <Plus strokeWidth={2.5} className='w-4 h-4 text-white' />
        <span>Create Custom Agent</span>
      </Link>
    );
  };

  return (
    <div className='flex flex-col gap-2'>
      <div className='w-full flex items-center justify-between'>
        <div className='flex gap-3'>
          {renderSearchInput()}
          {renderTeamAgentFilterMenu()}
          {renderCustomAgentStatusFilterMenu()}
          {renderClearFilterButton()}
        </div>

        <div>{renderBulkDeleteOption()}</div>
      </div>

      <div className='flex w-full items-center gap-4'>
        <MultiSelectOptionBadgeView
          label='Agents'
          options={filterState?.agents}
          getOptionLabel={(o) => o?.full_name}
          onRemoveOption={handleRemoveAgentFilter}
          minToolTipLength={30}
        />
        <MultiSelectOptionBadgeView
          label='Status'
          options={filterState.status}
          getOptionLabel={(o) => o?.label}
          onRemoveOption={handleRemoveStatusFilter}
        />
      </div>

      <DeleteAlert
        open={isDeleteModalOpen}
        onOpenChange={setIsDeleteModalOpen}
        title={t('Delete Custom Agent?')}
        description={t(
          `This will permanently delete ${
            Object.keys(rowSelection).length
          } custom agent. Are you sure you want to delete?`
        )}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDelete}
        isConfirming={isDeleting}
      />
    </div>
  );
};
