'use client';

import { Cross2Icon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';

import useTranslation from 'components/customHooks/useTranslation';
import { Badge } from 'libraryV2/ui/badge';
import { Button } from 'libraryV2/ui/button';
import { Input } from 'libraryV2/ui/input';
import { Search, Trash } from 'lucide-react';
import { useState } from 'react';
import useTableRowActions from '../../hooks/useTableRowActions';
import useUserManagement from '../../hooks/useUserManagement';
import { IUserManagement } from '../../interface';
import { userRolesLabels } from '../../utils';
import { DeleteWarningAlert } from '../common/DeleteWarningAlert';
import InviteTeamMembers from '../inviteTeamMembers/InviteTeamMembers';
import { UserRoleFilter } from './UserRoleFilter';

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  selectedProject: any;
}
interface ShowFilteredRolesProps {
  selectedValues: string[];
  removeSelectedValue: (value: string) => void;
}

export function DataTableToolbar<TData extends IUserManagement>({
  table,
  selectedProject,
}: DataTableToolbarProps<TData>) {
  const { t } = useTranslation();
  const { deleteMultipleUserFromTeam, deleteUserFromTeam } =
    useTableRowActions();
  const { hasWriteAccess } = useUserManagement();
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [resetKey, setResetKey] = useState(0);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const removeSelectedValue = (value: string) => {
    setSelectedValues((prev) => {
      const newValues = prev.filter((item) => item !== value);
      table
        .getColumn('user_role')
        ?.setFilterValue(newValues.length ? newValues : undefined);
      return newValues;
    });
  };

  const clearFilter = () => {
    setSelectedValues([]);
    table.getColumn('user_role')?.setFilterValue(undefined);

    setResetKey((prev) => prev + 1);
  };

  const handleDeleteClick = () => {
    setShowWarningModal(true);
  };
  const getSelectedIds = () => {
    return table
      .getSelectedRowModel()
      .rows.filter((row) => selectedProject?.creator.id !== row.original.id)
      .map((row) => row.original.id);
  };
  const handleUserDeletion = async () => {
    setShowWarningModal(false);
    const selectedIds = getSelectedIds();

    if (hasWriteAccess && selectedIds.length === 1) {
      await deleteUserFromTeam(selectedIds[0]);
    } else if (hasWriteAccess && selectedIds.length > 1) {
      await deleteMultipleUserFromTeam(selectedIds);
    }
  };

  const handleCancelCheckedFilter = () => {
    table.resetRowSelection();
  };

  return (
    <>
      <div className='mb-4'>
        <div className='flex items-center justify-between mb-4'>
          <div className='flex flex-1 items-center space-x-2'>
            <div className='flex relative items-center rtl:ml-2'>
              <Search
                className='absolute ltr:left-2 rtl:right-2 mt-0 h-4 w-4 text-zinc-500
            transform top-1/2 -translate-y-1/2'
              />
              <Input
                placeholder={t('Search with agent or email')}
                value={table.getState().globalFilter}
                onChange={(e) => table.setGlobalFilter(String(e.target.value))}
                className='ltr:pl-7 rtl:pr-7 h-8 w-[150px] lg:w-[250px] mt-0'
              />
            </div>

            {table.getColumn('user_role') && (
              <UserRoleFilter
                column={table.getColumn('user_role')}
                options={userRolesLabels}
                title={t('User role')}
                selectedValues={selectedValues}
                setSelectedValues={setSelectedValues}
                resetKey={resetKey}
              />
            )}

            {!!table.getColumn('user_role')?.getFilterValue() && (
              <Button
                variant='outline'
                onClick={() => clearFilter()}
                className='h-8 px-2.5 ltr:space-x-2 text-xs bg-[#F4F4F5] outline-none'
              >
                <Cross2Icon className='ltr:mr-2 rtl:ml-2 h-4 w-4' />
                {t('Clear Filter')}
              </Button>
            )}
          </div>

          {table.getIsSomePageRowsSelected() ||
          table.getIsAllPageRowsSelected() ? (
            <div className='flex items-center gap-1'>
              <Button
                onClick={handleDeleteClick}
                variant={'destructive'}
                className='text-white bg-[#ff0000] hover:bg-[#ff0000]'
              >
                <Trash className='text-white h-4 w-4 ltr:mr-[2.5px] rtl:ml-[2.5px]' />
                {t('Delete')}
              </Button>
              <Button onClick={handleCancelCheckedFilter} variant='outline'>
                {t('Cancel')}
              </Button>
            </div>
          ) : (
            <InviteTeamMembers table={table} />
          )}
        </div>
        {selectedValues.length > 0 && (
          <ShowFilteredRoles
            selectedValues={selectedValues}
            removeSelectedValue={removeSelectedValue}
          />
        )}
      </div>

      {/* warning modal */}
      <DeleteWarningAlert
        renderWarningModal={showWarningModal}
        setRenderWarningModal={setShowWarningModal}
        handleUserDeletion={handleUserDeletion}
        userIds={getSelectedIds()}
        isSingleDelete={getSelectedIds().length === 1}
        table={table}
      />
    </>
  );
}

const ShowFilteredRoles: React.FC<ShowFilteredRolesProps> = ({
  selectedValues,
  removeSelectedValue,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className=' inline-flex items-center ltr:space-x-2 rtl:gap-2'>
        <p className=' text-textSecondary font-medium text-xs'>
          {t('User role:')}
        </p>
        <div>
          {selectedValues.length > 0 && (
            <>
              <Badge
                variant='secondary'
                className=' bg-[#F4F4F5] text-zinc-900 shadow-none hover:text-zinc-900
                   hover:bg-[#F4F4F5] px-2 font-medium lg:hidden'
              >
                {selectedValues.length}
              </Badge>
              <div className='hidden ltr:space-x-1 rtl:gap-1 lg:flex'>
                {userRolesLabels
                  .filter((option) => selectedValues.includes(option.value))
                  .map((option, index) =>
                    index < 2 ? (
                      <Badge
                        variant='secondary'
                        key={option.value}
                        className='inline-flex items-center gap-2 text-zinc-800
                          bg-[#F4F4F5] px-2 font-medium  shadow-none hover:text-zinc-800
                          hover:bg-[#F4F4F5]'
                      >
                        {t(option.label)}
                        <span className=' hover:cursor-pointer'>
                          <Cross2Icon
                            onClick={() => removeSelectedValue(option.value)}
                            className='h-3 w-3 text-textSecondary-disable hover:cursor-pointer'
                          />
                        </span>
                      </Badge>
                    ) : null
                  )}
                {selectedValues.length > 2 && (
                  <Badge
                    variant='secondary'
                    className='bg-[#F4F4F5] px-1 font-medium text-zinc-900 shadow-none
                      hover:text-zinc-900 hover:bg-[#F4F4F5]'
                  >
                    +{selectedValues.length - 2}
                  </Badge>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
