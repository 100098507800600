import useTranslation from 'components/customHooks/useTranslation';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from 'libraryV2/ui/select';
import { FC, useEffect } from 'react';
import useInviteTeamMembers from '../../hooks/useInviteTeamMembers';
import useUserManagement from '../../hooks/useUserManagement';
import { IUserRole } from '../../interface';
import RoleName from '../common/RoleName';

interface UserRoleSelectProps {
  setAndSyncSelectedRole: (id: number) => void;
}

export const UserRoleSelect: FC<UserRoleSelectProps> = ({
  setAndSyncSelectedRole,
}) => {
  const { t } = useTranslation();
  const { agentRoleList } = useUserManagement();
  const {
    selectedRoleData,
    updateSelectedRoleData,
    clearSelectedRoleData,
    validateForm,
  } = useInviteTeamMembers();

  useEffect(() => {
    clearSelectedRoleData();
    // eslint-disable-next-line
  }, []);
  const roleDetails = (name: string): string => {
    switch (name) {
      case 'Admin':
        return t('Admin have access to everything.');
      case 'Supervisor':
        return `${t('Supervisors cannot access Team')} ${t(
          'Settings and integrations.'
        )}`;
      case 'Developer':
        return `${t('Developers cannot access Inbox,')} ${t(
          'customers and integrations.'
        )}`;
      case 'Marketer':
        return `${t(
          'Marketers cannot access Inbox, API, Datalab, Customers, and Integrations.'
        )}`;
      case 'Agent':
        return t('Agents have access to the Inbox only.');
      default:
        return '';
    }
  };

  const handleSelectChange = (value: string) => {
    const selectedRole = agentRoleList.find(
      (role: IUserRole) => role.name === value
    );
    if (selectedRole) {
      updateSelectedRoleData(selectedRole);
      setAndSyncSelectedRole(selectedRole.id);
      validateForm();
    }
  };

  console.log(roleDetails('Marketer'));

  return (
    <Select onValueChange={(value: string) => handleSelectChange(value)}>
      <SelectTrigger className='w-full rtl:flex-row-reverse inline-flex items-center justify-between border border-gray-300 rounded-md p-2 ltr:mr-2 rtl:ml-2'>
        {selectedRoleData ? (
          <span>
            {selectedRoleData.name === 'Marketeer'
              ? t('Marketer')
              : t(selectedRoleData.name)}
          </span>
        ) : (
          <span className='text-[#71717A] rtl:text-right'>
            {t('Select user role')}
          </span>
        )}
      </SelectTrigger>
      <SelectContent className='bg-white shadow-md rounded-lg px-1 w-96 rtl:text-right'>
        {agentRoleList &&
          agentRoleList.map((role: IUserRole) => (
            <SelectItem
              key={role.name}
              value={role.name}
              className='flex rtl:flex-row-reverse ltr:items-start rtl:items-end gap-2 w-full hover:bg-gray-100 rounded-md cursor-pointer'
            >
              <div className='flex flex-col rtl:text-right'>
                <span className='block font-medium rtl:text-right'>
                  <RoleName role={role} />
                </span>
                <span className='block rtl:text-right text-sm text-gray-500'>
                  {role.name === 'Marketeer'
                    ? roleDetails('Marketer')
                    : roleDetails(role.name)}
                </span>
              </div>
            </SelectItem>
          ))}
      </SelectContent>
    </Select>
  );
};
