import React from 'react';
import Linkify from 'react-linkify';

import useTranslation from 'components/customHooks/useTranslation';
import useCommentEdit from 'pages/inbox/hooks/useCommentEdit';
import useMessageBlocksAction from 'pages/inbox/hooks/useMessageBlocksAction';
import {
  CommentStatusPayloadInterface,
  ConversationBlockTextDataInterface,
  ConversationInterface,
  ConversationParentCommentInterface,
  ConversationRepliesInterface,
  conversationStatusType,
  CustomerReplyBlockInterface,
} from 'pages/inbox/inboxInterface';
import { TooltipProviderCustomised } from '../../../../../libraryV2/ui/tooltip';
import intialQuery from '../../../assets/images/intialQuery.svg';
import Avatar from '../components/conversation/Avatar';
import BlockActions from '../components/conversation/BlockActions';
import ConversationBlockWrapper from '../components/conversation/ConversationBlockWrapper';
import FeedCommentStatus from '../components/conversation/FeedCommentStatus';
import AdminReplyToButton from './AdminReplyToButton';
import CustomerReply from './CustomerReply';
import DateBar from './DateBar';

interface Props {
  blockData: ConversationBlockTextDataInterface;
  avatar: string;
  name: string;
  time: number;
  source: string;
  isSent: conversationStatusType | string;
  isMergeable: boolean;
  enableDateBar: boolean;
  report?: any; // TODO: format report
  platformType: string;
  // Reply To Props
  hasPermissionToShowAdminReply?: boolean;
  rawBlockData?:
    | ConversationInterface
    | ConversationRepliesInterface
    | ConversationParentCommentInterface;
  handleConversationDataForAdminReply?: (
    blockData: CustomerReplyBlockInterface
  ) => void;
  // Feed Props
  isReplyComment?: boolean;
  isParentComment?: boolean;
  isHighlighted?: boolean;
  commentType?: string;
  commentId?: string;
  status?: string;

  updateFeedCommentStatus?: (payload: CommentStatusPayloadInterface) => void;
}

const TextMessage: React.FC<Props> = ({
  rawBlockData,
  blockData,
  avatar,
  name,
  time,
  source,
  isSent,
  isMergeable,
  enableDateBar,
  report,
  platformType,
  // Reply To Props
  hasPermissionToShowAdminReply = false,
  handleConversationDataForAdminReply = () => null,
  // Feed Props
  isReplyComment = false,
  isParentComment = false,
  isHighlighted = false,
  commentType = '',
  commentId = '',
  status = '',
  updateFeedCommentStatus,
}) => {
  const { t } = useTranslation();
  const { isZigzagView, isAdminReply, rowDirection, elementDirection } =
    useMessageBlocksAction({ source });

  const { setCommentEditMode, commentEditData } = useCommentEdit();
  const [showReplyToButton, setShowReplyToButton] = React.useState(false);

  const isFeedCommentMessage = !!isParentComment || !!isReplyComment;
  const isFeedChannels = ['facebook_feed', 'instagram_feed'].includes(
    platformType
  );

  const getSourceNameColor = (): string => {
    switch (source) {
      case 'customer':
        return 'text-green-500';
      case 'bot':
        return 'text-[#3B82F6]';
      case 'admin':
        return 'text-gray-900';
      case 'echo':
        return 'text-gray-900';
      default:
        return 'text-green-500';
    }
  };

  const getTextMessageColor = (): string => {
    return status === 'hide' || status === 'remove'
      ? 'text-gray-400'
      : !!blockData.success
      ? isAdminReply
        ? 'text-gray-900'
        : 'text-gray-900'
      : isAdminReply
      ? 'text-white'
      : 'text-gray-500';
  };

  const renderMessageText = () => {
    return (
      <div className={`flex justify-between w-full`}>
        <p
          data-testid='text-message-block'
          className={`w-full h-auto ${getTextMessageColor()} sm:text-sm break-words whitespace-pre-wrap`}
        >
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a
                target='blank'
                className='underline text-[#3B82F6]'
                href={decoratedHref}
                key={key}
              >
                {decoratedText}
              </a>
            )}
          >
            {blockData?.success ? blockData?.text : blockData?.error}
          </Linkify>
        </p>
      </div>
    );
  };

  const renderCustomerReply = () => {
    return (
      blockData?.reply_to_data && (
        <CustomerReply
          blockData={blockData?.reply_to_data}
          isRightAligned={isAdminReply && isZigzagView}
          isZigzagView={isZigzagView}
          source={source}
        />
      )
    );
  };

  const renderContent = () => {
    return (
      <>
        {!!blockData.reply_to_data && renderCustomerReply()}
        <ConversationBlockWrapper
          time={time}
          success={isSent}
          isMerged={false}
          report={report}
          source={source}
          isFirstElement={!isMergeable}
          conversationType={blockData?.type}
          platformType={platformType}
        >
          {renderMessageText()}
        </ConversationBlockWrapper>
      </>
    );
  };

  const handleCommentEdit = (commentId: string) => {
    setCommentEditMode(commentId, blockData?.success ? blockData?.text : '');
  };

  const renderAgentName = () => {
    return (
      <div
        className={`my-1.5 w-auto h-auto font-bold sm:text-sm ${getSourceNameColor()}`}
      >
        {!!blockData?.permalink_url ? (
          <a
            className='hover:underline'
            target='_blank'
            href={blockData?.permalink_url}
            rel='noreferrer noopener'
          >
            {t(name)}
          </a>
        ) : (
          t(name)
        )}
      </div>
    );
  };

  const renderHighlightedContent = () => {
    return (
      <div className='flex content-center w-auto h-auto ltr:ml-2 rtl:mr-2 group group-hover:visible'>
        <TooltipProviderCustomised content={t('Initial Query')}>
          <img
            src={intialQuery}
            data-for='query'
            data-tip={t('Initial Query')}
            alt='query'
          />
        </TooltipProviderCustomised>
      </div>
    );
  };

  const renderTextMessageForMessangerChannels = () => {
    return (
      <div
        className={`relative group flex ${rowDirection} w-full h-auto pt-0.5 pb-1.5 hover:bg-[#F8F8F9]`}
      >
        <Avatar
          avatar={avatar}
          isReplyComment={false}
          isParentComment={false}
        />
        <div className='max-w-[60%]'>
          <div className={`flex gap-2 ${rowDirection}`}>
            {renderAgentName()}
            {!!isHighlighted && renderHighlightedContent()}
          </div>
          <div className={`flex ${elementDirection}`}>{renderContent()}</div>
        </div>
      </div>
    );
  };

  const renderTextMessageForFeedChannels = () => {
    return (
      <div
        className={`relative group flex w-full h-auto ${
          isParentComment ? '' : 'ltr:pl-11 rtl:pr-11'
        } pt-1 pb-2 ${
          commentEditData?.isEditing &&
          commentEditData?.conversationId === commentId
            ? 'bg-blue-50'
            : 'hover:bg-[#F8F8F9]'
        } `}
      >
        {isFeedCommentMessage && (
          <BlockActions
            source={source}
            commentId={commentId}
            isReplyComment={isReplyComment}
            isParentComment={isParentComment}
            commentType={commentType}
            commentStatus={status}
            platformType={platformType}
            updateFeedCommentStatus={updateFeedCommentStatus}
            handleCommentEdit={handleCommentEdit}
          />
        )}
        <Avatar
          avatar={avatar}
          isReplyComment={isReplyComment}
          isParentComment={isParentComment}
        />
        <div className='max-w-[60%]'>
          <div className='flex gap-2 w-full'>
            {renderAgentName()}
            {isFeedCommentMessage && commentType !== 'message' && (
              <FeedCommentStatus
                commentType={commentType}
                commentStatus={status}
              />
            )}
            {!!isHighlighted && renderHighlightedContent()}
          </div>
          {renderContent()}
          {isFeedCommentMessage && commentType === 'message' && (
            <FeedCommentStatus
              commentType={commentType}
              commentStatus={status}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className='relative'
        onMouseEnter={() => setShowReplyToButton(true)}
        onMouseLeave={() => setShowReplyToButton(false)}
      >
        {!isMergeable && (
          <>
            {isFeedChannels
              ? renderTextMessageForFeedChannels()
              : renderTextMessageForMessangerChannels()}
          </>
        )}

        {!!isMergeable && (
          <div
            className={`relative flex w-full h-auto py-1 px-16 group hover:bg-[#F8F8F9] ${elementDirection}`}
          >
            <div className={`max-w-[60%] relative flex ${elementDirection}`}>
              {renderContent()}
            </div>
          </div>
        )}

        {showReplyToButton && hasPermissionToShowAdminReply && (
          <AdminReplyToButton
            source={source}
            handleConversationDataForAdminReplyOnClick={() =>
              handleConversationDataForAdminReply({
                type: rawBlockData?.dataV2?.type,
                success: rawBlockData?.dataV2?.success,
                error: rawBlockData?.dataV2?.error,
                sub_type: '',
                text: rawBlockData?.dataV2?.text,
                urls: [],
                payload: rawBlockData?.conversation_id,
              })
            }
          />
        )}
      </div>
      {!!enableDateBar && <DateBar time={time} />}
    </>
  );
};

export default TextMessage;
