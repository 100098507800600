import useTranslation from 'components/customHooks/useTranslation';
import { Button } from 'libraryV2/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'libraryV2/ui/dropdown-menu';
import { EllipsisVertical, History, SquarePen, Trash } from 'lucide-react';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import DeleteAlert from 'pages/datalab/datalabList/component/DeleteAlert';
import { toast } from 'libraryV2/ui/use-toast';
import { IManageGPTAgent } from 'pages/raven/interface';
import { useNavigate, useParams } from '@reach/router';
interface AgentTableRowActionProps {
  agentInfo: IManageGPTAgent;
}

export const AgentTableRowAction: FC<AgentTableRowActionProps> = ({
  agentInfo,
}) => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const editPageUrl = `/projects/${projectId}/raven/update-custom-agent/`;
  const { t, isRtlLanguage } = useTranslation();
  const dispatch = useDispatch();
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    const customAgentId = agentInfo?.id;
    if (!customAgentId) {
      return;
    }
    try {
      setDeleting(true);
      const deleteResult = await dispatch.raven?.deleteSingleCustomAgent(
        customAgentId
      );
      if (!deleteResult) {
        throw new Error('Failed to delete custom agent.');
      }
      toast({
        duration: 2 * 1000,
        // @ts-expect-error just bad type defination!
        title: <p className='text-green-400'>Delete Successful</p>,
        description: (
          <p className='text-textPrimary text-xs'>
            Custom agent has been permanently deleted.
          </p>
        ),
      });
    } catch (error) {
      console.error('Error deleting entry:', error);
      toast({
        duration: 2 * 1000,
        // @ts-expect-error just bad type defination!
        title: <p className='text-red-500'>Failed to delete</p>,
        description: (
          <p className='text-textPrimary text-xs'>
            Unable to delete the custom agent.
          </p>
        ),
      });
    } finally {
      setDeleting(false);
      setAlertOpen(false);
    }
  };

  const openDeleteDialog = () => setAlertOpen(true);
  const closeDeleteDialog = () => setAlertOpen(false);

  const handleEditEntry = async () => {
    navigate(editPageUrl + agentInfo?.id);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant='ghost'
            className='flex h-fit w-8 p-0 data-[state=open]:bg-muted focus:border-traparent'
          >
            <EllipsisVertical className='w-4 h-4 text-[#18181B]' />
            <span className='sr-only'>Open gpt options</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align={isRtlLanguage ? 'start' : 'end'}
          className='w-fit bg-white'
        >
          <DropdownMenuItem
            className='ltr:text-left rtl:text-right hover:bg-gray-100 rtl:justify-end gap-1'
            onClick={handleEditEntry}
          >
            <SquarePen className='w-4 h-4 text-textPrimary' />
            {t('Edit')}
          </DropdownMenuItem>

          <DropdownMenuItem className='ltr:text-left rtl:text-right hover:bg-gray-100 rtl:justify-end gap-1 hidden'>
            <History className='w-4 h-4 text-textPrimary' />
            {t('Activity logs')}
          </DropdownMenuItem>
          <DropdownMenuSeparator className='bg-gray-200' />
          <DropdownMenuItem
            onClick={openDeleteDialog}
            className='ltr:text-left rtl:text-right hover:bg-gray-100 rtl:justify-end gap-1'
          >
            <Trash className='w-4 h-4 text-textPrimary' />
            {t('Delete')}
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <DeleteAlert
        open={isAlertOpen}
        onOpenChange={setAlertOpen}
        title={t('Delete Custom Agent?')}
        description={t(
          'This will permanently delete the custom agent. Are you sure you want to delete?'
        )}
        onCancel={closeDeleteDialog}
        onConfirm={handleDelete}
        isConfirming={isDeleting}
      />
    </>
  );
};
