'use client';

import * as React from 'react';

import useTranslation from 'components/customHooks/useTranslation';
import { ScrollArea } from 'libraryV2/ui/scroll-area';
import {
  Sidebar,
  SidebarContent,
  SidebarHeader,
  SidebarRail,
} from 'libraryV2/ui/sidebar';
import { settingsNavData } from '../utils';
import { SettingsNavMenu } from './SettingsNavMenu';

export function SettingsSidebar({
  ...props
}: React.ComponentProps<typeof Sidebar>) {
  const { t } = useTranslation();
  return (
    <>
      <Sidebar
        side='left'
        variant='inset'
        collapsible='none'
        {...props}
        className='w-[260px] p-0 border-r border-[#dfe1e6] h-screen'
        style={{ fontFamily: "'Inter', sans-serif" }}
      >
        <SidebarHeader className='text-xl h-[52px] py-3 px-4 text-textPrimary text-left border-b border-[#dfe1e6] font-sans'>
          <h4 className='rtl:text-right'>{t('Settings')}</h4>
        </SidebarHeader>
        <SidebarContent className='h-screen text-textPrimary mt-2 pb-4'>
          <ScrollArea>
            <SettingsNavMenu items={settingsNavData} />
          </ScrollArea>
        </SidebarContent>
        <SidebarRail />
      </Sidebar>
    </>
  );
}
