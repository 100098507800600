import { ColumnDef } from '@tanstack/react-table';
import { IWhatsappMetricsTemplateHistory } from 'pages/reportingV2/interface';
import * as datefns from 'date-fns';
import { TemplateDeliveryStatusView } from './HistoryTableHeaders';
import { getTemplateLogStatusWithColor } from 'pages/reportingV2/utility/utils';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';

import { TemplateLogDetailsSheet } from './TemplateLogDetailsSheet';
const defaultFallbackValue = '--';

export const templateHistoryTableColumns: ColumnDef<
  IWhatsappMetricsTemplateHistory,
  unknown
>[] = [
  {
    id: 'template-name',
    accessorKey: 'template_name',
    header: 'Template name',
    cell: (info) => (
      <span className='block text-sm truncate text-textPrimary max-w-[240px]'>
        {info.getValue() + ''}
      </span>
    ),
  },
  {
    id: 'created_at',
    accessorKey: 'created_at',
    header: 'Timestamp',
    cell: (info) =>
      datefns.format(
        new Date(Number(info.getValue()) * 1000),
        'MMM dd yyyy, hh:mm a'
      ) ?? defaultFallbackValue,
  },
  {
    id: 'customer-name',
    accessorKey: 'customer_full_name',
    header: 'Customer name',
    cell: (info) => info.getValue() || defaultFallbackValue,
  },
  {
    id: 'customer-phone',
    accessorKey: 'customer_phone',
    header: 'Phone number',
    cell: (info) => info.getValue() || defaultFallbackValue,
  },
  {
    id: 'status',
    header: 'Status',
    cell: ({ row }) => {
      const statusInfo = getTemplateLogStatusWithColor(row.original);
      let failedReason =
        statusInfo.value === 'is_failed' && row.original.failed_reason;
      const failedCode = row.original.failed_report?.error?.at(0)?.code;
      if (failedCode) {
        failedReason = `${failedCode} - ${failedReason}`;
      }

      return (
        <TooltipProviderCustomised content={failedReason}>
          <TemplateDeliveryStatusView
            label={statusInfo.label}
            color={statusInfo.color}
          />
        </TooltipProviderCustomised>
      );
    },
  },
  {
    id: 'source',
    accessorKey: 'source',
    header: 'Source',
    cell: ({ row }) =>
      row.original?.source ? (
        <p className='bg-background-hover px-2 py-[1.5px] w-fit rounded-md'>
          {row.original?.source}
        </p>
      ) : (
        defaultFallbackValue
      ),
  },
  {
    id: 'view-details',
    header: '',
    cell: (info) => <TemplateLogDetailsSheet logData={info.row.original} />,
  },
];
