import { FC } from 'react';
import DeployBanner from 'pages/raven/assets/deployBanner.svg';
import { Link, useParams } from '@reach/router';
import { Separator } from 'libraryV2/ui/separator';
import { ActiveAgentModal } from '../../common/ActiveAgentModal';
import { useCreateCustomAgent } from 'pages/raven/hooks/useCreateCustomAgent';
interface DeployProps {}

export const Deploy: FC<DeployProps> = () => {
  const { projectId } = useParams();
  const { customAgentFormData } = useCreateCustomAgent();
  return (
    <div
      data-testid='raven-deploy-info-section'
      className='flex flex-col items-center gap-7 w-full h-auto flex-1 max-w-2xl mx-auto pt-16'
    >
      <div className='w-[90px] h-[90px]'>
        <img src={DeployBanner} alt='deploy banner' />
      </div>
      <div className='text-center'>
        <h1 className='text-textPrimary font-bold text-xl leading-6 '>
          Custom Agent is Ready!
        </h1>
        <p className='max-w-xl text-textSecondary text-sm font-medium leading-5 mt-1.5'>
          Assign it to tickets in the {/* @ts-ignore */}
          <Link
            to={`/projects/${projectId}/automation`}
            className='text-blue-600 px-2'
          >
            chatbot
          </Link>{' '}
          to handle queries and automate tasks efficiently. Use the tutorial to
          explore setup tips and best practices.
        </p>
      </div>
      <Separator />
      <div>
        <iframe
          width='560'
          height='315'
          className='rounded-xl'
          src='https://www.youtube.com/embed/hw9_jzAEZcA?si=9QKdmUtFVbBV5lxO'
          title='YouTube video player'
          frameBorder='0'
          referrerPolicy='strict-origin-when-cross-origin'
          allowFullScreen
        ></iframe>
      </div>
      <ActiveAgentModal customAgentId={customAgentFormData?.id} />
    </div>
  );
};
