import useTranslation from 'components/customHooks/useTranslation';
import { Button } from 'libraryV2/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'libraryV2/ui/dropdown-menu';
import { MoreHorizontal, Trash } from 'lucide-react';
import { useState } from 'react';
import useTableRowActions from '../../hooks/useTableRowActions';
import { IUserManagement } from '../../interface';
import { DeleteWarningAlert } from '../common/DeleteWarningAlert';

interface RowActionsProps {
  row: IUserManagement;
}
const RowActions: React.FC<RowActionsProps> = ({ row }) => {
  const { t, isRtlLanguage } = useTranslation();
  const [renderWarningModal, setRenderWarningModal] = useState(false);
  const { selectedProject, deleteUserFromTeam } = useTableRowActions();

  const handleSingleDelete = async () => {
    await deleteUserFromTeam(row.id);
  };
  return (
    <>
      {selectedProject?.creator.id !== row.id && (
        <DropdownMenu>
          <DropdownMenuTrigger
            asChild
            disabled={selectedProject?.creator.id === row.id}
          >
            <Button variant='ghost' className='h-8 w-8 p-0'>
              <span className='sr-only'>Open menu</span>
              <MoreHorizontal className='h-4 w-4' />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align={isRtlLanguage ? 'start' : 'end'}
            className='bg-white'
          >
            <DropdownMenuItem
              onClick={() => setRenderWarningModal(true)}
              className='gap-2 rtl:flex-row-reverse'
            >
              <Trash className='w-4 h-4' />
              {t('Delete')}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}

      {/* Warning */}
      <DeleteWarningAlert
        renderWarningModal={renderWarningModal}
        setRenderWarningModal={setRenderWarningModal}
        handleUserDeletion={handleSingleDelete}
        userIds={[row.id]}
        isSingleDelete={true}
      />
    </>
  );
};

export default RowActions;
