import MyAliceIcon from 'assets/images/aliceLogo.svg';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';
import { Avatar, AvatarFallback, AvatarImage } from 'libraryV2/ui/avatar';
import {
  IEcommerceChannelProperty,
  LiveChatDataProps,
  SocialMediaData,
} from 'pages/integration/interface';
import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  channelInfo,
  classNames,
  platformIconWithColor,
} from 'utilities/utils';
import { DefaultAvatar } from '../../assets/img/icons/svgIcons';
interface Props {
  liveChatData: LiveChatDataProps;
  agents: any[];
}

const CardPreview: React.FC<Props> = ({ liveChatData, agents }) => {
  const integratedEcommerceData: IEcommerceChannelProperty = useSelector(
    (state: any) => state?.integration?.integratedEcommerceList
  );

  const { currentPartnerInfo } = usePartner();
  const currentPartnerName =
    currentPartnerInfo?.type === 'white-label'
      ? currentPartnerInfo.shortName
      : 'MyAlice';

  const isSallaLiveChat =
    liveChatData?.is_ecommerce_plugin_channel &&
    integratedEcommerceData?.ecommerce_type === 'salla';

  const [agentIcons, updateAgentIcons] = React.useState<JSX.Element[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    let imgArr = [];
    let leftMargin = 0;
    if (agents && agents.length > 0) {
      for (let i = 0; i < agents.length; i++) {
        if (agents[i].admin.avatar) {
          imgArr.push(
            <Avatar
              key={i}
              className='w-10 h-10 rounded-full absolute border-2 border-white bg-white shadow'
              style={{ left: `${leftMargin}px` }}
            >
              <AvatarImage src={agents[i].admin.avatar} alt='avatar' />
              <AvatarFallback>
                <DefaultAvatar
                  key={100}
                  width='40'
                  height='40'
                  classNames={`rounded-full absolute left-0 bg-white shadow`}
                />
              </AvatarFallback>
            </Avatar>
          );
          leftMargin += 24;
          if (imgArr.length >= 4) break;
        }
      }
    }

    if (imgArr.length < 1) {
      imgArr.push(
        <DefaultAvatar
          key={100}
          width='40'
          height='40'
          classNames={`rounded-full absolute left-0 bg-white shadow`}
        />
      );
    }

    updateAgentIcons([...imgArr]);
  }, [agents]);

  useEffect(() => {}, [liveChatData.livechat_data.social_chat_options_list]);

  const renderMyaliceBranding = () => {
    if (!liveChatData?.livechat_data.should_show_myalice_branding) {
      return null;
    }
    if (isSallaLiveChat) {
      return (
        <div className='flex items-center gap-2'>
          <div className='border border-gray-400 p-0.5 rounded-md'>
            <img
              src={MyAliceIcon}
              className='w-4 h-4 grayscale'
              alt='MyAlice Logo'
            />
          </div>
          <div>
            <p className='font-medium text-gray-500'>
              {t('We Support with ' + currentPartnerName)}
            </p>
          </div>
        </div>
      );
    }
    return (
      <span className={'text-xss leading-4 text-gray-500 my-1 flex'}>
        <span className='font-semibold text-[#0078CF]'>
          {currentPartnerName}
        </span>
        {'-'}
        {t('Conversational Commerce')}
      </span>
    );
  };

  return (
    <>
      <div className='w-full px-5 pt-8 m-auto bg-white border border-gray-100 shadow-md rounded-2xl '>
        <div className='mb-5 rounded-t-lg'>
          <span className='flex font-sans text-xl font-bold leading-7 text-gray-800'>
            {liveChatData.livechat_data.header_title}
          </span>
          <div className='mt-[9px] text-base flex rtl:text-right font-light leading-6 whitespace-pre-line text-gray-800 font-sans'>
            {liveChatData.livechat_data.isOnline
              ? liveChatData.livechat_data.welcome_prompt_online_text
              : liveChatData.livechat_data.welcome_prompt_offline_text}
          </div>
        </div>

        <div
          className={classNames(
            'relative h-10 flex items-center',
            liveChatData.livechat_data.agent_avatar_display_options === 'all'
              ? 'visible'
              : 'hidden'
          )}
        >
          {agentIcons.map((element) => element)}
        </div>

        <div className='mt-6 bg-white rounded-b-lg'>
          {liveChatData.livechat_data.social_chat_options_list
            ?.filter((item) => item.is_enabled)
            .map((data: SocialMediaData, index: Number) => {
              return (
                <div className='p-0 m-0 group' key={data.id}>
                  <button
                    type='button'
                    style={{
                      borderColor:
                        index === 0
                          ? `${liveChatData.livechat_data.brand_color_regular}`
                          : '#D1D5DB',
                      backgroundColor:
                        index === 0
                          ? `${liveChatData.livechat_data.brand_color_regular}`
                          : `#ffffff`,
                    }}
                    className={classNames(
                      'inline-flex w-full relative justify-start items-center text-center py-[9px] px-[17px] border rounded-md mb-4',
                      index === 0
                        ? `group-hover:bg-[${liveChatData.livechat_data.brand_color_regular}]`
                        : `group-hover:bg-[${
                            channelInfo(data.type).color
                          }] group-hover:border-[${
                            channelInfo(data.type).color
                          }]`
                    )}
                  >
                    {platformIconWithColor(
                      data.type,
                      index === 0
                        ? liveChatData.livechat_data.font_color_regular
                        : channelInfo(data.type).color,
                      index === 0
                        ? liveChatData.livechat_data.brand_color_regular
                        : '#ffffff',
                      classNames('ltr:mr-2 rtl:ml-2 w-4 h-4', '')
                    )}

                    <span
                      className='text-base flex font-medium leading-6 group-hover:text-white'
                      style={{
                        color:
                          index === 0
                            ? liveChatData.livechat_data.font_color_regular
                            : '#374151',
                      }}
                    >
                      {data.label_text}
                    </span>
                  </button>
                </div>
              );
            })}
        </div>
        <div className='flex items-center justify-center w-full mb-2'>
          {renderMyaliceBranding()}
        </div>
      </div>
    </>
  );
};

const mapState = (state: any) => ({
  agents: state.dashboard.agentAccess,
});

export default connect(mapState)(CardPreview);
