import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkPermission } from 'utilities/utils';

const useUserManagement = () => {
  const dispatch = useDispatch();

  const selectedProject = useSelector(
    (state: any) => state?.dashboard?.selectedProject
  );
  const { email } = useSelector((state: any) => state.auth);
  const { subscriptionDetails } = useSelector((state: any) => state.newBilling);
  const userManagementStateData = useSelector(
    (state: any) => state.settingsUserManagementConfig
  );
  const { agentRoleList, agentAccessList } = userManagementStateData;
  const dashboardStateData = useSelector((state: any) => state.dashboard);
  const { list: permissionList } = dashboardStateData.permission;
  const loadingState = useSelector(
    (state: any) => state.loading.effects.settingsUserManagementConfig
  );
  const { fetchAgentroleList, changeUserRole, deleteMultipleUsersFromTeam } =
    loadingState;

  const clearUserManagementData = () => {
    dispatch.settingsUserManagementConfig.clearState();
  };

  const getAgentRoleList = async () => {
    const teamId = selectedProject?.id;
    dispatch.settingsUserManagementConfig.fetchAgentroleList(teamId);
  };

  const getAgentAccessList = async () => {
    const teamId = selectedProject?.id;
    dispatch.settingsUserManagementConfig.fetchAgentAccessList(teamId);
  };

  const getSubscriptionDetails = async () => {
    const teamId = selectedProject?.id;
    dispatch.newBilling.fetchSubscriptionDetails(teamId);
  };

  const hasWriteAccess = useMemo(
    () => checkPermission(permissionList, 'write:settings_user_management'),
    [permissionList]
  );

  const getTotalTeamMemberCount = () => {
    return agentAccessList?.length || 0;
  };

  const getRemainningTeamMemberCount = () => {
    return (
      subscriptionDetails?.project_billing_info?.max_team_members -
      getTotalTeamMemberCount()
    );
  }
  return {
    selectedProject,
    userEmail: email,
    getAgentRoleList,
    agentRoleList,
    getAgentAccessList,
    agentAccessList,
    subscriptionDetails,
    getSubscriptionDetails,
    clearUserManagementData,
    isFetchAgentroleListLoading: fetchAgentroleList,
    isChangeUserRoleLoading: changeUserRole,
    isDeletingUsers: deleteMultipleUsersFromTeam,
    hasWriteAccess,
    getTotalTeamMemberCount,
    getRemainningTeamMemberCount
  };
};

export default useUserManagement;
