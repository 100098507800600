import Editor from '../editor';
import LeftSideBar from '../leftSideBar';
import Navbar from '../navbar';

const Layout = () => {
  return (
    <>
      <Navbar />
      <div className='mt-[52px] min-h-screen grid grid-rows-3 grid-flow-col gap-4 ltr:ml-px rtl:mr-px bg-white'>
        <LeftSideBar />
        <Editor />
      </div>
    </>
  );
};

export default Layout;
